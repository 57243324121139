import React, { useEffect, useMemo, useState } from 'react';
import { DeleteSharp } from '@material-ui/icons';
import ConfirmModal from 'Common/components/ConfirmModal';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import deleteGroupInstructionAssignment from 'Education/api/instruction-assignment/group/deleteGroupInstructionAssignment';
import axios from 'axios';

const DeleteMessage = styled.div``;

const DeleteLabel = styled.div`
    margin-bottom: 10px;
    color: ${colors.ORANGE};
`;

const GroupInstructionAssignmentDeleteModal = ({
    instructionAssignment,
    onCancel,
    onSuccess,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsDeleting(true);

            await deleteGroupInstructionAssignment(
                instructionAssignment.id,
                source.token,
            );

            onSuccess();

            setIsDeleting(false);

            notify('Der Präsenzunterweisung wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Der Präsenzunterweisung konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <ConfirmModal
            isOpen={instructionAssignment !== null}
            isConfirmLoading={isDeleting}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            confirmText="Löschen"
            confirmIcon={DeleteSharp}
        >
            <DeleteMessage>
                <DeleteLabel>Präsenzunterweisung löschen!</DeleteLabel>
                Wollen Sie der Präsenzunterweisung {
                    instructionAssignment?.name
                }{' '}
                wirklich Löschen?
            </DeleteMessage>
        </ConfirmModal>
    );
};

GroupInstructionAssignmentDeleteModal.defaultProps = {
    instructionAssignment: null,
};

GroupInstructionAssignmentDeleteModal.propTypes = {
    instructionAssignment: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentDeleteModal;
