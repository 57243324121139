import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import getEmployeeTasksProgress from 'Employee/api/getEmployeeTasksProgress';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import getCurrentEmployeeTasksProgress from 'Employee/api/getCurrentEmployeeTasksProgress';
import PropTypes from 'prop-types';
import OverallProgress from 'Employee/components/employee-view/OverallProgress';
import Loader from 'Common/components/Loader';
import ProgressBar from 'Employee/components/employee-view/ProgressBar';
import {
    ConstructionSiteIcon,
    InstructionIcon,
    MeasuresIcon,
} from 'Common/components/icons';
import modules from 'Common/constants/modules';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div`
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 460px) and (max-width: 939px) {
        flex-direction: row;
    }
`;

const ProgressBars = styled.div`
    display: block;
    width: 100%;
    margin-top: 20px;

    > * {
        margin-top: 10px;

        &:hover,
        &:focus {
            > span > span {
                background: ${colors.DARKEST_GRAY};
            }
        }
    }

    @media screen and (min-width: 460px) and (max-width: 939px) {
        margin-top: -10px;
        margin-left: -10px;
    }

    @media screen and (min-width: 780px) and (max-width: 939px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ProgressBarButton = styled.button`
    display: block;
    width: 100%;
    background: none;
    border: 0 none;
    outline: 0 none;
    padding: 0;
    cursor: pointer;

    @media screen and (min-width: 780px) and (max-width: 939px) {
        flex: 1 0 auto;
        width: calc(50% - 10px);
        margin-left: 10px;
    }

    @media screen and (min-width: 940px) {
        margin-left: 0;
    }
`;

const ProgressBarLink = styled(ProgressBarButton).attrs({
    as: 'a',
})`
    text-decoration: none;
`;

const EmployeeViewStats = ({ employee, selectedModule, onModuleChange }) => {
    const role = useSelector(state => state.user.role);

    const tasksProgressRequest = useCallback(
        cancelToken => {
            if (role === roles.EMPLOYEE) {
                return getCurrentEmployeeTasksProgress(cancelToken);
            } else {
                return getEmployeeTasksProgress(employee.id, cancelToken);
            }
        },
        [employee.id, role],
    );

    const {
        data: stats,
        loadData: loadStats,
        isLoading,
        hasError,
    } = useAxiosRequest(tasksProgressRequest, {
        instructions: {
            all: 0,
            pending: 0,
            completed: 0,
        },
        measures: {
            all: 0,
            pending: 0,
            completed: 0,
        },
        supplementaryRiskAssessments: {
            all: 0,
            pending: 0,
            completed: 0,
        },
    });

    const allStats = {
        all:
            stats.instructions.all +
            stats.measures.all +
            stats.supplementaryRiskAssessments.all,
        pending:
            stats.instructions.pending +
            stats.measures.pending +
            stats.supplementaryRiskAssessments.pending,
        completed:
            stats.instructions.completed +
            stats.measures.completed +
            stats.supplementaryRiskAssessments.completed,
    };

    return (
        <Wrapper>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadStats} />
            ) : (
                <>
                    <OverallProgress stats={allStats} />
                    <ProgressBars>
                        <ProgressBarButton
                            onClick={() => onModuleChange(modules.INSTRUCTIONS)}
                        >
                            <ProgressBar
                                title="Unterweisungen"
                                icon={InstructionIcon}
                                stats={stats.instructions}
                                isActive={
                                    selectedModule === modules.INSTRUCTIONS
                                }
                            />
                        </ProgressBarButton>
                        <ProgressBarButton
                            onClick={() => onModuleChange(modules.MEASURES)}
                        >
                            <ProgressBar
                                title="Maßnahmen"
                                icon={MeasuresIcon}
                                stats={stats.measures}
                                isActive={selectedModule === modules.MEASURES}
                            />
                        </ProgressBarButton>
                        <ProgressBarLink href="/backend/m/exec/call">
                            <ProgressBar
                                title="Ergänzende GF"
                                icon={ConstructionSiteIcon}
                                stats={stats.supplementaryRiskAssessments}
                                isActive={
                                    selectedModule ===
                                    modules.CONSTRUCTION_SITES
                                }
                            />
                        </ProgressBarLink>
                    </ProgressBars>
                </>
            )}
        </Wrapper>
    );
};

EmployeeViewStats.defaultProps = {
    employee: null,
};

EmployeeViewStats.propTypes = {
    employee: PropTypes.object,
    selectedModule: PropTypes.string.isRequired,
    onModuleChange: PropTypes.func.isRequired,
};

export default EmployeeViewStats;
