import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { history, persistor, store } from 'Application/store';
import { HelmetProvider } from 'react-helmet-async';
import { Normalize } from 'styled-normalize';
import App from 'Application/components/App';
import ScrollToTop from 'Application/components/ScrollToTop';

const Root = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <HelmetProvider>
                    <Normalize />
                    <ScrollToTop />
                    <App />
                </HelmetProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>
);

export default Root;
