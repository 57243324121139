import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import {
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
} from 'Common/components/table';
import Button from 'Common/components/Button';
import loadInstructionAreaOptions from 'Education/utils/loadInstructionAreaOptions';
import Input from 'Common/components/form/fields/Input';
import Select from 'Common/components/form/fields/Select';
import AsyncSelect from 'Common/components/form/fields/AsyncSelect';

const TableFormField = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 40px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const SelectedLibrariesForImportTable = ({
    libraryInstructions,
    libraryInstructionValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    formFieldPrefix,
    onOpenSlidesButtonClick,
}) => (
    <Table>
        <TableHead>
            <TableHeadRow>
                <TableHeadCell>Unterweisung</TableHeadCell>
                <TableHeadCell>Referenz / Folien</TableHeadCell>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {libraryInstructionValues.map((libraryInstructionValue, index) => {
                const prefix = `${formFieldPrefix}[${index}]`;

                const libraryInstruction = libraryInstructions.find(
                    libraryInstruction =>
                        libraryInstruction.id === libraryInstructionValue.id,
                );

                const allCount = libraryInstruction.slidesCount;

                const selectedCount =
                    libraryInstructionValue.slideIds?.length ?? allCount;

                return (
                    <TableBodyRow key={libraryInstruction.id}>
                        <TableBodyCell>
                            <TableFormField>
                                <Input
                                    label="Name"
                                    id={`${prefix}.name`}
                                    name={`${prefix}.name`}
                                    error={get(errors, `${prefix}.name`)}
                                    touched={get(
                                        touched,
                                        `${prefix}.name`,
                                        false,
                                    )}
                                    value={libraryInstructionValue.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </TableFormField>
                            <TableFormField>
                                <AsyncSelect
                                    label="Bereiche"
                                    id={`${prefix}.areas`}
                                    name={`${prefix}.areas`}
                                    error={get(errors, `${prefix}.areas`)}
                                    touched={get(
                                        touched,
                                        `${prefix}.areas`,
                                        false,
                                    )}
                                    value={libraryInstructionValue.areas}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    loadOptions={loadInstructionAreaOptions}
                                    menuPosition="fixed"
                                    isMulti
                                    isSearchable
                                    isClearable
                                />
                            </TableFormField>
                        </TableBodyCell>
                        <TableBodyCell
                            style={{
                                minWidth: '220px',
                                verticalAlign: 'top',
                            }}
                        >
                            <TableFormField>
                                <Button
                                    type="button"
                                    onClick={() =>
                                        onOpenSlidesButtonClick(
                                            libraryInstruction.id,
                                        )
                                    }
                                >
                                    {`Folienliste (${selectedCount} / ${allCount})`}
                                </Button>
                            </TableFormField>

                            <TableFormField>
                                <Select
                                    id={`${prefix}.keepReference`}
                                    name={`${prefix}.keepReference`}
                                    error={get(
                                        errors,
                                        `${prefix}.keepReference`,
                                    )}
                                    touched={get(
                                        touched,
                                        `${prefix}.keepReference`,
                                        false,
                                    )}
                                    value={
                                        libraryInstructionValue.keepReference
                                    }
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    options={[
                                        {
                                            value: true,
                                            label: 'Mit Referenz',
                                        },
                                        {
                                            value: true,
                                            label: 'Ohne Referenz',
                                        },
                                    ]}
                                    isSearchable={false}
                                />
                            </TableFormField>
                        </TableBodyCell>
                    </TableBodyRow>
                );
            })}
        </TableBody>
    </Table>
);

SelectedLibrariesForImportTable.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    libraryInstructionValues: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    formFieldPrefix: PropTypes.string.isRequired,
    onOpenSlidesButtonClick: PropTypes.func.isRequired,
};

export default SelectedLibrariesForImportTable;
