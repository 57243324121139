import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import { InputAdornment } from '@material-ui/core';
import StyledTextField from 'Common/components/form/fields/StyledTextField';

const Input = memo(
    ({
        label,
        icon: Icon,
        error,
        touched,
        value: rawValue,
        readOnly,
        ...props
    }) => {
        const hasError = !!error && touched;
        const hasLabel = label !== null;
        const hasIcon = !!Icon;

        let value = rawValue;

        if (props.type === 'number') {
            value = value !== '' ? Number(value).toString() : '';
        }

        const endAdornment = hasIcon ? (
            <InputAdornment position="end">
                <Icon fontSize="small" />
            </InputAdornment>
        ) : (
            undefined
        );

        return (
            <>
                <StyledTextField
                    variant="outlined"
                    error={hasError}
                    label={hasLabel ? label : undefined}
                    value={value}
                    InputProps={{
                        endAdornment,
                        readOnly,
                    }}
                    {...props}
                />

                {hasError && <ErrorMessage message={error} />}
            </>
        );
    },
);

Input.defaultProps = {
    label: null,
    icon: null,
    error: null,
    touched: false,
    readOnly: false,
};

Input.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.elementType,
    error: PropTypes.string,
    touched: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.any.isRequired,
};

export default Input;
