import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSortBy, useTable } from 'react-table';
import moment from 'moment-timezone';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import {
    DefaultHiddenColumnsTableBodyRow,
    DefaultTableBodyRow,
    DefaultTableHead,
    Table,
    TableBody,
    TableRowActions,
} from 'Common/components/table';
import { DownloadIcon } from 'Common/components/icons';
import ExternalLink from 'Common/components/ExternalLink';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';

const StyledTableBodyRow = styled(DefaultTableBodyRow)`
    &:hover {
        background: ${colors.ALT_GRAY};
    }
`;

const DocumentsTable = ({ documents }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Dokumentname',
                accessor: 'name',
                customCellStyles: {
                    minWidth: 150,
                    wordBreak: 'break-all',
                },
            },
            {
                Header: 'Archiviert am',
                accessor: 'archivedAt',
            },
            {
                Header: 'Download',
                accessor: 'download',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'archivedAt',
                desc: true,
            },
        ],
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const data = useMemo(
        () =>
            documents.map(document => ({
                name: document.name,
                archivedAt: moment(document.archivedAt.date).format(),
                download: (
                    <TableRowActions>
                        <ExternalLink href={document.url}>
                            <DownloadIcon size="sm" />
                        </ExternalLink>
                    </TableRowActions>
                ),
            })),
        [documents],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            disableSortRemove: true,
            disableMultiSort: true,
            hideColumnsPriority: ['archivedAt', 'actions'],
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <DefaultTableHead headerGroups={headerGroups} />
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    const hasHiddenColumns =
                        !!hiddenColumns && hiddenColumns.length > 0;

                    return (
                        <Fragment key={`${row.getRowProps().key}_fragment`}>
                            <StyledTableBodyRow
                                row={row}
                                hasHiddenColumns={hasHiddenColumns}
                            />
                            {hasHiddenColumns && (
                                <DefaultHiddenColumnsTableBodyRow
                                    row={row}
                                    hiddenColumns={hiddenColumns}
                                    visibleColumns={visibleColumns}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

DocumentsTable.propTypes = {
    documents: PropTypes.array.isRequired,
};

export default DocumentsTable;
