import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import Checkbox from 'Common/components/form/fields/Checkbox';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 350px;
    max-height: calc(100vh - 151px);
    background: ${colors.DARK_GRAY};
    padding: 15px;
`;

const SelectedInstructionsCounter = styled.div`
    margin-bottom: 10px;
    > span {
        display: inline-block;
        margin-right: 4px;
    }
`;

const HighlightedCount = styled.span`
    color: ${colors.ORANGE};
`;

const ItemsList = styled.div`
    flex: 1;
    overflow-y: scroll;
    background: ${colors.DARKEST_GRAY};
    padding: 10px;
    border-radius: 4px;
`;

const LibraryInstructionItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    ${ifProp(
        'isDisabled',
        css`
            user-select: none;
            opacity: 0.2;
        `,
    )}
`;

const NumberOfInstructionReferences = styled.div`
    color: ${colors.ORANGE};
    margin-left: 10px;
    cursor: default;
`;

const LibraryInstructionsList = ({
    libraryInstructions,
    selectedIds,
    onCheckboxChange,
    searchTerm,
}) => {
    let filteredLibraryInstructions = [...libraryInstructions];

    if (searchTerm) {
        filteredLibraryInstructions = filteredLibraryInstructions.filter(
            libraryInstruction =>
                libraryInstruction.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
        );
    }

    return (
        <Wrapper>
            <SelectedInstructionsCounter>
                <span>Bibliothek</span>
                <HighlightedCount>
                    {`(${selectedIds.length} / ${libraryInstructions.length} ausgewählt)`}
                </HighlightedCount>
            </SelectedInstructionsCounter>

            <ItemsList>
                {filteredLibraryInstructions.map(libraryInstruction => (
                    <LibraryInstructionItem
                        key={libraryInstruction.id}
                        isDisabled={
                            libraryInstruction.numberOfInstructionReferences > 0
                        }
                        title={
                            libraryInstruction.numberOfInstructionReferences > 0
                                ? 'Diese Unterweisung wurde bereits importiert'
                                : undefined
                        }
                    >
                        <Checkbox
                            label={libraryInstruction.name}
                            id={`libraryInstruction_${libraryInstruction.id}`}
                            name="libraryInstruction"
                            disabled={
                                libraryInstruction.numberOfInstructionReferences >
                                0
                            }
                            checked={selectedIds.includes(
                                libraryInstruction.id,
                            )}
                            onChange={e =>
                                onCheckboxChange(
                                    libraryInstruction.id,
                                    e.target.checked,
                                )
                            }
                        />
                        {libraryInstruction.numberOfInstructionReferences >
                            0 && (
                            <NumberOfInstructionReferences>
                                *
                            </NumberOfInstructionReferences>
                        )}
                    </LibraryInstructionItem>
                ))}
            </ItemsList>
        </Wrapper>
    );
};

LibraryInstructionsList.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default LibraryInstructionsList;
