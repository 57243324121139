import React from 'react';
import PropTypes from 'prop-types';
import colors from 'Common/constants/colors';
import PieChart from 'Common/components/PieChart';

const OverallProgress = ({ stats }) => {
    const percentage =
        stats.all > 0 ? Math.floor((stats.completed / stats.all) * 100) : 0;

    return (
        <PieChart
            data={[
                {
                    id: 'completed',
                    label: 'Abgeschlossen',
                    value: stats.completed,
                    backgroundColor: colors.ORANGE,
                },
                {
                    id: 'pending',
                    label: 'Ausstehend',
                    value: stats.pending,
                    backgroundColor: colors.ALT_DARKEST_GRAY,
                },
                {
                    id: 'others',
                    label: 'Andere',
                    value: stats.all - stats.completed - stats.pending,
                    backgroundColor: colors.ALT_DARKEST_GRAY,
                },
            ]}
            percentage={percentage}
            text="Aller Aufgaben"
            size={120}
        />
    );
};

OverallProgress.propTypes = {
    stats: PropTypes.object.isRequired,
};

export default OverallProgress;
