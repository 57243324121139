import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    DefaultHiddenColumnProperties,
    DefaultTableBodyRow,
    DefaultTableHead,
    HiddenColumnsTableBodyCell,
    HiddenColumnsTableBodyRow,
    Table,
    TableBody,
    TableRowActions,
} from 'Common/components/table';
import PropTypes from 'prop-types';
import colors from 'Common/constants/colors';
import styled from 'styled-components/macro';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import HamburgerButton from 'Common/components/HamburgerButton';
import { useOnClickOutside } from 'crooks';
import ButtonLink from 'Common/components/ButtonLink';
import { CancelSharp, CheckCircleSharp, PersonSharp } from '@material-ui/icons';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import ExternalLink from 'Common/components/ExternalLink';
import {
    DriverLicenseControlIcon,
    InstructionIcon,
} from 'Common/components/icons';
import activateEmployee from 'Employee/api/activateEmployee';
import deactivateEmployee from 'Employee/api/deactivateEmployee';
import Loader from 'Common/components/Loader';
import { useClickableTableRow } from 'Common/hooks/useClickableTableRow';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Button from 'Common/components/Button';

const ActionsMenu = styled.div`
    position: absolute;
    z-index: 1;
    top: -5px;
    right: -5px;
    width: 260px;
    padding: 20px 45px 20px 20px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        line-height: 1.5;

        > i,
        > svg {
            font-size: 18px !important;
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const LoaderIcon = styled(Loader)`
    margin-right: 10px;
`;

const ModulesWrapper = styled.div`
    display: flex;

    i,
    svg {
        font-size: 28px !important;
        width: 28px !important;
        height: 28px !important;
    }
`;

const ModuleIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 10px;
    background: ${colors.ALT_DARKEST_GRAY};
    border-radius: 50%;
    color: ${colors.ORANGE};

    &:last-child {
        margin-right: 0;
    }
`;

const StyledTableBodyRow = styled(DefaultTableBodyRow)`
    &:hover {
        background: ${colors.ALT_GRAY};
    }
`;

const ActionButton = styled(Button)`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;
    }
`;

const EmployeeListTable = ({ employees, onActivate, onDeactivate }) => {
    const dispatch = useDispatch();

    const [openedActionsMenuId, setOpenedActionsMenuId] = useState(null);
    const [isActivating, setIsActivating] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);

    const handleClickOutsideActions = () => {
        setOpenedActionsMenuId(null);
    };

    const actionsRef = useOnClickOutside(
        handleClickOutsideActions,
        openedActionsMenuId === null,
    );

    const handleHamburgerButtonClick = employeeId => {
        setOpenedActionsMenuId(prevOpenedActionsMenuId => {
            if (prevOpenedActionsMenuId === employeeId) {
                return null;
            }

            return employeeId;
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Mitarbeiter',
                accessor: 'name',
                customCellStyles: {
                    minWidth: 220,
                },
            },
            {
                Header: 'Fortschritt',
                accessor: 'progress',
                customBodyCellStyles: {
                    color: colors.ORANGE,
                },
            },
            {
                Header: 'Aufgaben',
                accessor: 'modules',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleActivate = useCallback(
        employeeId => {
            (async () => {
                setIsActivating(true);

                try {
                    await activateEmployee(employeeId, source.token);

                    await onActivate(employeeId);

                    setIsActivating(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        setIsActivating(false);

                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setOpenedActionsMenuId(null);
            })();
        },
        [onActivate, source.token],
    );

    const handleDeactivate = useCallback(
        employeeId => {
            (async () => {
                setIsDeactivating(true);

                try {
                    await deactivateEmployee(employeeId, source.token);

                    await onDeactivate(employeeId);

                    setIsDeactivating(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        setIsDeactivating(false);

                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setOpenedActionsMenuId(null);
            })();
        },
        [onDeactivate, source.token],
    );

    const data = useMemo(
        () =>
            employees.map(employee => {
                let areActionsOpened = openedActionsMenuId === employee.id;

                return {
                    name: `${employee.lastName}, ${employee.firstName}`,
                    progress: `${employee.tasksProgressPercentage} %`,
                    modules: (
                        <ModulesWrapper>
                            {employee.availableModules.map(module => {
                                let Icon;
                                let iconText;

                                // eslint-disable-next-line default-case
                                switch (module.value) {
                                    case 'INSTRUCTION':
                                        Icon = InstructionIcon;
                                        iconText = 'Unterweisungen';
                                        break;

                                    case 'DRIVER_LICENSE':
                                        Icon = DriverLicenseControlIcon;
                                        iconText = 'Führerscheinkontrolle';
                                        break;
                                }

                                return (
                                    <ModuleIconWrapper
                                        key={module.value}
                                        title={iconText}
                                    >
                                        <Icon />
                                    </ModuleIconWrapper>
                                );
                            })}
                        </ModulesWrapper>
                    ),
                    actions: (
                        <TableRowActions ref={actionsRef}>
                            <HamburgerButton
                                isOpened={areActionsOpened}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleHamburgerButtonClick(employee.id);
                                }}
                            />

                            {areActionsOpened && (
                                <ActionsMenu>
                                    {employee.isEnabled && (
                                        <ButtonLink
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleDeactivate(employee.id);
                                            }}
                                            disabled={isDeactivating}
                                        >
                                            {isDeactivating ? (
                                                <LoaderIcon size={16} />
                                            ) : (
                                                <CancelSharp />
                                            )}
                                            Inaktivieren
                                        </ButtonLink>
                                    )}
                                    {!employee.isEnabled && (
                                        <ButtonLink
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleActivate(employee.id);
                                            }}
                                            disabled={isActivating}
                                        >
                                            {isActivating ? (
                                                <LoaderIcon size={16} />
                                            ) : (
                                                <CheckCircleSharp />
                                            )}
                                            Aktivieren
                                        </ButtonLink>
                                    )}
                                    {employee.isEnabled && (
                                        <ExternalLink
                                            href={`/backend/m/exec/call?emp=${employee.id}&openEdit=1&fromList=1`}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <PersonSharp />
                                            Mitarbeiterprofil
                                        </ExternalLink>
                                    )}
                                </ActionsMenu>
                            )}
                        </TableRowActions>
                    ),
                    targetLink: `/employee/${employee.id}`,
                };
            }),
        [
            actionsRef,
            employees,
            handleActivate,
            handleDeactivate,
            isActivating,
            isDeactivating,
            openedActionsMenuId,
        ],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
        onRowClick,
    } = useTable(
        {
            columns,
            data,
            onRowClick: row => {
                dispatch(push(row.original.targetLink));
            },
            hideColumnsPriority: ['modules', 'progress', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useClickableTableRow,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    const hasHiddenColumns =
                        !!hiddenColumns && hiddenColumns.length > 0;

                    return (
                        <Fragment key={`${row.getRowProps().key}_fragment`}>
                            <StyledTableBodyRow
                                row={row}
                                hasHiddenColumns={hasHiddenColumns}
                            />
                            {hasHiddenColumns && (
                                <HiddenColumnsTableBodyRow>
                                    <HiddenColumnsTableBodyCell
                                        colSpan={visibleColumns.length}
                                    >
                                        <ActionButton
                                            theme="dark"
                                            onClick={() => onRowClick(row)}
                                        >
                                            Detailansicht
                                        </ActionButton>
                                        <DefaultHiddenColumnProperties
                                            row={row}
                                            hiddenColumns={hiddenColumns}
                                        />
                                    </HiddenColumnsTableBodyCell>
                                </HiddenColumnsTableBodyRow>
                            )}
                        </Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

EmployeeListTable.propTypes = {
    employees: PropTypes.array.isRequired,
    onActivate: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
};

export default EmployeeListTable;
