import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import {
    ConstructionSiteIcon,
    EmployeesIcon,
    InstructionIcon,
    MeasuresIcon,
    PdfDocumentIcon,
} from 'Common/components/icons';
import { useOnClickOutside } from 'crooks';
import Link from 'Common/components/Link';
import modules from 'Common/constants/modules';
import {
    AppsSharp,
    ArchiveSharp,
    ExitToAppSharp,
    PeopleOutlineSharp,
    PersonOutlineSharp,
    SettingsSharp,
} from '@material-ui/icons';
import ButtonLink from 'Common/components/ButtonLink';
import Loader from 'Common/components/Loader';
import ExternalLink from 'Common/components/ExternalLink';
import { Link as RouterLink } from 'react-router-dom';
import logout from 'User/services/logout';
import axios from 'axios';

const Wrapper = styled.header`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 10px;
    background: ${colors.ALT_DARKEST_GRAY};

    ${ifProp(
        'isReact',
        css`
            position: sticky;
            top: 0;
            min-height: 81px;
            z-index: 2000;
        `,
    )};

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const Inner = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
`;

const MenuButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: 0 none;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 18px;
    cursor: pointer;

    svg {
        width: 31px;
        font-size: 31px;
        color: #e2e2e2;

        &:first-child {
            margin-right: 8px;
        }

        &:last-child {
            margin-left: 8px;
        }
    }

    &:hover,
    &:focus {
        color: ${colors.WHITE};
        outline: 0 none;
    }

    span {
        display: none;
        user-select: none;
        line-height: 31px;
    }

    @media screen and (min-width: 740px) {
        span {
            display: inline;
            user-select: none;
        }
    }

    ${ifProp(
        'isOpened',
        css`
            z-index: 2;
        `,
    )}
`;

const MenuActions = styled.div`
    position: absolute;
    top: -5px;
    width: 100%;
    padding: 65px 45px 20px 20px;
    background-color: ${colors.DARKEST_GRAY};
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    text-align: left;
    font-size: 15px;
    z-index: 1;
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        line-height: 1.5;

        > i,
        > svg {
            font-size: 18px !important;
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const MainMenu = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

const MainMenuButton = styled(MenuButton)``;

const MainMenuActions = styled(MenuActions)`
    left: 0;

    @media screen and (min-width: 500px) {
        max-width: 350px;
    }
`;

const ProfileMenu = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const ProfileMenuButton = styled(MenuButton)``;

const ProfileMenuActions = styled(MenuActions)`
    right: 0;

    @media screen and (min-width: 500px) {
        max-width: 218px;
    }
`;

const LogoutIcon = styled(ExitToAppSharp)`
    font-size: 16px !important;
`;

const LoaderIcon = styled(Loader)`
    margin-right: 10px;
`;

const Logo = styled.div`
    position: relative;
    top: -8px;
    flex: 0 0 128px;

    ${ifProp(
        'isHiddenOnMobile',
        css`
            opacity: 0;
        `,
    )};

    @media screen and (min-width: 500px) {
        opacity: 1;
    }
`;

const LogoLink = styled(RouterLink)`
    display: block;
    width: 100%;
`;

const LogoExternalLink = styled.a`
    display: block;
    width: 100%;
`;

const LogoImage = styled.img`
    display: block;
    width: 100%;
`;

const moduleUnavailableMessage =
    'Freischaltung durch Produkterweiterung möglich, bitte kontaktieren Sie hierfür die Amedic GmbH & CO.KG';

const Header = ({ isManager, availableModules, isReact }) => {
    const [isMainMenuOpened, setIsMainMenuOpened] = useState(false);
    const [isProfileMenuOpened, setIsProfileMenuOpened] = useState(false);

    const handleClickOutsideActions = () => {
        setIsMainMenuOpened(false);
        setIsProfileMenuOpened(false);
    };

    const mainMenuButtonRef = useOnClickOutside(
        handleClickOutsideActions,
        !isMainMenuOpened,
    );

    const profileMenuButtonRef = useOnClickOutside(
        handleClickOutsideActions,
        !isProfileMenuOpened,
    );

    const handleMainMenuButtonClick = e => {
        e.stopPropagation();

        setIsMainMenuOpened(prevIsMainMenuOpened => !prevIsMainMenuOpened);
        setIsProfileMenuOpened(false);
    };

    const handleProfileMenuButtonClick = e => {
        e.stopPropagation();

        setIsProfileMenuOpened(
            prevIsProfileMenuOpened => !prevIsProfileMenuOpened,
        );
        setIsMainMenuOpened(false);
    };

    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleLogoutButtonClick = async () => {
        setIsLoggingOut(true);

        try {
            await logout(source.token);

            if (!isReact) {
                window.location.href = '/';
            }
        } catch (error) {
            if (!isReact) {
                window.location.href = '/';
                return;
            }

            if (!axios.isCancel(error)) {
                setIsLoggingOut(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const isModuleAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const ReactLink = isReact ? Link : ExternalLink;
    const reactLinkProp = isReact ? 'to' : 'href';

    return (
        <Wrapper isReact={isReact}>
            <Inner>
                <MainMenu>
                    <MainMenuButton
                        ref={mainMenuButtonRef}
                        type="button"
                        isOpened={isMainMenuOpened}
                        onClick={handleMainMenuButtonClick}
                    >
                        <AppsSharp />
                        <span>Amedic-Manager</span>
                    </MainMenuButton>
                </MainMenu>

                <Logo
                    isHiddenOnMobile={isMainMenuOpened || isProfileMenuOpened}
                >
                    {isReact ? (
                        <LogoLink to="/dashboard">
                            <LogoImage src="/amedic.svg" alt="Amedic-Manager" />
                        </LogoLink>
                    ) : (
                        <LogoExternalLink href="/dashboard">
                            <LogoImage src="/amedic.svg" alt="Amedic-Manager" />
                        </LogoExternalLink>
                    )}
                </Logo>
                <ProfileMenu>
                    <ProfileMenuButton
                        ref={profileMenuButtonRef}
                        type="button"
                        isOpened={isProfileMenuOpened}
                        onClick={handleProfileMenuButtonClick}
                    >
                        <span>myAmedic</span>
                        <PersonOutlineSharp />
                    </ProfileMenuButton>
                </ProfileMenu>

                {isMainMenuOpened && (
                    <MainMenuActions>
                        {isManager ? (
                            <>
                                <ReactLink
                                    {...{
                                        [reactLinkProp]: '/employees',
                                    }}
                                >
                                    <EmployeesIcon size="xs" />
                                    Mitarbeiter
                                </ReactLink>
                                <ReactLink
                                    {...{
                                        [reactLinkProp]: '/education',
                                    }}
                                    isDisabled={
                                        !isModuleAvailable(modules.INSTRUCTIONS)
                                    }
                                    title={
                                        !isModuleAvailable(modules.INSTRUCTIONS)
                                            ? moduleUnavailableMessage
                                            : undefined
                                    }
                                >
                                    <InstructionIcon size="xs" />
                                    Unterweisungen
                                </ReactLink>
                                <ReactLink
                                    {...{
                                        [reactLinkProp]: '/measures',
                                    }}
                                    isDisabled={
                                        !isModuleAvailable(modules.MEASURES)
                                    }
                                    title={
                                        !isModuleAvailable(modules.MEASURES)
                                            ? moduleUnavailableMessage
                                            : undefined
                                    }
                                >
                                    <MeasuresIcon size="xs" />
                                    Maßnahmen
                                </ReactLink>
                                <ExternalLink
                                    href="/backend/m/csite/call"
                                    isDisabled={
                                        !isModuleAvailable(
                                            modules.CONSTRUCTION_SITES,
                                        )
                                    }
                                    title={
                                        !isModuleAvailable(
                                            modules.CONSTRUCTION_SITES,
                                        )
                                            ? moduleUnavailableMessage
                                            : undefined
                                    }
                                >
                                    <ConstructionSiteIcon size="xs" />
                                    Ergänzende GF
                                </ExternalLink>
                            </>
                        ) : (
                            <>
                                <ReactLink
                                    {...{
                                        [reactLinkProp]:
                                            reactLinkProp === 'to'
                                                ? {
                                                      pathname: '/dashboard',
                                                      state: {
                                                          module:
                                                              modules.INSTRUCTIONS,
                                                      },
                                                  }
                                                : '/dashboard',
                                    }}
                                    isDisabled={
                                        !isModuleAvailable(modules.INSTRUCTIONS)
                                    }
                                >
                                    <InstructionIcon size="xs" />
                                    Unterweisungen
                                </ReactLink>
                                <ReactLink
                                    {...{
                                        [reactLinkProp]:
                                            reactLinkProp === 'to'
                                                ? {
                                                      pathname: '/dashboard',
                                                      search: `?module=${modules.MEASURES}`,
                                                      state: {
                                                          module:
                                                              modules.MEASURES,
                                                      },
                                                  }
                                                : `/dashboard?module=${modules.MEASURES}`,
                                    }}
                                    isDisabled={
                                        !isModuleAvailable(modules.MEASURES)
                                    }
                                >
                                    <MeasuresIcon size="xs" />
                                    Maßnahmen
                                </ReactLink>
                                <ExternalLink
                                    href="/backend/m/exec/call"
                                    isDisabled={
                                        !isModuleAvailable(
                                            modules.CONSTRUCTION_SITES,
                                        )
                                    }
                                >
                                    <ConstructionSiteIcon size="xs" />
                                    Ergänzende GF
                                </ExternalLink>
                            </>
                        )}

                        <ExternalLink href="/backend/m/docs/call">
                            <PdfDocumentIcon size="xs" />
                            Dokumente
                        </ExternalLink>
                    </MainMenuActions>
                )}
                {isProfileMenuOpened && (
                    <ProfileMenuActions>
                        <ExternalLink href="/backend/settings">
                            <SettingsSharp />
                            myEinstellungen
                        </ExternalLink>
                        {isManager && (
                            <ReactLink
                                {...{
                                    [reactLinkProp]: '/archive',
                                }}
                                isDisabled={!isModuleAvailable(modules.ARCHIVE)}
                                title={
                                    !isModuleAvailable(modules.ARCHIVE)
                                        ? moduleUnavailableMessage
                                        : undefined
                                }
                            >
                                <ArchiveSharp />
                                myArchiv
                            </ReactLink>
                        )}
                        {isManager ? (
                            <ExternalLink isDisabled>
                                <PeopleOutlineSharp />
                                myDSGVO
                            </ExternalLink>
                        ) : (
                            <ExternalLink href="/backend/dsgvo">
                                <PeopleOutlineSharp />
                                myDSGVO
                            </ExternalLink>
                        )}

                        <ButtonLink
                            disabled={isLoggingOut}
                            onClick={handleLogoutButtonClick}
                        >
                            {isLoggingOut ? (
                                <LoaderIcon size={16} />
                            ) : (
                                <LogoutIcon />
                            )}
                            Abmelden
                        </ButtonLink>
                    </ProfileMenuActions>
                )}
            </Inner>
        </Wrapper>
    );
};

Header.defaultProps = {
    isReact: true,
};

Header.propTypes = {
    isManager: PropTypes.bool.isRequired,
    availableModules: PropTypes.array.isRequired,
    isReact: PropTypes.bool,
};

export default Header;
