import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import axios from 'axios';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { ArrowBackSharp, CheckSharp } from '@material-ui/icons';
import {
    ButtonsWrapper,
    Layout,
    Title,
    ToolbarButtons,
} from 'Measure/components/vehicle/driver-license/layout';
import Button from 'Common/components/Button';
import LinkButton from 'Common/components/LinkButton';
import Loader from 'Common/components/Loader';
import CaptureImage from 'Measure/components/vehicle/driver-license/create/CaptureImage';
import ImageUpload from 'Measure/components/vehicle/driver-license/create/ImageUpload';
import LicenseImage from 'Measure/components/vehicle/driver-license/create/LicenseImage';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import { useFullscreen } from 'react-use';
import screenfull from 'screenfull';
import modules from 'Common/constants/modules';
import uploadDriverLicenseDistanceFile from 'Measure/api/driver-license/distance-check/uploadDriverLicenseDistanceFile';
import preProcessDriverLicenseFile from 'Measure/api/driver-license/create/preProcessDriverLicenseFile';

const SideBySideContent = styled.div`
    > div {
        margin-bottom: 10px;
        padding: 20px;
        background: ${colors.DARK_GRAY};

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 940px) {
        display: flex;
        min-height: 320px;
        padding: 0;
        background: none;

        > div {
            flex: 1;
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;
            padding: 40px 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 1180px) {
        > div {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
`;

const Content = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    @media screen and (min-width: 620px) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 0;
    }
`;

const CenteredContent = styled(Content)`
    @media screen and (min-width: 620px) {
        align-items: center;
    }
`;

const DriverLicenseDistanceImageUpload = ({
    driverLicenseControl,
    type,
    title,
    onConfirm,
    onBackButtonClick,
    shouldPreProcessImage,
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isPreProcessing, setIsPreProcessing] = useState(false);

    const [uploadedImageSrc, setUploadedImageSrc] = useState(() => {
        const distanceProcedure =
            driverLicenseControl?.driverLicense?.nextCheck?.distanceProcedure;

        if (distanceProcedure) {
            const image = distanceProcedure.files.find(
                file => file.type === type,
            );

            if (image) {
                return image.file;
            }
        }

        return null;
    });

    const [uploadedImageDate, setUploadedImageDate] = useState(() => {
        const distanceProcedure =
            driverLicenseControl?.driverLicense?.nextCheck?.distanceProcedure;

        if (distanceProcedure) {
            const image = distanceProcedure.files.find(
                file => file.type === type,
            );

            if (image) {
                return moment(image.uploadedAt.date);
            }
        }

        return null;
    });

    const [isCameraTurnedOn, setIsCameraTurnedOn] = useState(false);

    const fullscreenRef = useRef(null);
    useFullscreen(fullscreenRef, isCameraTurnedOn, {
        onClose: () => {
            if (screenfull.isEnabled) {
                setIsCameraTurnedOn(false);
            }
        },
    });

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleImageCaptureButtonClick = () => {
        setIsCameraTurnedOn(true);
    };

    const handleCancelCapture = () => {
        setIsCameraTurnedOn(false);
    };

    const clearImage = () => {
        setUploadedImage(null);
        setUploadedImageSrc(null);
        setUploadedImageDate(null);
    };

    const handleImageCapture = useCallback(
        file => {
            (async () => {
                if (file) {
                    setUploadedImage(file);
                    setUploadedImageSrc(URL.createObjectURL(file));
                    setUploadedImageDate(moment(file.lastModifiedDate));

                    setIsCameraTurnedOn(false);

                    if (shouldPreProcessImage) {
                        setIsPreProcessing(true);

                        try {
                            const response = await preProcessDriverLicenseFile(
                                file,
                                source.token,
                            );

                            const byteArray = new Uint8Array(
                                response.data.processedFile.data,
                            );

                            const blob = new Blob([byteArray.buffer], {
                                type: 'image/jpeg',
                            });

                            const preProcessedFile = new File(
                                [blob],
                                file.name,
                                {
                                    type: file.type,
                                    lastModified: file.lastModifiedDate,
                                },
                            );

                            setUploadedImage(preProcessedFile);
                            setUploadedImageSrc(
                                URL.createObjectURL(preProcessedFile),
                            );
                        } catch (error) {}

                        setIsPreProcessing(false);
                    }
                } else {
                    clearImage();
                    setIsCameraTurnedOn(false);
                }
            })();
        },
        [shouldPreProcessImage, source.token],
    );

    // Make sure to revoke the data URI to avoid memory leaks
    useEffect(
        () => () => {
            if (uploadedImageSrc !== null) {
                URL.revokeObjectURL(uploadedImageSrc);
            }
        },
        [uploadedImageSrc],
    );

    const handleConfirmButtonClick = async () => {
        setIsUploading(true);

        try {
            if (uploadedImage && uploadedImageDate) {
                await uploadDriverLicenseDistanceFile(
                    driverLicenseControl.id,
                    uploadedImage,
                    type,
                    uploadedImageDate,
                    source.token,
                );
            }

            const isAfterUpload = !!uploadedImage;

            await onConfirm(isAfterUpload);

            setIsUploading(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsUploading(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    let uploadedImageTitle;
    let uploadedImageSubTitle;
    let referenceImageSrc;
    let referenceImageHint;

    // eslint-disable-next-line
    switch (type) {
        case driverLicenseFileTypes.FRONT_WITH_HOLOGRAM:
            uploadedImageTitle = 'Führerscheinvorderseite';
            referenceImageSrc = '/images/driver-license/samples/front.png';
            referenceImageHint = (
                <>
                    Referenzbild mit sichtbaren Hologrammen.
                    <br />
                    Mindestens ein Hologramm sollte bei Ihrer Fotoaufnahme
                    erkennbar sein!
                </>
            );
            break;

        case driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS:
            uploadedImageTitle = 'Führerscheinrückseite';
            uploadedImageSubTitle = '(Abbildung mit Zeichenkette)';
            referenceImageSrc =
                '/images/driver-license/samples/back-letters.png';
            referenceImageHint = (
                <>
                    Referenzbild mit sichtbaren Hologrammen.
                    <br />
                    Die Zeichenkette sollte erkennbar sein.
                </>
            );
            break;

        case driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS:
            uploadedImageTitle = 'Führerscheinrückseite';
            uploadedImageSubTitle = '(Abbildung mit Verkehrszeichen)';
            referenceImageSrc = '/images/driver-license/samples/back-signs.png';
            referenceImageHint = (
                <>
                    Referenzbild mit sichtbaren Hologrammen.
                    <br />
                    Die Verkehrszeichen sollten erkennbar sein.
                </>
            );
            break;
    }

    const hasImage = !!uploadedImageSrc && !!uploadedImageDate;
    const StyledContent = hasImage ? Content : CenteredContent;

    return (
        <Layout>
            <Title>{title}</Title>
            <SideBySideContent>
                <StyledContent>
                    {hasImage ? (
                        <LicenseImage
                            title="Bereitgestellte Fotoaufnahme des Mitarbeiters"
                            hint={
                                <>
                                    Bitte kontrollieren Sie, ob alle geforderten
                                    Kriterien erfüllt wurden. Vergleichen Sie
                                    hierzu Ihre Aufnahme mit dem Referenzbild.
                                </>
                            }
                            src={uploadedImageSrc}
                            watermarkDate={uploadedImageDate}
                            onClear={clearImage}
                            isLoading={isPreProcessing}
                        />
                    ) : (
                        <ImageUpload
                            title={uploadedImageTitle}
                            subTitle={uploadedImageSubTitle}
                            hint="(EU-Kartenführerschein aus Deutschland)"
                            onImageCaptureButtonClick={
                                handleImageCaptureButtonClick
                            }
                            allowFileUpload={false}
                        />
                    )}
                </StyledContent>
                <StyledContent>
                    <LicenseImage
                        title="Referenzbild"
                        hint={referenceImageHint}
                        src={referenceImageSrc}
                    />
                </StyledContent>
            </SideBySideContent>
            <ToolbarButtons>
                <ButtonsWrapper>
                    <Button
                        disabled={
                            !uploadedImageSrc || isPreProcessing || isUploading
                        }
                        onClick={handleConfirmButtonClick}
                    >
                        {isUploading ? <Loader size={24} /> : <CheckSharp />}
                        Weiter
                    </Button>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    <Button
                        icon={ArrowBackSharp}
                        onClick={onBackButtonClick}
                        disabled={isPreProcessing || !onBackButtonClick}
                    >
                        Zurück
                    </Button>
                    <LinkButton
                        disabled={isPreProcessing || isUploading}
                        to={`/dashboard?module=${modules.MEASURES}`}
                    >
                        Abbrechen
                    </LinkButton>
                </ButtonsWrapper>
            </ToolbarButtons>
            <div ref={fullscreenRef}>
                {isCameraTurnedOn && (
                    <CaptureImage
                        onCapture={handleImageCapture}
                        onCancel={handleCancelCapture}
                    />
                )}
            </div>
        </Layout>
    );
};

DriverLicenseDistanceImageUpload.defaultProps = {
    onBackButtonClick: undefined,
    shouldPreProcessImage: false,
};

DriverLicenseDistanceImageUpload.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    shouldPreProcessImage: PropTypes.bool,
};

export default DriverLicenseDistanceImageUpload;
