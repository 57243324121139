import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import roles from 'User/constants/roles';
import { css } from 'styled-components';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import isAuthorized from 'User/utils/isAuthorized';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getManagers from 'Manager/api/getManagers';
import { AssignmentLateSharp, CloseSharp } from '@material-ui/icons';
import updateDriverLicenseControlExaminers from 'Measure/api/driver-license/update/updateDriverLicenseControlExaminers';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddExaminer from 'Measure/components/vehicle/driver-license/overview/AddExaminer';
import { ifProp } from 'styled-tools';
import { orderBy } from 'lodash';

const Title = styled.div`
    margin-bottom: 20px;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 18px;
`;

const List = styled.div`
    overflow: auto;
    max-height: 190px;
`;

const Examiner = styled.div`
    position: relative;
    margin-bottom: 10px;
    padding: 7px 12px;
    background: #d6d6d6;
    border-radius: 4px;
    color: ${colors.ALT_DARKEST_GRAY};
    font-size: 14px;

    &:last-child {
        margin-bottom: 0;
    }

    ${ifProp(
        'hasRejectedThePhotos',
        css`
            background: ${colors.ORANGE};
            padding-left: 40px;
        `,
    )};
`;

const RejectedByExaminerIcon = styled(AssignmentLateSharp)`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
`;

const RemoveButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 15px;
    outline: 0 none;
    border: 0 none;
    background: none;
    color: ${colors.ALT_DARKEST_GRAY};
    cursor: pointer;

    &[disabled] {
        color: ${colors.LIGHT_GRAY};
        cursor: not-allowed;
    }

    svg {
        font-size: 18px !important;
    }
`;

const Examiners = ({ driverLicenseControl }) => {
    const managersRequest = useCallback(
        cancelToken =>
            getManagers(
                ['user'],
                {
                    onlyActive: true,
                },
                cancelToken,
            ),
        [],
    );

    const {
        data: managers,
        loadData: loadManagers,
        isLoading: isLoadingManagers,
        hasError: hasManagersError,
    } = useAxiosRequest(managersRequest, null);

    const [selectedExaminers, setSelectedExaminers] = useState(() =>
        driverLicenseControl.examiners.map(examiner => ({
            value: examiner.id,
            label: `${examiner.firstName} ${examiner.lastName}`,
        })),
    );

    const [isUpdatingExaminers, setIsUpdatingExaminers] = useState(false);

    const handleExaminerAdd = async option => {
        if (option) {
            setIsUpdatingExaminers(true);

            try {
                await updateDriverLicenseControlExaminers(
                    driverLicenseControl.id,
                    [
                        ...selectedExaminers.map(option => option.value),
                        option.value,
                    ],
                );

                setSelectedExaminers(prevExaminers => [
                    ...prevExaminers,
                    option,
                ]);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsUpdatingExaminers(false);
        }
    };

    const handleExaminerRemove = async examinerId => {
        const examinerIds = [...selectedExaminers.map(option => option.value)];

        const index = examinerIds.indexOf(examinerId);

        if (index !== -1) {
            setIsUpdatingExaminers(true);

            examinerIds.splice(index, 1);

            try {
                await updateDriverLicenseControlExaminers(
                    driverLicenseControl.id,
                    examinerIds,
                );

                setSelectedExaminers(prevExaminers => {
                    const newExaminers = [...prevExaminers];

                    const index = newExaminers.findIndex(
                        option => option.value === examinerId,
                    );

                    if (index !== -1) {
                        newExaminers.splice(index, 1);
                    }

                    return newExaminers;
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsUpdatingExaminers(false);
        }
    };

    if (isLoadingManagers) {
        return <Loader />;
    }

    if (hasManagersError) {
        return <RetryLoading onRetry={loadManagers} />;
    }

    const nextCheck = driverLicenseControl.driverLicense.nextCheck;
    const previousDistanceProcedure = nextCheck.previousDistanceProcedure;
    const isLastProcedureRejected =
        previousDistanceProcedure?.isRejected &&
        previousDistanceProcedure?.examiner;

    const rejectedByExaminer = previousDistanceProcedure?.examiner;
    const rejectedText = 'Abweisung erfolgte über diesen Prüfer';

    const allExaminers = orderBy(
        [
            ...managers.map(manager => ({
                id: manager.user.id,
                name: `${manager.firstName} ${manager.lastName}`,
                isEmployee: false,
                hasRejectedThePhotos:
                    isLastProcedureRejected &&
                    manager.user.id === rejectedByExaminer.id,
            })),
            ...selectedExaminers.map(option => ({
                id: option.value,
                name: option.label,
                isEmployee: true,
                hasRejectedThePhotos:
                    isLastProcedureRejected &&
                    option.value === rejectedByExaminer.id,
            })),
        ],
        ['hasRejectedThePhotos'],
        ['desc'],
    );

    return (
        <>
            <Title>Zugewiesene Prüfer</Title>
            {isAuthorized([roles.MANAGER]) && (
                <AddExaminer
                    driverLicenseControl={driverLicenseControl}
                    selectedExaminers={selectedExaminers}
                    isUpdatingExaminers={isUpdatingExaminers}
                    onAdd={handleExaminerAdd}
                />
            )}
            <List>
                {allExaminers.map(examiner => (
                    <Examiner
                        key={examiner.id}
                        hasRejectedThePhotos={examiner.hasRejectedThePhotos}
                        title={
                            examiner.hasRejectedThePhotos
                                ? rejectedText
                                : undefined
                        }
                    >
                        {examiner.hasRejectedThePhotos && (
                            <RejectedByExaminerIcon />
                        )}
                        {examiner.name}
                        {examiner.isEmployee && isAuthorized([roles.MANAGER]) && (
                            <RemoveButton
                                type="button"
                                disabled={isUpdatingExaminers}
                                onClick={() =>
                                    handleExaminerRemove(examiner.id)
                                }
                            >
                                <CloseSharp />
                            </RemoveButton>
                        )}
                    </Examiner>
                ))}
            </List>
        </>
    );
};

Examiners.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default Examiners;
