import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import FormField from 'Common/components/form/FormField';
import Select from 'Common/components/form/fields/Select';
import Button from 'Common/components/Button';
import getEmployeesWithoutDriverLicenseControl from 'Employee/api/getEmployeesWithoutDriverLicenseControl';
import getEmployeeExaminersByMeasure from 'User/api/getEmployeeExaminersByMeasure';
import measures from 'Measure/constants/measures';
import { useSelector } from 'react-redux';
import createDriverLicenseControl from 'Measure/api/driver-license/create/createDriverLicenseControl';
import Loader from 'Common/components/Loader';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import axios from 'axios';
import driverLicenseEmployeeExaminersLimit from 'Measure/constants/driverLicenseEmployeeExaminersLimit';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';

const Wrapper = styled.div`
    padding: 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 0 0 4px 4px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    background: ${colors.DARK_GRAY};

    button {
        padding-left: 17px;
        padding-right: 17px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    ${ifProp(
        'isDisabled',
        css`
            pointer-events: none;
        `,
    )};
`;

const validationSchema = Yup.object().shape({
    employee: Yup.object()
        .required('Pflichtangabe')
        .nullable(),
    examiners: Yup.array()
        .of(Yup.object())
        .max(
            driverLicenseEmployeeExaminersLimit,
            `Maximal ${driverLicenseEmployeeExaminersLimit} Prüfer`,
        )
        .nullable(),
});

const examinersStyles = {
    multiValueLabel: (provided, state) => {
        if (state.data.isFixed) {
            return { ...provided, paddingRight: '6px' };
        }

        return provided;
    },
    multiValueRemove: (provided, state) => {
        if (state.data.isFixed) {
            return { ...provided, display: 'none' };
        }

        return provided;
    },
};

const DriverLicenseControlCreateForm = ({ onCancel, onSuccess }) => {
    const [isDropDownMenuOpened, setIsDropDownMenuOpened] = useState(false);

    const handleDropDownMenuOpen = () => {
        setIsDropDownMenuOpened(true);
    };

    const handleDropDownMenuClose = () => {
        setIsDropDownMenuOpened(false);
    };

    const [isCreating, setIsCreating] = useState(false);

    const currentUserId = useSelector(state => state.user.id);

    const examinersRequest = useCallback(
        cancelToken =>
            getEmployeeExaminersByMeasure(measures.DRIVER_LICENSE, cancelToken),
        [],
    );

    const {
        data: examiners,
        loadData: loadExaminers,
        isLoading: isLoadingExaminers,
        hasError: hasExaminersError,
    } = useAxiosRequest(examinersRequest, []);

    const employeesRequest = useCallback(
        cancelToken =>
            getEmployeesWithoutDriverLicenseControl(['user'], cancelToken),
        [],
    );

    const {
        data: employees,
        loadData: loadEmployees,
        isLoading: isLoadingEmployees,
        hasError: hasEmployeesError,
    } = useAxiosRequest(employeesRequest, []);

    const isLoading = isLoadingExaminers || isLoadingEmployees;
    const hasError = hasExaminersError || hasEmployeesError;

    const handleRetryLoading = () => {
        loadExaminers();
        loadEmployees();
    };

    const initialValues = useMemo(
        () => ({
            employee: null,
            examiners: [],
        }),
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createDriverLicenseControl(
                    {
                        employeeId: values.employee.value,
                        examinerIds: values.examiners
                            ? values.examiners.map(option => option.value)
                            : [],
                    },
                    source.token,
                );

                await onSuccess(response.data);

                setIsCreating(false);

                notify('Die Führerscheinkontrolle wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsCreating(false);

                    notify(
                        'Die Führerscheinkontrolle wurde nicht erfolgreich erstellt',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        },
    });

    useEffect(() => {
        const areExaminersLoaded = examiners.length > 0;
        const noExaminersSelected =
            !values.examiners || values.examiners.length === 0;

        if (currentUserId && areExaminersLoaded && noExaminersSelected) {
            const preSelectedExaminer = examiners.find(
                examiner => examiner.id === currentUserId,
            );

            if (preSelectedExaminer) {
                setFieldValue('examiners', [
                    {
                        value: preSelectedExaminer.id,
                        label: `${preSelectedExaminer.firstName} ${preSelectedExaminer.lastName}`,
                        isFixed: true,
                    },
                ]);
            }
        }
    }, [currentUserId, examiners, setFieldValue, values.examiners]);

    const employeeId = values.employee ? values.employee.value : null;
    const employeeUserId = employees.find(
        employee => employee.id === employeeId,
    )?.user.id;

    const examinerIds = values.examiners
        ? values.examiners.map(examiner => examiner.value)
        : [];

    const employeeOptions = employees
        .filter(employee => !examinerIds.includes(employee.user.id))
        .filter(employee => employee.user.id !== currentUserId)
        .map(employee => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
        }));

    const examinerOptions = examiners
        .filter(examiner => examiner.id !== employeeUserId)
        .map(examiner => ({
            value: examiner.id,
            label: `${examiner.firstName} ${examiner.lastName}`,
        }));

    return (
        <Wrapper>
            {isLoading ? (
                <Loader size={80} />
            ) : hasError ? (
                <RetryLoading onRetry={handleRetryLoading} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <FormField>
                        <Select
                            label="Mitarbeiterauswahl"
                            id="employee"
                            name="employee"
                            error={errors.employee}
                            touched={touched.employee}
                            value={values.employee}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            options={employeeOptions}
                            isSearchable
                            onMenuOpen={handleDropDownMenuOpen}
                            onMenuClose={handleDropDownMenuClose}
                        />
                    </FormField>
                    <FormField>
                        <Select
                            label="Prüferauswahl"
                            id="examiners"
                            name="examiners"
                            error={errors.examiners}
                            touched={touched.examiners}
                            value={values.examiners}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            options={examinerOptions}
                            isMulti
                            isSearchable
                            isClearable={
                                values.examiners
                                    ? values.examiners.some(
                                          value => !value.isFixed,
                                      )
                                    : false
                            }
                            styles={examinersStyles}
                            onMenuOpen={handleDropDownMenuOpen}
                            onMenuClose={handleDropDownMenuClose}
                            isDisabled={isAuthorized([roles.EMPLOYEE])}
                        />
                    </FormField>
                    <ButtonsWrapper isDisabled={isDropDownMenuOpened}>
                        <Button
                            onClick={onCancel}
                            disabled={isCreating || isDropDownMenuOpened}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="submit"
                            disabled={
                                !isValid || isCreating || isDropDownMenuOpened
                            }
                        >
                            {isCreating && <Loader size={24} />}
                            Weiter
                        </Button>
                    </ButtonsWrapper>
                </form>
            )}
        </Wrapper>
    );
};

DriverLicenseControlCreateForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default DriverLicenseControlCreateForm;
