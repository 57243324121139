import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import getEmployeeInstructionAssignments from 'Education/api/instruction-assignment/getEmployeeInstructionAssignments';
import Loader from 'Common/components/Loader';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import AllInstructionAssignmentsTable from 'Employee/components/employee-view/instruction/AllInstructionAssignmentsTable';
import colors from 'Common/constants/colors';

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 8px 0;
    border-radius: 4px;
`;

const AllEmployeeInstructionAssignments = () => {
    const instructionAssignmentsRequest = useCallback(
        cancelToken =>
            getEmployeeInstructionAssignments(
                undefined,
                [
                    'groupAssignmentDetails',
                    'groupAssignmentDetails.assignees.employee.user',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: instructionAssignments,
        loadData: loadInstructionAssignments,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAssignmentsRequest, []);

    return (
        <Content>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : hasError ? (
                <LoaderWrapper>
                    <RetryLoading onRetry={loadInstructionAssignments} />
                </LoaderWrapper>
            ) : (
                <AllInstructionAssignmentsTable
                    instructionAssignments={instructionAssignments}
                />
            )}
        </Content>
    );
};

export default AllEmployeeInstructionAssignments;
