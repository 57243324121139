import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import DriverLicenseToolbarButtons from 'Measure/components/vehicle/driver-license/check/DriverLicenseToolbarButtons';
import {
    Layout,
    Title,
} from 'Measure/components/vehicle/driver-license/layout';
import Zoom from 'react-medium-image-zoom';
import moment from 'moment-timezone';

const SideBySideContent = styled.div`
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 620px) {
        background: none;
    }

    @media screen and (min-width: 940px) {
        min-height: 320px;
    }

    @media screen and (min-width: 1130px) {
        display: flex;
        min-height: 240px;
    }
`;

const Content = styled.div`
    padding: 20px;
    border-radius: 4px;

    @media screen and (min-width: 620px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: ${colors.DARK_GRAY};
    }

    @media screen and (min-width: 940px) {
        padding: 40px;
    }
`;

const HintWrapper = styled(Content)`
    width: 100%;

    @media screen and (min-width: 620px) {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 1130px) {
        flex: 0 0 300px;
        width: 300px;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const FilesWrapper = styled(Content)`
    flex: 1;
`;

const ContentTitle = styled.div`
    display: block;
    margin-bottom: 20px;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 18px;
    text-align: center;
`;

const Hint = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
`;

const List = styled.div`
    width: 100%;
`;

const RejectedFile = styled.div`
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 740px) {
        display: flex;
    }
`;

const ImageWrapper = styled.div`
    position: relative;

    button {
        &:focus {
            outline: 0 none;
        }
    }

    @media screen and (min-width: 740px) {
        flex: 0 0 300px;
        width: 300px;
        margin-right: 20px;
    }
`;

const Image = styled.img`
    display: block;
    width: 100%;
    border-radius: 4px;
`;

const WatermarkDate = styled.div`
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    color: ${colors.WHITE};
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 2px ${colors.BLACK};
`;

const RejectedReasons = styled.div`
    margin-top: -3px;
    padding: 20px;
    background: ${colors.DARKEST_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 740px) {
        flex: 1;
        margin-top: 0;
    }
`;

const RejectedReason = styled.div`
    position: relative;
    padding-left: 15px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        content: '•';
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const RejectedFilesOverview = ({ driverLicenseControl, onConfirm }) => {
    const nextCheck = driverLicenseControl.driverLicense.nextCheck;
    const examiner = nextCheck.previousDistanceProcedure.examiner;
    const rejectedFiles = nextCheck.previousDistanceProcedure.files.filter(
        file => file.isRejected,
    );

    return (
        <Layout>
            <Title>Grund der Abweisung</Title>
            <SideBySideContent>
                <HintWrapper>
                    <ContentTitle>Hinweis</ContentTitle>
                    <Hint>
                        {`Die prüfende Person (${examiner.firstName} ${examiner.lastName})`}
                        , hat die folgenden Fotos abgewiesen. Bitte wiederholen
                        Sie den Vorgang und beachten Sie bei der erneuten
                        Bereitstellung die Führerscheinfotos nach den
                        entsprechenden Vorgaben zu tätigen.
                    </Hint>
                </HintWrapper>
                <FilesWrapper>
                    <List>
                        {rejectedFiles.map(rejectedFile => (
                            <RejectedFile key={rejectedFile.type}>
                                <ImageWrapper>
                                    <Zoom
                                        overlayBgColorStart="rgba(0, 0, 0, 0)"
                                        overlayBgColorEnd="rgba(0, 0, 0, 0.55)"
                                        zoomMargin={20}
                                        wrapStyle={{ width: '100%' }}
                                    >
                                        <Image
                                            src={rejectedFile.file}
                                            alt={rejectedFile.type}
                                        />
                                        <WatermarkDate>
                                            {`${moment(
                                                rejectedFile.uploadedAt.date,
                                            ).format(
                                                'DD.MM.YYYY, HH:mm:ss',
                                            )} Uhr`}
                                        </WatermarkDate>
                                    </Zoom>
                                </ImageWrapper>
                                <RejectedReasons>
                                    {rejectedFile.rejectedReasons.map(
                                        rejectedReason => (
                                            <RejectedReason
                                                key={rejectedReason.value}
                                            >
                                                {rejectedReason.name}
                                            </RejectedReason>
                                        ),
                                    )}
                                </RejectedReasons>
                            </RejectedFile>
                        ))}
                    </List>
                </FilesWrapper>
            </SideBySideContent>
            <DriverLicenseToolbarButtons
                cancelButtonLink="/measures/vehicles/driver-license/overview"
                confirmButtonText="Weiter"
                onConfirm={onConfirm}
                showBackButton={false}
            />
        </Layout>
    );
};

RejectedFilesOverview.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default RejectedFilesOverview;
