import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import getInstructionArea from 'Education/api/instruction-area/getInstructionArea';
import updateInstructionArea from 'Education/api/instruction-area/updateInstructionArea';
import ConfirmModal from 'Common/components/ConfirmModal';
import FormField from 'Common/components/form/FormField';
import Loader from 'Common/components/Loader';
import Input from 'Common/components/form/fields/Input';
import Textarea from 'Common/components/form/fields/Textarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Link from 'Common/components/Link';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import RetryLoading from 'Common/components/RetryLoading';

const SubTitle = styled.h3`
    margin-top: 0;
`;

const Content = styled.div``;

const Form = styled.form`
    label {
        text-align: left;
    }

    @media screen and (min-width: 732px) {
        display: flex;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 732px) {
        flex: 1;
    }
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (min-width: 732px) {
        flex: 0 0 300px;
        max-height: 251px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: 40px;
    }
`;

const InstructionName = styled.div`
    a {
        color: white;

        &:hover,
        &:focus {
            color: ${colors.LIGHTER_GRAY};
            outline: 0 none;
        }
    }
`;

const InstructionsCount = styled.div`
    font-size: 18px;
    margin-bottom: 10px;
    color: ${colors.LIGHTER_GRAY};
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    description: Yup.string(),
});

const InstructionAreaEditModal = ({ areaId, onCancel, onSuccess }) => {
    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const instructionAreaRequest = useCallback(
        cancelToken =>
            getInstructionArea(
                areaId,
                ['instructionsCount', 'instructions'],
                cancelToken,
            ),
        [areaId],
    );

    const {
        data: instructionArea,
        loadData: loadInstructionArea,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAreaRequest, null);

    const initialValues = useMemo(
        () =>
            instructionArea
                ? {
                      name: instructionArea.name,
                      description: instructionArea.description,
                  }
                : {
                      name: '',
                      description: '',
                  },
        [instructionArea],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            if (areaId) {
                setIsSavingChanges(true);

                try {
                    await updateInstructionArea(
                        areaId,
                        {
                            name: values.name,
                            description: values.description,
                        },
                        source.token,
                    );

                    await onSuccess();

                    setIsSavingChanges(false);

                    notify('Der Bereich wurde aktualisiert', {
                        type: toast.TYPE.SUCCESS,
                    });
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        setIsSavingChanges(false);

                        notify('Der Bereich wurde nicht aktualisiert', {
                            type: toast.TYPE.ERROR,
                        });
                    }
                }
            }
        },
    });

    return (
        <ConfirmModal
            isOpen={areaId !== null}
            isLoading={isLoading}
            isConfirmLoading={isSavingChanges}
            isConfirmDisabled={!isValid}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmText="Speichern"
        >
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadInstructionArea} />
            ) : instructionArea === null ? (
                <p>Dieser Bereiche ist nicht mehr verfügbar</p>
            ) : (
                <Content>
                    <SubTitle>Bereich Bearbeiten</SubTitle>
                    <Form onSubmit={handleSubmit}>
                        <LeftColumn>
                            <FormField>
                                <Input
                                    label="Bereich"
                                    id="name"
                                    name="name"
                                    error={errors.name}
                                    touched={touched.name}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField>
                                <Textarea
                                    label="Beschreibung"
                                    id="description"
                                    name="description"
                                    error={errors.description}
                                    touched={touched.description}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                        </LeftColumn>
                        <RightColumn>
                            <InstructionsCount>
                                {`${instructionArea.instructionsCount} Unterweisungen`}
                            </InstructionsCount>
                            {instructionArea.instructions.map(instruction => (
                                <InstructionName key={instruction.id}>
                                    <Link
                                        to={`/education/instruction/${instruction.id}/presentation`}
                                    >
                                        {instruction.name}
                                    </Link>
                                </InstructionName>
                            ))}
                        </RightColumn>
                    </Form>
                </Content>
            )}
        </ConfirmModal>
    );
};

InstructionAreaEditModal.defaultProps = {
    areaId: null,
};

InstructionAreaEditModal.propTypes = {
    areaId: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaEditModal;
