import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import {
    ButtonsWrapper,
    Layout,
    Title,
    ToolbarButtons,
} from 'Measure/components/vehicle/driver-license/layout';
import LinkButton from 'Common/components/LinkButton';
import colors from 'Common/constants/colors';
import moment from 'moment-timezone';
import Examiners from 'Measure/components/vehicle/driver-license/overview/Examiners';
import { useSelector } from 'react-redux';
import measures from 'Measure/constants/measures';
import isAllowedToExamineMeasure from 'User/utils/isAllowedToExamineMeasure';
import MarkAsLost from 'Measure/components/vehicle/driver-license/overview/MarkAsLost';
import DriverLicenseControlOverviewMainButton from 'Measure/components/vehicle/driver-license/overview/DriverLicenseControlOverviewMainButton';
import DriverLicenseSentEmails from 'Measure/components/vehicle/driver-license/overview/DriverLicenseSentEmails';

const SideBySideContent = styled.div`
    background: ${colors.DARK_GRAY};
    padding: 20px;
    border-radius: 4px;

    @media screen and (min-width: 1090px) {
        display: flex;
        min-height: 320px;
        padding: 0;
        background: none;
    }
`;

const Content = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1090px) {
        margin-right: 10px;
        margin-bottom: 0;
        padding: 20px;
        background: ${colors.DARK_GRAY};

        &:last-child {
            margin-right: 0;
        }
    }
`;

const LeftContent = styled(Content)`
    @media screen and (min-width: 1090px) {
        flex: 1;
    }
`;

const RightContent = styled(Content)`
    @media screen and (min-width: 1090px) {
        flex: 0 0 310px;
        width: 310px;
    }
`;

const DriverLicenseControlOverview = ({ driverLicenseControl }) => {
    const userId = useSelector(state => state.user.id);

    const [selectedCheckId, setSelectedCheckId] = useState(null);

    const driverLicense = driverLicenseControl.driverLicense;
    const checks = driverLicense.checks;

    const selectedCheck = selectedCheckId
        ? checks.find(check => check.id === selectedCheckId)
        : checks[checks.length - 1];

    if (
        driverLicenseControl &&
        driverLicense &&
        !driverLicense.isInCreationProcess
    ) {
        let breadcrumbs;

        const isExaminer =
            isAuthorized([roles.MANAGER]) ||
            (isAllowedToExamineMeasure(measures.DRIVER_LICENSE) &&
                driverLicenseControl.examiners.findIndex(
                    examiner => examiner.id === userId,
                ) !== -1);

        if (isAuthorized([roles.MANAGER])) {
            breadcrumbs = (
                <>
                    <Helmet>
                        <title>{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}</title>
                    </Helmet>
                    <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles">Fuhrpark</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles/driver-licenses">
                        Führerscheinkontrolle
                    </Breadcrumb>
                    <Breadcrumb
                        isActive
                    >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
                </>
            );
        } else if (isAuthorized(roles.EMPLOYEE)) {
            if (isExaminer) {
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Maßnahmen`}</title>
                        </Helmet>
                        <Breadcrumb
                            to={`/dashboard?module=${modules.MEASURES}`}
                        >
                            Maßnahmen
                        </Breadcrumb>
                        <Breadcrumb to="/measures/vehicles/driver-licenses">
                            Führerscheinkontrolle
                        </Breadcrumb>
                        <Breadcrumb
                            isActive
                        >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
                    </>
                );
            } else {
                // Standard employee (viewing his own driver's license)
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>Führerscheinkontrolle - Maßnahmen</title>
                        </Helmet>
                        <Breadcrumb
                            to={`/dashboard?module=${modules.MEASURES}`}
                        >
                            Maßnahmen
                        </Breadcrumb>
                        <Breadcrumb isActive>Führerscheinkontrolle</Breadcrumb>
                    </>
                );
            }
        }

        let title = 'Aktuell keine anstehende Kontrolle';

        const dueDate = moment(driverLicense.nextCheck.dueDate.date);

        if (selectedCheckId !== null) {
            title = `Prüfprotokoll der Kontrolle vom ${moment(
                selectedCheck.completedAt.date,
            ).format()}`;
        } else if (driverLicense.nextCheck.isInVerificationPeriod) {
            title = `Prüfprotokoll der Kontrolle vom ${dueDate.format()}`;
        }

        return (
            <>
                {breadcrumbs}
                <Layout>
                    <Title>{title}</Title>
                    <SideBySideContent>
                        <LeftContent>
                            <DriverLicenseSentEmails
                                isExaminer={isExaminer}
                                driverLicenseControl={driverLicenseControl}
                                selectedCheckId={selectedCheckId}
                                onChangeSelectedCheckId={setSelectedCheckId}
                            />
                        </LeftContent>
                        <RightContent>
                            <Examiners
                                driverLicenseControl={driverLicenseControl}
                            />
                            <MarkAsLost
                                driverLicenseControl={driverLicenseControl}
                            />
                        </RightContent>
                    </SideBySideContent>
                    <ToolbarButtons>
                        <ButtonsWrapper>
                            <DriverLicenseControlOverviewMainButton
                                driverLicenseControl={driverLicenseControl}
                            />
                        </ButtonsWrapper>
                        <ButtonsWrapper>
                            <LinkButton to="/measures/vehicles/driver-licenses">
                                Abbrechen
                            </LinkButton>
                        </ButtonsWrapper>
                    </ToolbarButtons>
                </Layout>
            </>
        );
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

DriverLicenseControlOverview.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default DriverLicenseControlOverview;
