import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import MarkAsFoundButton from 'Measure/components/vehicle/driver-license/overview/MarkAsFoundButton';
import MarkAsLostButton from 'Measure/components/vehicle/driver-license/overview/MarkAsLostButton';
import { WarningSharp } from '@material-ui/icons';

const Wrapper = styled.div`
    margin-top: 20px;
`;

const WarningMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: ${colors.ORANGE};
    font-weight: 600;
    font-size: 13px;

    svg {
        font-size: 16px !important;
        margin-right: 5px;
    }
`;

const MarkAsLost = ({ driverLicenseControl }) => {
    const isLost = driverLicenseControl.driverLicense.isLost;

    return (
        <Wrapper>
            {isLost ? (
                <>
                    <MarkAsFoundButton
                        driverLicenseControl={driverLicenseControl}
                    />
                    <WarningMessage>
                        <WarningSharp />
                        Führerscheinverlust gemeldet
                    </WarningMessage>
                </>
            ) : (
                <MarkAsLostButton driverLicenseControl={driverLicenseControl} />
            )}
        </Wrapper>
    );
};

MarkAsLost.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default MarkAsLost;
