import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from 'Common/utils/notify';
import { popToast } from 'Common/utils/toast';
import EmployeeDsgvoCertificates from 'Employee/components/EmployeeDsgvoCertificates';
import LaravelBreadcrumbs from 'Application/components/Header/LaravelBreadcrumbs';
import Breadcrumbs from 'Application/components/Header/Breadcrumbs';
import Root from 'Application/components/Root';
import LaravelHeader from 'Application/components/Header/LaravelHeader';
import { CloseSharp } from '@material-ui/icons';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/de';

const FadeInOut = cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
    duration: 350,
});

const CloseButton = ({ closeToast }) => (
    <CloseSharp className="close-button" onClick={closeToast} />
);

toast.configure({
    autoClose: 3500,
    draggable: false,
    transition: FadeInOut,
    closeButton: <CloseButton />,
});

// Change the default format of Moment.js
moment.defaultFormat = 'DD.MM.YYYY';
momentTimezone.defaultFormat = 'DD.MM.YYYY';
momentTimezone.tz.setDefault('Europe/Berlin');

(async () => {
    let toast = popToast();

    while (toast !== undefined) {
        if (toast.message) {
            notify(toast.message, toast.options ?? {});
        }

        toast = popToast();
    }
})();

if (document.getElementById('root')) {
    ReactDOM.render(<Root />, document.getElementById('root'));
}

if (document.getElementById('manager-header')) {
    ReactDOM.render(
        <LaravelHeader isManager />,
        document.getElementById('manager-header'),
    );
}

if (document.getElementById('employee-header')) {
    ReactDOM.render(
        <LaravelHeader isManager={false} />,
        document.getElementById('employee-header'),
    );
}

if (document.getElementById('react-breadcrumbs')) {
    ReactDOM.render(
        <Breadcrumbs isReact={false} />,
        document.getElementById('react-breadcrumbs'),
    );
}

if (document.getElementById('employee-dsgvo-certificates')) {
    ReactDOM.render(
        <EmployeeDsgvoCertificates />,
        document.getElementById('employee-dsgvo-certificates'),
    );
}

if (document.getElementById('laravel-breadcrumbs')) {
    ReactDOM.render(
        <LaravelBreadcrumbs />,
        document.getElementById('laravel-breadcrumbs'),
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
