import React from 'react';
import styled from 'styled-components/macro';
import TileLink from 'Common/components/TileLink';
import ExternalTileLink from 'Common/components/ExternalTileLink';
import {
    EmptyDocumentIcon,
    FireExtinguisherIcon,
    FirstAidIcon,
    ToolsIcon,
    ToxicIcon,
    VehiclesIcon,
    VestIcon,
} from 'Common/components/icons';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';

const Wrapper = styled.div`
    @media screen and (min-width: 860px) {
        display: flex;
    }
`;

const Column = styled.div`
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 860px) {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }

        a {
            flex: 1;
        }
    }
`;

const ColumnNarrow = styled(Column)`
    @media screen and (min-width: 860px) {
        flex: 2;
    }
`;

const ColumnWide = styled(Column)`
    @media screen and (min-width: 860px) {
        flex: 3;
    }
`;

const MeasureName = styled.span`
    @media screen and (min-width: 860px) {
        flex: 1;
    }

    @media screen and (min-width: 1120px) {
        padding-right: 67px;
        text-align: center;
    }

    @media screen and (min-width: 1180px) {
        padding-right: 87px;
    }
`;

const ManagerMeasures = () => (
    <Wrapper>
        <Helmet>
            <title>Maßnahmen</title>
        </Helmet>
        <Breadcrumb isActive>Maßnahmen</Breadcrumb>
        <ColumnNarrow>
            <TileLink to="/measures/vehicles">
                <VehiclesIcon />
                <MeasureName>Fuhrpark</MeasureName>
            </TileLink>
        </ColumnNarrow>
        <ColumnWide>
            <ExternalTileLink href="/backend/m/reminder/content?t=ladder">
                <ToolsIcon />
                <MeasureName>Prüfbedürftige Einrichtungen</MeasureName>
            </ExternalTileLink>
            <ExternalTileLink href="/backend/m/reminder/content?t=psa">
                <VestIcon />
                <MeasureName>Persönliche Schutzausrüstung</MeasureName>
            </ExternalTileLink>
        </ColumnWide>
        <ColumnNarrow>
            <ExternalTileLink href="/backend/m/reminder/content?t=aid">
                <FirstAidIcon />
                <MeasureName>Erste Hilfe</MeasureName>
            </ExternalTileLink>
            <ExternalTileLink href="/backend/m/reminder/content?t=fire">
                <FireExtinguisherIcon />
                <MeasureName>Brandschutz</MeasureName>
            </ExternalTileLink>
            <ExternalTileLink href="/backend/m/reminder/content?t=eg">
                <ToxicIcon />
                <MeasureName>Gefahrenstoffe</MeasureName>
            </ExternalTileLink>
            <ExternalTileLink href="/backend/m/reminder/content?t=other">
                <EmptyDocumentIcon />
                <MeasureName>Rechtsbelehrungen</MeasureName>
            </ExternalTileLink>
        </ColumnNarrow>
    </Wrapper>
);

export default ManagerMeasures;
