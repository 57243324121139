import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    CloseSharp,
    FlashOnSharp,
    FlipCameraIosSharp,
    PhotoCameraSharp,
} from '@material-ui/icons';
import ScrollLock from 'react-scrolllock';
import Button from 'Common/components/Button';
import useWindowSize from '@react-hook/window-size';
import TurnYourDeviceInLandscapeModeOverlay from 'Common/components/TurnYourDeviceInLandscapeModeOverlay';
import { ifProp } from 'styled-tools';
import { useVideoInputDevices } from 'Common/hooks/useVideoInputDevices';
import Webcam from 'react-webcam';
import Loader from 'Common/components/Loader';
import moment from 'moment-timezone';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: ${colors.DARK_GRAY};

    video {
        position: relative;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const ButtonsWrapper = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: auto;

    > * {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }

    ${ifProp(
        'isVertical',
        css`
            flex-direction: column;
            top: 0;
            left: auto;
            bottom: auto;
            right: 20px;
            width: auto;
            height: 100%;

            > * {
                margin-right: 0;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        `,
    )};
`;

const ExtraButtonPlaceholder = styled.div`
    width: 60px;
    height: 60px;
`;

const ExtraButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 0 none;
    outline: 0 none;
    border-radius: 50%;
    background: ${colors.ALT_DARKEST_GRAY};
    cursor: pointer;

    svg {
        color: ${colors.LIGHTEST_GRAY};
        font-size: 28px;
    }

    &[disabled] {
        opacity: 0.75;
        cursor: not-allowed;
    }
`;

const SwitchCameraButton = styled(ExtraButton)``;

const TorchButton = styled(ExtraButton)`
    ${ifProp(
        'isOn',
        css`
            svg {
                color: ${colors.ORANGE};
            }
        `,
    )};
`;

const TakePhotoButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border: 7px solid rgba(0, 0, 0, 0.75);
    outline: 0 none;
    border-radius: 50%;
    background: ${colors.LIGHTEST_GRAY};
    box-shadow: 0 0 10px 0 rgba(66, 66, 66, 0.5);
    cursor: pointer;

    &[disabled] {
        opacity: 0.75;
        cursor: not-allowed;
    }

    svg {
        color: ${colors.ALT_DARKEST_GRAY};
        font-size: 28px;
    }
`;

const CancelButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 9px;
    border: 0 none;
    font-size: 14px;
    cursor: pointer;
    z-index: 1;

    &:hover,
    &:hover:not([disabled]),
    &:focus {
        outline: 0 none;
        border: 0 none;
        box-shadow: none;
        background: ${colors.DARK_GRAY};
        color: ${colors.LIGHTEST_GRAY};
    }

    svg {
        margin-right: 0;
    }
`;

const CaptureImage = ({ onCapture, onCancel }) => {
    const [width, height] = useWindowSize();
    const isPortrait = width < height;
    const isOverlayVisible = isMobile && isPortrait;

    const [videoTrack, setVideoTrack] = useState(null);
    const [videoDeviceId, setVideoDeviceId] = useState(null);
    const videoInputDevices = useVideoInputDevices();

    const videoDevice = videoInputDevices
        ? videoInputDevices.find(
              device => device.device.deviceId === videoDeviceId,
          )
        : null;

    useEffect(() => {
        if (
            videoDeviceId === null &&
            videoInputDevices &&
            videoInputDevices.length > 0
        ) {
            let initialVideoDevice = videoInputDevices[0];

            if (isMobile) {
                const backFacingDeviceWithTorch = videoInputDevices.find(
                    device =>
                        device.capabilities.facingMode &&
                        device.capabilities.facingMode.includes(
                            'environment',
                        ) &&
                        device.capabilities.torch,
                );

                if (backFacingDeviceWithTorch) {
                    initialVideoDevice = backFacingDeviceWithTorch;
                } else {
                    const backFacingDevice = videoInputDevices.find(
                        device =>
                            device.capabilities.facingMode &&
                            device.capabilities.facingMode.includes(
                                'environment',
                            ),
                    );

                    if (backFacingDevice) {
                        initialVideoDevice = backFacingDevice;
                    }
                }
            }

            setVideoDeviceId(initialVideoDevice.device.deviceId);
        }
    }, [videoDeviceId, videoInputDevices]);

    const webcamRef = useRef(null);

    const [isTorchOn, setIsTorchOn] = useState(false);
    const [isTorchAvailable, setIsTorchAvailable] = useState(false);
    const [isInteractive, setIsInteractive] = useState(false);

    const takePhoto = useCallback(() => {
        setIsInteractive(false);

        (async () => {
            if (webcamRef.current.state.hasUserMedia) {
                const canvas = webcamRef.current.getCanvas();
                if (canvas) {
                    canvas.toBlob(
                        blob => {
                            const date = moment();
                            const file = new File(
                                [blob],
                                `DriverLicense_${date.format(
                                    'YYYY-MM-DD_HH-mm-ss',
                                )}.jpg`,
                                {
                                    type: 'image/jpeg',
                                    lastModified: date.toDate(),
                                },
                            );

                            onCapture(file);

                            setIsInteractive(true);
                        },
                        webcamRef.current.props.screenshotFormat,
                        webcamRef.current.props.screenshotQuality,
                    );
                }
            }
        })();
    }, [onCapture]);

    const switchCamera = () => {
        setIsInteractive(false);

        setVideoDeviceId(prevDeviceId => {
            const currentIndex = videoInputDevices.findIndex(
                device => device.device.deviceId === prevDeviceId,
            );

            if (currentIndex === -1) {
                return prevDeviceId;
            }

            let nextIndex = currentIndex + 1;

            if (nextIndex > videoInputDevices.length - 1) {
                nextIndex = 0;
            }

            const nextDevice = videoInputDevices[nextIndex];

            setIsInteractive(true);

            return nextDevice.device.deviceId;
        });
    };

    const toggleTorch = async () => {
        if (isTorchAvailable && videoTrack) {
            setIsInteractive(false);

            setIsTorchOn(prevIsTorchOn => {
                const newIsTorchOn = !prevIsTorchOn;

                videoTrack
                    .applyConstraints({
                        advanced: [{ torch: newIsTorchOn }],
                    })
                    .then(() => {
                        setIsInteractive(true);
                    });

                return newIsTorchOn;
            });
        }
    };

    const handleUserMedia = stream => {
        const mutedTrack = stream.getTracks().find(track => track.muted);

        if (mutedTrack) {
            switchCamera();
        } else {
            const track = stream.getVideoTracks()[0];

            setVideoTrack(track);

            // The timeout is needed, because the capabilities are not present from
            // the very beginning.
            setTimeout(async () => {
                const capabilities = track.getCapabilities();

                if (capabilities.torch) {
                    await track.applyConstraints({
                        advanced: [{ torch: true }],
                    });

                    setIsTorchAvailable(true);
                    setIsTorchOn(true);
                } else {
                    setIsTorchAvailable(false);
                    setIsTorchOn(false);
                }
            }, 500);
        }

        setIsInteractive(true);
    };

    const handleUserMediaError = () => {
        switchCamera();
    };

    return (
        <ScrollLock>
            <Wrapper>
                {!videoDevice ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : (
                    <>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            screenshotQuality={1}
                            onUserMedia={handleUserMedia}
                            onUserMediaError={handleUserMediaError}
                            minScreenshotWidth={1920}
                            minScreenshotHeight={1080}
                            videoConstraints={{
                                width: 1920,
                                height: 1080,
                                deviceId: { exact: videoDeviceId },
                            }}
                        />
                        <ButtonsWrapper isVertical={isMobile && !isPortrait}>
                            <ExtraButtonPlaceholder>
                                {videoDevice.capabilities.torch && (
                                    <TorchButton
                                        onClick={toggleTorch}
                                        isOn={isTorchOn}
                                        disabled={!isInteractive}
                                    >
                                        <FlashOnSharp />
                                    </TorchButton>
                                )}
                            </ExtraButtonPlaceholder>
                            <TakePhotoButton
                                onClick={takePhoto}
                                title="Foto aufnehmen"
                                disabled={!isInteractive}
                            >
                                <PhotoCameraSharp />
                            </TakePhotoButton>
                            <ExtraButtonPlaceholder>
                                {videoInputDevices.length > 1 && (
                                    <SwitchCameraButton
                                        onClick={switchCamera}
                                        disabled={!isInteractive}
                                    >
                                        <FlipCameraIosSharp />
                                    </SwitchCameraButton>
                                )}
                            </ExtraButtonPlaceholder>
                        </ButtonsWrapper>
                    </>
                )}
                {isOverlayVisible && <TurnYourDeviceInLandscapeModeOverlay />}
                <CancelButton onClick={onCancel}>
                    <CloseSharp />
                </CancelButton>
            </Wrapper>
        </ScrollLock>
    );
};

CaptureImage.propTypes = {
    onCapture: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CaptureImage;
