import React, { useCallback, useEffect, useState } from 'react';
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getArchivedEmployeeDocuments from 'Archive/api/getArchivedEmployeeDocuments';
import moment from 'moment-timezone';
import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import { orderBy } from 'lodash';
import DocumentsTable from 'Archive/components/employee/DocumentsTable';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import getEmployee from 'Employee/api/getEmployee';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    max-width: 980px;
    margin: 15px auto 0;
`;
const Accordion = styled(MaterialAccordion)`
    margin-bottom: 30px;
    background: ${colors.DARK_GRAY} !important;
    color: ${colors.WHITE} !important;

    &:last-child {
        margin-bottom: 0;
    }
    &.MuiAccordion-root {
        &.MuiAccordion-rounded {
            border-radius: 4px;
        }
        &.MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
`;
const AccordionSummary = styled(MaterialAccordionSummary)`
    &.MuiAccordionSummary-root {
        .MuiIconButton-root {
            color: ${colors.WHITE};
        }
        .MuiAccordionSummary-content {
            margin: 25px 0;
        }
    }
`;
const AccordionDetails = styled(MaterialAccordionDetails)`
    display: block !important;
`;

const EmptyWrapper = styled.div`
    padding: 55px 20px;
    text-align: center;
    color: ${colors.ORANGE};
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const EmployeeArchive = () => {
    const [expandedMonth, setExpandedMonth] = useState(null);

    const handleToggleMonth = (month, isExpanded) => {
        setExpandedMonth(isExpanded ? month : null);
    };

    const dispatch = useDispatch();
    const { id } = useParams();

    const employeeRequest = useCallback(
        cancelToken => getEmployee(id, [], cancelToken),
        [id],
    );

    const {
        data: employee,
        loadData: loadEmployee,
        isLoading: isLoadingEmployee,
        hasError: hasEmployeeError,
        error: employeeError,
    } = useAxiosRequest(employeeRequest, null);

    useEffect(() => {
        if (employeeError?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, employeeError]);

    const archivedDocumentsRequest = useCallback(
        cancelToken => getArchivedEmployeeDocuments(id, cancelToken),
        [id],
    );

    const {
        data: archivedDocuments,
        loadData: loadArchivedDocuments,
        isLoading: isLoadingDocuments,
        hasError: hasDocumentsError,
    } = useAxiosRequest(archivedDocumentsRequest, []);

    const isLoading = isLoadingEmployee || isLoadingDocuments;
    const hasError = hasEmployeeError || hasDocumentsError;

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return (
            <RetryLoading
                onRetry={() => {
                    if (hasEmployeeError) {
                        loadEmployee();
                    }

                    if (hasDocumentsError) {
                        loadArchivedDocuments();
                    }
                }}
            />
        );
    }

    let documentsByMonth = [];

    archivedDocuments.forEach(document => {
        let month = moment(document.archivedAt.date).format('YYYY-MM');
        let index = documentsByMonth.findIndex(item => item.month === month);

        if (index > -1) {
            documentsByMonth[index].documents.push(document);
        } else {
            documentsByMonth.push({ month, documents: [document] });
        }
    });

    documentsByMonth.forEach(item => {
        item.documents = orderBy(item.documents, ['archivedAt.date'], ['desc']);
    });

    documentsByMonth = orderBy(documentsByMonth, ['month'], ['desc']);

    return (
        <>
            <Helmet>
                <title>{`${employee.firstName} ${employee.lastName} - Mitarbeiter (2020 - ) - Archive`}</title>
            </Helmet>
            <Breadcrumb to="/archive">Archiv</Breadcrumb>
            <Breadcrumb to="/archive/employees">
                Mitarbeiter (2020 - )
            </Breadcrumb>
            <Breadcrumb
                isActive
            >{`${employee.firstName} ${employee.lastName}`}</Breadcrumb>
            <Wrapper>
                {documentsByMonth.length > 0 ? (
                    documentsByMonth.map(item => {
                        const date = moment(item.documents[0].archivedAt.date);

                        return (
                            <Accordion
                                key={item.month}
                                expanded={expandedMonth === item.month}
                                onChange={(e, isExpanded) =>
                                    handleToggleMonth(item.month, isExpanded)
                                }
                            >
                                <AccordionSummary
                                    id={`documents-${item.month}-header`}
                                    aria-controls={`documents-${item.month}-content`}
                                    expandIcon={<ExpandMore />}
                                >
                                    {date.format('MMMM, YYYY')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DocumentsTable
                                        documents={item.documents}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                ) : (
                    <EmptyWrapper>
                        Es liegen derzeit keine archivierten Dokumente vor.
                    </EmptyWrapper>
                )}
            </Wrapper>
        </>
    );
};

export default EmployeeArchive;
