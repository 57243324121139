import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails';

export const SidebarAccordionPanel = styled(MaterialAccordion)`
    &.MuiAccordion-root {
        padding: 0;
        background: ${colors.DARK_GRAY};
        box-shadow: none;

        &:last-child {
            border-bottom: 1px solid ${colors.LIGHT_GRAY};
        }

        &:before {
            display: none;
        }

        &.Mui-expanded {
            margin: 0;
        }
    }
`;

export const SidebarAccordionTitle = styled(MaterialAccordionSummary)`
    &.MuiAccordionSummary-root {
        padding: 0 20px;
        flex-direction: row-reverse;
        color: ${colors.LIGHTER_GRAY};
        border-top: 1px solid ${colors.LIGHT_GRAY};

        &:focus {
            background: transparent;
        }

        &.Mui-expanded {
            background: ${colors.DARKER_GRAY};
        }

        .MuiAccordionSummary-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            &.Mui-expanded {
                margin: 20px 0;
            }
        }

        .MuiIconButton-root {
            color: ${colors.LIGHTER_GRAY};
        }

        .MuiIconButton-edgeEnd {
            margin-right: 0;
            margin-left: -12px;
        }
    }
`;

export const SidebarAccordionContent = styled(MaterialAccordionDetails)`
    &.MuiAccordionDetails-root {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 20px 20px;
        background: ${colors.DARKER_GRAY};
        color: ${colors.LIGHTER_GRAY};

        > div {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
