import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ConfirmModal from 'Common/components/ConfirmModal';
import { DeleteSharp } from '@material-ui/icons';

const WarningMessage = styled.div`
    font-size: 13px;
    color: ${colors.ORANGE};
`;

const QuestionDeleteModal = ({ questionId, onConfirm, onCancel, stats }) => (
    <ConfirmModal
        isOpen={questionId !== null}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText="Löschen"
        confirmIcon={DeleteSharp}
    >
        <h4>Möchten Sie diese Frage wirklich löschen?</h4>
        <small>
            Die Änderungen werden nicht automatisch gespeichert, Sie müssen den
            gesamten Test speichern.
        </small>
        {!!stats && stats.examsInProgressCount > 0 && (
            <WarningMessage>
                {`Hinweis: Es gibt ${stats.examsInProgressCount} Mitarbeiter, die eine Testprüfung machen`}
            </WarningMessage>
        )}
    </ConfirmModal>
);

QuestionDeleteModal.defaultProps = {
    questionId: null,
    stats: null,
};

QuestionDeleteModal.propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
};

export default QuestionDeleteModal;
