import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import getInstructions from 'Education/api/instruction/getInstructions';
import Loader from 'Common/components/Loader';
import InstructionSidebar from 'Education/components/instruction/InstructionSidebar';
import InstructionsTable from 'Education/components/instruction/InstructionsTable';
import getInstruction from 'Education/api/instruction/getInstruction';
import axios from 'axios';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 8px 0;
    border-radius: 4px;
`;

const loadProperties = [
    'presentationAttachment',
    'slidesCount',
    'questionsCount',
    'areas',
];

const InstructionListTab = () => {
    const instructionsRequest = useCallback(
        cancelToken => getInstructions(loadProperties, cancelToken),
        [],
    );

    const {
        data: instructions,
        setData: setInstructions,
        loadData,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionsRequest, []);

    const [selectedAreaIds, setSelectedAreaIds] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);

    useEffect(() => {
        setActiveFilters(prevActiveFilters => {
            const newActiveFilters = [...prevActiveFilters];

            const index = newActiveFilters.indexOf('area');

            if (selectedAreaIds.length > 0 && index === -1) {
                newActiveFilters.push('area');
            } else if (selectedAreaIds.length === 0 && index !== -1) {
                newActiveFilters.splice(index, 1);
            }

            return newActiveFilters;
        });
    }, [selectedAreaIds]);

    const handleActiveFilterChange = (filter, isChecked) => {
        setActiveFilters(prevActiveFilters => {
            const newActiveFilters = [...prevActiveFilters];

            const index = newActiveFilters.indexOf(filter);

            if (isChecked && index === -1) {
                newActiveFilters.push(filter);
            } else if (!isChecked && index !== -1) {
                newActiveFilters.splice(index, 1);
            }

            return newActiveFilters;
        });
    };

    const handleCheckboxChange = (id, isChecked) => {
        setSelectedAreaIds(prevSelectedAreaIds => {
            const newSelectedAreaIds = [...prevSelectedAreaIds];

            if (isChecked && !prevSelectedAreaIds.includes(id)) {
                newSelectedAreaIds.push(id);
            } else if (!isChecked && prevSelectedAreaIds.includes(id)) {
                const index = newSelectedAreaIds.indexOf(id);

                newSelectedAreaIds.splice(index, 1);
            }

            return newSelectedAreaIds;
        });
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleInstructionCreate = async newInstruction => {
        try {
            const response = await getInstruction(
                newInstruction.id,
                loadProperties,
                source.token,
            );

            setInstructions(prevInstructions => [
                ...prevInstructions,
                response.data,
            ]);
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleInstructionDelete = id => {
        setInstructions(prevInstructions => {
            const newInstructions = [...prevInstructions];

            const index = newInstructions.findIndex(
                instruction => instruction.id === id,
            );

            if (index !== -1) {
                newInstructions.splice(index, 1);
            }

            return newInstructions;
        });
    };

    const [searchTerm, setSearchTerm] = useState('');

    let filteredInstructions = [...instructions];

    if (searchTerm) {
        filteredInstructions = filteredInstructions.filter(instruction =>
            instruction.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }

    if (activeFilters.includes('area') && selectedAreaIds.length > 0) {
        filteredInstructions = filteredInstructions.filter(instruction => {
            const instructionAreaIds = instruction.areas.map(area => area.id);

            const intersectingAreaIds = instructionAreaIds.filter(areaId =>
                selectedAreaIds.includes(areaId),
            );

            return intersectingAreaIds.length > 0;
        });
    }

    return (
        <>
            <Wrapper>
                <InstructionSidebar
                    searchTerm={searchTerm}
                    onSearchTermChange={setSearchTerm}
                    selectedAreaIds={selectedAreaIds}
                    activeFilters={activeFilters}
                    onCheckboxChange={handleCheckboxChange}
                    onActiveFilterChange={handleActiveFilterChange}
                />
                <Content>
                    {isLoading ? (
                        <Loader />
                    ) : hasError ? (
                        <RetryLoading onRetry={loadData} />
                    ) : (
                        <InstructionsTable
                            instructions={filteredInstructions}
                            onInstructionCreate={handleInstructionCreate}
                            onInstructionDelete={handleInstructionDelete}
                        />
                    )}
                </Content>
            </Wrapper>
        </>
    );
};

export default InstructionListTab;
