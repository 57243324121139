import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import { Link as RouterLink } from 'react-router-dom';
import Button from 'Common/components/Button';

const StyledLink = styled(RouterLink)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 46px;
    padding: 5px 20px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    border-radius: 4px;

    &:focus {
        outline: 0 none;
        border-width: 2px;
    }

    ${switchProp('theme', {
        dark: css`
            background: ${colors.DARKEST_GRAY};
            border: 1px solid ${colors.LIGHT_GRAY};
            color: ${colors.LIGHTEST_GRAY};

            &:hover {
                background: ${colors.ORANGE};
                border-color: ${colors.ORANGE};
                color: ${colors.DARKEST_GRAY};
            }

            &:focus {
                border-color: ${colors.ORANGE};
            }
        `,
        light: css`
            background: ${colors.DARK_GRAY};
            border: 1px solid ${colors.LIGHT_GRAY};
            color: ${colors.LIGHTEST_GRAY};

            &:hover:not([disabled]) {
                background: ${colors.ORANGE};
                border-color: ${colors.ORANGE};
                color: ${colors.DARKEST_GRAY};
            }

            &:focus {
                border-color: ${colors.ORANGE};
            }
        `,
    })}

    > i,
    > svg {
        margin-right: 10px;
    }
`;

const LinkButton = ({ icon: Icon, disabled, children, ...props }) => {
    const hasIcon = !!Icon;

    if (disabled) {
        return (
            <Button disabled icon={Icon} {...props}>
                {children}
            </Button>
        );
    }

    return (
        <StyledLink {...props}>
            {hasIcon && <Icon size="xs" />}
            {children}
        </StyledLink>
    );
};

LinkButton.defaultProps = {
    theme: 'light',
};

export default LinkButton;
