import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

export const ActionBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    padding: 15px 20px;
    border-bottom: 1px solid ${colors.DARKER_GRAY};
    background: ${colors.DARK_GRAY};
    border-radius: 4px 4px 0 0;

    @media screen and (min-width: 940px) {
        position: sticky;
        top: 81px;
        flex-direction: row;
        justify-content: space-between;
        min-height: 69px;
    }
`;

export const ActionBarTitle = styled.h1`
    flex: 1;
    margin-top: 0;
    margin-bottom: 10px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;

    @media screen and (min-width: 680px) {
        margin-bottom: 20px;
        font-size: 18px;
    }

    @media screen and (min-width: 940px) {
        margin-bottom: 0;
    }
`;

export const ActionBarButtonsWrapper = styled.div`
    width: 100%;
    text-align: center;

    > button,
    > a {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 420px) {
        display: flex;
        align-items: center;

        > button,
        > a {
            margin-right: 20px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 580px) {
        width: auto;

        > button,
        > a {
            min-width: 220px;
        }
    }

    @media screen and (min-width: 940px) {
        margin-left: 20px;
    }
`;
