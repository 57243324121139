import React from 'react';
import { toast } from 'react-toastify';
import {
    CheckCircleOutlineSharp,
    ErrorOutlineSharp,
    InfoOutlined,
} from '@material-ui/icons';

const notify = (content, options) => {
    let Icon;

    if (options && options.type) {
        switch (options.type) {
            case toast.TYPE.ERROR:
            case toast.TYPE.WARNING:
                Icon = ErrorOutlineSharp;
                break;

            case toast.TYPE.SUCCESS:
                Icon = CheckCircleOutlineSharp;
                break;

            case toast.TYPE.INFO:
            case toast.TYPE.DEFAULT:
            default:
                Icon = InfoOutlined;
                break;
        }
    }

    const hasIcon = !!Icon;

    return toast(
        <>
            {hasIcon && <Icon />}
            {content}
        </>,
        options,
    );
};

export default notify;
