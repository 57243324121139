import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { NotificationsNoneSharp } from '@material-ui/icons';
import ConfirmModal from 'Common/components/ConfirmModal';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';
import axios from 'axios';
import markDriverLicenseAsFound from 'Measure/api/driver-license/update/markDriverLicenseAsFound';

const ModalTitle = styled.h3`
    margin-top: 0;
`;

const MarkAsFoundButton = ({ driverLicenseControl }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const openModal = () => {
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setIsModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmMarkAsFound = async () => {
        setIsLoading(true);

        try {
            await markDriverLicenseAsFound(
                driverLicenseControl.id,
                source.token,
            );

            notify(
                'Der Führerschein wurde als gefunden markiert und aktiviert',
                {
                    type: toast.TYPE.SUCCESS,
                },
            );

            dispatch(push('/measures/vehicles/driver-licenses'));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    return (
        <>
            <Button type="button" disabled={isLoading} onClick={openModal}>
                {isLoading ? <Loader size={24} /> : <NotificationsNoneSharp />}
                Führerschein gefunden
            </Button>
            {isModalOpened && (
                <ConfirmModal
                    isOpen={isModalOpened}
                    onConfirm={handleConfirmMarkAsFound}
                    onCancel={closeModal}
                    isConfirmLoading={isLoading}
                    isConfirmDisabled={isLoading}
                >
                    <ModalTitle>
                        Bestätigen Sie, dass Sie den Führerschein gefunden haben
                    </ModalTitle>
                </ConfirmModal>
            )}
        </>
    );
};

MarkAsFoundButton.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default MarkAsFoundButton;
