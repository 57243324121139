import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { TextField as MaterialTextField } from '@material-ui/core';

const StyledTextField = styled(MaterialTextField)`
    &.MuiTextField-root {
        width: 100%;

        .MuiInputBase-input {
            display: block;
            width: 100%;
            color: ${colors.WHITE};
            font-size: 14px;
            line-height: 21px;

            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px ${colors.DARK_GRAY} inset;
                -webkit-text-fill-color: ${colors.WHITE};
                caret-color: ${colors.WHITE};
            }
        }

        .MuiOutlinedInput-multiline {
            padding: 15px;
        }

        .MuiInputBase-input.Mui-disabled {
            cursor: not-allowed;
        }

        .MuiOutlinedInput-input {
            padding: 0 15px;
            height: 46px;
            background: none;

            &.MuiInputBase-inputMultiline {
                padding: 0;
            }
        }
    }

    .MuiFormLabel-root {
        color: ${colors.LIGHTER_GRAY};
        font-family: 'Open Sans', sans-serif;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.GRAY};
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.LIGHT_GRAY};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.ORANGE};
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.ERROR};
    }

    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.GRAY};
    }

    .MuiInputBase-root,
    .MuiFormLabel-root {
        font-size: 14px;
    }

    .MuiInputBase-root.Mui-disabled {
        opacity: 0.3;
        color: ${colors.GRAY};
        cursor: not-allowed;
    }

    .MuiInputLabel-outlined {
        transform: translate(14px, 16px) scale(1);
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${colors.ORANGE};
    }

    .MuiFormLabel-root.Mui-error {
        color: ${colors.ERROR};
    }
    .MuiFormLabel-root.Mui-disabled {
        color: ${colors.LIGHT_GRAY};
    }

    .MuiSvgIcon-root {
        color: ${colors.LIGHTER_GRAY};
    }
`;

export default StyledTextField;
