import React from 'react';
import {
    ArrowBackSharp,
    ArrowForwardSharp,
    CloseSharp,
} from '@material-ui/icons';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ReactPlayer from 'react-player';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import TurnYourDeviceInLandscapeModeOverlay from 'Common/components/TurnYourDeviceInLandscapeModeOverlay';
import useWindowSize from '@react-hook/window-size';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background: ${colors.DARK_GRAY};
`;

const VideoPlayerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
`;

const VideoPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;

    video {
        &:focus {
            outline: 0 none;
        }
    }
`;

const SlideArrows = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 70px;
    transform: translateY(-50%);
    pointer-events: none;
`;

const SlideShowImagePreview = styled.div`
    width: 100%;
    height:100%;
    background-image: url('${prop('src')}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 9px;
    border: 0 none;
    font-size: 14px;
    cursor: pointer;
    z-index: 1;

    &:hover,
    &:hover:not([disabled]),
    &:focus {
        outline: 0 none;
        border: 0 none;
        box-shadow: none;
        background: ${colors.DARK_GRAY};
        color: ${colors.LIGHTEST_GRAY};
    }

    svg {
        margin-right: 0;
    }
`;

const ArrowButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: ${colors.ORANGE};
    background: none;
    border: 0 none;
    cursor: pointer;
    pointer-events: all;
    opacity: 0.75;
    transition: opacity 0.3s ease-out;

    &:hover,
    &:focus {
        outline: 0 none;
    }

    &:hover {
        opacity: 1;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            opacity: 0.4;
        }
    }

    svg {
        font-size: 40px;
    }
`;

const PreviousButton = styled(ArrowButton)`
    left: 10px;
`;

const NextButton = styled(ArrowButton)`
    right: 10px;
`;

const SlideShowModal = ({
    slide,
    isFirstSlide,
    isLastSlide,
    onClose,
    onPreviousButtonClick,
    onNextButtonClick,
    ...props
}) => {
    const [width, height] = useWindowSize();

    const isPortrait = width < height;
    const isOverlayVisible = isMobile && isPortrait;

    return (
        <Wrapper {...props}>
            {slide.fileType === 'IMAGE' && (
                <SlideShowImagePreview src={slide.file} />
            )}
            {slide.fileType === 'VIDEO' && (
                <VideoPlayerWrapper>
                    <VideoPlayer
                        url={slide.file}
                        playing={!isOverlayVisible}
                        controls
                        width="100%"
                        height="100%"
                    />
                </VideoPlayerWrapper>
            )}

            <CloseButton onClick={onClose}>
                <CloseSharp />
            </CloseButton>

            <SlideArrows>
                <PreviousButton
                    type="button"
                    onClick={onPreviousButtonClick}
                    disabled={isFirstSlide}
                >
                    <ArrowBackSharp />
                </PreviousButton>
                <NextButton
                    type="button"
                    onClick={onNextButtonClick}
                    disabled={isLastSlide}
                >
                    <ArrowForwardSharp />
                </NextButton>
            </SlideArrows>
            {isOverlayVisible && <TurnYourDeviceInLandscapeModeOverlay />}
        </Wrapper>
    );
};

SlideShowModal.propTypes = {
    slide: PropTypes.object.isRequired,
    isFirstSlide: PropTypes.bool.isRequired,
    isLastSlide: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPreviousButtonClick: PropTypes.func.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
};

export default SlideShowModal;
