import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ButtonsWrapper,
    ToolbarButtons,
} from 'Measure/components/vehicle/driver-license/layout';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';
import { ArrowBackSharp, CheckSharp } from '@material-ui/icons';
import LinkButton from 'Common/components/LinkButton';

const DriverLicenseToolbarButtons = ({
    cancelButtonLink,
    cancelButtonText,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    showBackButton,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirmButtonClick = async () => {
        setIsConfirming(true);

        await onConfirm();

        setIsConfirming(false);
    };

    return (
        <ToolbarButtons>
            <ButtonsWrapper>
                <Button
                    type="button"
                    onClick={handleConfirmButtonClick}
                    disabled={isConfirming}
                >
                    {isConfirming ? <Loader size={24} /> : <CheckSharp />}
                    {confirmButtonText}
                </Button>
            </ButtonsWrapper>
            <ButtonsWrapper>
                {showBackButton && (
                    <Button
                        icon={ArrowBackSharp}
                        disabled={!onBackButtonClick}
                        onClick={onBackButtonClick}
                    >
                        Zurück
                    </Button>
                )}
                <LinkButton to={cancelButtonLink} disabled={isConfirming}>
                    {cancelButtonText}
                </LinkButton>
            </ButtonsWrapper>
        </ToolbarButtons>
    );
};

DriverLicenseToolbarButtons.defaultProps = {
    cancelButtonText: 'Abbrechen',
    onBackButtonClick: undefined,
    showBackButton: true,
};

DriverLicenseToolbarButtons.propTypes = {
    cancelButtonLink: PropTypes.string.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    showBackButton: PropTypes.bool,
};

export default DriverLicenseToolbarButtons;
