import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';

const Wrapper = styled.div`
    margin-bottom: 25px;

    ${switchProp(
        'size',
        {
            xs: css`
                flex: 1;
            `,
            sm: css`
                flex: 2;
            `,
            md: css`
                flex: 3;
            `,
            lg: css`
                flex: 4;
            `,
            xl: css`
                flex: 5;
            `,
        },
        css`
            flex: 0 0 ${prop('size')}px;
        `,
    )};
`;

const FormField = ({ size, className, ...props }) => (
    <Wrapper size={size} className={className}>
        {props.children}
    </Wrapper>
);

FormField.defaultProps = {
    size: 'md',
};

export default FormField;
