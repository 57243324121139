import api from 'Application/api/api';

const getEmployees = (load, filter, cancelToken = null) =>
    api.get('/employees', {
        params: {
            load,
            ...filter,
        },
        cancelToken,
    });

export default getEmployees;
