import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import colors from 'Common/constants/colors';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components/macro';
import Label from 'Common/components/form/Label';

const Wrapper = styled.div`
    flex: 1;
`;

const Progress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: colors.ALT_DARKEST_GRAY,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: colors.ORANGE,
    },
})(LinearProgress);

const ProgressBar = ({
    progress,
    autoLoading,
    isLoading,
    estimatedLoadingTime,
    label,
    className,
}) => {
    const [customProgress, setCustomProgress] = useState(0);

    useEffect(() => {
        let progressInterval;

        if (estimatedLoadingTime) {
            if (isLoading) {
                const step = (100 / estimatedLoadingTime) * 100;

                progressInterval = setInterval(() => {
                    setCustomProgress(prevProgress => {
                        let nextProgress = prevProgress + step;

                        if (nextProgress >= 95) {
                            nextProgress = prevProgress + step / 10;
                        }

                        if (nextProgress > 100) {
                            nextProgress = 100;
                        }

                        return nextProgress;
                    });
                }, 100);
            } else {
                setCustomProgress(0);
                clearInterval(progressInterval);
            }
        }

        return () => {
            clearInterval(progressInterval);
        };
    }, [estimatedLoadingTime, isLoading]);

    const currentProgress = autoLoading ? customProgress : progress;

    return (
        <Wrapper className={className}>
            {!!label && <Label label={label} />}
            <Progress variant="determinate" value={currentProgress} />
        </Wrapper>
    );
};

ProgressBar.defaultProps = {
    label: undefined,
    progress: undefined,
    autoLoading: false,
    isLoading: false,
    estimatedLoadingTime: undefined,
};

ProgressBar.propTypes = {
    progress: PropTypes.number,
    label: PropTypes.string,
    autoLoading: PropTypes.bool,
    isLoading: PropTypes.bool,
    estimatedLoadingTime: PropTypes.number,
};

export default ProgressBar;
