import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import Modal from 'Common/components/Modal';
import Checkbox from 'Common/components/form/fields/Checkbox';
import Loader from 'Common/components/Loader';
import Button from 'Common/components/Button';
import getLibraryInstruction from 'Education/api/library/getLibraryInstruction';
import { EyeIcon } from 'Common/components/icons';
import ReactPlayer from 'react-player';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    padding-bottom: 66px;
`;
const PreviewWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ImagePreview = styled.div`
    width: 100%;
    height:100%;
    background-image: url('${prop('src')}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;

`;

const VideoPlayerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 0;
    padding-top: 100%;
    transform: translate(-50%, -50%);
`;

const VideoPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const CountInfo = styled.div`
    text-align: center;
    color: white;
    margin-bottom: 5px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 20px;
    background: ${colors.DARK_GRAY};

    > button {
        width: 250px;
    }
`;

const Sidebar = styled.div`
    flex: 0 0 300px;
`;

const SlidesList = styled.div`
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
    margin-right: 10px;
    background: ${colors.DARKEST_GRAY};
    border-radius: 4px;
`;

const Content = styled.div`
    flex: 1;
`;

const LibraryInstructionSlideItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    text-align: left;
`;

const IconWrapper = styled.div`
    text-align: center;
`;

const IconText = styled.div`
    font-size: 28px;
`;

const SelectedLibraryForImportSlidesModal = ({
    libraryInstructionId,
    selectedSlideIds,
    onSlidesLoaded,
    onSlideChange,
    onClose,
}) => {
    const [slideIdOnPreview, setSlideIdOnPreview] = useState(null);

    const libraryInstructionRequest = useCallback(
        cancelToken => {
            if (!libraryInstructionId) {
                return null;
            }

            return getLibraryInstruction(
                libraryInstructionId,
                ['slides'],
                cancelToken,
            );
        },
        [libraryInstructionId],
    );

    const {
        data: libraryInstruction,
        loadData: loadLibraryInstruction,
        isLoading,
        hasError,
    } = useAxiosRequest(libraryInstructionRequest, null);

    useEffect(() => {
        if (libraryInstruction) {
            onSlidesLoaded(libraryInstruction.slides);
        }
    }, [libraryInstruction, onSlidesLoaded]);

    const handleSlideCheckboxChange = (slideId, isChecked) => {
        onSlideChange(slideId, isChecked);
    };

    const handleSlideItemMouseOver = slideId => {
        setSlideIdOnPreview(slideId);
    };

    const handleSlideItemMouseOut = () => {
        setSlideIdOnPreview(null);
    };

    const slideOnPreview = libraryInstruction?.slides.find(
        slide => slide.id === slideIdOnPreview,
    );

    return (
        <Modal
            isLoading={isLoading}
            isOpen={libraryInstructionId !== null}
            onRequestClose={onClose}
            isFullHeight
        >
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadLibraryInstruction} />
            ) : libraryInstruction === null ? (
                <p>Diese Unterweisung ist nicht mehr verfügbar</p>
            ) : (
                <>
                    <Wrapper>
                        <Sidebar>
                            <CountInfo>{`Folienliste (${selectedSlideIds.length} / ${libraryInstruction.slides.length})`}</CountInfo>
                            <SlidesList>
                                {libraryInstruction.slides.map(slide => (
                                    <LibraryInstructionSlideItem
                                        key={slide.id}
                                        onMouseOver={() =>
                                            handleSlideItemMouseOver(slide.id)
                                        }
                                        onMouseOut={handleSlideItemMouseOut}
                                    >
                                        <Checkbox
                                            label={slide.title}
                                            id={`slide_${slide.id}`}
                                            checked={selectedSlideIds.includes(
                                                slide.id,
                                            )}
                                            onChange={e =>
                                                handleSlideCheckboxChange(
                                                    slide.id,
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    </LibraryInstructionSlideItem>
                                ))}
                            </SlidesList>
                        </Sidebar>
                        <Content>
                            <PreviewWrapper>
                                {!slideOnPreview ? (
                                    <IconWrapper>
                                        <EyeIcon
                                            size="xl"
                                            color={colors.ORANGE}
                                        />
                                        <IconText>Vorschau</IconText>
                                    </IconWrapper>
                                ) : (
                                    <>
                                        {slideOnPreview.fileType ===
                                            'IMAGE' && (
                                            <ImagePreview
                                                src={slideOnPreview.file}
                                            />
                                        )}
                                        {slideOnPreview.fileType ===
                                            'VIDEO' && (
                                            <VideoPlayerWrapper>
                                                <VideoPlayer
                                                    url={slideOnPreview.file}
                                                    playing
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </VideoPlayerWrapper>
                                        )}
                                    </>
                                )}
                            </PreviewWrapper>
                        </Content>
                    </Wrapper>
                    <ButtonWrapper>
                        <Button onClick={onClose}>
                            Bestätigen und schließen
                        </Button>
                    </ButtonWrapper>
                </>
            )}
        </Modal>
    );
};

export default SelectedLibraryForImportSlidesModal;
