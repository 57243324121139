import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import { SingleDatePicker as ReactSingleDatePicker } from 'react-dates';
import StyledNotchedOutline from 'Common/components/form/StyledNotchedOutline';
import StyledInputLabel from 'Common/components/form/StyledInputLabel';

const Wrapper = styled.div`
    position: relative;
    width: 100%;

    &:hover {
        .MuiOutlinedInput-notchedOutline:not(.Mui-disabled) {
            border-color: ${colors.LIGHT_GRAY};
        }
    }

    ${ifProp(
        'hasError',
        css`
            .SingleDatePicker {
                .DateInput_input {
                    border-color: ${colors.RED} !important;
                }
            }
        `,
    )};
`;

const SingleDatePicker = ({
    label,
    value,
    id,
    name,
    error,
    touched,
    setFieldValue,
    setFieldTouched,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocusChange = ({ focused }) => {
        setIsFocused(focused);

        if (!focused) {
            // @TODO: Remove when Formik gets updated and the following GitHub issue gets resolved:
            //        https://github.com/formium/formik/issues/2457
            setTimeout(() => {
                setFieldTouched(name, true);
            }, 50);
        }
    };

    const hasError =
        !!error &&
        ((typeof touched === 'boolean' && touched) ||
            typeof touched === 'object');

    const hasLabel = label !== null;

    const handleChange = option => {
        setFieldValue(name, option);
    };

    const hasValue = !!value;

    return (
        <>
            <Wrapper hasError={hasError}>
                {hasLabel && (
                    <StyledInputLabel
                        id={id}
                        focused={isFocused}
                        shrink={isFocused || hasValue || hasError}
                        error={hasError}
                        disabled={props.disabled}
                    >
                        {label}
                    </StyledInputLabel>
                )}

                <ReactSingleDatePicker
                    id={id}
                    date={value}
                    onDateChange={handleChange}
                    focused={isFocused}
                    onFocusChange={handleFocusChange}
                    placeholder=""
                    displayFormat="DD.MM.YYYY"
                    numberOfMonths={1}
                    transitionDuration={0}
                    hideKeyboardShortcutsPanel
                    noBorder
                    block
                    {...props}
                />

                <StyledNotchedOutline
                    label={label}
                    labelWidth={0}
                    notched={isFocused || hasValue || hasError}
                    classes={{
                        root: `MuiOutlinedInput-notchedOutline ${
                            isFocused ? 'Mui-focused' : ''
                        } ${hasError ? 'Mui-error' : ''} ${
                            props.disabled ? 'Mui-disabled' : ''
                        } ${!hasLabel ? 'no-label' : ''}`,
                    }}
                />
            </Wrapper>

            {hasError && <ErrorMessage message={error} />}
        </>
    );
};

SingleDatePicker.defaultProps = {
    label: null,
    value: null,
    name: null,
    error: null,
    touched: false,
    setFieldValue: undefined,
    setFieldTouched: null,
};

SingleDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    name: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.any,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
};

export default SingleDatePicker;
