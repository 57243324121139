import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'Common/components/form/fields/Input';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import DriverLicenseToolbarButtons from 'Measure/components/vehicle/driver-license/check/DriverLicenseToolbarButtons';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';

const FullWidthContent = styled.div`
    margin-bottom: 10px;
    padding: 30px 20px;
    background: ${colors.DARK_GRAY};

    @media screen and (min-width: 940px) {
        display: flex;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const PasswordField = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: center;
        flex: 0 0 350px;
        width: 350px;
        margin-right: 40px;
        margin-bottom: 0;
    }
`;

const AdditionalInformation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;

    @media screen and (min-width: 940px) {
        flex: 1;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

const Hint = styled.div`
    max-width: 480px;
    font-size: 14px;
`;

const DriverLicensePasswordVerification = ({
    hint,
    cancelButtonLink,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    showBackButton,
    successMessage,
}) => {
    const [password, setPassword] = useState('');

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const handleConfirm = async () => {
        try {
            await onConfirm(password);

            notify(successMessage, {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Dein Passwort ist falsch', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <>
            <FullWidthContent>
                <PasswordField>
                    <Input
                        type="password"
                        label="Passwort"
                        value={password}
                        onChange={handlePasswordChange}
                        autoComplete="off"
                    />
                </PasswordField>
                <AdditionalInformation>
                    <Hint>{hint}</Hint>
                </AdditionalInformation>
            </FullWidthContent>
            <DriverLicenseToolbarButtons
                cancelButtonLink={cancelButtonLink}
                confirmButtonText={confirmButtonText}
                onConfirm={handleConfirm}
                onBackButtonClick={onBackButtonClick}
                showBackButton={showBackButton}
            />
        </>
    );
};

DriverLicensePasswordVerification.defaultProps = {
    onBackButtonClick: undefined,
    showBackButton: true,
    successMessage: 'Erfolgreiche Überprüfung',
};

DriverLicensePasswordVerification.propTypes = {
    hint: PropTypes.any.isRequired,
    cancelButtonLink: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    showBackButton: PropTypes.bool,
    successMessage: PropTypes.string,
};

export default DriverLicensePasswordVerification;
