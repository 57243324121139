import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { get } from 'lodash';
import { CropOriginalSharp, OndemandVideoSharp } from '@material-ui/icons';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    TrashIcon,
} from 'Common/components/icons';
import Input from 'Common/components/form/fields/Input';
import SlideShowModal from 'Education/components/instruction/presentation-edit/SlideShowModal';
import { useFullscreen } from 'react-use';
import screenfull from 'screenfull';

const Slides = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    background: ${colors.DARK_GRAY};
    border-top: 1px solid ${colors.DARKEST_GRAY};
`;

const Slide = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 140px;
    padding: 10px;
    margin-bottom: 30px;
    background: ${colors.ALT_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 520px) {
        flex-direction: row;
    }

    @media screen and (min-width: 820px) and (max-width: 1179px) {
        width: calc(50% - 10px);
        margin-right: 20px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    @media screen and (min-width: 1180px) {
        width: calc(33.33% - 14px);
        margin-right: 20px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
`;

const SlideImageWrapper = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 160px;
    background: ${colors.DARKEST_GRAY};
    color: ${colors.LIGHTER_GRAY};
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;

    &:focus {
        border: 0;
        outline: 0;
    }
`;

const SlideImage = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`;

const SlideInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    @media screen and (min-width: 520px) {
        flex: 1;
        justify-content: space-between;
        margin-top: 0;
        padding: 5px 0 0 10px;
    }
`;

const SlideInfoTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (min-width: 520px) {
        margin-bottom: 0;
    }
`;

const SlideNumber = styled.div`
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.LIGHTER_GRAY};
`;

const ActionButton = styled.button`
    flex: 0 0 15px;
    margin-left: 7px;
    padding: 0 3px;
    background: none;
    border: 0 none;
    color: ${colors.LIGHT_GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0 none;
        color: ${colors.WHITE};
    }
`;

const SlidesList = ({
    slides,
    slideValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    onSlideMoveBackwards,
    onSlideMoveForwards,
    onSlideDelete,
}) => {
    const [slideIdInSlideShow, setSlideIdInSlideShow] = useState(null);

    const isSlideShowModalOpened = slideIdInSlideShow !== null;

    const fullscreenRef = useRef(null);
    useFullscreen(fullscreenRef, isSlideShowModalOpened, {
        onClose: () => {
            if (screenfull.isEnabled) {
                setSlideIdInSlideShow(null);
            }
        },
    });

    if (slideValues.length === 0) {
        return null;
    }

    const handleSlideClick = slideId => {
        setSlideIdInSlideShow(slideId);
    };

    const handleSlideShowModalClose = () => {
        setSlideIdInSlideShow(null);
    };

    const slideInSlideShow = isSlideShowModalOpened
        ? slides.find(slide => slide.id === slideIdInSlideShow)
        : null;

    const slideInSlideShowIndex = isSlideShowModalOpened
        ? slideValues.findIndex(
              slideValue => slideValue.id === slideIdInSlideShow,
          )
        : null;

    const handleSlideShowPreviousButtonClick = () => {
        if (slideInSlideShowIndex > 0) {
            const previousSlideId = slideValues[slideInSlideShowIndex - 1].id;

            setSlideIdInSlideShow(previousSlideId);
        }
    };

    const handleSlideShowNextButtonClick = () => {
        if (slideInSlideShowIndex < slideValues.length - 1) {
            const nextSlideId = slideValues[slideInSlideShowIndex + 1].id;

            setSlideIdInSlideShow(nextSlideId);
        }
    };

    return (
        <>
            <Slides>
                {slideValues.map((slideValue, index) => {
                    const slide = slides.find(
                        slide => slide.id === slideValue.id,
                    );

                    if (!slide) {
                        return null;
                    }

                    const isFirst = index === 0;
                    const isLast = index === slideValues.length - 1;

                    const prefix = `slides[${index}]`;

                    const number = index + 1;

                    const hasFile = !!slide.file;
                    const isImage = slide.fileType === 'IMAGE';
                    const isVideo = slide.fileType === 'VIDEO';

                    const style = {};

                    if (hasFile && isImage) {
                        style.backgroundImage = `url(${slide.file})`;
                    }

                    return (
                        <Slide key={slide.id}>
                            <SlideImageWrapper
                                type="button"
                                onClick={() => handleSlideClick(slide.id)}
                            >
                                {hasFile && isImage && (
                                    <SlideImage style={style} />
                                )}
                                {hasFile && isVideo && (
                                    <OndemandVideoSharp fontSize="large" />
                                )}
                                {!hasFile && (
                                    <CropOriginalSharp fontSize="large" />
                                )}
                            </SlideImageWrapper>
                            <SlideInfo>
                                <SlideInfoTopBar>
                                    <SlideNumber>{`Folie ${number}`}</SlideNumber>
                                    {!isFirst && (
                                        <ActionButton
                                            onClick={() =>
                                                onSlideMoveBackwards(slide.id)
                                            }
                                        >
                                            <ArrowLeftIcon size="xs" />
                                        </ActionButton>
                                    )}
                                    {!isLast && (
                                        <ActionButton
                                            onClick={() =>
                                                onSlideMoveForwards(slide.id)
                                            }
                                        >
                                            <ArrowRightIcon size="xs" />
                                        </ActionButton>
                                    )}
                                    <ActionButton
                                        onClick={() => onSlideDelete(slide.id)}
                                    >
                                        <TrashIcon size="xs" />
                                    </ActionButton>
                                </SlideInfoTopBar>
                                <div>
                                    <Input
                                        label="Titel"
                                        id={`${prefix}.title`}
                                        name={`${prefix}.title`}
                                        error={get(errors, `${prefix}.title`)}
                                        touched={get(
                                            touched,
                                            `${prefix}.title`,
                                            false,
                                        )}
                                        value={slideValue.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </SlideInfo>
                        </Slide>
                    );
                })}
            </Slides>
            <div ref={fullscreenRef}>
                {isSlideShowModalOpened && (
                    <SlideShowModal
                        slide={slideInSlideShow}
                        isFirstSlide={slideInSlideShowIndex === 0}
                        isLastSlide={
                            slideInSlideShowIndex === slideValues.length - 1
                        }
                        onClose={handleSlideShowModalClose}
                        onPreviousButtonClick={
                            handleSlideShowPreviousButtonClick
                        }
                        onNextButtonClick={handleSlideShowNextButtonClick}
                    />
                )}
            </div>
        </>
    );
};

SlidesList.propTypes = {
    slides: PropTypes.array.isRequired,
    slideValues: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    onSlideMoveBackwards: PropTypes.func.isRequired,
    onSlideMoveForwards: PropTypes.func.isRequired,
    onSlideDelete: PropTypes.func.isRequired,
};

export default SlidesList;
