import colors from 'Common/constants/colors';

const reactSelectCustomStyle = {
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        zIndex: state.isFocused ? 999 : 0,
        opacity: state.isDisabled ? 0.3 : 1,
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: 46,
        paddingLeft: state.isMulti && state.hasValue ? 0 : 7,
        outline: '0 none',
        border: '0 none',
        boxShadow: 'none',
        background: 'none',
        fontSize: '14px',
        transition: 'width 100ms, height 100ms',
        cursor:
            state.isFocused && state.selectProps.isSearchable
                ? 'text'
                : 'pointer',
        '> div:first-of-type': {
            padding: '6px 10px',
        },
    }),
    input: provided => ({
        ...provided,
        padding: 0,
        color: colors.WHITE,
        '> div > input': {
            boxShadow: 'none !important',
        },
        lineHeight: '21px',
    }),
    singleValue: provided => ({
        ...provided,
        color: colors.WHITE,
        fontSize: '14px',
        lineHeight: '21px',
    }),
    placeholder: provided => ({
        ...provided,
        margin: 0,
        color: colors.LIGHT_GRAY,
        lineHeight: '21px',
    }),
    multiValue: provided => ({
        ...provided,
        alignItems: 'center',
        margin: '2px 4px 2px 0',
        padding: '2px 7px',
        borderRadius: 4,
        color: colors.ALT_DARKEST_GRAY,
        background: '#d6d6d6',
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: colors.ALT_DARKEST_GRAY,
        fontSize: '14px',
        lineHeight: '21px',
        cursor: 'default',
        padding: 0,
        paddingLeft: '6px',
    }),
    multiValueRemove: provided => ({
        ...provided,
        width: 22,
        height: 22,
        marginLeft: 5,
        borderRadius: 0,
        cursor: 'pointer',
        ':hover': {
            color: colors.ALT_DARKEST_GRAY,
        },
    }),
    menu: provided => ({
        ...provided,
        zIndex: 99999,
        margin: '4px 0',
        borderRadius: 0,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        textAlign: 'left',
    }),
    menuList: provided => ({
        ...provided,
        maxHeight: 220,
        padding: 0,
        borderRadius: 0,
    }),
    noOptionsMessage: provided => ({
        ...provided,
        background: colors.DARKEST_GRAY,
        color: colors.LIGHTER_GRAY,
    }),
    loadingMessage: provided => ({
        ...provided,
        background: colors.DARKEST_GRAY,
        color: colors.LIGHTER_GRAY,
    }),
    option: (provided, state) => {
        let background = colors.DARKEST_GRAY;

        if (state.isSelected && state.isFocused) {
            background = colors.DARK_GRAY;
        } else if (state.isSelected) {
            background = colors.DARK_GRAY;
        } else if (state.isFocused) {
            background = colors.DARK_GRAY;
        }

        return {
            ...provided,
            padding: '10px 15px',
            background: background,
            color: colors.WHITE,
            fontSize: '13px',
            lineHeight: '21px',
            cursor: 'pointer',
            ':hover': {
                background:
                    state.isSelected || state.isFocused
                        ? background
                        : colors.LIGHT_GRAY,
            },
        };
    },
    dropdownIndicator: provided => ({
        ...provided,
        color: colors.LIGHTER_GRAY,
        cursor: 'pointer',
        ':hover': {
            color: colors.WHITE,
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        color: colors.LIGHTER_GRAY,
        cursor: 'pointer',
        ':hover': {
            color: colors.WHITE,
        },
    }),
    indicatorSeparator: provided => ({
        ...provided,
        margin: '6px 0',
        background: colors.LIGHT_GRAY,
    }),
};

export default reactSelectCustomStyle;
