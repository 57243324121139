import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    CreateTableBodyRow,
    DefaultTableBody,
    DefaultTableHead,
    Table,
    TableRowActionButton,
    TableRowActions,
} from 'Common/components/table';
import InstructionAreaEditModal from 'Education/components/instruction-area/InstructionAreaEditModal';
import InstructionAreaDeleteModal from 'Education/components/instruction-area/InstructionAreaDeleteModal';
import InstructionAreaCreateModal from 'Education/components/instruction-area/InstructionAreaCreateModal';
import colors from 'Common/constants/colors';
import { EditIcon, TrashIcon } from 'Common/components/icons';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import styled from 'styled-components/macro';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const InstructionAreasTable = ({
    instructionAreas,
    onAreaCreate,
    onAreaUpdate,
    onAreaDelete,
}) => {
    const [areaIdForEdit, setAreaIdForEdit] = useState(null);
    const [areaIdForDeletion, setAreaIdForDeletion] = useState(null);
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const handleEditButtonClick = areaId => {
        setAreaIdForEdit(areaId);
    };

    const handleEditCancel = () => {
        setAreaIdForEdit(null);
    };

    const handleEditSuccess = async () => {
        await onAreaUpdate(areaIdForEdit);
        setAreaIdForEdit(null);
    };

    const handleDeleteButtonClick = areaId => {
        setAreaIdForDeletion(areaId);
    };

    const handleDeleteCancel = () => {
        setAreaIdForDeletion(null);
    };

    const handleDeleteSuccess = () => {
        if (areaIdForDeletion) {
            onAreaDelete(areaIdForDeletion);
            setAreaIdForDeletion(null);
        }
    };

    const handleCreateButtonClick = () => {
        setIsCreateModalOpened(true);
    };

    const handleCreateCancel = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateSuccess = async newInstructionArea => {
        await onAreaCreate(newInstructionArea);
        setIsCreateModalOpened(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Beschreibung',
                accessor: 'description',
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Anzahl der Unterweisungen',
                accessor: 'instructionsCount',
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            instructionAreas.map(area => {
                const isDeleteDisabled =
                    area.instructionsCount > 0 || area.employeesCount > 0;

                return {
                    name: area.name,
                    description: area.description,
                    instructionsCount: area.instructionsCount,
                    actions: (
                        <TableRowActions>
                            <TableRowActionButton
                                icon={EditIcon}
                                onClick={() => handleEditButtonClick(area.id)}
                            />
                            <TableRowActionButton
                                icon={TrashIcon}
                                onClick={() => handleDeleteButtonClick(area.id)}
                                disabled={isDeleteDisabled}
                                title={
                                    isDeleteDisabled
                                        ? 'Das Löschen von Breichen ist nicht möglich!'
                                        : undefined
                                }
                            />
                        </TableRowActions>
                    ),
                };
            }),
        [instructionAreas],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: [
                'description',
                'instructionsCount',
                'actions',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );
    return (
        <>
            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                    before={
                        <CreateTableBodyRow
                            onClick={handleCreateButtonClick}
                            columnsCount={visibleColumns.length}
                        >
                            Neuen Bereich anlegen
                        </CreateTableBodyRow>
                    }
                />
            </Table>
            {areaIdForEdit !== null && (
                <InstructionAreaEditModal
                    areaId={areaIdForEdit}
                    onCancel={handleEditCancel}
                    onSuccess={handleEditSuccess}
                />
            )}
            {areaIdForDeletion !== null && (
                <InstructionAreaDeleteModal
                    area={
                        areaIdForDeletion
                            ? instructionAreas.find(
                                  area => area.id === areaIdForDeletion,
                              )
                            : null
                    }
                    onCancel={handleDeleteCancel}
                    onSuccess={handleDeleteSuccess}
                />
            )}
            {isCreateModalOpened && (
                <InstructionAreaCreateModal
                    isOpen={isCreateModalOpened}
                    onCancel={handleCreateCancel}
                    onSuccess={handleCreateSuccess}
                />
            )}
        </>
    );
};

InstructionAreasTable.propTypes = {
    instructionAreas: PropTypes.array.isRequired,
    onAreaCreate: PropTypes.func.isRequired,
    onAreaUpdate: PropTypes.func.isRequired,
    onAreaDelete: PropTypes.func.isRequired,
};

export default InstructionAreasTable;
