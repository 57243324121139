import React from 'react';
import styled from 'styled-components/macro';
import Box from 'Application/components/Static/Box';
import { ErrorOutlineSharp } from '@material-ui/icons';
import { useHistory } from 'react-router';
import Button from 'Common/components/Button';
import { Helmet } from 'react-helmet-async';

const StyledBox = styled(Box)`
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    svg {
        font-size: 70px;
    }
`;

const BackButton = styled(Button)`
    width: 250px;
`;

const PageNotFound = () => {
    const history = useHistory();

    const handleBackButtonClick = () => {
        history.goBack();
    };

    return (
        <StyledBox>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <ErrorOutlineSharp />
            <h1>Error 404</h1>
            <h2>Seite nicht gefunden</h2>
            <BackButton type="button" onClick={handleBackButtonClick}>
                Zurück
            </BackButton>
        </StyledBox>
    );
};

export default PageNotFound;
