import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import getPendingDriverLicenseControls from 'Measure/api/driver-license/getPendingDriverLicenseControls';
import DriverLicenseControlsGrid from 'Measure/components/vehicle/driver-license/DriverLicenseControlsGrid';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import driverLicensePendingFilters from 'Measure/constants/driverLicensePendingFilters';

const doNothing = () => {};

const PendingDriverLicenseControlsTab = ({ selectedPendingFilter }) => {
    const driverLicenseControlsRequest = useCallback(
        cancelToken =>
            getPendingDriverLicenseControls(
                [
                    'employee',
                    'driverLicense',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.directProcedure',
                    'driverLicense.nextCheck.distanceProcedure',
                    'driverLicense.previousCheck',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: driverLicenseControls,
        setData: setDriverLicenseControls,
        loadData: loadDriverLicenseControls,
        isLoading,
        hasError,
    } = useAxiosRequest(driverLicenseControlsRequest, []);

    const handleDriverLicenseControlDeactivate = async driverLicenseControl => {
        setDriverLicenseControls(prevDriverLicenseControls => {
            const newDriverLicenseControls = [...prevDriverLicenseControls];

            const index = newDriverLicenseControls.findIndex(
                control => control.id === driverLicenseControl.id,
            );

            if (index !== -1) {
                newDriverLicenseControls.splice(index, 1);
            }

            return newDriverLicenseControls;
        });
    };

    const pendingDriverLicenseControls = useMemo(() => {
        let filteredDriverLicenseControls = [...driverLicenseControls];

        if (selectedPendingFilter === driverLicensePendingFilters.MY_PENDING) {
            filteredDriverLicenseControls = filteredDriverLicenseControls.filter(
                driverLicenseControl =>
                    (driverLicenseControl.driverLicense
                        .isUsingDirectControlMethod &&
                        driverLicenseControl.driverLicense.nextCheck
                            .directProcedure.isPendingExaminerVerification) ||
                    (driverLicenseControl.driverLicense
                        .isUsingDistanceControlMethod &&
                        driverLicenseControl.driverLicense.nextCheck
                            .distanceProcedure.isPendingExaminerVerification),
            );
        } else if (
            selectedPendingFilter ===
            driverLicensePendingFilters.EMPLOYEE_PENDING
        ) {
            filteredDriverLicenseControls = filteredDriverLicenseControls.filter(
                driverLicenseControl =>
                    (driverLicenseControl.driverLicense
                        .isUsingDirectControlMethod &&
                        driverLicenseControl.driverLicense.nextCheck
                            .directProcedure.isPendingEmployeeConfirmation) ||
                    (driverLicenseControl.driverLicense
                        .isUsingDistanceControlMethod &&
                        driverLicenseControl.driverLicense.nextCheck
                            .distanceProcedure.isPendingEmployeeFileUpload),
            );
        }

        return filteredDriverLicenseControls;
    }, [driverLicenseControls, selectedPendingFilter]);

    return (
        <DriverLicenseControlsGrid
            driverLicenseControls={pendingDriverLicenseControls}
            onDriverLicenseControlActivate={doNothing}
            onDriverLicenseControlDeactivate={
                handleDriverLicenseControlDeactivate
            }
            isLoading={isLoading}
            hasError={hasError}
            onReload={loadDriverLicenseControls}
        />
    );
};

PendingDriverLicenseControlsTab.propTypes = {
    selectedPendingFilter: PropTypes.string.isRequired,
};

export default PendingDriverLicenseControlsTab;
