import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    padding: 10px 0;
    margin-bottom: 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: flex-start;
    }
`;

const Title = styled.h5`
    margin: 0;
    color: ${colors.WHITE};
    font-size: 18px;
    line-height: 28px;

    @media screen and (min-width: 940px) {
        flex: 1;
        order: 1;
    }
`;

const Image = styled.img`
    display: block;
    max-height: 160px;
    max-width: 100%;
    margin-bottom: 40px;

    @media screen and (min-width: 940px) {
        order: 2;
        width: auto;
        height: auto;
        max-width: 350px;
        max-height: 220px;
        margin-left: 40px;
        margin-bottom: 0;
    }
`;

const QuestionTitle = ({ question }) => {
    const hasImage = question.image !== null;

    return (
        <Wrapper>
            {hasImage && <Image src={question.image} alt={question.title} />}
            <Title>{question.title}</Title>
        </Wrapper>
    );
};

QuestionTitle.propTypes = {
    question: PropTypes.object.isRequired,
};

export default QuestionTitle;
