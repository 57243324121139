import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DefaultTable, TableRowActions } from 'Common/components/table';
import colors from 'Common/constants/colors';
import {
    examStatuses,
    examStatusOptions,
} from 'Education/constants/examStatuses';
import {
    presentationStatuses,
    presentationStatusOptions,
} from 'Education/constants/presentationStatuses';
import { DownloadIcon, GroupIcon } from 'Common/components/icons';
import HamburgerButton from 'Common/components/HamburgerButton';
import ExternalLink from 'Common/components/ExternalLink';
import Link from 'Common/components/Link';
import {
    CheckSharp,
    CloseSharp,
    LibraryAddCheckSharp,
    PriorityHighSharp,
} from '@material-ui/icons';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useOnClickOutside } from 'crooks';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';

const CheckTableIcon = styled(CheckSharp)`
    color: ${colors.ORANGE};
`;
const CrossTableIcon = styled(CloseSharp)`
    color: ${colors.ALT_DARKEST_GRAY};
`;
const ExclamationTableIcon = styled(PriorityHighSharp)`
    color: ${colors.ALT_DARKEST_GRAY};
`;

const StyledTable = styled(DefaultTable)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const Actions = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 260px;
    padding: 20px 45px 20px 20px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        i,
        svg {
            margin-right: 10px;
        }

        svg {
            width: 15px;
            height: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const InstructionAssignmentsTableManagerView = ({
    instructionAssignments,
    employeeId,
    showOnlyOpened,
    tabNumber,
}) => {
    const fromTabQueryParam = tabNumber ? `&fromTab=${tabNumber}` : '';

    const [openedActionsMenuId, setOpenedActionsMenuId] = useState(null);

    const handleClickOutsideActions = () => {
        setOpenedActionsMenuId(null);
    };

    const actionsRef = useOnClickOutside(
        handleClickOutsideActions,
        openedActionsMenuId === null,
    );

    const handleHamburgerButtonClick = id => {
        setOpenedActionsMenuId(prevOpenedActionsMenuId => {
            if (prevOpenedActionsMenuId === id) {
                return null;
            }

            return id;
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Unterweisungen',
                accessor: 'name',
            },
            {
                Header: '',
                accessor: 'group',
                disableSortBy: true,
            },
            {
                Header: 'Dauer (Min.)',
                accessor: 'timeSpent',
                disableSortBy: true,
                customCellStyles: {
                    minWidth: 150,
                },
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Gelesen',
                accessor: 'presentationStatus',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Test',
                accessor: 'examStatus',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        let filteredInstructionAssignments = [...instructionAssignments];

        if (showOnlyOpened) {
            filteredInstructionAssignments = filteredInstructionAssignments.filter(
                assignment => assignment.examStatus !== examStatuses.COMPLETED,
            );
        }

        return filteredInstructionAssignments.map(assignment => {
            const timeSpent = Math.ceil(
                (assignment.timeSpentOnPresentationInSeconds +
                    assignment.timeSpentOnExamInSeconds) /
                    60,
            );

            const recommendedTime =
                assignment.recommendedPresentationDurationInMinutes +
                assignment.recommendedTestDurationInMinutes;

            const presentationIconTitle = presentationStatusOptions.find(
                option => option.value === assignment.presentationStatus,
            )?.label;

            let PresentationStatusIcon = CrossTableIcon;
            if (
                assignment.presentationStatus ===
                presentationStatuses.IN_PROGRESS
            ) {
                PresentationStatusIcon = ExclamationTableIcon;
            } else if (
                assignment.presentationStatus === presentationStatuses.COMPLETED
            ) {
                PresentationStatusIcon = CheckTableIcon;
            }

            const testIconTitle = examStatusOptions.find(
                option => option.value === assignment.examStatus,
            )?.label;

            let TestStatusIcon = CrossTableIcon;
            if (
                [
                    examStatuses.IN_PROGRESS,
                    examStatuses.IN_APPROVAL,
                    examStatuses.FAILED,
                ].includes(assignment.examStatus)
            ) {
                TestStatusIcon = ExclamationTableIcon;
            } else if (assignment.examStatus === examStatuses.COMPLETED) {
                TestStatusIcon = CheckTableIcon;
            }

            let areActionsOpened = openedActionsMenuId === assignment.id;

            const assigneesList = assignment.groupAssignmentDetails?.assignees
                ?.map(
                    assignee =>
                        `${assignee.employee.firstName} ${assignee.employee.lastName}`,
                )
                ?.join(', ');

            const isAssignee =
                assignment.isSingleAssignment ||
                (assignment.isGroupAssignment &&
                    assignment.groupAssignmentDetails?.assignees.findIndex(
                        assignee => assignee.employee.id === employeeId,
                    ) !== -1);

            return {
                name: assignment.name,
                group: assignment.isGroupAssignment ? (
                    <div title={assigneesList}>
                        <GroupIcon size="sm" />
                    </div>
                ) : (
                    ''
                ),
                timeSpent: `${timeSpent} / ${recommendedTime}`,
                presentationStatus: (
                    <div title={presentationIconTitle}>
                        <PresentationStatusIcon />
                    </div>
                ),
                examStatus: (
                    <div title={testIconTitle}>
                        <TestStatusIcon />
                    </div>
                ),
                actions: [
                    examStatuses.COMPLETED,
                    examStatuses.IN_APPROVAL,
                    examStatuses.FAILED,
                ].includes(assignment.examStatus) && (
                    <TableRowActions ref={actionsRef}>
                        <HamburgerButton
                            isOpened={areActionsOpened}
                            onClick={e => {
                                e.stopPropagation();
                                handleHamburgerButtonClick(assignment.id);
                            }}
                        />
                        {areActionsOpened && (
                            <Actions>
                                {assignment.examStatus ===
                                    examStatuses.COMPLETED && (
                                    <>
                                        {isAssignee && (
                                            <ExternalLink
                                                href={`/api/education/instruction-assignments/${assignment.id}/certificates/single/download/${employeeId}`}
                                                target="_blank"
                                            >
                                                <DownloadIcon size="xs" />
                                                Zertifikat
                                            </ExternalLink>
                                        )}
                                        {assignment.isGroupAssignment && (
                                            <ExternalLink
                                                href={`/api/education/instruction-assignments/${assignment.id}/certificates/group/download`}
                                                target="_blank"
                                            >
                                                <DownloadIcon size="xs" />
                                                Gruppenzertifikat
                                            </ExternalLink>
                                        )}
                                    </>
                                )}
                                <Link
                                    to={`/education/assignment/exam/preview/${assignment.id}?comingFromEmployeeId=${employeeId}${fromTabQueryParam}`}
                                >
                                    <LibraryAddCheckSharp />
                                    Test anzeigen
                                </Link>
                            </Actions>
                        )}
                    </TableRowActions>
                ),
            };
        });
    }, [
        actionsRef,
        employeeId,
        fromTabQueryParam,
        instructionAssignments,
        openedActionsMenuId,
        showOnlyOpened,
    ]);

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: [
                'timeSpent',
                'presentationStatus',
                'group',
                'examStatus',
                'actions',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <StyledTable
            headerGroups={headerGroups}
            rows={rows}
            hiddenColumns={hiddenColumns}
            visibleColumns={visibleColumns}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
        />
    );
};

InstructionAssignmentsTableManagerView.defaultProps = {
    tabNumber: null,
};

InstructionAssignmentsTableManagerView.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    employeeId: PropTypes.number.isRequired,
    showOnlyOpened: PropTypes.bool.isRequired,
    tabNumber: PropTypes.number,
};

export default InstructionAssignmentsTableManagerView;
