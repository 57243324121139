import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';

const StyledLink = styled.a`
    color: ${colors.WHITE};
    text-decoration: none;

    &:hover {
        color: ${colors.ORANGE};
    }

    ${ifProp(
        'isDisabled',
        css`
            cursor: not-allowed;
            color: ${colors.LIGHT_GRAY};
        `,
    )}
`;

const ExternalLink = ({ icon: Icon, isDisabled, href, children, ...props }) => {
    const hasIcon = !!Icon;

    return (
        <StyledLink
            isDisabled={isDisabled}
            href={isDisabled ? null : href}
            {...props}
        >
            {hasIcon && <Icon size="xs" />}
            {children}
        </StyledLink>
    );
};

ExternalLink.defaultProps = {
    isDisabled: false,
};

ExternalLink.propTypes = {
    isDisabled: PropTypes.bool,
};

export default ExternalLink;
