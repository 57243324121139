import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import LinkButton from 'Common/components/LinkButton';
import ConfirmModal from 'Common/components/ConfirmModal';
import SelectedLibrariesForImportTable from 'Education/components/library/SelectedLibrariesForImportTable';
import SelectedLibraryForImportSlidesModal from 'Education/components/library/SelectedLibraryForImportSlidesModal';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100vh - 151px);
    background: ${colors.DARK_GRAY};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    form {
        padding-bottom: 85px;
    }
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    bottom: 0;
    z-index: 1;
    width: calc(100% - 600px);
    padding: 20px;
    background: ${colors.DARK_GRAY};

    > a,
    > button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const formFieldPrefix = 'libraryInstructions';

const preventDefault = e => {
    e.preventDefault();
};

const SelectedLibrariesForImport = ({
    libraryInstructions,
    libraryInstructionValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isImporting,
    isImportModalOpened,
    onImportButtonClick,
    onImportCancel,
}) => {
    const [
        openedSlidesModalLibraryInstructionId,
        setOpenedSlidesModalLibraryInstructionId,
    ] = useState(null);

    const handleSlidesLoaded = useCallback(
        slides => {
            const newLibraryInstructions = [...libraryInstructionValues];

            const index = newLibraryInstructions.findIndex(
                libraryInstruction =>
                    libraryInstruction.id ===
                    openedSlidesModalLibraryInstructionId,
            );

            if (index !== -1) {
                const libraryInstruction = newLibraryInstructions[index];

                if (libraryInstruction.slideIds === null) {
                    newLibraryInstructions[index] = {
                        ...libraryInstruction,
                        slideIds: slides.map(slide => slide.id),
                    };

                    setFieldValue(
                        'libraryInstructions',
                        newLibraryInstructions,
                        false,
                    );
                }
            }
        },
        [
            openedSlidesModalLibraryInstructionId,
            setFieldValue,
            libraryInstructionValues,
        ],
    );

    // Update the selected slides when the checkboxes are changed.
    const handleSlideChanged = useCallback(
        (slideId, isChecked) => {
            const newLibraryInstructions = [...libraryInstructionValues];

            const libraryInstructionIndex = newLibraryInstructions.findIndex(
                libraryInstruction =>
                    libraryInstruction.id ===
                    openedSlidesModalLibraryInstructionId,
            );

            if (libraryInstructionIndex !== -1) {
                const libraryInstruction =
                    newLibraryInstructions[libraryInstructionIndex];

                const newSlideIds = [...libraryInstruction.slideIds];

                const slideIdIndex = newSlideIds.indexOf(slideId);

                if (isChecked && slideIdIndex === -1) {
                    newSlideIds.push(slideId);
                } else if (!isChecked && slideIdIndex !== -1) {
                    newSlideIds.splice(slideIdIndex, 1);
                }

                newLibraryInstructions[libraryInstructionIndex] = {
                    ...libraryInstruction,
                    slideIds: newSlideIds,
                };

                setFieldValue(
                    'libraryInstructions',
                    newLibraryInstructions,
                    false,
                );
            }
        },
        [
            openedSlidesModalLibraryInstructionId,
            setFieldValue,
            libraryInstructionValues,
        ],
    );

    const handleOpenSlidesButtonClick = libraryInstructionId => {
        setOpenedSlidesModalLibraryInstructionId(libraryInstructionId);
    };

    const handleSlidesModalClose = () => {
        setOpenedSlidesModalLibraryInstructionId(null);
    };

    let openedLibraryInstructionSelectedSlideIds = [];

    if (openedSlidesModalLibraryInstructionId) {
        openedLibraryInstructionSelectedSlideIds =
            libraryInstructionValues.find(
                libraryInstructionValue =>
                    libraryInstructionValue.id ===
                    openedSlidesModalLibraryInstructionId,
            )?.slideIds ?? [];
    }

    return (
        <Wrapper>
            <form onSubmit={preventDefault}>
                <SelectedLibrariesForImportTable
                    libraryInstructions={libraryInstructions}
                    libraryInstructionValues={libraryInstructionValues}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    formFieldPrefix={formFieldPrefix}
                    onOpenSlidesButtonClick={handleOpenSlidesButtonClick}
                />
                <ButtonsWrapper>
                    <LinkButton to="/education">Zurück</LinkButton>
                    {libraryInstructionValues.length > 0 && (
                        <Button
                            type="button"
                            onClick={onImportButtonClick}
                            disabled={!isValid}
                            title={
                                !isValid
                                    ? 'Füllen Sie alle Felder aus'
                                    : undefined
                            }
                        >
                            Importieren
                        </Button>
                    )}
                </ButtonsWrapper>
            </form>
            <SelectedLibraryForImportSlidesModal
                libraryInstructionId={openedSlidesModalLibraryInstructionId}
                selectedSlideIds={openedLibraryInstructionSelectedSlideIds}
                onSlidesLoaded={handleSlidesLoaded}
                onSlideChange={handleSlideChanged}
                onClose={handleSlidesModalClose}
            />
            <ConfirmModal
                isOpen={isImportModalOpened}
                isConfirmLoading={isImporting}
                onConfirm={handleSubmit}
                onCancel={onImportCancel}
                confirmText="Importieren"
            >
                Möchten Sie die Unterweisung/en importieren?
            </ConfirmModal>
        </Wrapper>
    );
};

SelectedLibrariesForImport.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    libraryInstructionValues: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    isImporting: PropTypes.bool.isRequired,
    isImportModalOpened: PropTypes.bool.isRequired,
    onImportButtonClick: PropTypes.func.isRequired,
    onImportCancel: PropTypes.func.isRequired,
};

export default SelectedLibrariesForImport;
