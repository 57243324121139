import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import ExternalLink from 'Common/components/ExternalLink';
import { EditIcon } from 'Common/components/icons';

const Wrapper = styled.div`
    order: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;
`;

const EditLink = styled(ExternalLink)`
    position: absolute;
    right: 20px;

    i {
        font-size: 22px;
    }
`;

const EmployeeTile = ({ employee }) => {
    const role = useSelector(state => state.user.role);

    return (
        <Wrapper>
            {`${employee.firstName} ${employee.lastName}`}
            {role === roles.MANAGER && (
                <EditLink
                    href={`/backend/m/exec/call?emp=${employee.id}&openEdit=1`}
                >
                    <EditIcon size="sm" />
                </EditLink>
            )}
        </Wrapper>
    );
};

EmployeeTile.propTypes = {
    employee: PropTypes.object.isRequired,
};

export default EmployeeTile;
