import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ConfirmModal from 'Common/components/ConfirmModal';
import { WarningSharp } from '@material-ui/icons';

const WarningMessage = styled.div`
    font-size: 13px;
    color: ${colors.ORANGE};

    svg {
        font-size: 36px !important;
        margin-bottom: 5px;
    }
`;

const ModalMessageRow = styled.div`
    margin-bottom: 25px;
`;

const InstructionSaveModal = ({
    isOpen,
    isConfirmLoading,
    onConfirm,
    onCancel,
    stats,
    keepReference,
}) => (
    <ConfirmModal
        isOpen={isOpen}
        isConfirmLoading={isConfirmLoading}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText="Speichern"
    >
        <h4>Alle an der Präsentation vorgenommenen Änderungen speichern?</h4>
        {!!stats && stats.presentationsInProgressCount > 0 && (
            <ModalMessageRow>
                <small>
                    Hinweis: Der Fortschritt des Mitarbeiters wird zurückgesetzt
                </small>
                <WarningMessage>
                    {`Es gibt ${stats.presentationsInProgressCount} Mitarbeiter, die eine Präsentation halten`}
                </WarningMessage>
            </ModalMessageRow>
        )}
        {keepReference && (
            <ModalMessageRow>
                <WarningMessage>
                    <WarningSharp />
                    <p>
                        Sie haben eine Unterweisung mit einer Referenz auf die
                        Bibliothek geändert. Alle Änderungen gehen verloren,
                        wenn Amedic die Bibliothek synchronisiert. Stellen Sie
                        sicher, dass Sie Ihre Unterweisung von der Bibliothek
                        trennen, um überschriebene Daten zu vermeiden. Sie
                        können zurückgehen und "Ohne Referenz" aus der
                        Dropdown-Liste "Bibliothek Referenz" auswählen.
                    </p>
                </WarningMessage>
            </ModalMessageRow>
        )}
    </ConfirmModal>
);

InstructionSaveModal.defaultProps = {
    stats: null,
    keepReference: null,
};

InstructionSaveModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirmLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
    keepReference: PropTypes.bool,
};

export default InstructionSaveModal;
