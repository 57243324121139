import { useCallback, useEffect, useRef } from 'react';
import useSize from '@react-hook/size';

const actions = {};

actions.setColumnVisibleAtWidth = 'setColumnVisibleAtWidth';

const pluginName = 'useResponsiveTable';

export const useResponsiveTable = hooks => {
    hooks.stateReducers.push(reducer);
    hooks.useInstance.push(useInstance);
    hooks.getTableProps.push(getTableProps);
};

useResponsiveTable.pluginName = pluginName;

const reducer = (state, action) => {
    if (action.type === actions.init) {
        return {
            ...state,
            columnsVisibleAtWidth: {},
        };
    }

    if (action.type === actions.setColumnVisibleAtWidth) {
        return {
            ...state,
            columnsVisibleAtWidth: {
                ...state.columnsVisibleAtWidth,
                [action.columnId]: action.width,
            },
        };
    }
};

const useInstance = instance => {
    const {
        dispatch,
        hideColumnsPriority,
        state: { hiddenColumns, columnsVisibleAtWidth },
    } = instance;

    const setColumnVisibleAtWidth = useCallback(
        (columnId, width) =>
            dispatch({
                type: actions.setColumnVisibleAtWidth,
                columnId,
                width,
            }),
        [dispatch],
    );

    Object.assign(instance, {
        setColumnVisibleAtWidth,
    });

    const wrapperRef = useRef(null);
    const [wrapperWidth] = useSize(wrapperRef);

    instance.wrapperRef = wrapperRef;

    const nextColumnIndex =
        !hiddenColumns || hiddenColumns.length === 0 ? 0 : hiddenColumns.length;

    const hideColumnId =
        hideColumnsPriority.length > nextColumnIndex
            ? hideColumnsPriority[nextColumnIndex]
            : null;

    const previousColumnIndex =
        !hiddenColumns || hiddenColumns.length === 0
            ? null
            : hiddenColumns.length - 1;

    const showColumnId =
        previousColumnIndex !== null
            ? hideColumnsPriority[previousColumnIndex]
            : null;

    useEffect(() => {
        if (wrapperRef.current) {
            let tableWidth = wrapperRef.current.querySelector('table')
                .scrollWidth;

            if (wrapperWidth > 0) {
                if (wrapperWidth < tableWidth && hideColumnId !== null) {
                    instance.setColumnVisibleAtWidth(
                        hideColumnId,
                        wrapperWidth,
                    );
                    instance.toggleHideColumn(hideColumnId);
                } else if (
                    showColumnId !== null &&
                    columnsVisibleAtWidth &&
                    wrapperWidth >= tableWidth &&
                    wrapperWidth > columnsVisibleAtWidth[showColumnId]
                ) {
                    instance.toggleHideColumn(showColumnId);
                }
            }
        }
    }, [
        wrapperWidth,
        instance,
        hideColumnId,
        showColumnId,
        columnsVisibleAtWidth,
    ]);
};

const getTableProps = (props, { instance }) => [
    props,
    {
        wrapperRef: instance.wrapperRef,
    },
];
