import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import driverLicenseStatusFilters from 'Measure/constants/driverLicenseStatusFilters';
import getAllDriverLicenseControls from 'Measure/api/driver-license/getAllDriverLicenseControls';
import DriverLicenseControlsGrid from 'Measure/components/vehicle/driver-license/DriverLicenseControlsGrid';
import getDriverLicenseControl from 'Measure/api/driver-license/getDriverLicenseControl';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const loadProperties = [
    'employee',
    'driverLicense',
    'driverLicense.nextCheck',
    'driverLicense.previousCheck',
];

const filterBySearchTerm = (driverLicenseControls, searchTerm) =>
    driverLicenseControls.filter(
        driverLicenseControl =>
            driverLicenseControl.employee.firstName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
            driverLicenseControl.employee.lastName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()),
    );

const AllDriverLicenseControlsTab = ({
    searchTerm,
    selectedStatus,
    onDriverLicenseControlsFound,
}) => {
    const driverLicenseControlsRequest = useCallback(
        cancelToken => getAllDriverLicenseControls(loadProperties, cancelToken),
        [],
    );

    const {
        data: allDriverLicenseControls,
        setData: setAllDriverLicenseControls,
        loadData: loadAllDriverLicenseControls,
        isLoading,
        hasError,
    } = useAxiosRequest(driverLicenseControlsRequest, []);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleDriverLicenseControlStatusChange = async driverLicenseControl => {
        try {
            const response = await getDriverLicenseControl(
                driverLicenseControl.id,
                loadProperties,
                source.token,
            );

            setAllDriverLicenseControls(prevAllDriverLicenseControls => {
                const newDriverLicenseControls = [
                    ...prevAllDriverLicenseControls,
                ];

                const index = newDriverLicenseControls.findIndex(
                    control => control.id === driverLicenseControl.id,
                );

                if (index !== -1) {
                    newDriverLicenseControls[index] = response.data;
                }

                return newDriverLicenseControls;
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const driverLicenseControls = useMemo(() => {
        let filteredDriverLicenseControls = [...allDriverLicenseControls];

        if (searchTerm) {
            filteredDriverLicenseControls = filterBySearchTerm(
                filteredDriverLicenseControls,
                searchTerm,
            );
        }

        if (selectedStatus === driverLicenseStatusFilters.ACTIVE) {
            filteredDriverLicenseControls = filteredDriverLicenseControls.filter(
                driverLicenseControl => driverLicenseControl.isActive,
            );
        } else if (selectedStatus === driverLicenseStatusFilters.INACTIVE) {
            filteredDriverLicenseControls = filteredDriverLicenseControls.filter(
                driverLicenseControl => !driverLicenseControl.isActive,
            );
        }

        return filteredDriverLicenseControls;
    }, [allDriverLicenseControls, searchTerm, selectedStatus]);

    useEffect(() => {
        if (searchTerm) {
            onDriverLicenseControlsFound(
                filterBySearchTerm(allDriverLicenseControls, searchTerm),
            );
        }
    }, [allDriverLicenseControls, onDriverLicenseControlsFound, searchTerm]);

    return (
        <DriverLicenseControlsGrid
            isLoading={isLoading}
            hasError={hasError}
            onReload={loadAllDriverLicenseControls}
            driverLicenseControls={driverLicenseControls}
            onDriverLicenseControlActivate={
                handleDriverLicenseControlStatusChange
            }
            onDriverLicenseControlDeactivate={
                handleDriverLicenseControlStatusChange
            }
        />
    );
};

AllDriverLicenseControlsTab.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onDriverLicenseControlsFound: PropTypes.func.isRequired,
};

export default AllDriverLicenseControlsTab;
