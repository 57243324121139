import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CheckSharp, CloseSharp } from '@material-ui/icons';
import Modal from 'Common/components/Modal';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';

const Content = styled.div`
    text-align: center;
`;

const ButtonsWrapper = styled.div`
    margin-top: 30px;

    button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 520px) {
        display: flex;

        button {
            margin-right: 20px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const ConfirmModal = ({
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    confirmIcon: CustomConfirmIcon,
    isConfirmLoading,
    isConfirmDisabled,
    children,
    ...props
}) => {
    const ConfirmIcon = CustomConfirmIcon ?? CheckSharp;

    return (
        <Modal
            {...props}
            onRequestClose={isConfirmLoading ? undefined : onCancel}
        >
            <Content>{children}</Content>
            {!props.isLoading && (
                <ButtonsWrapper>
                    <Button
                        type="button"
                        onClick={onCancel}
                        disabled={isConfirmLoading}
                        outline
                    >
                        <CloseSharp />
                        {cancelText ?? 'Abbrechen'}
                    </Button>
                    <Button
                        type="button"
                        onClick={onConfirm}
                        disabled={isConfirmLoading || isConfirmDisabled}
                    >
                        {isConfirmLoading ? (
                            <Loader size={24} />
                        ) : (
                            <ConfirmIcon />
                        )}
                        {confirmText ?? 'Bestätigen'}
                    </Button>
                </ButtonsWrapper>
            )}
        </Modal>
    );
};

ConfirmModal.defaultProps = {
    confirmText: null,
    cancelText: null,
    confirmIcon: undefined,
    isConfirmLoading: false,
    isConfirmDisabled: false,
};

ConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmIcon: PropTypes.elementType,
    isConfirmLoading: PropTypes.bool,
    isConfirmDisabled: PropTypes.bool,
};

export default ConfirmModal;
