import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import { DefaultTable } from 'Common/components/table';
import colors from 'Common/constants/colors';
import {
    CheckSharp,
    CloseSharp,
    MailOutlineSharp,
    UpdateSharp,
    WarningSharp,
} from '@material-ui/icons';
import moment from 'moment-timezone';
import { ifProp } from 'styled-tools';
import Draggable from 'react-draggable';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    margin-top: 20px;
    padding: 0 10px 10px;
    border-top: 10px solid ${colors.DARKER_GRAY};
    background: ${colors.DARKER_GRAY};
    border-radius: 4px;
    overflow-y: auto;
    max-height: 342px;

    @media screen and (min-width: 1090px) {
        padding: 0 20px 20px;
        border-top-width: 20px;
    }
`;

const BorderlessButton = styled.button`
    background: none;
    border: 0 none;
    outline: 0 none;
    padding: 0;
    margin: 0;
    color: ${ifProp('isActive', colors.ORANGE, colors.LIGHTEST_GRAY)};
    cursor: pointer;
    text-align: left;
`;

const DraggableContentWrapper = styled.div`
    position: fixed;
    z-index: 9999;
    cursor: move;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.4), 0 0 0 1px rgb(0, 0, 0, 0.17);
    border-radius: 4px;
    overflow: hidden;

    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: ${colors.ALT_GRAY};
        border: 0;
        border-radius: 0 0 0 4px;

        svg {
            margin-right: 0;
        }
    }
`;

const DraggableContent = styled.div`
    width: 100%;
    max-width: 550px;
    min-width: 350px;
    background: ${colors.ALT_GRAY};
    padding: 20px 50px 20px 20px;
`;

const Label = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
`;

const RecipientsList = styled.div`
    margin-bottom: 15px;
`;

const Recipient = styled.div`
    margin-bottom: 5px;
    font-size: 14px;
    color: ${colors.LIGHTER_GRAY};

    &:last-child {
        margin-bottom: 0;
    }
`;

const EmailSubject = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
    color: ${colors.LIGHTER_GRAY};
`;

const EmailBody = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const PendingNotInitiatedIcon = styled(UpdateSharp)`
    color: ${colors.LIGHTEST_GRAY};
`;

const PendingInProgressIcon = styled(UpdateSharp)`
    color: ${colors.ORANGE};
`;

const OverdueIcon = styled(WarningSharp)`
    color: ${colors.ORANGE};
`;

const CompletedIcon = styled(CheckSharp)`
    color: ${colors.ORANGE};
`;

const StyledTable = styled(DefaultTable)`
    th {
        top: 0;
        background: ${colors.DARKER_GRAY};
    }
`;

const DriverLicenseSentEmailsTable = ({ driverLicenseControl, sentEmails }) => {
    const [openedEmailId, setOpenedEmailId] = useState(null);

    const handleEmailButtonClick = sentEmailId => {
        setOpenedEmailId(prevOpenedEmailId => {
            if (prevOpenedEmailId === sentEmailId) {
                return null;
            }

            return sentEmailId;
        });
    };

    const handleEmailClose = () => {
        setOpenedEmailId(null);
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'emailIcon',
                customCellStyles: {
                    textAlign: 'center',
                },
            },
            {
                Header: 'Kontrollinformation',
                accessor: 'information',
            },
            {
                Header: 'Zeitstempel',
                accessor: 'sentAt',
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Empfänger',
                accessor: 'recipient',
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const employee = driverLicenseControl.employee;

    const data = useMemo(
        () =>
            sentEmails.map(sentEmail => {
                const sentAt = moment(sentEmail.sentAt.date);

                const isEmployeeRecipient =
                    sentEmail.recipients.length === 1 &&
                    sentEmail.recipients[0].user.id === employee.user.id;

                let StatusIcon;
                let statusTitle;

                if (sentEmail.isPendingNotInitiated) {
                    StatusIcon = PendingNotInitiatedIcon;
                    statusTitle = 'Ausstehend (nicht initiiert)';
                }

                if (sentEmail.isPendingInitiated) {
                    StatusIcon = PendingInProgressIcon;
                    statusTitle = 'Ausstehend (in Bearbeitung)';
                }

                if (sentEmail.isCompleted) {
                    StatusIcon = CompletedIcon;
                    statusTitle = 'Abgeschlossen';
                }

                if (sentEmail.isOverdue) {
                    StatusIcon = OverdueIcon;
                    statusTitle = 'Überfällig';
                }

                return {
                    emailIcon: (
                        <BorderlessButton
                            type="button"
                            onClick={() => handleEmailButtonClick(sentEmail.id)}
                            isActive={openedEmailId === sentEmail.id}
                        >
                            <MailOutlineSharp />
                        </BorderlessButton>
                    ),
                    information: (
                        <BorderlessButton
                            type="button"
                            onClick={() => handleEmailButtonClick(sentEmail.id)}
                            isActive={openedEmailId === sentEmail.id}
                        >
                            {sentEmail.sendingReason.name}
                        </BorderlessButton>
                    ),
                    sentAt: sentAt.format('DD.MM.YYYY, HH:mm:ss'),
                    recipient: isEmployeeRecipient
                        ? `${employee.firstName} ${employee.lastName}`
                        : 'Zugewiesene Prüfer',
                    status: StatusIcon ? (
                        <div title={statusTitle}>
                            <StatusIcon />
                        </div>
                    ) : (
                        '-'
                    ),
                };
            }),
        [
            sentEmails,
            employee.user.id,
            employee.firstName,
            employee.lastName,
            openedEmailId,
        ],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['sentAt', 'emailIcon', 'recipient', 'status'],
        },
        useCustomCellStyles,
        useResponsiveTable,
    );

    const openedEmail =
        openedEmailId !== null
            ? sentEmails.find(sentEmail => sentEmail.id === openedEmailId)
            : null;

    return (
        <Wrapper>
            {!!openedEmail && (
                <Draggable cancel=".draggable-cancel">
                    <DraggableContentWrapper>
                        <Button
                            className="draggable-cancel"
                            onClick={handleEmailClose}
                        >
                            <CloseSharp />
                        </Button>
                        <DraggableContent>
                            <Label>Empfänger:</Label>
                            <RecipientsList>
                                {openedEmail.recipients.map(recipient => (
                                    <Recipient>
                                        {`${recipient.user?.firstName} ${recipient.user?.lastName} (${recipient.email})`}
                                    </Recipient>
                                ))}
                            </RecipientsList>

                            <Label>Betreff:</Label>
                            <EmailSubject>{openedEmail.subject}</EmailSubject>
                            <EmailBody
                                dangerouslySetInnerHTML={{
                                    __html: openedEmail.body,
                                }}
                            />
                        </DraggableContent>
                    </DraggableContentWrapper>
                </Draggable>
            )}
            <StyledTable
                headerGroups={headerGroups}
                rows={rows}
                hiddenColumns={hiddenColumns}
                visibleColumns={visibleColumns}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
            />
        </Wrapper>
    );
};

DriverLicenseSentEmailsTable.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    sentEmails: PropTypes.array.isRequired,
};

export default DriverLicenseSentEmailsTable;
