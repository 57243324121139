import api from 'Application/api/api';

const updateInstructionTest = (instructionId, data, cancelToken = null) => {
    const formData = new FormData();

    data.questions.forEach((question, index) => {
        if (question.image !== null && typeof question.image === 'object') {
            formData.append(`questionImages[${index}]`, question.image);
        }
    });

    formData.append(
        'data',
        JSON.stringify({
            ...data,
            questions: data.questions.map(question => ({
                ...question,
                image: undefined,
                hasImage: question.image !== null,
            })),
        }),
    );

    return api.post(`/education/instructions/${instructionId}/test`, formData, {
        cancelToken,
    });
};

export default updateInstructionTest;
