import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import Input from 'Common/components/form/fields/Input';
import ConfirmModal from 'Common/components/ConfirmModal';
import { CheckSharp, CloseSharp } from '@material-ui/icons';
import Loader from 'Common/components/Loader';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import signInstructionAssignmentForEmployeeWithPassword from 'Education/api/instruction-assignment/signInstructionAssignmentForEmployeeWithPassword';
import signInstructionAssignmentForEmployeeWithSignature from 'Education/api/instruction-assignment/signInstructionAssignmentForEmployeeWithSignature';
import dataUriToBlob from 'Common/utils/dataUriToBlob';
import SignaturePad from 'Common/components/SignaturePad';
import axios from 'axios';

const Wrapper = styled.div`
    width: 100%;
    max-width: 550px;
    margin-bottom: 45px;

    @media screen and (min-width: 940px) {
        margin-bottom: 90px;
    }
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.5em;
    color: ${colors.ORANGE};
    text-align: center;

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 30px;
    color: ${colors.WHITE};
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;

    @media screen and (min-width: 940px) {
        margin-bottom: 50px;
        font-size: 18px;
    }
`;

const Employees = styled.div``;

const Employee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 620px) {
        flex-direction: row;
        margin-bottom: 20px;
    }
`;

const EmployeeName = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 620px) {
        flex: 1;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const EmployeeActions = styled.div`
    display: flex;
    max-width: 360px;

    > button {
        flex: 1;

        &:nth-of-type(n + 2) {
            margin-left: 10px;
        }

        > div {
            margin-right: 0 !important;
        }

        svg {
            margin-right: 0;
            font-size: 18px;
        }
    }

    @media screen and (min-width: 620px) {
        flex: 0 0 340px;
    }
`;

const EmployeePasswordField = styled.div`
    flex: 0 1 180px;
    margin-right: 10px;
`;

const GroupAssignmentEmployeeSignatures = ({
    assignment,
    onSignatureConfirmed,
}) => {
    const assignmentDetails = assignment.groupAssignmentDetails;
    const groupLeader = assignmentDetails.groupLeader;
    const assignees = assignmentDetails.assignees;
    const isGroupLeaderAnAssignee =
        assignees.findIndex(
            assignee => assignee.employee.id === groupLeader.employee.id,
        ) !== -1;

    const employeesWhoSigned = assignees
        .filter(assignee => assignee.isSignaturePlaced)
        .map(assignee => assignee.employee);

    if (groupLeader.isSignaturePlaced) {
        const index = employeesWhoSigned.findIndex(
            employee => employee.id === groupLeader.employee.id,
        );

        if (index === -1) {
            employeesWhoSigned.push(groupLeader.employee);
        }
    }

    const hasEmployeeSigned = useCallback(
        employee =>
            employeesWhoSigned.findIndex(
                employeeWhoSigned => employeeWhoSigned.id === employee.id,
            ) !== -1,
        [employeesWhoSigned],
    );

    let employees = assignees.map(assignee => assignee.employee);

    if (!isGroupLeaderAnAssignee) {
        employees.push(groupLeader.employee);
    }

    employees.sort((employee1, employee2) => {
        if (!hasEmployeeSigned(employee1) && hasEmployeeSigned(employee2)) {
            return -1;
        }

        if (hasEmployeeSigned(employee1) && !hasEmployeeSigned(employee2)) {
            return 1;
        }

        return 0;
    });

    const [employeeIdForConfirmation, setEmployeeIdForConfirmation] = useState(
        null,
    );
    const [employeePassword, setEmployeePassword] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);

    const signaturePad = useRef();

    const employeeForConfirmation = employees.find(
        employee => employee.id === employeeIdForConfirmation,
    );

    const handleEmployeeButtonClick = employeeId => {
        setEmployeeIdForConfirmation(employeeId);
    };

    const handleCancelEmployeeConfirmation = () => {
        setEmployeeIdForConfirmation(null);
        setEmployeePassword('');
    };

    const handlePasswordChange = e => {
        setEmployeePassword(e.target.value);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmEmployeeSignatureWithPassword = async () => {
        setIsConfirming(true);

        try {
            await signInstructionAssignmentForEmployeeWithPassword(
                assignment.id,
                employeeIdForConfirmation,
                employeePassword,
                source.token,
            );

            await onSignatureConfirmed();

            setEmployeeIdForConfirmation(null);
            setEmployeePassword('');
            setIsConfirming(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify('Dein Passwort ist falsch', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    const handleConfirmEmployeeSignatureWithSignature = async () => {
        if (signaturePad.current.isEmpty()) {
            notify('Die Unterschrift darf nicht leer sein', {
                type: toast.TYPE.ERROR,
            });
        } else {
            setIsConfirming(true);

            try {
                await signInstructionAssignmentForEmployeeWithSignature(
                    assignment.id,
                    employeeIdForConfirmation,
                    dataUriToBlob(signaturePad.current.toDataURL()),
                    source.token,
                );

                await onSignatureConfirmed();

                setEmployeeIdForConfirmation(null);
                setEmployeePassword('');
                setIsConfirming(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsConfirming(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    const isManualSignatureConfirmModalOpened =
        !!employeeForConfirmation &&
        !employeeForConfirmation.isAutomaticSigningOfInstructionsAllowed;

    return (
        <Wrapper>
            <Title>Unterschriften der Mitarbeiter zur Unterweisung</Title>
            <SubTitle>{assignment.name}</SubTitle>
            <Employees>
                {employees.map(employee => (
                    <Employee key={employee.id}>
                        <EmployeeName>
                            {employee.firstName} {employee.lastName}
                        </EmployeeName>
                        <EmployeeActions>
                            {employeeIdForConfirmation === employee.id &&
                            employee.isAutomaticSigningOfInstructionsAllowed ? (
                                <>
                                    <EmployeePasswordField>
                                        <Input
                                            type="password"
                                            label="Passwort"
                                            value={employeePassword}
                                            onChange={handlePasswordChange}
                                            disabled={isConfirming}
                                            autoComplete="off"
                                        />
                                    </EmployeePasswordField>
                                    <Button
                                        type="button"
                                        disabled={isConfirming}
                                        onClick={
                                            handleConfirmEmployeeSignatureWithPassword
                                        }
                                    >
                                        {isConfirming ? (
                                            <Loader size={18} />
                                        ) : (
                                            <CheckSharp />
                                        )}
                                    </Button>
                                    <Button
                                        type="button"
                                        icon={CloseSharp}
                                        disabled={isConfirming}
                                        onClick={
                                            handleCancelEmployeeConfirmation
                                        }
                                    />
                                </>
                            ) : hasEmployeeSigned(employee) ? (
                                'Bestätigt'
                            ) : (
                                <Button
                                    type="button"
                                    disabled={isConfirming}
                                    onClick={() =>
                                        handleEmployeeButtonClick(employee.id)
                                    }
                                >
                                    Bestätigen
                                </Button>
                            )}
                        </EmployeeActions>
                    </Employee>
                ))}
            </Employees>
            {isManualSignatureConfirmModalOpened && (
                <ConfirmModal
                    isOpen={isManualSignatureConfirmModalOpened}
                    isConfirmLoading={isConfirming}
                    onCancel={handleCancelEmployeeConfirmation}
                    onConfirm={handleConfirmEmployeeSignatureWithSignature}
                >
                    <SignaturePad ref={signaturePad} />
                </ConfirmModal>
            )}
        </Wrapper>
    );
};

GroupAssignmentEmployeeSignatures.propTypes = {
    assignment: PropTypes.object.isRequired,
    onSignatureConfirmed: PropTypes.func.isRequired,
};

export default GroupAssignmentEmployeeSignatures;
