import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import getLibraryInstructionsCount from 'Education/api/library/getLibraryInstructionsCount';
import { LibraryIcon } from 'Common/components/icons';
import { Link } from 'react-router-dom';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';

const StyledLink = styled(Link)`
    display: block;
    text-align: center;
    text-decoration: none;
`;

const LibraryTitle = styled.div`
    margin-top: 10px;
    color: ${colors.WHITE};
    font-size: 24px;
    line-height: 28px;
`;

const LibraryCount = styled.div`
    margin-top: 15px;
    color: ${colors.WHITE};
    font-size: 14px;
    line-height: 18px;
`;

const LinkToLibraryInstructions = () => {
    const { data: libraryInstructionsCount } = useAxiosRequest(
        getLibraryInstructionsCount,
        null,
    );

    return (
        <StyledLink to="/education/library">
            <LibraryIcon size="xl" color={colors.ORANGE} />
            <LibraryTitle>Bibliothek</LibraryTitle>
            {libraryInstructionsCount !== null && (
                <LibraryCount>
                    {`${libraryInstructionsCount} verfügbar`}
                </LibraryCount>
            )}
        </StyledLink>
    );
};

export default LinkToLibraryInstructions;
