import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import colors from 'Common/constants/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const LoadingIcon = withStyles({
    root: {
        color: colors.ORANGE,
    },
})(CircularProgress);

const Loader = ({ size, className }) => (
    <Wrapper className={className}>
        <LoadingIcon size={size} thickness={4} />
    </Wrapper>
);

Loader.defaultProps = {
    size: 150,
};

Loader.propTypes = {
    size: PropTypes.number,
};

export default Loader;
