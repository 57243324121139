import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import createSingleInstructionAssignmentByInstructionId from 'Education/api/instruction-assignment/createSingleInstructionAssignmentByInstructionId';
import AssignmentPresentation from 'Education/components/instruction-assignment/presentation/AssignmentPresentation';
import { useDispatch } from 'react-redux';
import { push, replace } from 'connected-react-router';

const AssignmentPresentationRouter = () => {
    const dispatch = useDispatch();

    const { instructionId, assignmentId } = useParams();

    useEffect(() => {
        (async () => {
            if (!assignmentId) {
                try {
                    const response = await createSingleInstructionAssignmentByInstructionId(
                        instructionId,
                    );

                    dispatch(
                        replace(
                            `/education/assignment/presentation/${instructionId}/${response.data.id}`,
                        ),
                    );
                } catch (e) {
                    dispatch(push(`/`));
                }
            }
        })();
    }, [assignmentId, dispatch, instructionId]);

    if (!assignmentId) {
        return <Loader />;
    }

    return <AssignmentPresentation />;
};

export default AssignmentPresentationRouter;
