import React, { useState } from 'react';
import Input from 'Common/components/form/fields/Input';
import { AddSharp, Search } from '@material-ui/icons';
import styled from 'styled-components/macro';
import DriverLicenseControlCreateForm from 'Measure/components/vehicle/driver-license/DriverLicenseControlCreateForm';
import PropTypes from 'prop-types';
import driverLicenseStatusFilters from 'Measure/constants/driverLicenseStatusFilters';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import DriverLicenseControlProgress from 'Measure/components/vehicle/driver-license/DriverLicenseControlProgress';
import DriverLicenseControlDocuments from 'Measure/components/vehicle/driver-license/DriverLicenseControlDocuments';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import driverLicensePendingFilters from 'Measure/constants/driverLicensePendingFilters';

const Wrapper = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        position: sticky;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        top: 81px;
        max-height: calc(100vh - 151px);
        width: 300px;
        flex: 0 0 300px;
        align-self: flex-start;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const SearchWrapper = styled.div`
    margin-top: 10px;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    margin-bottom: 1px;
    border-radius: 4px 4px 0 0;
`;

const CreateButton = styled.button`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background: ${colors.DARK_GRAY};
    color: ${ifProp('isActive', colors.ORANGE, colors.LIGHTER_GRAY)};
    border: 0 none;
    outline: 0;
    cursor: pointer;
    border-radius: ${ifProp('isActive', '4px 4px 0 0', '4px')};

    svg {
        margin-left: 10px;
    }
`;

const CreateBox = styled.div`
    border-radius: 4px;
`;

const DriverLicenseFiltersWrapper = styled.div`
    display: flex;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    margin-bottom: 1px;
`;

const ItemsFound = styled.div`
    position: absolute;
    top: -10px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    padding: 0 7px;
    color: ${colors.WHITE};
    border-radius: 24px;
    border: 2px solid ${colors.ORANGE};
    font-size: 11px;
    font-weight: 600;
    background: ${colors.DARK_GRAY};
`;

const StatusFilterButton = styled.button`
    position: relative;
    flex: 1;
    margin-right: 1px;
    padding: 14px 20px;
    font-size: 13px;
    font-weight: 300;
    color: ${ifProp('isActive', colors.ORANGE, colors.WHITE)};
    background: ${colors.DARKEST_GRAY};
    border: 0 none;
    outline: 0;
    cursor: pointer;

    &:hover:not([disabled]) {
        background: ${colors.ALT_GRAY};
    }

    &[disabled] {
        cursor: default;
    }

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

const DriverLicenseExaminerDashboardSidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedTabIndex,
    selectedStatus,
    onStatusChange,
    selectedPendingFilter,
    onPendingFilterChange,
    driverLicenseControlsFound,
}) => {
    const dispatch = useDispatch();

    const [isCreateBoxOpened, setIsCreateBoxOpened] = useState(false);

    const handleCreateBoxButtonClick = () => {
        setIsCreateBoxOpened(prevIsCreateBoxOpened => !prevIsCreateBoxOpened);
    };

    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    const handleCreateCancel = () => {
        setIsCreateBoxOpened(false);
    };

    const handleCreateSuccess = driverLicenseControl => {
        setIsCreateBoxOpened(false);

        dispatch(
            push(
                `/measures/vehicles/driver-license/create/${driverLicenseControl.id}`,
            ),
        );
    };

    const numberOfActiveDriverLicenseControlsFound = driverLicenseControlsFound.filter(
        driverLicenseControlFound => driverLicenseControlFound.isActive,
    ).length;

    const numberOfInactiveDriverLicenseControlsFound = driverLicenseControlsFound.filter(
        driverLicenseControlFound => !driverLicenseControlFound.isActive,
    ).length;

    return (
        <Wrapper>
            <CreateBox>
                <CreateButton
                    isActive={isCreateBoxOpened}
                    onClick={handleCreateBoxButtonClick}
                >
                    Führerscheinkontrolle
                    <AddSharp />
                </CreateButton>
                {isCreateBoxOpened && (
                    <DriverLicenseControlCreateForm
                        onCancel={handleCreateCancel}
                        onSuccess={handleCreateSuccess}
                    />
                )}
            </CreateBox>
            <SearchWrapper>
                <Input
                    name="search"
                    label="Suche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </SearchWrapper>
            {selectedTabIndex === 0 && (
                <DriverLicenseFiltersWrapper>
                    <StatusFilterButton
                        disabled={
                            selectedPendingFilter ===
                            driverLicensePendingFilters.MY_PENDING
                        }
                        isActive={
                            selectedPendingFilter ===
                            driverLicensePendingFilters.MY_PENDING
                        }
                        onClick={() =>
                            onPendingFilterChange(
                                driverLicensePendingFilters.MY_PENDING,
                            )
                        }
                    >
                        Meine Aufgaben
                    </StatusFilterButton>
                    <StatusFilterButton
                        disabled={
                            selectedPendingFilter ===
                            driverLicensePendingFilters.EMPLOYEE_PENDING
                        }
                        isActive={
                            selectedPendingFilter ===
                            driverLicensePendingFilters.EMPLOYEE_PENDING
                        }
                        onClick={() =>
                            onPendingFilterChange(
                                driverLicensePendingFilters.EMPLOYEE_PENDING,
                            )
                        }
                    >
                        Mitarbeiter Aufgaben
                    </StatusFilterButton>
                </DriverLicenseFiltersWrapper>
            )}
            {selectedTabIndex === 1 && (
                <DriverLicenseFiltersWrapper>
                    <StatusFilterButton
                        disabled={
                            selectedStatus === driverLicenseStatusFilters.ACTIVE
                        }
                        isActive={
                            selectedStatus === driverLicenseStatusFilters.ACTIVE
                        }
                        onClick={() =>
                            onStatusChange(driverLicenseStatusFilters.ACTIVE)
                        }
                    >
                        Aktive Kontrollen
                        {!!searchTerm && (
                            <ItemsFound>
                                {numberOfActiveDriverLicenseControlsFound}
                            </ItemsFound>
                        )}
                    </StatusFilterButton>
                    <StatusFilterButton
                        disabled={
                            selectedStatus ===
                            driverLicenseStatusFilters.INACTIVE
                        }
                        isActive={
                            selectedStatus ===
                            driverLicenseStatusFilters.INACTIVE
                        }
                        onClick={() =>
                            onStatusChange(driverLicenseStatusFilters.INACTIVE)
                        }
                    >
                        Deaktiviert Kontrollen
                        {!!searchTerm && (
                            <ItemsFound>
                                {numberOfInactiveDriverLicenseControlsFound}
                            </ItemsFound>
                        )}
                    </StatusFilterButton>
                </DriverLicenseFiltersWrapper>
            )}
            <DriverLicenseControlProgress />
            <DriverLicenseControlDocuments />
        </Wrapper>
    );
};

DriverLicenseExaminerDashboardSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    selectedPendingFilter: PropTypes.string.isRequired,
    onPendingFilterChange: PropTypes.func.isRequired,
    driverLicenseControlsFound: PropTypes.array.isRequired,
};

export default DriverLicenseExaminerDashboardSidebar;
