import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import { CheckSharp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import isAllowedToExamineMeasure from 'User/utils/isAllowedToExamineMeasure';
import measures from 'Measure/constants/measures';
import LinkButton from 'Common/components/LinkButton';

const DriverLicenseControlOverviewMainButton = ({ driverLicenseControl }) => {
    const userId = useSelector(state => state.user.id);

    const driverLicense = driverLicenseControl.driverLicense;
    const nextCheck = driverLicense.nextCheck;

    const isExaminer =
        isAuthorized([roles.MANAGER]) ||
        (isAllowedToExamineMeasure(measures.DRIVER_LICENSE) &&
            driverLicenseControl.examiners.findIndex(
                examiner => examiner.id === userId,
            ) !== -1);

    if (
        driverLicenseControl.isActive &&
        !driverLicense.isLost &&
        !driverLicense.isExpired &&
        nextCheck.isInVerificationPeriod &&
        !nextCheck.isCompleted
    ) {
        if (driverLicense.isUsingDirectControlMethod) {
            if (nextCheck.directProcedure.isPendingExaminerVerification) {
                if (isExaminer) {
                    return (
                        <LinkButton
                            to={`/measures/vehicles/driver-license/examiner/direct-check/${driverLicenseControl.id}`}
                        >
                            <CheckSharp />
                            Kontrolle starten
                        </LinkButton>
                    );
                } else {
                    return (
                        <Button
                            type="button"
                            title="Bitte legen Sie Ihren Führerschein einen der aufgeführten Prüfer vor"
                            disabled
                        >
                            <CheckSharp />
                            Kontrolle bestätigen
                        </Button>
                    );
                }
            }

            if (nextCheck.directProcedure.isPendingEmployeeConfirmation) {
                if (isExaminer) {
                    return (
                        <Button
                            type="button"
                            title="Vom Prüfer bereits absolviert"
                            disabled
                        >
                            <CheckSharp />
                            Kontrolle bestätigen
                        </Button>
                    );
                } else {
                    return (
                        <LinkButton to="/measures/vehicles/driver-license/employee/direct-check">
                            <CheckSharp />
                            Kontrolle bestätigen
                        </LinkButton>
                    );
                }
            }
        }

        if (driverLicense.isUsingDistanceControlMethod) {
            if (nextCheck.distanceProcedure.isPendingEmployeeFileUpload) {
                if (isExaminer) {
                    return (
                        <Button
                            type="button"
                            title="Führerscheinfotos  seitens des Mitarbeiters noch nicht bereitgestellt"
                            disabled
                        >
                            <CheckSharp />
                            Abgleich Starten
                        </Button>
                    );
                } else {
                    return (
                        <LinkButton to="/measures/vehicles/driver-license/employee/distance-check">
                            <CheckSharp />
                            Fotos bereitstellen
                        </LinkButton>
                    );
                }
            }

            if (nextCheck.distanceProcedure.isPendingExaminerVerification) {
                if (isExaminer) {
                    return (
                        <LinkButton
                            to={`/measures/vehicles/driver-license/examiner/distance-check/${driverLicenseControl.id}`}
                        >
                            <CheckSharp />
                            Abgleich Starten
                        </LinkButton>
                    );
                } else {
                    return (
                        <Button
                            type="button"
                            title="Führerscheinfotos befinden sich im Abgleich"
                            disabled
                        >
                            <CheckSharp />
                            Fotos bereitstellen
                        </Button>
                    );
                }
            }
        }
    }

    return null;
};

DriverLicenseControlOverviewMainButton.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default DriverLicenseControlOverviewMainButton;
