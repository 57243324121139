import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Loader from 'Common/components/Loader';
import MeasureTasksTable from 'Employee/components/employee-view/measure/MeasureTasksTable';
import getPendingMeasureTasksByEmployee from 'Measure/api/getPendingMeasureTasksByEmployee';
import getPendingMeasureTasks from 'Measure/api/getPendingMeasureTasks';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.DARK_GRAY};
    padding: 20px 0;
    border-radius: 4px;
`;

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const PendingEmployeeMeasureTasks = ({ employeeId }) => {
    const measureTasksRequest = useCallback(
        cancelToken => {
            if (employeeId) {
                return getPendingMeasureTasksByEmployee(
                    employeeId,
                    cancelToken,
                );
            }

            return getPendingMeasureTasks(cancelToken);
        },
        [employeeId],
    );

    const {
        data: measureTasks,
        loadData: loadMeasureTasks,
        isLoading,
        hasError,
    } = useAxiosRequest(measureTasksRequest, []);

    return (
        <Wrapper>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : hasError ? (
                <RetryLoading onRetry={loadMeasureTasks} />
            ) : (
                <MeasureTasksTable
                    measureTasks={measureTasks}
                    employeeId={employeeId}
                />
            )}
        </Wrapper>
    );
};

PendingEmployeeMeasureTasks.defaultProps = {
    employeeId: null,
};

PendingEmployeeMeasureTasks.propTypes = {
    employeeId: PropTypes.number,
};

export default PendingEmployeeMeasureTasks;
