import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import getDriverLicenseControl from 'Measure/api/driver-license/getDriverLicenseControl';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import { push, replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import axios from 'axios';
import {
    Layout,
    Title,
} from 'Measure/components/vehicle/driver-license/layout';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import DriverLicenseDistanceImageReview from 'Measure/components/vehicle/driver-license/distance-check/DriverLicenseDistanceImageReview';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import confirmUploadedFilesVerificationForDistanceProcedure from 'Measure/api/driver-license/distance-check/confirmUploadedFilesVerificationForDistanceProcedure';
import moment from 'moment-timezone';
import passwordConfirmUploadedFilesVerificationForDistanceProcedure from 'Measure/api/driver-license/distance-check/passwordConfirmUploadedFilesVerificationForDistanceProcedure';
import signatureConfirmUploadedFilesVerificationForDistanceProcedure from 'Measure/api/driver-license/distance-check/signatureConfirmUploadedFilesVerificationForDistanceProcedure';

const steps = {
    FRONT_IMAGE: 'FRONT_IMAGE',
    BACK_IMAGE_WITH_LETTERS: 'BACK_IMAGE_WITH_LETTERS',
    BACK_IMAGE_WITH_SIGNS: 'BACK_IMAGE_WITH_SIGNS',
    PASSWORD_SIGNATURE_VERIFICATION: 'PASSWORD_SIGNATURE_VERIFICATION',
};

const DriverLicenseExaminerDistanceCheck = () => {
    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.id);

    const isAutomaticSigningAllowed = useSelector(
        state => state.user.isAutomaticSigningAllowed,
    );

    const [step, setStep] = useState(null);
    const [stepNumbers, setStepNumbers] = useState(null);
    const [stepsCount, setStepsCount] = useState(null);

    const { id: driverLicenseControlId } = useParams();

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getDriverLicenseControl(
                driverLicenseControlId,
                [
                    'examiners',
                    'employee',
                    'driverLicense',
                    'driverLicense.files',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.distanceProcedure',
                    'driverLicense.nextCheck.distanceProcedure.files',
                    'driverLicense.nextCheck.approvedFilesForDistanceProcedure',
                ],
                cancelToken,
            ),
        [driverLicenseControlId],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        if (error?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // Set the initial step when the driver license control loads
    useEffect(() => {
        if (driverLicenseControl && step === null) {
            const nextCheck = driverLicenseControl?.driverLicense?.nextCheck;
            const currentDistanceProcedure = nextCheck?.distanceProcedure;

            if (currentDistanceProcedure) {
                const pendingFileTypes = currentDistanceProcedure.files
                    .filter(file => file.isPendingApproval)
                    .map(file => file.type);

                const isFrontImagePendingApproval = pendingFileTypes.includes(
                    driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
                );

                const isBackImageWithLettersPendingApproval = pendingFileTypes.includes(
                    driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
                );

                const isBackImageWithSignsPendingApproval = pendingFileTypes.includes(
                    driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
                );

                const areAllFilesApproved =
                    nextCheck.approvedFilesForDistanceProcedure.length === 3;

                if (isFrontImagePendingApproval) {
                    setStep(steps.FRONT_IMAGE);
                } else if (isBackImageWithLettersPendingApproval) {
                    setStep(steps.BACK_IMAGE_WITH_LETTERS);
                } else if (isBackImageWithSignsPendingApproval) {
                    setStep(steps.BACK_IMAGE_WITH_SIGNS);
                } else if (
                    !nextCheck.isExaminerSignaturePlaced &&
                    areAllFilesApproved
                ) {
                    setStep(steps.PASSWORD_SIGNATURE_VERIFICATION);
                }

                const count =
                    Number(isFrontImagePendingApproval) +
                    Number(isBackImageWithLettersPendingApproval) +
                    Number(isBackImageWithSignsPendingApproval) +
                    Number(!nextCheck.isExaminerSignaturePlaced);

                setStepsCount(count);
                setStepNumbers({
                    [steps.FRONT_IMAGE]: 1,
                    [steps.BACK_IMAGE_WITH_LETTERS]:
                        2 - Number(!isFrontImagePendingApproval),
                    [steps.BACK_IMAGE_WITH_SIGNS]:
                        3 -
                        Number(!isFrontImagePendingApproval) -
                        Number(!isBackImageWithLettersPendingApproval),
                    [steps.PASSWORD_SIGNATURE_VERIFICATION]: count,
                });
            }
        }
    }, [driverLicenseControl, step]);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const driverLicense = driverLicenseControl?.driverLicense;
    const nextCheck = driverLicenseControl?.driverLicense?.nextCheck;
    const currentDistanceProcedure = nextCheck?.distanceProcedure;
    const approvedFiles = nextCheck.approvedFilesForDistanceProcedure;

    const pendingFileTypes = currentDistanceProcedure.files
        .filter(file => file.isPendingApproval)
        .map(file => file.type);

    const isBackImageWithLettersPendingApproval = pendingFileTypes.includes(
        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
    );

    const isBackImageWithSignsPendingApproval = pendingFileTypes.includes(
        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
    );

    const isExaminerAuthorizedToExamine =
        isAuthorized([roles.MANAGER]) ||
        driverLicenseControl.examiners.findIndex(
            examiner => examiner.id === userId,
        ) !== -1;

    const handleApprove = async () => {
        const isLastPendingFile = pendingFileTypes.length === 1;
        const areTheOtherFilesApproved = approvedFiles.length === 2;

        if (isLastPendingFile && !areTheOtherFilesApproved) {
            await confirmUploadedFilesVerificationForDistanceProcedure(
                driverLicenseControl.id,
                source.token,
            );

            notify('Kontrolle absolviert', {
                type: toast.TYPE.SUCCESS,
            });

            dispatch(push('/measures/vehicles/driver-licenses'));
        } else {
            await loadDriverLicenseControl();

            setStep(currentStep => {
                if (currentStep === steps.FRONT_IMAGE) {
                    if (isBackImageWithLettersPendingApproval) {
                        return steps.BACK_IMAGE_WITH_LETTERS;
                    } else if (isBackImageWithSignsPendingApproval) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    } else if (
                        !nextCheck.isExaminerSignaturePlaced &&
                        areTheOtherFilesApproved
                    ) {
                        return steps.PASSWORD_SIGNATURE_VERIFICATION;
                    }
                }

                if (currentStep === steps.BACK_IMAGE_WITH_LETTERS) {
                    if (isBackImageWithSignsPendingApproval) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    } else if (
                        !nextCheck.isExaminerSignaturePlaced &&
                        areTheOtherFilesApproved
                    ) {
                        return steps.PASSWORD_SIGNATURE_VERIFICATION;
                    }
                }

                if (
                    currentStep === steps.BACK_IMAGE_WITH_SIGNS &&
                    !nextCheck.isExaminerSignaturePlaced &&
                    areTheOtherFilesApproved
                ) {
                    return steps.PASSWORD_SIGNATURE_VERIFICATION;
                }

                return currentStep;
            });
        }
    };

    const handleReject = async () => {
        const isLastPendingFile = pendingFileTypes.length === 1;

        if (isLastPendingFile) {
            await confirmUploadedFilesVerificationForDistanceProcedure(
                driverLicenseControl.id,
                source.token,
            );

            notify('Kontrolle absolviert', {
                type: toast.TYPE.SUCCESS,
            });

            dispatch(push('/measures/vehicles/driver-licenses'));
        } else {
            await loadDriverLicenseControl();

            setStep(currentStep => {
                if (currentStep === steps.FRONT_IMAGE) {
                    if (isBackImageWithLettersPendingApproval) {
                        return steps.BACK_IMAGE_WITH_LETTERS;
                    } else if (isBackImageWithSignsPendingApproval) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    }
                }

                if (currentStep === steps.BACK_IMAGE_WITH_LETTERS) {
                    if (isBackImageWithSignsPendingApproval) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    } else if (!nextCheck.isExaminerSignaturePlaced) {
                        return steps.PASSWORD_SIGNATURE_VERIFICATION;
                    }
                }

                return currentStep;
            });
        }
    };

    const handleConfirmPassword = async password => {
        await passwordConfirmUploadedFilesVerificationForDistanceProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        dispatch(push('/measures/vehicles/driver-licenses'));
    };

    const handleConfirmSignature = async blob => {
        const date = moment();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Examiner_${userId}_${date.format(
                'YYYY-MM-DD_HH-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date.toDate(),
            },
        );

        await signatureConfirmUploadedFilesVerificationForDistanceProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        dispatch(push('/measures/vehicles/driver-licenses'));
    };

    if (
        driverLicenseControl &&
        driverLicenseControl.isActive &&
        driverLicense &&
        driverLicense.isUsingDistanceControlMethod &&
        !driverLicense.isInCreationProcess &&
        !driverLicense.isExpired &&
        !driverLicense.isLost &&
        driverLicense.nextCheck.isInVerificationPeriod &&
        driverLicense.nextCheck.distanceProcedure
            .isPendingExaminerVerification &&
        (pendingFileTypes.length > 0 || approvedFiles.length === 3) &&
        isExaminerAuthorizedToExamine
    ) {
        const breadcrumbs = isAuthorized([roles.MANAGER]) ? (
            <>
                <Helmet>
                    <title>
                        {`Prüfung - ${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}
                    </title>
                </Helmet>
                <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                <Breadcrumb to="/measures/vehicles">Fuhrpark</Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
                <Breadcrumb isActive>Prüfung</Breadcrumb>
            </>
        ) : (
            <>
                <Helmet>
                    <title>
                        {`Prüfung - ${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Maßnahmen`}
                    </title>
                </Helmet>
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
                <Breadcrumb isActive>Prüfung</Breadcrumb>
            </>
        );

        const hint = (
            <>
                Hinweis: Hiermit bestätigen Sie den ordnungsgemäßen Abgleich des
                Führerscheins
            </>
        );

        const stepsCounter = `${stepNumbers[step]}/${stepsCount} Führerscheinkontrolle`;

        const cancelButtonLink = `/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`;
        const confirmButtonText = 'Unterschrift übernehmen';

        return (
            <>
                {breadcrumbs}
                {step === steps.FRONT_IMAGE && (
                    <DriverLicenseDistanceImageReview
                        type={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onApprove={handleApprove}
                        onReject={handleReject}
                    />
                )}
                {step === steps.BACK_IMAGE_WITH_LETTERS && (
                    <DriverLicenseDistanceImageReview
                        type={driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onApprove={handleApprove}
                        onReject={handleReject}
                    />
                )}
                {step === steps.BACK_IMAGE_WITH_SIGNS && (
                    <DriverLicenseDistanceImageReview
                        type={driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onApprove={handleApprove}
                        onReject={handleReject}
                    />
                )}
                {step === steps.PASSWORD_SIGNATURE_VERIFICATION && (
                    <Layout>
                        <Title>{stepsCounter}</Title>
                        {isAutomaticSigningAllowed ? (
                            <DriverLicensePasswordVerification
                                cancelButtonLink={cancelButtonLink}
                                confirmButtonText={confirmButtonText}
                                onConfirm={handleConfirmPassword}
                                showBackButton={false}
                                hint={hint}
                                successMessage="Kontrolle absolviert"
                            />
                        ) : (
                            <DriverLicenseSignatureVerification
                                cancelButtonLink={cancelButtonLink}
                                confirmButtonText={confirmButtonText}
                                onConfirm={handleConfirmSignature}
                                showBackButton={false}
                                hint={hint}
                                successMessage="Kontrolle absolviert"
                            />
                        )}
                    </Layout>
                )}
            </>
        );
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

export default DriverLicenseExaminerDistanceCheck;
