import React, { useCallback } from 'react';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import AssignmentExamInProgress from 'Education/components/instruction-assignment/exam/AssignmentExamInProgress';
import Loader from 'Common/components/Loader';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { examStatuses } from 'Education/constants/examStatuses';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import getEmployee from 'Employee/api/getEmployee';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const doNothing = async () => {};

const AssignmentExamPreview = () => {
    const [comingFromEmployeeId] = useQueryParameter('comingFromEmployeeId');
    const [fromTab] = useQueryParameter('fromTab');

    const tabHash = fromTab ? `#${fromTab}` : '';

    const { id: assignmentId } = useParams();

    const instructionAssignmentRequest = useCallback(
        cancelToken =>
            getInstructionAssignment(
                assignmentId,
                ['instruction', 'examQuestions'],
                cancelToken,
            ),
        [assignmentId],
    );

    const {
        data: assignment,
        loadData: loadAssignment,
        isLoading: isLoadingAssignment,
        hasError: hasAssignmentError,
    } = useAxiosRequest(instructionAssignmentRequest, null);

    const employeeRequest = useCallback(
        cancelToken => {
            if (!comingFromEmployeeId) {
                return null;
            }

            return getEmployee(comingFromEmployeeId, [], cancelToken);
        },
        [comingFromEmployeeId],
    );

    const {
        data: employee,
        loadData: loadEmployee,
        isLoading: isLoadingEmployee,
        hasError: hasEmployeeError,
    } = useAxiosRequest(employeeRequest, null);

    const isLoading = isLoadingAssignment || isLoadingEmployee;
    const hasError = hasAssignmentError || hasEmployeeError;

    const handleRetryLoading = () => {
        loadAssignment();
        loadEmployee();
    };

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={handleRetryLoading} />;
    }

    if (
        assignment !== null &&
        (employee !== null || !comingFromEmployeeId) &&
        [
            examStatuses.COMPLETED,
            examStatuses.IN_APPROVAL,
            examStatuses.FAILED,
        ].includes(assignment.examStatus)
    ) {
        return (
            <>
                {comingFromEmployeeId ? (
                    <>
                        <Helmet>
                            <title>
                                {`${assignment.name} - ${employee.firstName} ${employee.lastName} - Mitarbeiter`}
                            </title>
                        </Helmet>
                        <Breadcrumb to="/employees">Mitarbeiter</Breadcrumb>
                        <Breadcrumb
                            to={`/employee/${comingFromEmployeeId}${tabHash}`}
                        >
                            {`${employee.firstName} ${employee.lastName}`}
                        </Breadcrumb>
                    </>
                ) : (
                    <>
                        <Helmet>
                            <title>
                                {`${assignment.name} - Unterweisungen`}
                            </title>
                        </Helmet>
                        <Breadcrumb to="/education">Unterweisungen</Breadcrumb>
                    </>
                )}
                <Breadcrumb isActive>
                    Test anzeigen - {assignment.name}
                </Breadcrumb>
                <AssignmentExamInProgress
                    assignment={assignment}
                    isShowingMistakes
                    isInPreviewMode
                    initialQuestionNumber={1}
                    initialSelectedAnswerIds={assignment.givenAnswerIds}
                    closeButtonLink={
                        comingFromEmployeeId
                            ? `/employee/${comingFromEmployeeId}`
                            : '/dashboard'
                    }
                    onProgress={doNothing}
                    onComplete={doNothing}
                />
            </>
        );
    }

    return <Redirect to="/dashboard" />;
};

export default AssignmentExamPreview;
