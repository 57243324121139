import React, { useEffect, useMemo, useState } from 'react';
import deleteInstructionArea from 'Education/api/instruction-area/deleteInstructionArea';
import { DeleteSharp } from '@material-ui/icons';
import ConfirmModal from 'Common/components/ConfirmModal';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import axios from 'axios';

const DeleteMessage = styled.div``;

const DeleteLabel = styled.div`
    margin-bottom: 10px;
    color: ${colors.ORANGE};
`;

const InstructionAreaDeleteModal = ({ area, onCancel, onSuccess }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsDeleting(true);

            await deleteInstructionArea(area.id, source.token);

            onSuccess();

            setIsDeleting(false);

            notify('Der Bereich wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Der Bereich konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <ConfirmModal
            isOpen={area !== null}
            isConfirmLoading={isDeleting}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            confirmText="Löschen"
            confirmIcon={DeleteSharp}
        >
            <DeleteMessage>
                <DeleteLabel>Bereich löschen!</DeleteLabel>
                Wollen Sie den Bereich {area?.name} wirklich Löschen?
            </DeleteMessage>
        </ConfirmModal>
    );
};

InstructionAreaDeleteModal.defaultProps = {
    area: null,
};

InstructionAreaDeleteModal.propTypes = {
    area: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaDeleteModal;
