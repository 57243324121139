import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { SignatureIcon, TrashIcon } from 'Common/components/icons';
import ReactSignaturePad from 'react-signature-pad-wrapper';

const Wrapper = styled.div`
    position: relative;
    background: ${colors.ALT_DARKEST_GRAY};
    cursor: crosshair;
`;

const SignatureText = styled.div`
    max-width: 250px;
    text-align: left;
    color: ${colors.LIGHT_GRAY};
    font-size: 14px;
`;

const ClearButton = styled.div`
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: ${colors.LIGHT_GRAY};
    cursor: pointer;
`;

const InformationWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 20px;
    left: 20px;
    pointer-events: none;
    user-select: none;

    > i {
        display: flex;
        flex-direction: column;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        color: ${colors.LIGHT_GRAY};
    }
`;

const SignaturePad = ({ innerRef, className, ...props }) => {
    const handleClearSignature = () => {
        innerRef.current.clear();
    };

    return (
        <Wrapper className={className}>
            <InformationWrapper>
                <SignatureIcon size="lg" />
                <SignatureText>
                    Unterschreiben sie in der dafür vorgesehenden Fläche
                </SignatureText>
            </InformationWrapper>
            <ReactSignaturePad ref={innerRef} {...props} />
            <ClearButton onClick={handleClearSignature}>
                <TrashIcon size="sm" />
            </ClearButton>
        </Wrapper>
    );
};

export default forwardRef((props, ref) => (
    <SignaturePad innerRef={ref} {...props} />
));
