import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Link from 'Common/components/Link';
import { ifProp } from 'styled-tools';
import ExternalLink from 'Common/components/ExternalLink';

const Item = styled.div`
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;

    &:after {
        content: '/';
        display: inline-block;
        vertical-align: middle;
        margin-left: 7px;
        margin-right: 7px;
        color: ${colors.LIGHTEST_GRAY};
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
`;

const ItemLink = styled(Link)`
    display: inline-block;
    vertical-align: middle;
    color: ${ifProp('$isActive', colors.ORANGE, colors.LIGHTEST_GRAY)};
`;

const ItemExternalLink = styled(ExternalLink)`
    display: inline-block;
    vertical-align: middle;
    color: ${ifProp('$isActive', colors.ORANGE, colors.LIGHTEST_GRAY)};
`;

const ItemText = styled.span`
    display: inline-block;
    vertical-align: middle;
    color: ${ifProp('isActive', colors.ORANGE, colors.LIGHTEST_GRAY)};
`;

const Breadcrumb = ({ to, href, isActive, ...props }) => {
    const [portalNode, setPortalNode] = useState();

    useEffect(() => {
        setPortalNode(document.getElementById('breadcrumbs'));
    }, []);

    const hasLink = !!to || !!href;

    const LinkComponent = !!to ? ItemLink : ItemExternalLink;
    const linkProp = !!to ? 'to' : 'href';

    return portalNode
        ? ReactDOM.createPortal(
              <Item>
                  {hasLink ? (
                      <LinkComponent
                          {...{
                              [linkProp]: to || href,
                          }}
                          $isActive={isActive}
                      >
                          {props.children}
                      </LinkComponent>
                  ) : (
                      <ItemText isActive={isActive}>{props.children}</ItemText>
                  )}
              </Item>,
              portalNode,
          )
        : null;
};

Breadcrumb.defaultProps = {
    to: null,
    href: null,
    isActive: false,
};

Breadcrumb.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    isActive: PropTypes.bool,
};

export default Breadcrumb;
