import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ConfirmModal from 'Common/components/ConfirmModal';
import { DeleteSharp } from '@material-ui/icons';

const WarningMessage = styled.div`
    font-size: 13px;
    color: ${colors.ORANGE};
`;

const InstructionPresentationSlideDeleteModal = ({
    slideId,
    onConfirm,
    onCancel,
    stats,
}) => (
    <ConfirmModal
        isOpen={slideId !== null}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText="Löschen"
        confirmIcon={DeleteSharp}
    >
        <h4>Möchten Sie diese Folie wirklich löschen?</h4>
        <small>
            Die Änderungen werden nicht automatisch gespeichert, Sie müssen die
            gesamte Präsentation speichern.
        </small>
        {!!stats && stats.presentationsInProgressCount > 0 && (
            <WarningMessage>
                {`Hinweis: Es gibt ${stats.presentationsInProgressCount} Mitarbeiter, die eine Präsentation halten`}
            </WarningMessage>
        )}
    </ConfirmModal>
);

InstructionPresentationSlideDeleteModal.defaultProps = {
    slideId: null,
    stats: null,
};

InstructionPresentationSlideDeleteModal.propTypes = {
    slideId: PropTypes.number,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
};

export default InstructionPresentationSlideDeleteModal;
