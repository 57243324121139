import api from 'Application/api/api';

const uploadDriverLicenseDistanceFile = (
    driverLicenseControlId,
    file,
    type,
    uploadedAt,
    cancelToken = null,
) => {
    const formData = new FormData();

    formData.append('file', file);

    formData.append(
        'data',
        JSON.stringify({
            type,
            uploadedAt,
        }),
    );

    return api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/distance-check/upload-file`,
        formData,
        {
            cancelToken,
        },
    );
};

export default uploadDriverLicenseDistanceFile;
