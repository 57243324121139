import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import modules from 'Common/constants/modules';
import ModuleTiles from 'Employee/components/employee-view/ModuleTiles';
import EmployeeTile from 'Employee/components/employee-view/EmployeeTile';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import EmployeeViewTabs from 'Employee/components/employee-view/EmployeeViewTabs';
import EmployeeViewStats from 'Employee/components/employee-view/EmployeeViewStats';
import { DownloadIcon } from 'Common/components/icons';
import colors from 'Common/constants/colors';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';

const Layout = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 940px) {
        flex-direction: row;
    }
`;

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 940px) {
        display: block;
        position: sticky;
        top: 81px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 151px);
        width: 300px;
        margin-right: 10px;

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const Content = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 940px) {
        flex: 1;
        margin-bottom: 0;
    }
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    color: ${colors.WHITE};
    border-radius: 50%;
    border: 1px solid ${colors.WHITE};

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background: ${colors.LIGHT_GRAY};
        position: absolute;
        top: 27px;
    }
`;

const DocumentName = styled.div`
    color: ${colors.LIGHTEST_GRAY};
    font-size: 14px;
`;

const DocumentItem = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    text-decoration: none;
    background: ${colors.DARK_GRAY};
    color: ${colors.LIGHTER_GRAY};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    &:last-child {
        ${IconWrapper} {
            &::after {
                display: none;
            }
        }
    }
    &:hover {
        ${DocumentName} {
            color: ${colors.ORANGE};
        }
        ${IconWrapper} {
            border-color: ${colors.ORANGE};
        }
    }
`;

const defaultModule = modules.INSTRUCTIONS;

const EmployeeView = ({ employee }) => {
    const [module, setModule] = useQueryParameter('module');

    const handleModuleChange = newModule => {
        if (newModule === defaultModule) {
            setModule(null);
        } else {
            setModule(newModule);
        }
    };

    const selectedModule = module ?? defaultModule;

    const areaInstructionCertificateLink = isAuthorized([roles.MANAGER])
        ? `/api/education/instruction-assignments/certificates/area/download/${employee.id}`
        : '/api/education/instruction-assignments/certificates/area/download';

    return (
        <Layout>
            <Sidebar>
                <EmployeeTile employee={employee} />
                <ModuleTiles
                    selectedModule={selectedModule}
                    onModuleTileClick={handleModuleChange}
                    employee={employee}
                />
                <EmployeeViewStats
                    employee={employee}
                    selectedModule={selectedModule}
                    onModuleChange={handleModuleChange}
                />
                {employee.completedInstructions.length > 0 && (
                    <DocumentItem href={areaInstructionCertificateLink}>
                        <IconWrapper>
                            <DownloadIcon size="xs" />
                        </IconWrapper>
                        <DocumentName>Bereichszertifikat</DocumentName>
                    </DocumentItem>
                )}
            </Sidebar>
            <Content>
                <EmployeeViewTabs
                    selectedModule={selectedModule}
                    employee={employee}
                />
            </Content>
        </Layout>
    );
};

EmployeeView.defaultProps = {
    employee: null,
};

EmployeeView.propTypes = {
    employee: PropTypes.object,
};

export default EmployeeView;
