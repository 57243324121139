import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import Checkbox from 'Common/components/form/fields/Checkbox';

const Wrapper = styled.div``;

const Answer = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 10px;
    background: ${colors.DARKER_GRAY};
    border: 2px solid ${colors.DARKER_GRAY};
    border-radius: 4px;

    &:hover {
        background: ${colors.DARKEST_GRAY};
        border-color: ${colors.DARKEST_GRAY};
    }

    ${ifProp(
        'isShowingMistakes',
        css`
            &:hover {
                background: ${colors.DARKER_GRAY};
                border-color: ${colors.DARKER_GRAY};
            }
        `,
    )}

    ${ifProp(
        'isCorrect',
        css`
            border-color: ${colors.GREEN};

            &:hover {
                border-color: ${colors.GREEN};
            }
        `,
    )}

    ${ifProp(
        'isWrong',
        css`
            border-color: ${colors.RED};

            &:hover {
                border-color: ${colors.RED};
            }
        `,
    )}
`;

const AnswerCheckbox = styled(Checkbox)`
    .MuiCheckbox-root {
        width: 30px;
        height: 30px;
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    ${ifProp(
        'disabled',
        css`
            label {
                cursor: default;
            }
        `,
    )}
`;

const AnswerLabel = styled.div`
    margin-left: 15px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    line-height: 30px;
    user-select: none;

    ${ifProp(
        'isChecked',
        css`
            color: ${colors.WHITE};
        `,
    )}
`;

const AnswerChoices = ({
    answers,
    selectedIds,
    onAnswerCheckboxChange,
    isShowingMistakes,
    isQuestionCorrectlyAnswered,
}) => (
    <Wrapper>
        {answers.map(answer => {
            const isChecked = selectedIds.includes(answer.id);

            const isCorrect =
                isShowingMistakes && isChecked && isQuestionCorrectlyAnswered;

            const isWrong =
                isShowingMistakes && isChecked && !isQuestionCorrectlyAnswered;

            return (
                <Answer
                    key={answer.id}
                    isShowingMistakes={isShowingMistakes}
                    isCorrect={isCorrect}
                    isWrong={isWrong}
                >
                    <AnswerCheckbox
                        label={' '}
                        id={`answer_${answer.id}`}
                        name="answer[]"
                        checked={isChecked}
                        disabled={isShowingMistakes}
                        onChange={e =>
                            onAnswerCheckboxChange(answer.id, e.target.checked)
                        }
                    />
                    <AnswerLabel isChecked={isChecked}>
                        {answer.text}
                    </AnswerLabel>
                </Answer>
            );
        })}
    </Wrapper>
);

AnswerChoices.propTypes = {
    answers: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    onAnswerCheckboxChange: PropTypes.func.isRequired,
    isShowingMistakes: PropTypes.bool.isRequired,
    isQuestionCorrectlyAnswered: PropTypes.bool.isRequired,
};

export default AnswerChoices;
