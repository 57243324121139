import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import DriverLicenseToolbarButtons from 'Measure/components/vehicle/driver-license/check/DriverLicenseToolbarButtons';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import SignaturePad from 'Common/components/SignaturePad';
import dataUriToBlob from 'Common/utils/dataUriToBlob';

const FullWidthContent = styled.div`
    margin-bottom: 10px;
    padding: 30px 20px;
    background: ${colors.DARK_GRAY};

    @media screen and (min-width: 940px) {
        display: flex;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const StyledSignaturePad = styled(SignaturePad)`
    flex: 1;
    margin-right: 40px;
`;

const AdditionalInformation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;

    @media screen and (min-width: 940px) {
        flex: 1;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

const Hint = styled.div`
    max-width: 480px;
    font-size: 14px;
`;

const DriverLicenseSignatureVerification = ({
    hint,
    cancelButtonLink,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    showBackButton,
    successMessage,
}) => {
    const signaturePad = useRef();

    const handleConfirm = async () => {
        if (signaturePad.current.isEmpty()) {
            notify('Die Unterschrift darf nicht leer sein', {
                type: toast.TYPE.ERROR,
            });
        } else {
            try {
                const blob = dataUriToBlob(signaturePad.current.toDataURL());

                await onConfirm(blob);

                notify(successMessage, {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    return (
        <>
            <FullWidthContent>
                <StyledSignaturePad ref={signaturePad} />
                <AdditionalInformation>
                    <Hint>{hint}</Hint>
                </AdditionalInformation>
            </FullWidthContent>
            <DriverLicenseToolbarButtons
                cancelButtonLink={cancelButtonLink}
                confirmButtonText={confirmButtonText}
                onConfirm={handleConfirm}
                onBackButtonClick={onBackButtonClick}
                showBackButton={showBackButton}
            />
        </>
    );
};

DriverLicenseSignatureVerification.defaultProps = {
    onBackButtonClick: undefined,
    showBackButton: true,
    successMessage: 'Erfolgreiche Überprüfung',
};

DriverLicenseSignatureVerification.propTypes = {
    hint: PropTypes.any.isRequired,
    cancelButtonLink: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    showBackButton: PropTypes.bool,
    successMessage: PropTypes.string,
};

export default DriverLicenseSignatureVerification;
