import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Loader from 'Common/components/Loader';
import getGroupInstructionAssignments from 'Education/api/instruction-assignment/group/getGroupInstructionAssignments';
import GroupInstructionAssignmentSidebar from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentSidebar';
import GroupInstructionAssignmentsTable from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentsTable';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 8px 0;
    border-radius: 4px;
`;

const loadProperties = ['groupAssignmentDetails'];

const GroupInstructionAssignmentsListTab = () => {
    const groupInstructionAssignmentsRequest = useCallback(
        cancelToken =>
            getGroupInstructionAssignments(loadProperties, cancelToken),
        [],
    );

    const {
        data: instructionAssignments,
        setData: setInstructionAssignments,
        loadData: loadInstructionAssignments,
        isLoading,
        hasError,
    } = useAxiosRequest(groupInstructionAssignmentsRequest, []);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleAssignmentCreate = async newInstructionAssignment => {
        try {
            const response = await getInstructionAssignment(
                newInstructionAssignment.id,
                loadProperties,
                source.token,
            );

            setInstructionAssignments(prevInstructionAssignments => [
                ...prevInstructionAssignments,
                response.data,
            ]);
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleAssignmentUpdate = async id => {
        try {
            const response = await getInstructionAssignment(
                id,
                loadProperties,
                source.token,
            );

            setInstructionAssignments(prevInstructionAssignments => {
                const newInstructionAssignments = [
                    ...prevInstructionAssignments,
                ];

                const index = newInstructionAssignments.findIndex(
                    assignment => assignment.id === id,
                );

                newInstructionAssignments[index] = response.data;

                return newInstructionAssignments;
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleAssignmentDelete = id => {
        setInstructionAssignments(prevInstructionAssignments => {
            const newInstructionAssignments = [...prevInstructionAssignments];

            const index = newInstructionAssignments.findIndex(
                assignment => assignment.id === id,
            );

            if (index !== -1) {
                newInstructionAssignments.splice(index, 1);
            }

            return newInstructionAssignments;
        });
    };

    const [searchTerm, setSearchTerm] = useState('');

    let filteredInstructionAssignments = [...instructionAssignments];

    if (searchTerm) {
        filteredInstructionAssignments = filteredInstructionAssignments.filter(
            assignment =>
                assignment.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
        );
    }

    return (
        <>
            <Wrapper>
                <GroupInstructionAssignmentSidebar
                    searchTerm={searchTerm}
                    onSearchTermChange={setSearchTerm}
                />
                <Content>
                    {isLoading ? (
                        <Loader />
                    ) : hasError ? (
                        <RetryLoading onRetry={loadInstructionAssignments} />
                    ) : (
                        <GroupInstructionAssignmentsTable
                            instructionAssignments={
                                filteredInstructionAssignments
                            }
                            onAssignmentCreate={handleAssignmentCreate}
                            onAssignmentUpdate={handleAssignmentUpdate}
                            onAssignmentDelete={handleAssignmentDelete}
                        />
                    )}
                </Content>
            </Wrapper>
        </>
    );
};

export default GroupInstructionAssignmentsListTab;
