import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';
import Checkbox from 'Common/components/form/fields/Checkbox';
import FormField from 'Common/components/form/FormField';

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0 40px 0;

    > button {
        width: auto;
        min-width: 200px;
        margin: 10px;
    }

    @media screen and (min-width: 940px) {
        margin-top: 30px;
    }
`;

const ButtonWrapper = styled.div`
    > button {
        width: auto;
    }
`;

const Title = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5em;
    color: ${colors.WHITE};

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const CheckboxesWrapper = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 520px) {
        display: flex;
        flex-wrap: wrap;
        max-width: 420px;
    }

    @media screen and (min-width: 940px) {
        margin-bottom: 40px;
    }
`;

const StyledFormField = styled(FormField)`
    @media screen and (min-width: 520px) {
        flex: 0 0 auto;
        width: calc(50% - 20px);
        margin-right: 40px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
`;

const GroupAssignmentEmployeeChecklist = ({
    employees,
    confirmButtonText,
    onConfirm,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckboxChange = (id, isChecked) => {
        setSelectedIds(prevSelectedIds => {
            const newSelectedIds = [...prevSelectedIds];

            if (isChecked && !prevSelectedIds.includes(id)) {
                newSelectedIds.push(id);
            } else if (!isChecked && prevSelectedIds.includes(id)) {
                const index = newSelectedIds.indexOf(id);

                newSelectedIds.splice(index, 1);
            }

            return newSelectedIds;
        });
    };

    const handleButtonClick = async () => {
        setIsConfirming(true);

        await onConfirm(selectedIds);

        setIsConfirming(false);
    };

    const handleSelectAll = () => {
        setSelectedIds(employees.map(employee => employee.id));
    };

    const handleDeselectAll = () => {
        setSelectedIds([]);
    };

    return (
        <>
            <Title>Bestätigen Sie hier die Anwesenheit der Mitarbeiter</Title>
            <ButtonsWrapper>
                <Button
                    onClick={handleSelectAll}
                    disabled={selectedIds.length === employees.length}
                >
                    Wählen Sie Alle
                </Button>
                <Button
                    onClick={handleDeselectAll}
                    disabled={selectedIds.length === 0}
                >
                    Alle abwählen
                </Button>
            </ButtonsWrapper>
            <CheckboxesWrapper>
                {employees.map(employee => (
                    <StyledFormField key={employee.id}>
                        <Checkbox
                            label={`${employee.firstName} ${employee.lastName}`}
                            id={`employee_${employee.id}`}
                            name="employee[]"
                            checked={selectedIds.includes(employee.id)}
                            onChange={e =>
                                handleCheckboxChange(
                                    employee.id,
                                    e.target.checked,
                                )
                            }
                        />
                    </StyledFormField>
                ))}
            </CheckboxesWrapper>
            <ButtonWrapper>
                <Button
                    disabled={isConfirming || selectedIds.length === 0}
                    type="button"
                    onClick={handleButtonClick}
                >
                    {isConfirming && <Loader size={24} />}
                    {confirmButtonText}
                </Button>
            </ButtonWrapper>
        </>
    );
};

GroupAssignmentEmployeeChecklist.propTypes = {
    employees: PropTypes.array.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
};

export default GroupAssignmentEmployeeChecklist;
