import { createGlobalStyle } from 'styled-components';
import colors from 'Common/constants/colors';

const GlobalStyles = createGlobalStyle`
    html {
        padding-bottom: 30px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background: ${colors.ALT_DARKEST_GRAY};
        color: ${colors.LIGHTEST_GRAY};
        font-size: 16px;
        
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: inherit;
           -moz-box-sizing: inherit;
                box-sizing: inherit;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-button:start:decrement,
    ::-webkit-scrollbar-button:end:increment  {
        display: none;
    }

    ::-webkit-scrollbar-track-piece  {
        background-color: ${colors.DARKER_GRAY};
        -webkit-border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${colors.GRAY};
        -webkit-border-radius: 5px;
    }
    
    ::-ms-clear {
       display: none;
    }
    
    ::-ms-reveal {
       display: none;
    }
    
    button::-moz-focus-inner {
      border: 0;
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }
    
    * {
        font-family: 'Open Sans', sans-serif;
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        
        to {
            opacity: 1;
        }
    }
    
    .fadeIn {
        animation-name: fadeIn;
    }
    
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        
        to {
            opacity: 0;
        }
    }
    
    .fadeOut {
        animation-name: fadeOut;
    }
    
    .Toastify {
        .Toastify__toast-container {
            width: calc(100% - 40px);
            max-width: 400px;
        }
    
        .Toastify__toast {
            padding: 12px 50px 12px 62px;
            border-radius: 4px;
        }
    
        .Toastify__toast--error,
        .Toastify__toast--warning{
            background-image: linear-gradient(to left, #c14e4f, #8e4646 110%);
        }
    
        .Toastify__toast--success,
        .Toastify__toast--info {
            background-image: linear-gradient(to left, #c58958, #966e4c 110%);
        }
    
        .Toastify__toast-body > svg {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    
        .close-button {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            color: ${colors.WHITE};
            transition: .3s ease;
            opacity: 0.7;
            cursor: pointer;
            
            &:hover {
                opacity: 1;
            }
        }
    }
    
    .SingleDatePickerInput {
        background-color: transparent;
        
        &__disabled {
            opacity: 0.3;
        }
    }
        
    .DateInput {
        background: transparent;
    }
            
    .DateInput_input {
        height: 46px;
        padding: 0 15px;  
        background-color: transparent;
        border: 0 none;
        color: ${colors.WHITE};
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
            color: ${colors.LIGHT_GRAY};
        }
    
        &:focus,
        &__focused {
            outline: 0 none;
            color: ${colors.WHITE};
        }
        
        &[disabled],
        &__disabled {
            font-style: normal;
            background: transparent;
            cursor: not-allowed;
        }
    }
    
    .DateInput_fang {
        transform: translateY(1px);
    }
    
    .DateInput_fangShape {
        fill: ${colors.ALT_DARKEST_GRAY};
    }
    
    .DateInput_fangStroke {
        stroke: transparent;
    }
    
    .SingleDatePicker_picker {
        z-index: 2;
    }
    
    .SingleDatePicker_picker,
    .DayPicker,
    .DayPicker__horizontal,
    .CalendarMonth,
    .CalendarMonthGrid {
        background: ${colors.ALT_DARKEST_GRAY};
    }
    
    .DayPicker__withBorder {
        box-shadow: 0 2px 6px rgb(0, 0, 0, 0.4), 0 0 0 1px rgb(0, 0, 0, 0.17);
        border-radius: 0;
    }
    
    .DayPicker_transitionContainer {
        border-radius: 0;
    }
    
    .DayPicker_transitionContainer__horizontal {
        transition: none;
    }
    
    .DayPickerNavigation_button__default {
        border: 0 none;
        border-radius: 0;
        background-color: ${colors.DARK_GRAY};
        
        &:hover,
        &:focus {
            outline: 0 none;
            border: 0 none;
            background-color: ${colors.ALT_GRAY};
        }
    }
    
    .CalendarMonth_caption {
        color: ${colors.LIGHTEST_GRAY};
    }
    
    .DayPicker_weekHeader {
        color: ${colors.LIGHT_GRAY};
    }
    
    .CalendarDay__default {
        background: ${colors.DARK_GRAY};
        border: 1px solid ${colors.DARKER_GRAY};
        color: ${colors.LIGHTEST_GRAY};
        font-size: 13px;
        
        &:hover,
        &:focus {
            background: ${colors.DARKEST_GRAY};
            border: 1px solid ${colors.ALT_DARKEST_GRAY};
            outline: 0 none;
        }
    }
    
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
        background: ${colors.ORANGE};
        border: 1px double ${colors.GOLD_ORANGE};
        color: ${colors.WHITE};
        font-weight: 600;
    }
    
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
        background: ${colors.DARK_GRAY};
        border: 1px solid ${colors.ALT_GRAY};
        color: ${colors.GRAY};
    }
    
    [data-rmiz-btn-close] {
        &:focus {
            outline: 0 none;
        }
    }
`;

export default GlobalStyles;
