import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';

const StyledNotchedOutline = styled(NotchedOutline)`
    &.MuiOutlinedInput-notchedOutline {
        border-color: ${colors.GRAY};
        border-radius: 4px;
        font-size: 14px;

        &.Mui-focused {
            border-width: 2px;
            border-color: ${colors.ORANGE} !important;
        }

        &.Mui-error {
            border-color: ${colors.ERROR} !important;
        }

        &.Mui-disabled {
            opacity: 0.3;
        }

        &.no-label {
            legend {
                span {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
`;

export default StyledNotchedOutline;
