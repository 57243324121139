import colors from 'Common/constants/colors';
import styled from 'styled-components/macro';

export const DriverLicenseControlGridItemWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    padding: 15px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) {
        width: calc(50% - 5px);
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 0;

        &:first-child,
        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
`;
