import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';
import Input from 'Common/components/form/fields/Input';
import ConfirmModal from 'Common/components/ConfirmModal';
import AsyncSelect from 'Common/components/form/fields/AsyncSelect';
import loadInstructionAreaOptions from 'Education/utils/loadInstructionAreaOptions';
import createInstruction from 'Education/api/instruction/createInstruction';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const SubTitle = styled.h3`
    margin-top: 0;
`;

const Content = styled.div``;

const Form = styled.form`
    label {
        text-align: left;
    }
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    areas: Yup.array()
        .of(Yup.object())
        .required('Pflichtangabe')
        .nullable(),
});

const InstructionCreateModal = ({ isOpen, onCancel, onSuccess }) => {
    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: {
            name: '',
            areas: [],
        },
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createInstruction(
                    {
                        name: values.name,
                        areaIds: values.areas.map(option => option.value),
                    },
                    source.token,
                );

                await onSuccess(response.data);

                setIsCreating(false);

                notify('Die Unterweisung wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsCreating(false);

                    notify(
                        'Die Unterweisung wurde nicht erfolgreich erstellt',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        },
    });

    return (
        <ConfirmModal
            isOpen={isOpen}
            isConfirmLoading={isCreating}
            isConfirmDisabled={!isValid}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmText="Erstellen"
        >
            <Content>
                <SubTitle>Unterweisung erstellen</SubTitle>
                <Form onSubmit={handleSubmit}>
                    <FormField>
                        <Input
                            label="Unterweisungsname"
                            id="name"
                            name="name"
                            error={errors.name}
                            touched={touched.name}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                    <FormField>
                        <AsyncSelect
                            label="Bereich auswählen"
                            id="areas"
                            name="areas"
                            error={errors.areas}
                            touched={touched.areas}
                            value={values.areas}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            loadOptions={loadInstructionAreaOptions}
                            menuPosition="fixed"
                            isMulti
                            isSearchable
                            isClearable
                        />
                    </FormField>
                </Form>
            </Content>
        </ConfirmModal>
    );
};

InstructionCreateModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionCreateModal;
