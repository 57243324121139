import React, { useCallback, useState } from 'react';
import getInstructionAssignmentTodos from 'Education/api/instruction-assignment/getInstructionAssignmentTodos';
import styled from 'styled-components/macro';
import Loader from 'Common/components/Loader';
import AssignmentSignatureModal from 'Employee/components/employee-view/instruction/AssignmentSignatureModal';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import OpenInstructionAssignmentsTable from 'Employee/components/employee-view/instruction/OpenInstructionAssignmentsTable';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import colors from 'Common/constants/colors';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 8px 0;
    border-radius: 4px;
`;

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const OpenEmployeeInstructionAssignments = () => {
    const instructionAssignmentsRequest = useCallback(
        cancelToken =>
            getInstructionAssignmentTodos(
                [
                    'groupAssignmentDetails',
                    'groupAssignmentDetails.assignees.employee.user',
                    'responsibleEmployee',
                    'responsibleEmployee.user',
                    'responsibleEmployee.completedInstructions',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: instructionAssignments,
        setData: setInstructionAssignments,
        loadData: loadInstructionAssignments,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAssignmentsRequest, []);

    const [
        assignmentIdForConfirmation,
        setAssignmentIdForConfirmation,
    ] = useState(null);

    const handleAssignmentConfirmationCancel = () => {
        setAssignmentIdForConfirmation(null);
    };

    const handleAssignmentConfirmationSuccess = async () => {
        setInstructionAssignments(prevInstructionAssignments =>
            prevInstructionAssignments.filter(
                assignment => assignment.id !== assignmentIdForConfirmation,
            ),
        );

        setAssignmentIdForConfirmation(null);

        notify('Die Unterschrift wurde bestätigt', {
            type: toast.TYPE.SUCCESS,
        });
    };

    return (
        <Content>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : hasError ? (
                <LoaderWrapper>
                    <RetryLoading onRetry={loadInstructionAssignments} />
                </LoaderWrapper>
            ) : (
                <>
                    <OpenInstructionAssignmentsTable
                        instructionAssignments={instructionAssignments}
                        onSelectAssignmentIdForConfirmation={
                            setAssignmentIdForConfirmation
                        }
                    />
                    {assignmentIdForConfirmation !== null && (
                        <AssignmentSignatureModal
                            assignmentId={assignmentIdForConfirmation}
                            isOpen={assignmentIdForConfirmation !== null}
                            onCancel={handleAssignmentConfirmationCancel}
                            onSuccess={handleAssignmentConfirmationSuccess}
                        />
                    )}
                </>
            )}
        </Content>
    );
};
export default OpenEmployeeInstructionAssignments;
