import React from 'react';
import Input from 'Common/components/form/fields/Input';
import { Search } from '@material-ui/icons';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import employeeTypeFilters from 'Employee/constants/employeeTypeFilters';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        position: sticky;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        top: 81px;
        max-height: calc(100vh - 151px);
        width: 300px;
        flex: 0 0 300px;
        align-self: flex-start;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const SearchWrapper = styled.div`
    padding: 20px;
    background: ${colors.DARK_GRAY};
    margin-bottom: 1px;
    border-radius: 4px 4px 0 0;
`;

const FiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 1px;
    background: ${colors.DARK_GRAY};
`;

const EmployeeFiltersWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) and (max-width: 939px) {
        flex: 1;
        width: auto;
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const StatusFilterButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 1px;
    padding: 14px 20px;
    background: ${colors.DARKEST_GRAY};
    border: 0 none;
    outline: 0;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    color: ${ifProp('isActive', colors.ORANGE, colors.WHITE)};
    cursor: pointer;

    &:hover:not([disabled]) {
        background: ${colors.ALT_GRAY};
    }

    &[disabled] {
        cursor: default;
    }

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

const ItemsFound = styled.div`
    position: absolute;
    top: -10px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    padding: 0 7px;
    color: ${colors.WHITE};
    border-radius: 24px;
    border: 2px solid ${colors.ORANGE};
    font-size: 11px;
    font-weight: 600;
    background: ${colors.DARK_GRAY};
`;

const Sidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedStatus,
    onStatusChange,
    selectedEmployeeType,
    onEmployeeTypeChange,
    employees,
}) => {
    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    const numberOfActiveEmployeesFound = employees.filter(
        employee => employee.isEnabled,
    ).length;

    const numberOfInactiveEmployeesFound = employees.filter(
        employee => !employee.isEnabled,
    ).length;

    return (
        <Wrapper>
            <SearchWrapper>
                <Input
                    name="search"
                    label="Suche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </SearchWrapper>
            <FiltersWrapper>
                <EmployeeFiltersWrapper>
                    <StatusFilterButton
                        disabled={
                            selectedStatus === employeeStatusFilters.ACTIVE
                        }
                        isActive={
                            selectedStatus === employeeStatusFilters.ACTIVE
                        }
                        onClick={() =>
                            onStatusChange(employeeStatusFilters.ACTIVE)
                        }
                    >
                        Aktive Mitarbeiter
                        {!!searchTerm && (
                            <ItemsFound>
                                {numberOfActiveEmployeesFound}
                            </ItemsFound>
                        )}
                    </StatusFilterButton>
                    <StatusFilterButton
                        disabled={
                            selectedStatus === employeeStatusFilters.INACTIVE
                        }
                        isActive={
                            selectedStatus === employeeStatusFilters.INACTIVE
                        }
                        onClick={() =>
                            onStatusChange(employeeStatusFilters.INACTIVE)
                        }
                    >
                        Inaktive Mitarbeiter
                        {!!searchTerm && (
                            <ItemsFound>
                                {numberOfInactiveEmployeesFound}
                            </ItemsFound>
                        )}
                    </StatusFilterButton>
                </EmployeeFiltersWrapper>
                <EmployeeFiltersWrapper>
                    <StatusFilterButton
                        disabled={
                            selectedEmployeeType === employeeTypeFilters.ALL
                        }
                        isActive={
                            selectedEmployeeType === employeeTypeFilters.ALL
                        }
                        onClick={() =>
                            onEmployeeTypeChange(employeeTypeFilters.ALL)
                        }
                    >
                        Alle Mitarbeiter
                    </StatusFilterButton>
                    <StatusFilterButton
                        disabled={
                            selectedEmployeeType === employeeTypeFilters.TRAINEE
                        }
                        isActive={
                            selectedEmployeeType === employeeTypeFilters.TRAINEE
                        }
                        onClick={() =>
                            onEmployeeTypeChange(employeeTypeFilters.TRAINEE)
                        }
                    >
                        Auszubildende
                    </StatusFilterButton>
                </EmployeeFiltersWrapper>
            </FiltersWrapper>
        </Wrapper>
    );
};

Sidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    selectedEmployeeType: PropTypes.string.isRequired,
    onEmployeeTypeChange: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
};

export default Sidebar;
