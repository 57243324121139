import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
    CreateTableBodyRow,
    DefaultTableBody,
    DefaultTableHead,
    Table,
    TableRowActions,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import colors from 'Common/constants/colors';
import { WarningSharp } from '@material-ui/icons';
import HamburgerButton from 'Common/components/HamburgerButton';
import ExternalLink from 'Common/components/ExternalLink';
import {
    DocumentIcon,
    DownloadIcon,
    EditIcon,
    TrashIcon,
} from 'Common/components/icons';
import Link from 'Common/components/Link';
import ButtonLink from 'Common/components/ButtonLink';
import { useOnClickOutside } from 'crooks';
import InstructionCreateModal from 'Education/components/instruction/InstructionCreateModal';
import InstructionDeleteModal from 'Education/components/instruction/InstructionDeleteModal';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';

const Actions = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 260px;
    padding: 20px 45px 20px 20px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        i {
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const WarningMessage = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: ${colors.ORANGE};

    svg {
        font-size: 13px !important;
        margin-right: 5px;
    }
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const InstructionsTable = ({
    instructions,
    onInstructionCreate,
    onInstructionDelete,
}) => {
    const [instructionIdForDeletion, setInstructionIdForDeletion] = useState(
        null,
    );

    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const handleCreateButtonClick = () => {
        setIsCreateModalOpened(true);
    };

    const handleCreateCancel = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateSuccess = async newInstruction => {
        await onInstructionCreate(newInstruction);
        setIsCreateModalOpened(false);
    };

    const handleDeleteButtonClick = instructionId => {
        setInstructionIdForDeletion(instructionId);
    };

    const handleDeleteCancel = () => {
        setInstructionIdForDeletion(null);
    };

    const handleDeleteSuccess = () => {
        if (instructionIdForDeletion) {
            onInstructionDelete(instructionIdForDeletion);
            setInstructionIdForDeletion(null);
        }
    };

    const [openedActionsMenuId, setOpenedActionsMenuId] = useState(null);

    const handleClickOutsideActions = () => {
        setOpenedActionsMenuId(null);
    };

    const actionsRef = useOnClickOutside(
        handleClickOutsideActions,
        openedActionsMenuId === null,
    );

    const handleHamburgerButtonClick = (e, instructionId) => {
        e.stopPropagation();

        setOpenedActionsMenuId(prevOpenedActionsMenuId => {
            if (prevOpenedActionsMenuId === instructionId) {
                return null;
            }

            return instructionId;
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Bereich',
                accessor: 'areas',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            instructions.map(instruction => {
                let areActionsOpened = openedActionsMenuId === instruction.id;

                return {
                    name: (
                        <>
                            {instruction.name}
                            {instruction.slidesCount === 0 && (
                                <WarningMessage>
                                    <WarningSharp />
                                    Keine Folien in der Präsentation
                                </WarningMessage>
                            )}
                            {instruction.questionsCount === 0 && (
                                <WarningMessage>
                                    <WarningSharp />
                                    Keine Fragen im Test
                                </WarningMessage>
                            )}
                        </>
                    ),
                    areas: instruction.areas.map(area => area.name).join(', '),
                    actions: (
                        <TableRowActions ref={actionsRef}>
                            <HamburgerButton
                                isOpened={areActionsOpened}
                                onClick={e =>
                                    handleHamburgerButtonClick(
                                        e,
                                        instruction.id,
                                    )
                                }
                            />
                            {areActionsOpened && (
                                <Actions>
                                    {instruction.slidesCount > 0 && (
                                        <ExternalLink
                                            href={`/api/education/instructions/${instruction.id}/presentation/download-slides`}
                                            target="_blank"
                                        >
                                            <DocumentIcon size="xs" />
                                            Als PDF öffnen
                                        </ExternalLink>
                                    )}
                                    {!!instruction.presentationAttachment && (
                                        <ExternalLink
                                            href={`/api/education/instructions/${instruction.id}/presentation/download-attachment`}
                                            target="_blank"
                                        >
                                            <DownloadIcon size="xs" />
                                            Download PowerPoint
                                        </ExternalLink>
                                    )}
                                    <Link
                                        to={`/education/instruction/${instruction.id}/presentation`}
                                    >
                                        <EditIcon size="xs" />
                                        Folien bearbeiten
                                    </Link>
                                    <Link
                                        to={`/education/instruction/${instruction.id}/test`}
                                    >
                                        <EditIcon size="xs" />
                                        Test bearbeiten
                                    </Link>
                                    <ButtonLink
                                        onClick={() =>
                                            handleDeleteButtonClick(
                                                instruction.id,
                                            )
                                        }
                                    >
                                        <TrashIcon size="xs" />
                                        Löschen
                                    </ButtonLink>
                                </Actions>
                            )}
                        </TableRowActions>
                    ),
                };
            }),
        [instructions, openedActionsMenuId, actionsRef],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['areas', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <>
            {isCreateModalOpened && (
                <InstructionCreateModal
                    isOpen={isCreateModalOpened}
                    onCancel={handleCreateCancel}
                    onSuccess={handleCreateSuccess}
                />
            )}
            {instructionIdForDeletion !== null && (
                <InstructionDeleteModal
                    instruction={
                        instructionIdForDeletion
                            ? instructions.find(
                                  instruction =>
                                      instruction.id ===
                                      instructionIdForDeletion,
                              )
                            : null
                    }
                    onCancel={handleDeleteCancel}
                    onSuccess={handleDeleteSuccess}
                />
            )}

            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    before={
                        <CreateTableBodyRow
                            onClick={handleCreateButtonClick}
                            columnsCount={visibleColumns.length}
                        >
                            Neue Unterweisung anlegen
                        </CreateTableBodyRow>
                    }
                />
            </Table>
        </>
    );
};

InstructionsTable.propTypes = {
    instructions: PropTypes.array.isRequired,
    onInstructionCreate: PropTypes.func.isRequired,
    onInstructionDelete: PropTypes.func.isRequired,
};

export default InstructionsTable;
