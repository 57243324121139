import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { PortraitToLandscapeIcon } from 'Common/components/icons';

const Wrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    background: rgba(0, 0, 0, 0.65);
`;

const Icon = styled(PortraitToLandscapeIcon)`
    color: ${colors.LIGHTEST_GRAY};
`;

const Text = styled.div`
    margin-top: 20px;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 21px;
    line-height: 32px;
    text-align: center;
`;

const TurnYourDeviceInLandscapeModeOverlay = () => (
    <Wrapper>
        <Icon />
        <Text>Drehen Sie Ihren Bildschirm zur Seite</Text>
    </Wrapper>
);

export default TurnYourDeviceInLandscapeModeOverlay;
