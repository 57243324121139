import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const FailedMessage = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5em;
    color: ${colors.ORANGE};

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const InformationText = styled.div`
    margin-bottom: 30px;
    font-size: 14px;
    color: ${colors.WHITE};
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > a,
    > button {
        width: auto;

        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const FailedExamScreen = ({
    attemptsLeft,
    onShowExamMistakes,
    onShowPresentation,
    onRetakeExam,
}) => {
    const [isRetaking, setIsRetaking] = useState(false);

    const handleRetakeExamButtonClick = async () => {
        setIsRetaking(true);

        await onRetakeExam();

        setIsRetaking(false);
    };

    let informationText;

    if (attemptsLeft === 0) {
        informationText = 'Sie haben keine Versuche mehr';
    } else {
        informationText =
            'Sie können Ihre Fehler überprüfen und erneut versuchen';

        if (attemptsLeft !== null) {
            informationText += `. Versuche übrig: ${attemptsLeft}`;
        }
    }

    return (
        <Wrapper>
            <FailedMessage>
                Leider haben Sie diesen Test nicht bestanden
            </FailedMessage>
            <InformationText>{informationText}</InformationText>
            <ButtonWrapper>
                <Button
                    type="button"
                    disabled={isRetaking}
                    onClick={onShowExamMistakes}
                >
                    Prüfungsfehler anzeigen
                </Button>
                <Button
                    type="button"
                    disabled={isRetaking}
                    onClick={onShowPresentation}
                >
                    Präsentation erneut anzeigen
                </Button>
                {attemptsLeft !== 0 && (
                    <Button
                        type="button"
                        disabled={isRetaking}
                        onClick={handleRetakeExamButtonClick}
                    >
                        {isRetaking && <Loader size={24} />}
                        Prüfung wiederholen
                    </Button>
                )}
            </ButtonWrapper>
        </Wrapper>
    );
};

FailedExamScreen.defaultProps = {
    attemptsLeft: null,
};

FailedExamScreen.propTypes = {
    attemptsLeft: PropTypes.number,
    onShowExamMistakes: PropTypes.func.isRequired,
    onShowPresentation: PropTypes.func.isRequired,
    onRetakeExam: PropTypes.func.isRequired,
};

export default FailedExamScreen;
