import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import createInstructionArea from 'Education/api/instruction-area/createInstructionArea';
import FormField from 'Common/components/form/FormField';
import Input from 'Common/components/form/fields/Input';
import Textarea from 'Common/components/form/fields/Textarea';
import ConfirmModal from 'Common/components/ConfirmModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const SubTitle = styled.h3`
    margin-top: 0;
`;

const Content = styled.div``;

const Form = styled.form`
    label {
        text-align: left;
    }
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    description: Yup.string(),
});

const InstructionAreaCreateModal = ({ isOpen, onCancel, onSuccess }) => {
    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createInstructionArea(
                    {
                        name: values.name,
                        description: values.description,
                    },
                    source.token,
                );

                await onSuccess(response.data);

                setIsCreating(false);

                notify('Der Bereich wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsCreating(false);

                    notify('Der Bereich wurde nicht erfolgreich erstellt', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    return (
        <ConfirmModal
            isOpen={isOpen}
            isConfirmLoading={isCreating}
            isConfirmDisabled={!isValid}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmText="Erstellen"
        >
            <Content>
                <SubTitle>Bereich erstellen</SubTitle>
                <Form onSubmit={handleSubmit}>
                    <FormField>
                        <Input
                            label="Bereich"
                            id="name"
                            name="name"
                            error={errors.name}
                            touched={touched.name}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                    <FormField>
                        <Textarea
                            label="Beschreibung"
                            id="description"
                            name="description"
                            error={errors.description}
                            touched={touched.description}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                </Form>
            </Content>
        </ConfirmModal>
    );
};

InstructionAreaCreateModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaCreateModal;
