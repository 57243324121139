import React, { useCallback } from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import ExternalLink from 'Common/components/ExternalLink';
import { AddSharp } from '@material-ui/icons';
import getCompany from 'Company/api/getCompany';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';

const CreateButton = styled(ExternalLink)`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background: ${colors.DARK_GRAY};
    color: ${colors.LIGHTER_GRAY};
    border: 0 none;
    outline: 0;
    border-radius: 4px;

    ${ifProp(
        'isDisabled',
        css`
            opacity: 0.5;

            &:hover {
                color: ${colors.LIGHTER_GRAY};
            }
        `,
    )};
`;

const EmployeeCreateButton = () => {
    const companyRequest = useCallback(
        cancelToken => getCompany(['usedEmployeeLicenses'], cancelToken),
        [],
    );

    const { data: company, isLoading, hasError } = useAxiosRequest(
        companyRequest,
        {},
    );

    let isLimitReached = false;

    if (company) {
        isLimitReached =
            company.usedEmployeeLicenses >= company.employeeLicensesLimit;
    }

    return (
        <CreateButton
            isDisabled={isLoading || hasError || isLimitReached}
            title={isLimitReached ? 'Limit erreicht' : undefined}
            href="/backend/employees"
        >
            Mitarbeiter
            <AddSharp />
        </CreateButton>
    );
};

export default EmployeeCreateButton;
