import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import ConfirmModal from 'Common/components/ConfirmModal';
import notify from 'Common/utils/notify';

import getEmployees from 'Employee/api/getEmployees';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import Loader from 'Common/components/Loader';
import updateGroupInstructionAssignment from 'Education/api/instruction-assignment/group/updateGroupInstructionAssignment';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import Select from 'Common/components/form/fields/Select';
import * as Yup from 'yup';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getInstruction from 'Education/api/instruction/getInstruction';
import RetryLoading from 'Common/components/RetryLoading';
import axios from 'axios';

const Title = styled.h3`
    margin-top: 0;
`;

const SubTitle = styled.h5``;

const Content = styled.div`
    text-align: left;
`;

const validationSchema = Yup.object().shape({
    groupLeader: Yup.object()
        .required('Pflichtangabe')
        .nullable(),
    employees: Yup.array()
        .of(Yup.object())
        .required('Pflichtangabe')
        .nullable(),
});

const GroupInstructionAssignmentEditModal = ({
    assignmentId,
    onCancel,
    onSuccess,
}) => {
    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const isOpen = assignmentId !== null;

    const request = useCallback(
        async cancelToken => {
            const instructionAssignmentResponse = await getInstructionAssignment(
                assignmentId,
                ['responsibleEmployee', 'groupAssignmentDetails'],
                cancelToken,
            );

            const instructionResponse = await getInstruction(
                instructionAssignmentResponse.data.instructionId,
                ['areas'],
                cancelToken,
            );

            return {
                instructionAssignment: instructionAssignmentResponse.data,
                instruction: instructionResponse.data,
            };
        },
        [assignmentId],
    );

    const {
        data: { instructionAssignment, instruction },
        loadData: loadInstruction,
        isLoading: isLoadingInstruction,
        hasError: hasInstructionError,
    } = useAxiosRequest(request, {
        instructionAssignment: null,
        instruction: null,
    });

    const employeesRequest = useCallback(
        cancelToken =>
            getEmployees(
                ['areas', 'completedInstructions', 'assignedGroupInstructions'],
                {
                    onlyActive: true,
                },
                cancelToken,
            ),
        [],
    );

    const {
        data: employees,
        loadData: loadEmployees,
        isLoading: isLoadingEmployees,
        hasError: hasEmployeesError,
    } = useAxiosRequest(employeesRequest, []);

    const initialValues = useMemo(() => {
        if (!instructionAssignment) {
            return {
                groupLeader: null,
                employees: [],
            };
        }

        const assignmentDetails = instructionAssignment.groupAssignmentDetails;
        const groupLeader = assignmentDetails.groupLeader;

        return {
            groupLeader: {
                value: groupLeader.employee.id,
                label: `${groupLeader.employee.firstName} ${groupLeader.employee.lastName}`,
            },
            employees: assignmentDetails.assignees.map(assignee => ({
                value: assignee.employee.id,
                label: `${assignee.employee.firstName} ${assignee.employee.lastName}`,
            })),
        };
    }, [instructionAssignment]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            setIsSavingChanges(true);

            try {
                await updateGroupInstructionAssignment(
                    assignmentId,
                    {
                        groupLeaderId: values.groupLeader.value,
                        employeeIds: values.employees.map(
                            option => option.value,
                        ),
                    },
                    source.token,
                );

                await onSuccess();

                setIsSavingChanges(false);

                notify('Der Präsenzunterweisung wurde aktualisiert', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsSavingChanges(false);

                    notify('Der Präsenzunterweisung wurde nicht aktualisiert', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    const instructionId = instruction?.id ?? null;
    const groupLeaderId = values.groupLeader ? values.groupLeader.value : null;
    const selectedEmployeeOptions = values.employees ?? [];

    const employeesWithMatchingAreas = employees.filter(employee => {
        if (!instruction) {
            return false;
        }

        const instructionAreaIds = instruction.areas.map(area => area.id);
        const employeeAreaIds = employee.areas.map(area => area.id);
        const intersectingAreaIds = instructionAreaIds.filter(areaId =>
            employeeAreaIds.includes(areaId),
        );

        return intersectingAreaIds.length > 0;
    });

    const leaderEmployeeOptions = employeesWithMatchingAreas
        .filter(employee => !employee.isTrainee)
        .map(employee => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
        }));

    const employeeOptions = employeesWithMatchingAreas
        .filter(employee => {
            const assignmentDetails =
                instructionAssignment.groupAssignmentDetails;
            const assignees = assignmentDetails.assignees;

            const savedEmployeeIds = assignees.map(
                assignee => assignee.employee.id,
            );

            const wasSavedPreviously = savedEmployeeIds.includes(employee.id);

            if (wasSavedPreviously) {
                return true;
            }

            const groupInstructionIds = employee.assignedGroupInstructions.map(
                instruction => instruction.id,
            );
            const completedInstructionIds = employee.completedInstructions.map(
                instruction => instruction.id,
            );

            const isPartOfAnotherGroupAssignmentOfThisInstruction = groupInstructionIds.includes(
                instructionId,
            );

            const hasCompletedInstruction = completedInstructionIds.includes(
                instructionId,
            );

            return (
                !isPartOfAnotherGroupAssignmentOfThisInstruction &&
                !hasCompletedInstruction
            );
        })
        .map(employee => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
        }));

    const handleGroupLeaderChange = option => {
        setFieldValue('groupLeader', option);

        const newEmployees = [...selectedEmployeeOptions];

        // Remove previously selected option
        if (groupLeaderId) {
            const currentlySelectedOptionIndex = selectedEmployeeOptions.findIndex(
                selectedOption => selectedOption.value === groupLeaderId,
            );

            if (currentlySelectedOptionIndex !== -1) {
                newEmployees.splice(currentlySelectedOptionIndex, 1);
            }
        }

        // Add new option
        if (option) {
            const optionIndex = employeeOptions.findIndex(
                employeeOption => employeeOption.value === option.value,
            );

            const isAlreadySelected =
                selectedEmployeeOptions.findIndex(
                    selectedOption => selectedOption.value === option.value,
                ) !== -1;

            if (optionIndex !== -1 && !isAlreadySelected) {
                newEmployees.push(option);
            }
        }

        setFieldValue('employees', newEmployees);
    };

    const isLoading = isLoadingInstruction || isLoadingEmployees;
    const hasError = hasInstructionError || hasEmployeesError;

    const handleRetryLoading = () => {
        loadInstruction();
        loadEmployees();
    };

    return (
        <ConfirmModal
            isOpen={isOpen}
            isLoading={isLoading}
            isConfirmLoading={isSavingChanges}
            isConfirmDisabled={!isValid}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmText="Speichern"
        >
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={handleRetryLoading} />
            ) : instructionAssignment === null ? (
                <p>Der Präsenzunterweisung ist nicht mehr verfügbar</p>
            ) : (
                <>
                    <Title>Präsenzunterweisung Bearbeiten</Title>
                    <SubTitle>{`Unterweisung: ${instruction.name}`}</SubTitle>
                    <Content>
                        <form onSubmit={handleSubmit}>
                            <FormField>
                                <Select
                                    label="Gruppenleiter"
                                    id="groupLeader"
                                    name="groupLeader"
                                    error={errors.groupLeader}
                                    touched={touched.groupLeader}
                                    value={values.groupLeader}
                                    onChange={handleGroupLeaderChange}
                                    onBlur={handleBlur}
                                    options={leaderEmployeeOptions}
                                    menuPosition="fixed"
                                    isSearchable
                                    isClearable
                                />
                            </FormField>
                            <FormField>
                                <Select
                                    label="Mitarbeiter"
                                    id="employees"
                                    name="employees"
                                    error={errors.employees}
                                    touched={touched.employees}
                                    value={values.employees}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    options={employeeOptions}
                                    menuPosition="fixed"
                                    isMulti
                                    isSearchable
                                    isClearable
                                />
                            </FormField>
                        </form>
                    </Content>
                </>
            )}
        </ConfirmModal>
    );
};

GroupInstructionAssignmentEditModal.defaultProps = {
    assignmentId: null,
};

GroupInstructionAssignmentEditModal.propTypes = {
    assignmentId: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentEditModal;
