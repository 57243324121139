import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import {
    ActionBar,
    ActionBarButtonsWrapper,
    ActionBarTitle,
} from 'Common/components/actionBar';
import ImageSlide from 'Education/components/instruction-assignment/presentation/ImageSlide';
import Loader from 'Common/components/Loader';
import {
    ArrowBackSharp,
    ArrowForwardSharp,
    FullscreenExitSharp,
    FullscreenSharp,
} from '@material-ui/icons';
import VideoSlide from 'Education/components/instruction-assignment/presentation/VideoSlide';
import goToNextPresentationSlide from 'Education/api/instruction-assignment/presentation/goToNextPresentationSlide';
import HamburgerButton from 'Common/components/HamburgerButton';
import SlidesSidebarMenu from 'Education/components/instruction-assignment/presentation/SlidesSidebarMenu';
import completeInstructionAssignmentPresentation from 'Education/api/instruction-assignment/presentation/completeInstructionAssignmentPresentation';
import { useOnClickOutside } from 'crooks';
import Button from 'Common/components/Button';
import LinkButton from 'Common/components/LinkButton';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import TurnYourDeviceInLandscapeModeOverlay from 'Common/components/TurnYourDeviceInLandscapeModeOverlay';
import useWindowSize from '@react-hook/window-size';
import { useFullscreen } from 'react-use';
import screenfull from 'screenfull';
import useQueryParameter from 'Common/hooks/useQueryParameter';

const StyledActionBarTitle = styled(ActionBarTitle)`
    display: flex;
    align-items: center;

    button {
        margin-right: 10px;
    }
`;

const SlidesCounter = styled.div`
    flex: 1 0 auto;
    margin-bottom: 10px;
    color: ${colors.LIGHT_GRAY};
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 420px) {
        margin-right: 20px;
        margin-bottom: 0;
    }
`;

const SlideWrapper = styled.div`
    position: relative;
    min-height: 330px;
    padding: 10px;
    background: ${colors.DARK_GRAY};
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    @media screen and (max-width: 939px) {
        ${ifProp(
            'isFullscreen',
            css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                z-index: 9999;
            `,
        )};
    }

    @media screen and (min-width: 940px) {
        min-height: 500px;
    }
`;

const FullscreenControls = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @media screen and (min-width: 940px) {
        display: none;
    }
`;

const ToggleFullscreenButton = styled(Button)`
    border: 0 none;
    padding: 5px 15px;
    font-size: 14px;
    z-index: 1;
    border-radius: 0 0 0 4px;

    &:hover,
    &:hover:not([disabled]),
    &:focus {
        outline: 0 none;
        border: 0 none;
        box-shadow: none;
        background: ${colors.DARK_GRAY};
        color: ${colors.LIGHTEST_GRAY};
    }

    svg {
        color: ${colors.ORANGE};
        font-size: 32px;
        opacity: 0.75;
    }
`;

const SlideContent = styled.div`
    ${ifProp(
        'isLoading',
        css`
            opacity: 0.3;
            pointer-events: none;

            button {
                pointer-events: none;
            }
        `,
    )};
`;

const SlideArrows = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 70px;
    transform: translateY(-50%);
    pointer-events: none;
`;

const ArrowButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: ${colors.ORANGE};
    background: none;
    border: 0 none;
    cursor: pointer;
    pointer-events: all;
    opacity: 0.75;
    transition: opacity 0.3s ease-out;

    &:hover,
    &:focus {
        outline: 0 none;
    }

    &:hover {
        opacity: 1;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    ${ifProp(
        'isOpaque',
        css`
            opacity: 0.4;

            &:hover {
                opacity: 0.4;
            }
        `,
    )}

    svg {
        font-size: 60px;
    }
`;

const PreviousButton = styled(ArrowButton)`
    left: 10px;
`;

const NextButton = styled(ArrowButton)`
    right: 10px;
`;

const AssignmentPresentationInProgress = ({
    assignment,
    initialSlideNumber,
    isInPreviewMode,
    disableLastSlideArrow,
    onProgress,
    onComplete,
    onNavigateBack,
    closeButtonLink,
}) => {
    const [fromTab] = useQueryParameter('fromTab');

    const tabHash = fromTab ? `#${fromTab}` : '';

    const [isLoadingNextSlide, setIsLoadingNextSlide] = useState(false);
    const [slideNumber, setSlideNumber] = useState(initialSlideNumber);

    const [hasSlideTimeElapsed, setHasSlideTimeElapsed] = useState(true);

    const [hasVideoEnded, setHasVideoEnded] = useState(false);
    const [hasVideoTimeElapsed, setHasVideoTimeElapsed] = useState(false);
    const [videoDuration, setVideoDuration] = useState(null);

    const [isSidebarMenuOpened, setIsSidebarMenuOpened] = useState(false);
    const [isFullscreenToggled, setIsFullscreenToggled] = useState(false);

    const fullscreenRef = useRef(null);
    useFullscreen(fullscreenRef, isFullscreenToggled, {
        onClose: () => {
            if (screenfull.isEnabled) {
                setIsFullscreenToggled(false);
            }
        },
    });

    const [width, height] = useWindowSize();
    const isPortrait = width < height;
    const isOverlayVisible = isFullscreenToggled && isMobile && isPortrait;

    const slides = assignment.instruction.slides;
    const slidesCount = slides.length;
    const slide = slides.find((slide, index) => index + 1 === slideNumber);
    const slideDuration = assignment.minimumPresentationSlideDuration * 1000;

    // Reset the video properties once the slide changes
    useEffect(() => {
        setHasVideoEnded(false);
        setHasVideoTimeElapsed(false);
        setVideoDuration(null);
    }, [slideNumber]);

    // Slide time elapse timeout
    useEffect(() => {
        let timeout;

        if (slideDuration > 0) {
            setHasSlideTimeElapsed(false);

            timeout = setTimeout(() => {
                setHasSlideTimeElapsed(true);
            }, slideDuration);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [slideDuration, slideNumber]);

    // Video time elapse timeout
    useEffect(() => {
        let timeout;

        if (videoDuration !== null) {
            timeout = setTimeout(() => {
                setHasVideoTimeElapsed(true);
            }, Number(videoDuration));
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [videoDuration]);

    const handleVideoDurationCalculated = seconds => {
        setVideoDuration(seconds);
    };

    const handleVideoCompleted = () => {
        setHasVideoEnded(true);
    };

    const handlePreviousButtonClick = async () => {
        if (slideNumber > 1 && !isLoadingNextSlide) {
            setSlideNumber(prevSlideNumber => prevSlideNumber - 1);
        }
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleNextButtonClick = async () => {
        if (!isLoadingNextSlide) {
            if (slideNumber < slidesCount) {
                // Change the slide via an API only if it has not been visited yet
                if (slideNumber >= assignment.currentPresentationSlide) {
                    setIsLoadingNextSlide(true);

                    try {
                        await goToNextPresentationSlide(
                            assignment.id,
                            source.token,
                        );
                        await onProgress();

                        setSlideNumber(prevSlideNumber => prevSlideNumber + 1);
                        setIsLoadingNextSlide(false);
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            setIsLoadingNextSlide(false);

                            notify(
                                'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                                {
                                    type: toast.TYPE.ERROR,
                                },
                            );
                        }
                    }
                } else {
                    setSlideNumber(prevSlideNumber => prevSlideNumber + 1);
                }
            } else if (
                slideNumber === slidesCount &&
                slideNumber === assignment.currentPresentationSlide
            ) {
                // The presentation is over
                setIsLoadingNextSlide(true);

                // Do not make API calls if the employee-view is in preview mode
                if (!isInPreviewMode) {
                    try {
                        await completeInstructionAssignmentPresentation(
                            assignment.id,
                        );

                        await onComplete();
                        setIsLoadingNextSlide(false);
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            setIsLoadingNextSlide(false);

                            notify(
                                'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                                {
                                    type: toast.TYPE.ERROR,
                                },
                            );
                        }
                    }
                } else {
                    await onComplete();
                    setIsLoadingNextSlide(false);
                }
            }
        }
    };

    const toggleFullscreen = () => {
        setIsFullscreenToggled(
            prevIsFullscreenToggled => !prevIsFullscreenToggled,
        );
    };

    const handleClickOutsideSidebar = () => {
        setIsSidebarMenuOpened(false);
    };

    const sidebarRef = useOnClickOutside(
        handleClickOutsideSidebar,
        !isSidebarMenuOpened,
    );

    const handleHamburgerButtonClick = () => {
        setIsSidebarMenuOpened(
            prevIsSidebarMenuOpened => !prevIsSidebarMenuOpened,
        );
    };

    const handleSlideNumberChange = number => {
        if (number <= assignment.currentPresentationSlide) {
            setSlideNumber(number);
        }
    };

    const isSlideAlreadyPassed =
        slideNumber < assignment.currentPresentationSlide;

    const isNextArrowVisible =
        isSlideAlreadyPassed ||
        isInPreviewMode ||
        (slide.fileType !== 'VIDEO' && hasSlideTimeElapsed) ||
        (slide.fileType === 'VIDEO' && hasVideoEnded && hasVideoTimeElapsed);

    let nextArrowAvailabilityText;

    if (
        slide.fileType === 'VIDEO' &&
        (!hasVideoTimeElapsed || !hasVideoEnded)
    ) {
        nextArrowAvailabilityText =
            'Sie sollten bis zum Ende des Videos warten';
    } else if (slide.fileType !== 'VIDEO' && !hasSlideTimeElapsed) {
        nextArrowAvailabilityText =
            'Nehmen Sie sich Zeit, um die Folie anzusehen';
    }

    const renderedCloseButtonLink = (
        <LinkButton to={`${closeButtonLink ?? '/dashboard'}${tabHash}`}>
            Schließen
        </LinkButton>
    );

    const hideNextArrow = slideNumber === slidesCount && disableLastSlideArrow;

    return (
        <>
            <ActionBar>
                <StyledActionBarTitle>
                    <HamburgerButton
                        isOpened={false}
                        onClick={handleHamburgerButtonClick}
                    />
                    {slide.title}
                </StyledActionBarTitle>
                <ActionBarButtonsWrapper>
                    <SlidesCounter>
                        {`Seite: ${slideNumber} / ${slidesCount}`}
                    </SlidesCounter>
                    {isInPreviewMode && !closeButtonLink ? (
                        <Button type="button" onClick={onNavigateBack}>
                            Schließen
                        </Button>
                    ) : (
                        renderedCloseButtonLink
                    )}
                </ActionBarButtonsWrapper>
            </ActionBar>
            <div ref={fullscreenRef}>
                <SlideWrapper isFullscreen={isFullscreenToggled}>
                    <FullscreenControls>
                        <ToggleFullscreenButton onClick={toggleFullscreen}>
                            {isFullscreenToggled ? (
                                <>
                                    <FullscreenExitSharp />
                                    Vollbild Beenden
                                </>
                            ) : (
                                <>
                                    <FullscreenSharp />
                                    Vollbild
                                </>
                            )}
                        </ToggleFullscreenButton>
                    </FullscreenControls>
                    <SlideContent>
                        {slide.fileType === 'IMAGE' && (
                            <ImageSlide
                                slide={slide}
                                isFullscreen={isFullscreenToggled}
                            />
                        )}
                        {slide.fileType === 'VIDEO' && (
                            <VideoSlide
                                slide={slide}
                                onDurationCalculated={
                                    handleVideoDurationCalculated
                                }
                                onComplete={handleVideoCompleted}
                                isSeekable={isNextArrowVisible}
                                isPlaying={!isOverlayVisible}
                                isFullscreen={isFullscreenToggled}
                            />
                        )}
                        <SlideArrows>
                            {slideNumber > 1 && (
                                <PreviousButton
                                    type="button"
                                    onClick={handlePreviousButtonClick}
                                >
                                    <ArrowBackSharp />
                                </PreviousButton>
                            )}
                            {!hideNextArrow && (
                                <NextButton
                                    type="button"
                                    onClick={handleNextButtonClick}
                                    disabled={
                                        isLoadingNextSlide ||
                                        !isNextArrowVisible
                                    }
                                    isOpaque={!isNextArrowVisible}
                                    title={
                                        !isNextArrowVisible
                                            ? nextArrowAvailabilityText
                                            : undefined
                                    }
                                >
                                    {isLoadingNextSlide ? (
                                        <Loader size={40} />
                                    ) : (
                                        <ArrowForwardSharp />
                                    )}
                                </NextButton>
                            )}
                        </SlideArrows>
                    </SlideContent>
                    {isSidebarMenuOpened && (
                        <SlidesSidebarMenu
                            elementRef={sidebarRef}
                            slides={slides}
                            slideNumber={slideNumber}
                            maxAvailableSlideNumber={
                                assignment.currentPresentationSlide
                            }
                            onSlideNumberChange={handleSlideNumberChange}
                        />
                    )}
                    {isOverlayVisible && (
                        <TurnYourDeviceInLandscapeModeOverlay />
                    )}
                </SlideWrapper>
            </div>
        </>
    );
};

AssignmentPresentationInProgress.defaultProps = {
    disableLastSlideArrow: false,
    closeButtonLink: undefined,
    onNavigateBack: () => {},
};

AssignmentPresentationInProgress.propTypes = {
    assignment: PropTypes.object.isRequired,
    initialSlideNumber: PropTypes.number.isRequired,
    isInPreviewMode: PropTypes.bool.isRequired,
    disableLastSlideArrow: PropTypes.bool,
    onProgress: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onNavigateBack: PropTypes.func,
    closeButtonLink: PropTypes.string,
};

export default AssignmentPresentationInProgress;
