import api from 'Application/api/api';

const uploadInstructionPresentationSlideFiles = (
    instructionId,
    files,
    cancelToken = null,
) => {
    const formData = new FormData();

    files.forEach((file, index) => {
        formData.append(`file${index}`, file);
    });

    return api.post(
        `/education/instructions/${instructionId}/presentation/upload-slide-files`,
        formData,
        cancelToken,
    );
};

export default uploadInstructionPresentationSlideFiles;
