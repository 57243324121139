import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import colors from 'Common/constants/colors';

const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 46px;
    padding: 5px 20px;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    border-radius: 4px;

    &:focus {
        outline: 0 none;
        border-width: 2px;
    }

    ${switchProp('theme', {
        dark: css`
            background: ${colors.DARKEST_GRAY};
            border: 1px solid ${colors.LIGHT_GRAY};
            color: ${colors.LIGHTEST_GRAY};

            &:hover:not([disabled]) {
                background: ${colors.ORANGE};
                border-color: ${colors.ORANGE};
                color: ${colors.DARKEST_GRAY};
            }

            &:focus {
                border-color: ${colors.ORANGE};
            }
        `,
        light: css`
            background: ${colors.DARK_GRAY};
            border: 1px solid ${colors.LIGHT_GRAY};
            color: ${colors.LIGHTEST_GRAY};

            &:hover:not([disabled]) {
                background: ${colors.ORANGE};
                border-color: ${colors.ORANGE};
                color: ${colors.DARKEST_GRAY};
            }

            &:focus {
                border-color: ${colors.ORANGE};
            }
        `,
    })}

    &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    > i,
    > svg,
    > div[class^='Loader__'] {
        margin-right: 10px;
    }

    > div[class^='Loader__'] {
        flex: 0 0 auto;
    }
`;

const Button = ({ icon: Icon, children, innerRef, ...props }) => {
    const hasIcon = !!Icon;

    return (
        <StyledButton ref={innerRef} {...props}>
            {hasIcon && <Icon size="xs" />}
            {children}
        </StyledButton>
    );
};

Button.defaultProps = {
    theme: 'light',
};

export default Button;
