import React, { useEffect, useMemo, useState } from 'react';
import { DeleteSharp } from '@material-ui/icons';
import ConfirmModal from 'Common/components/ConfirmModal';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import deleteInstruction from 'Education/api/instruction/deleteInstruction';
import axios from 'axios';

const DeleteMessage = styled.div``;

const DeleteLabel = styled.div`
    margin-bottom: 10px;
    color: ${colors.ORANGE};
`;

const InstructionDeleteModal = ({ instruction, onCancel, onSuccess }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsDeleting(true);

            await deleteInstruction(instruction.id, source.token);

            onSuccess();

            setIsDeleting(false);

            notify('Die Unterweisung wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Die Unterweisung konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <ConfirmModal
            isOpen={instruction !== null}
            isConfirmLoading={isDeleting}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            confirmText="Löschen"
            confirmIcon={DeleteSharp}
        >
            <DeleteMessage>
                <DeleteLabel>Unterweisung löschen!</DeleteLabel>
                Wollen Sie den Unterweisung {instruction?.name} wirklich
                Löschen?
            </DeleteMessage>
        </ConfirmModal>
    );
};

InstructionDeleteModal.defaultProps = {
    instruction: null,
};

InstructionDeleteModal.propTypes = {
    instruction: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionDeleteModal;
