import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Breadcrumb from 'Application/components/Header/Breadcrumb';

const Wrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 10px;
    margin: 10px auto;
    background: ${colors.ALT_DARKEST_GRAY};

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const Inner = styled.div`
    display: flex;
    justify-content: center;
`;

const BreadcrumbsPortal = styled.div`
    text-align: center;
`;

const Breadcrumbs = ({ isReact }) => (
    <Wrapper>
        <Inner>
            <BreadcrumbsPortal id="breadcrumbs" />
            {isReact ? (
                <Breadcrumb to="/dashboard">Amedic-Manager</Breadcrumb>
            ) : (
                <Breadcrumb href="/dashboard">Amedic-Manager</Breadcrumb>
            )}
        </Inner>
    </Wrapper>
);

Breadcrumbs.defaultProps = {
    isReact: true,
};

Breadcrumbs.propTypes = {
    isReact: PropTypes.bool,
};

export default Breadcrumbs;
