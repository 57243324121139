import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    background: 0 none;
    border: 0 none;
    color: ${colors.WHITE};
    font-size: 15px;
    cursor: pointer;

    > i,
    > svg {
        margin-right: 10px;
    }

    &:hover {
        color: ${colors.ORANGE};
    }

    &:focus {
        outline: 0 none;
    }

    &[disabled] {
        cursor: not-allowed;
        color: ${colors.LIGHT_GRAY};
    }
`;

const ButtonLink = ({ icon: Icon, children, ...props }) => {
    const hasIcon = !!Icon;

    return (
        <StyledButton {...props}>
            {hasIcon && <Icon size="xs" />}
            {children}
        </StyledButton>
    );
};

export default ButtonLink;
