import React from 'react';
import styled from 'styled-components/macro';
import { InputLabel as MaterialInputLabel } from '@material-ui/core';
import colors from 'Common/constants/colors';

const InputLabel = styled(MaterialInputLabel)`
    &.MuiFormLabel-root {
        color: ${colors.LIGHTER_GRAY};
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;

        &.Mui-focused {
            color: ${colors.ORANGE};
        }

        &.Mui-error {
            color: ${colors.ERROR};
        }
    }

    &.MuiInputLabel-outlined {
        transform: translate(14px, 16px) scale(1);

        &.Mui-disabled {
            color: #857f7a;
        }
    }
`;

const StyledInputLabel = ({ ...props }) => (
    <InputLabel
        variant="outlined"
        classes={{
            root: 'MuiInputLabel-formControl',
        }}
        {...props}
    />
);

export default StyledInputLabel;
