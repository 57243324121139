import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import modules from 'Common/constants/modules';
import {
    ConstructionSiteIcon,
    InstructionIcon,
    MeasuresIcon,
    PdfDocumentIcon,
} from 'Common/components/icons';
import Loader from 'Common/components/Loader';
import TileLink from 'Common/components/TileLink';
import ExternalTileLink from 'Common/components/ExternalTileLink';
import { useSelector } from 'react-redux';

const ContentModuleLink = styled(TileLink)`
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:nth-child(2n + 1):last-child {
            width: 100%;
            margin-right: 0;
        }
    }
`;

const ContentModuleExternalLink = styled(ExternalTileLink)`
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:nth-child(2n + 1):last-child {
            width: 100%;
            margin-right: 0;
        }
    }
`;

const ModuleName = styled.span`
    @media screen and (min-width: 1180px) {
        flex: 1;
        padding-right: 87px;
        text-align: center;
    }
`;

const moduleUnavailableMessage =
    'Freischaltung durch Produkterweiterung möglich, bitte kontaktieren Sie hierfür die Amedic GmbH & CO.KG';

const AvailableModules = () => {
    const availableModules = useSelector(state => state.user.availableModules);

    const isAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const isLoading = availableModules.length === 0;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <ContentModuleLink
                to={isAvailable(modules.INSTRUCTIONS) ? '/education' : null}
                isDisabled={!isAvailable(modules.INSTRUCTIONS)}
                title={
                    !isAvailable(modules.INSTRUCTIONS)
                        ? moduleUnavailableMessage
                        : undefined
                }
            >
                <InstructionIcon />
                <ModuleName>Unterweisungen</ModuleName>
            </ContentModuleLink>
            <ContentModuleLink
                to={isAvailable(modules.MEASURES) ? '/measures' : null}
                isDisabled={!isAvailable(modules.MEASURES)}
                title={
                    !isAvailable(modules.MEASURES)
                        ? moduleUnavailableMessage
                        : undefined
                }
            >
                <MeasuresIcon />
                <ModuleName>Maßnahmen</ModuleName>
            </ContentModuleLink>
            <ContentModuleExternalLink
                href={
                    isAvailable(modules.CONSTRUCTION_SITES)
                        ? '/backend/m/csite/call'
                        : null
                }
                isDisabled={!isAvailable(modules.CONSTRUCTION_SITES)}
                title={
                    !isAvailable(modules.CONSTRUCTION_SITES)
                        ? moduleUnavailableMessage
                        : undefined
                }
            >
                <ConstructionSiteIcon />
                <ModuleName>Ergänzende Gefährdungsbeurteilung</ModuleName>
            </ContentModuleExternalLink>
            <ContentModuleExternalLink href="/backend/m/docs/call">
                <PdfDocumentIcon />
                <ModuleName>Dokumente</ModuleName>
            </ContentModuleExternalLink>
        </>
    );
};

export default AvailableModules;
