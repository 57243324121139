import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { NotificationImportantSharp, WarningSharp } from '@material-ui/icons';
import ConfirmModal from 'Common/components/ConfirmModal';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';
import markDriverLicenseAsLost from 'Measure/api/driver-license/update/markDriverLicenseAsLost';
import axios from 'axios';

const ModalTitle = styled.h3`
    margin-top: 0;
`;

const WarningMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    color: ${colors.ORANGE};
    font-weight: 600;
    font-size: 13px;

    svg {
        font-size: 16px !important;
        margin-right: 5px;
    }
`;

const MarkAsLostButton = ({ driverLicenseControl }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const openModal = () => {
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setIsModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmMarkAsLost = async () => {
        setIsLoading(true);

        try {
            await markDriverLicenseAsLost(
                driverLicenseControl.id,
                source.token,
            );

            notify(
                'Der Führerschein wurde als verloren markiert und deaktiviert',
                {
                    type: toast.TYPE.SUCCESS,
                },
            );

            dispatch(push('/measures/vehicles/driver-licenses'));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    return (
        <>
            <Button type="button" disabled={isLoading} onClick={openModal}>
                {isLoading ? (
                    <Loader size={24} />
                ) : (
                    <NotificationImportantSharp />
                )}
                Führerscheinverlust
            </Button>
            {isModalOpened && (
                <ConfirmModal
                    isOpen={isModalOpened}
                    onConfirm={handleConfirmMarkAsLost}
                    onCancel={closeModal}
                    isConfirmLoading={isLoading}
                    isConfirmDisabled={isLoading}
                >
                    <ModalTitle>
                        Bestätigen Sie den Verlust des Führerscheins
                    </ModalTitle>
                    <WarningMessage>
                        <WarningSharp />
                        Die Führerscheinkontrolle wird deaktiviert!
                    </WarningMessage>
                </ConfirmModal>
            )}
        </>
    );
};

MarkAsLostButton.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default MarkAsLostButton;
