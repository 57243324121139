import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import ReactModal from 'react-modal';

if (document.getElementsByClassName('react-component').length > 0) {
    ReactModal.setAppElement('.react-component');
} else if (document.getElementById('root')) {
    ReactModal.setAppElement('#root');
}

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
    <ReactModal
        className={modalClassName}
        portalClassName={className}
        {...props}
    />
);

const Modal = styled(ReactModalAdapter).attrs({
    modalClassName: 'Modal',
})`
    .ReactModal__Overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        z-index: 9999;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.55) !important;

        &--after-open {
            opacity: 1;
        }
        &--before-close {
            opacity: 0;
        }
    }

    .ReactModal__Content {
        position: relative;
        display: block;
        z-index: 10000;
        width: 100%;
        max-width: 900px;
        max-height: 100%;
        padding: 20px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        border: 1px solid ${colors.GRAY};
        box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.8);
        border-radius: 4px;

        ${ifProp(
            'isFullHeight',
            css`
                height: 100%;
            `,
        )}

        &:focus {
            outline: 0 none;
        }

        ${ifProp(
            'isLoading',
            css`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                background: none;
                border: 0 none;
                box-shadow: none;
            `,
            css`
                background: ${colors.DARK_GRAY};
            `,
        )}
    }

    @media screen and (min-width: 520px) {
        .ReactModal__Overlay {
            padding: 30px;
        }
    }
`;

export default Modal;
