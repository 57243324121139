import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { prop } from 'styled-tools';
import Loader from 'Common/components/Loader';
import getDriverLicenseTasksProgress from 'Measure/api/driver-license/getDriverLicenseTasksProgress';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.span`
    display: block;
    text-align: center;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    margin-bottom: 1px;
`;

const ProgressTitle = styled.div`
    display: flex;
    padding-bottom: 15px;
    color: ${colors.LIGHTER_GRAY};
`;

const Bar = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: ${colors.ALT_DARKEST_GRAY};
    border-radius: 4px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${prop('progress')}%;
        height: 100%;
        background: ${colors.GRAY};
    }
`;

const Number = styled.div`
    position: relative;
    z-index: 1;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 12px;
`;

const DriverLicenseControlProgress = () => {
    const {
        data: stats,
        loadData: loadStats,
        isLoading,
        hasError,
    } = useAxiosRequest(getDriverLicenseTasksProgress, {
        pending: 0,
        completed: 0,
    });

    const progressPercentage =
        stats.all > 0 ? Math.floor((stats.completed / stats.all) * 100) : 0;

    return (
        <Wrapper>
            <ProgressTitle>Aktive Kontrollen</ProgressTitle>

            {isLoading ? (
                <Loader size={80} />
            ) : hasError ? (
                <RetryLoading onRetry={loadStats} />
            ) : (
                <Bar progress={progressPercentage.toFixed(4)}>
                    <Number>{`${stats.completed} absolviert`}</Number>
                    <Number>{`${stats.pending} ausstehend`}</Number>
                </Bar>
            )}
        </Wrapper>
    );
};

export default DriverLicenseControlProgress;
