import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { ResponsivePie } from '@nivo/pie';

const ChartWrapper = styled.div`
    position: relative;
    width: ${prop('size')}px;
    height: ${prop('size')}px;
    max-height: ${prop('size')}px;

    svg {
        position: relative;
        z-index: 1;
    }

    @media screen and (min-width: 460px) and (max-width: 939px) {
        flex: 0 0 ${prop('size')}px;
        margin-right: 30px;
    }

    @media screen and (min-width: 940px) {
        flex: auto;
        width: 100%;
    }
`;

const InnerCircle = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    width: ${prop('size')}px;
    height: ${prop('size')}px;
    background: ${colors.ALT_DARKEST_GRAY};
    border-radius: 50%;
    transform: translate(-50%, -50%);
`;

const ProgressPercentage = styled.div`
    color: ${colors.WHITE};
    font-size: 21px;
    font-weight: 600;
`;

const ProgressText = styled.div`
    margin-top: 3px;
    color: ${colors.WHITE};
    font-size: 13px;
    font-weight: 300;
`;

const PieChart = ({ data, percentage, text, size, ...props }) => (
    <ChartWrapper size={size}>
        <ResponsivePie
            data={data}
            theme={{
                tooltip: {
                    container: {
                        background: colors.ALT_GRAY,
                    },
                },
            }}
            colors={bar => bar.backgroundColor}
            innerRadius={0.95}
            enableRadialLabels={false}
            enableSlicesLabels={false}
            {...props}
        />
        <InnerCircle size={size - 10}>
            {(!!percentage || percentage === 0) && (
                <ProgressPercentage>
                    {percentage.toFixed(0)}%
                </ProgressPercentage>
            )}
            {!!text && <ProgressText>{text}</ProgressText>}
        </InnerCircle>
    </ChartWrapper>
);

PieChart.defaultProps = {
    percentage: null,
    text: null,
    size: 150,
};

PieChart.propTypes = {
    data: PropTypes.array.isRequired,
    percentage: PropTypes.number,
    text: PropTypes.string,
    size: PropTypes.number,
};

export default PieChart;
