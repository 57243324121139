const colors = {
    WHITE: '#fff',
    LIGHTEST_GRAY: '#f2f2f2',
    LIGHTER_GRAY: '#b9b1ab',
    LIGHT_GRAY: '#857f7a',
    GRAY: '#625f5b',
    ALT_GRAY: '#4a4845',
    DARK_GRAY: '#3e3c38',
    DARKER_GRAY: '#383633',
    DARKEST_GRAY: '#32302f',
    ALT_DARKEST_GRAY: '#2f2c2b',
    BLACK: '#000',
    ORANGE: '#e99b65',
    LIGHT_ORANGE: '#efac76',
    GOLD_ORANGE: '#c48857',
    RED: '#be494c',
    ERROR: '#e25255',
    GREEN: '#259242',
    BLUE: '#51aff5',
};

export default colors;
