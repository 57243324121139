import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
    DefaultHiddenColumnProperties,
    DefaultTableBodyRow,
    DefaultTableHead,
    HiddenColumnsTableBodyCell,
    HiddenColumnsTableBodyRow,
    Table,
    TableBody,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import {
    examStatuses,
    examStatusOptions,
} from 'Education/constants/examStatuses';
import { push } from 'connected-react-router';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/useClickableTableRow';
import colors from 'Common/constants/colors';
import {
    presentationStatuses,
    presentationStatusOptions,
} from 'Education/constants/presentationStatuses';
import { GroupIcon } from 'Common/components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CheckSharp, CloseSharp, PriorityHighSharp } from '@material-ui/icons';
import { ifProp, prop } from 'styled-tools';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import Button from 'Common/components/Button';
import { isMobileSafari, isSafari } from 'react-device-detect';

const CheckTableIcon = styled(CheckSharp)`
    color: ${colors.ORANGE};
`;

const CrossTableIcon = styled(CloseSharp)`
    color: ${colors.ALT_DARKEST_GRAY};
`;

const ExclamationTableIcon = styled(PriorityHighSharp)`
    color: ${colors.ALT_DARKEST_GRAY};
`;

const StyledTableBodyRow = styled(DefaultTableBodyRow)`
    ${ifProp(
        'hasHiddenColumns',
        css``,
        css`
            &:hover,
            &.is-hover-always-visible {
                ${ifProp(
                    'isSafari',
                    css`
                        background: ${colors.ORANGE};

                        td {
                            color: ${colors.WHITE} !important;
                        }
                    `,
                    css`
                        background-image: linear-gradient(
                            to left,
                            #e99b65,
                            transparent 60%
                        );
                    `,
                )};
                
                td {
                    svg {
                        visibility: hidden;
                    }
                
                    &:last-child {
                        position: relative;
                    
                        &:after {
                            content: '${prop('hoverText')}';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            color: ${colors.DARKEST_GRAY};
                            transform: translateY(-50%);
                            width: 375px;
                            text-align: right;
                            padding-right: 20px;
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                }
            }
        `,
    )};
`;

const ActionButton = styled(Button)`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const OpenInstructionAssignmentsTable = ({
    instructionAssignments,
    onSelectAssignmentIdForConfirmation,
}) => {
    const userId = useSelector(state => state.user.id);
    const dispatch = useDispatch();

    const columns = useMemo(
        () => [
            {
                Header: 'Unterweisungen',
                accessor: 'name',
            },
            {
                Header: '',
                accessor: 'group',
                disableSortBy: true,
            },
            {
                Header: 'Dauer (Min.)',
                accessor: 'timeSpent',
                disableSortBy: true,
                customCellStyles: {
                    minWidth: 150,
                },
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Gelesen',
                accessor: 'presentationStatusIcon',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Test',
                accessor: 'examStatusIcon',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            instructionAssignments.map(assignment => {
                const timeSpent = Math.ceil(
                    (assignment.timeSpentOnPresentationInSeconds +
                        assignment.timeSpentOnExamInSeconds) /
                        60,
                );

                const recommendedTime =
                    assignment.recommendedPresentationDurationInMinutes +
                    assignment.recommendedTestDurationInMinutes;

                const presentationIconTitle = presentationStatusOptions.find(
                    option => option.value === assignment.presentationStatus,
                )?.label;

                let PresentationStatusIcon = CrossTableIcon;
                if (
                    assignment.presentationStatus ===
                    presentationStatuses.IN_PROGRESS
                ) {
                    PresentationStatusIcon = ExclamationTableIcon;
                } else if (
                    assignment.presentationStatus ===
                    presentationStatuses.COMPLETED
                ) {
                    PresentationStatusIcon = CheckTableIcon;
                }

                const testIconTitle = examStatusOptions.find(
                    option => option.value === assignment.examStatus,
                )?.label;

                let TestStatusIcon = CrossTableIcon;
                if (
                    [
                        examStatuses.IN_PROGRESS,
                        examStatuses.IN_APPROVAL,
                        examStatuses.FAILED,
                    ].includes(assignment.examStatus)
                ) {
                    TestStatusIcon = ExclamationTableIcon;
                } else if (assignment.examStatus === examStatuses.COMPLETED) {
                    TestStatusIcon = CheckTableIcon;
                }

                let hoverText = 'Starten';

                if (
                    assignment.presentationStatus ===
                    presentationStatuses.IN_PROGRESS
                ) {
                    hoverText = 'Fortsetzen';
                } else if (
                    assignment.presentationStatus ===
                        presentationStatuses.COMPLETED &&
                    [
                        examStatuses.NOT_STARTED,
                        examStatuses.IN_PROGRESS,
                        examStatuses.IN_APPROVAL,
                    ].includes(assignment.examStatus)
                ) {
                    hoverText = 'Zum Test';
                } else if (assignment.examStatus === examStatuses.FAILED) {
                    hoverText = 'Neuer Versuch';
                } else if (assignment.examStatus === examStatuses.COMPLETED) {
                    hoverText = 'Bestätigen';
                }

                let targetLink = `/education/assignment/presentation/${assignment.instructionId}`;

                if (assignment.id) {
                    targetLink = `${targetLink}/${assignment.id}`;
                }

                if (
                    assignment.presentationStatus ===
                    presentationStatuses.COMPLETED
                ) {
                    targetLink = `/education/assignment/exam/${assignment.id}`;
                }

                const isEmployeeResponsible =
                    assignment.responsibleEmployee.user.id === userId;

                if (
                    !isEmployeeResponsible &&
                    assignment.examStatus !== examStatuses.COMPLETED
                ) {
                    hoverText = 'Im Besitz des Gruppenleiters';
                    targetLink = null;
                }

                // When the employee is a group leader, but is not an assignee,
                // allow the processing of the group assignment only if the
                // employee has completed another assignment for the same instruction.
                if (isEmployeeResponsible && assignment.isGroupAssignment) {
                    const isAssigneeInGroupAssignment =
                        assignment.groupAssignmentDetails?.assignees.findIndex(
                            assignee => assignee.employee.user.id === userId,
                        ) !== -1;

                    if (!isAssigneeInGroupAssignment) {
                        const isInstructionCompleted =
                            assignment.responsibleEmployee.completedInstructions.findIndex(
                                instruction =>
                                    instruction.id === assignment.instructionId,
                            ) !== -1;

                        if (!isInstructionCompleted) {
                            hoverText = 'Machen Sie zuerst die andere Aufgabe';
                            targetLink = null;
                        }
                    }
                }

                const assigneesList = assignment.groupAssignmentDetails?.assignees
                    ?.map(
                        assignee =>
                            `${assignee.employee.firstName} ${assignee.employee.lastName}`,
                    )
                    ?.join(', ');

                return {
                    id: assignment.id,
                    name: assignment.name,
                    group: assignment.isGroupAssignment ? (
                        <div title={assigneesList}>
                            <GroupIcon size="sm" />
                        </div>
                    ) : (
                        ''
                    ),
                    timeSpent: `${timeSpent} / ${recommendedTime}`,
                    presentationStatusIcon: (
                        <div title={presentationIconTitle}>
                            <PresentationStatusIcon />
                        </div>
                    ),
                    examStatusIcon: (
                        <div title={testIconTitle}>
                            <TestStatusIcon />
                        </div>
                    ),
                    examStatus: assignment.examStatus,
                    targetLink,
                    hoverText,
                    isClickable: targetLink !== null,
                    isHoverAlwaysVisible:
                        assignment.examStatus === examStatuses.COMPLETED,
                };
            }),
        [instructionAssignments, userId],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
        onRowClick,
    } = useTable(
        {
            columns,
            data,
            onRowClick: row => {
                if (row.original.examStatus === examStatuses.COMPLETED) {
                    onSelectAssignmentIdForConfirmation(row.original.id);
                } else {
                    if (row.original.targetLink) {
                        dispatch(push(row.original.targetLink));
                    }
                }
            },
            hideColumnsPriority: [
                'timeSpent',
                'presentationStatusIcon',
                'group',
                'examStatusIcon',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useClickableTableRow,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    const hasHiddenColumns =
                        !!hiddenColumns && hiddenColumns.length > 0;

                    return (
                        <Fragment key={`${row.getRowProps().key}_fragment`}>
                            <StyledTableBodyRow
                                row={row}
                                hasHiddenColumns={hasHiddenColumns}
                                hoverText={row.original.hoverText}
                                className={
                                    row.original.isHoverAlwaysVisible
                                        ? 'is-hover-always-visible'
                                        : undefined
                                }
                                isSafari={isSafari || isMobileSafari}
                            />
                            {hasHiddenColumns && (
                                <HiddenColumnsTableBodyRow>
                                    <HiddenColumnsTableBodyCell
                                        colSpan={visibleColumns.length}
                                    >
                                        <ActionButton
                                            theme="dark"
                                            onClick={() => onRowClick(row)}
                                            disabled={!row.original.targetLink}
                                        >
                                            {row.original.hoverText}
                                        </ActionButton>
                                        <DefaultHiddenColumnProperties
                                            row={row}
                                            hiddenColumns={hiddenColumns}
                                        />
                                    </HiddenColumnsTableBodyCell>
                                </HiddenColumnsTableBodyRow>
                            )}
                        </Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

OpenInstructionAssignmentsTable.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    onSelectAssignmentIdForConfirmation: PropTypes.func.isRequired,
};

export default OpenInstructionAssignmentsTable;
