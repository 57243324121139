import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import StyledTextField from 'Common/components/form/fields/StyledTextField';

const StyledTextFieldArea = styled(StyledTextField)`
    textarea {
        min-height: 150px;
        resize: vertical;

        ${ifProp(
            '$resize',
            css`
                resize: ${prop('$resize')};
            `,
        )};
    }
`;
const Textarea = memo(
    ({ label, error, touched, readOnly, resize, ...props }) => {
        const hasError = !!error && touched;
        const hasLabel = label !== null;

        return (
            <>
                <StyledTextFieldArea
                    variant="outlined"
                    error={hasError}
                    label={hasLabel ? label : undefined}
                    multiline
                    $resize={resize}
                    InputProps={{
                        readOnly,
                    }}
                    {...props}
                />

                {hasError && <ErrorMessage message={error} />}
            </>
        );
    },
);

Textarea.defaultProps = {
    label: null,
    error: null,
    touched: false,
    readOnly: false,
    resize: null,
};

Textarea.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    readOnly: PropTypes.bool,
    resize: PropTypes.string,
};

export default Textarea;
