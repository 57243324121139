import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Checkbox from 'Common/components/form/fields/Checkbox';
import Button from 'Common/components/Button';
import Loader from 'Common/components/Loader';
import startSingleInstructionAssignmentPresentation from 'Education/api/instruction-assignment/presentation/startSingleInstructionAssignmentPresentation';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const StartInstructionAssignment = styled.div`
    color: ${colors.WHITE};
    font-size: 18px;
    line-height: 1.5em;

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const InstructionAssignmentName = styled.div`
    margin-bottom: 30px;
    color: ${colors.ORANGE};
    font-size: 18px;
    line-height: 1.5em;

    @media screen and (min-width: 940px) {
        margin-bottom: 70px;
        font-size: 22px;
    }
`;

const InformationText = styled.div`
    margin-bottom: 30px;
    color: ${colors.WHITE};
    font-size: 14px;
`;

const CheckboxWrapper = styled.div`
    margin-bottom: 30px;

    label {
        color: ${colors.WHITE};
        font-size: 14px;
        line-height: 1.5em;
    }

    @media screen and (min-width: 940px) {
        margin-bottom: 70px;
    }
`;

const ButtonWrapper = styled.div`
    > button {
        width: auto;
    }
`;

const SingleAssignmentStartScreen = ({ assignment, onStart }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isStarting, setIsStarting] = useState(false);

    const handleCheckboxChange = e => {
        setIsChecked(e.target.checked);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleButtonClick = async () => {
        setIsStarting(true);

        try {
            await startSingleInstructionAssignmentPresentation(
                assignment.id,
                source.token,
            );
            await onStart();

            setIsStarting(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsStarting(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Wrapper>
            <StartInstructionAssignment>
                Sie starten die Unterweisung
            </StartInstructionAssignment>
            <InstructionAssignmentName>
                {assignment.name}
            </InstructionAssignmentName>
            <InformationText>
                Wenn Sie Fragen zu der Unterweisung oder dem Test haben, so
                wenden Sie sich an Ihren Vorgesetzten.
            </InformationText>
            <CheckboxWrapper>
                <Checkbox
                    name="assignment"
                    id="assignment"
                    label="Ich habe die Anweisung gelesen und verstanden."
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </CheckboxWrapper>
            <ButtonWrapper>
                <Button
                    disabled={isStarting || !isChecked}
                    type="button"
                    onClick={handleButtonClick}
                >
                    {isStarting && <Loader size={24} />}
                    Unterweisung starten
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

SingleAssignmentStartScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onStart: PropTypes.func.isRequired,
};

export default SingleAssignmentStartScreen;
