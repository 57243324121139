import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DriverLicenseControlGridItemWrapper } from 'Measure/components/vehicle/driver-license/DriverLicenseControlGridItemWrapper';
import {
    AssignmentIndSharp,
    CancelSharp,
    CheckCircleSharp,
    CheckSharp,
    CreditCardSharp,
    NotificationsNoneSharp,
    RotateRightSharp,
    UpdateSharp,
    WarningSharp,
} from '@material-ui/icons';
import moment from 'moment-timezone';
import HamburgerButton from 'Common/components/HamburgerButton';
import { useOnClickOutside } from 'crooks';
import ExternalLink from 'Common/components/ExternalLink';
import { DocumentIcon } from 'Common/components/icons';
import ButtonLink from 'Common/components/ButtonLink';
import activateDriverLicenseControl from 'Measure/api/driver-license/activateDriverLicenseControl';
import deactivateDriverLicenseControl from 'Measure/api/driver-license/deactivateDriverLicenseControl';
import Loader from 'Common/components/Loader';
import colors from 'Common/constants/colors';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Link from 'Common/components/Link';
import { Link as RouterLink } from 'react-router-dom';
import ConfirmModal from 'Common/components/ConfirmModal';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import sendManualReminder from 'Measure/api/driver-license/sendManualReminder';

const GridItemWrapper = styled(DriverLicenseControlGridItemWrapper)`
    padding: 0;
`;

const GridItemLink = styled(RouterLink)`
    display: block;
    width: 100%;
    text-decoration: none;
    outline: 0 none;
    padding: 15px;
    color: ${colors.WHITE};
`;

const GridItemDiv = styled(GridItemLink).attrs({
    as: 'div',
})``;

const StatusBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Information = styled.div`
    font-size: 14px;
`;

const EmployeeName = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-left: 8px;
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    padding-right: 38px;
`;

const InfoItem = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const Label = styled.div`
    margin-right: 10px;
    color: ${colors.LIGHTER_GRAY};
    min-width: 125px;
`;

const Value = styled.div`
    color: ${colors.WHITE};
`;

const CreationProcessIcon = styled(RotateRightSharp)`
    color: ${colors.ORANGE};
`;

const PendingNotInitiatedIcon = styled(UpdateSharp)`
    color: ${colors.LIGHTEST_GRAY};
`;

const PendingInProgressIcon = styled(UpdateSharp)`
    color: ${colors.ORANGE};
`;

const OverdueIcon = styled(WarningSharp)`
    color: ${colors.ORANGE};
`;

const CompletedIcon = styled(CheckSharp)`
    color: ${colors.ORANGE};
`;

const LostIcon = styled(CreditCardSharp)`
    color: ${colors.RED};
`;

const ExpiredIcon = styled(CreditCardSharp)`
    color: ${colors.RED};
`;

const Status = styled.div`
    span {
        display: block;
    }

    svg {
        display: block;
    }
`;

const HamburgerWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`;

const ActionsMenu = styled.div`
    position: absolute;
    z-index: 1;
    top: -5px;
    right: -5px;
    width: 260px;
    padding: 20px 45px 20px 20px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        line-height: 1.5;

        > i,
        > svg {
            font-size: 18px !important;
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const LoaderIcon = styled(Loader)`
    margin-right: 10px;
`;

const ModalTitle = styled.h3`
    margin-top: 0;
`;

const WarningMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    color: ${colors.ORANGE};
    font-weight: 600;
    font-size: 13px;

    svg {
        font-size: 16px !important;
        margin-right: 5px;
    }
`;

const DriverLicenseControlGridItem = ({
    driverLicenseControl,
    onActivate,
    onDeactivate,
}) => {
    const dispatch = useDispatch();

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isActivating, setIsActivating] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);
    const [isSendingManualReminder, setIsSendingManualReminder] = useState(
        false,
    );

    const [isReCreateModalOpened, setIsReCreateModalOpened] = useState(false);

    const handleHamburgerButtonClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);
    };

    const openReCreateModal = () => {
        setIsReCreateModalOpened(true);
    };

    const closeReCreateModal = () => {
        setIsReCreateModalOpened(false);
    };

    const handleConfirmReCreateButtonClick = async () => {
        dispatch(
            push(
                `/measures/vehicles/driver-license/create/${driverLicenseControl.id}?recreate=1`,
            ),
        );
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleActivate = async () => {
        setIsActivating(true);

        try {
            await activateDriverLicenseControl(
                driverLicenseControl.id,
                source.token,
            );
            await onActivate();

            setIsActivating(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsActivating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleDeactivate = async () => {
        setIsDeactivating(true);

        try {
            await deactivateDriverLicenseControl(
                driverLicenseControl.id,
                source.token,
            );
            await onDeactivate();

            setIsDeactivating(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeactivating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleSendManualReminder = async () => {
        setIsSendingManualReminder(true);

        try {
            await sendManualReminder(driverLicenseControl.id, source.token);

            setIsSendingManualReminder(false);
            setIsMenuOpened(false);

            notify('Die Erinnerung wird in Kürze gesendet.', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsSendingManualReminder(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleClickOutsideActions = () => {
        setIsMenuOpened(false);
    };

    const actionsRef = useOnClickOutside(
        handleClickOutsideActions,
        !isMenuOpened,
    );

    let nextCheckDate = '-';
    let controlMethodName = '-';

    let isInCreationProcess = true;
    let isPending = false;
    let isOverdue = false;
    let isCompleted = false;
    let isPendingInitiation = false;
    let isLost = false;
    let isExpired = false;
    let isInVerificationPeriod = false;
    let canSendManualReminder = false;

    const driverLicense = driverLicenseControl.driverLicense;

    const nextCheck = driverLicense?.nextCheck;
    const previousCheck = driverLicense?.previousCheck;

    if (driverLicense) {
        isInCreationProcess = driverLicense.isInCreationProcess;

        controlMethodName = driverLicense.isUsingDirectControlMethod
            ? 'Direct'
            : 'Distance';

        if (!isInCreationProcess) {
            if (nextCheck.dueDate) {
                nextCheckDate = moment(nextCheck.dueDate.date).format();
            }

            isPending =
                nextCheck.isInitiated &&
                nextCheck.isInVerificationPeriod &&
                !nextCheck.isOverdue;

            isPendingInitiation =
                !nextCheck.isInitiated &&
                nextCheck.isInVerificationPeriod &&
                !nextCheck.isOverdue;

            isOverdue = nextCheck.isInVerificationPeriod && nextCheck.isOverdue;

            isCompleted =
                !nextCheck.isInVerificationPeriod &&
                previousCheck &&
                previousCheck.isCompleted;

            isLost = driverLicense.isLost;
            isExpired = driverLicense.isExpired;
            isInVerificationPeriod = nextCheck.isInVerificationPeriod;

            if (nextCheck.dueDate) {
                const minimumNumberOfDaysAfterDueDateToAllowManualReminder = 15;

                const daysAfterDueDate = moment()
                    .startOf('day')
                    .diff(
                        moment(nextCheck.dueDate.date).startOf('day'),
                        'days',
                    );

                canSendManualReminder =
                    driverLicenseControl.isActive &&
                    isInVerificationPeriod &&
                    isOverdue &&
                    daysAfterDueDate >=
                        minimumNumberOfDaysAfterDueDateToAllowManualReminder;
            }
        }
    }

    const isClickable = driverLicenseControl.isActive;
    const GridItem = isClickable ? GridItemLink : GridItemDiv;

    let link;

    if (isClickable) {
        link = isInCreationProcess
            ? `/measures/vehicles/driver-license/create/${driverLicenseControl.id}`
            : `/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`;
    }

    const isEmployeeEnabled = driverLicenseControl.employee.isEnabled;

    return (
        <GridItemWrapper>
            <GridItem to={link}>
                <StatusBar>
                    <EmployeeName>
                        {`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}
                        {isLost && (
                            <span title="Führerschein als verloren gemeldet">
                                <LostIcon />
                            </span>
                        )}
                        {isExpired && (
                            <span title="Der Führerschein ist abgelaufen">
                                <ExpiredIcon />
                            </span>
                        )}
                    </EmployeeName>
                    <Actions>
                        <Status>
                            {isPendingInitiation && (
                                <span title="Ausstehend (nicht initiiert)">
                                    <PendingNotInitiatedIcon />
                                </span>
                            )}
                            {isPending && (
                                <span title="Ausstehend (in Bearbeitung)">
                                    <PendingInProgressIcon />
                                </span>
                            )}
                            {isOverdue && (
                                <span title="Überfällig">
                                    <OverdueIcon />
                                </span>
                            )}
                            {isCompleted && (
                                <span title="Abgeschlossen">
                                    <CompletedIcon />
                                </span>
                            )}
                            {isInCreationProcess && (
                                <span title="Noch nicht vollständig erstellt">
                                    <CreationProcessIcon />
                                </span>
                            )}
                        </Status>
                    </Actions>
                </StatusBar>
                <Information>
                    <InfoItem>
                        <Label>Prüfmethode:</Label>
                        <Value>{controlMethodName}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Nächste Kontrolle:</Label>
                        <Value>{nextCheckDate}</Value>
                    </InfoItem>
                </Information>
            </GridItem>
            <HamburgerWrapper ref={actionsRef}>
                <HamburgerButton
                    isOpened={isMenuOpened}
                    onClick={handleHamburgerButtonClick}
                />
                {isMenuOpened && (
                    <ActionsMenu>
                        {!isInCreationProcess && (
                            <ExternalLink
                                href={`/api/measures/vehicles/driver-licenses/controls/${driverLicenseControl.id}/download-proof-document`}
                                target="_blank"
                            >
                                <DocumentIcon size="xs" />
                                Dokument anzeigen
                            </ExternalLink>
                        )}
                        {driverLicenseControl.isActive && isInCreationProcess && (
                            <Link
                                to={`/measures/vehicles/driver-license/create/${driverLicenseControl.id}`}
                            >
                                <RotateRightSharp />
                                Erstellung fortsetzten
                            </Link>
                        )}
                        {driverLicenseControl.isActive && !isInCreationProcess && (
                            <Link
                                to={`/measures/vehicles/driver-license/update/${driverLicenseControl.id}`}
                            >
                                <AssignmentIndSharp />
                                Fahrerdaten
                            </Link>
                        )}
                        {canSendManualReminder && (
                            <ButtonLink
                                onClick={handleSendManualReminder}
                                disabled={isSendingManualReminder}
                            >
                                {isSendingManualReminder ? (
                                    <LoaderIcon size={16} />
                                ) : (
                                    <NotificationsNoneSharp />
                                )}
                                Erinnerung versenden
                            </ButtonLink>
                        )}
                        {driverLicenseControl.isActive && (
                            <ButtonLink
                                onClick={handleDeactivate}
                                disabled={isDeactivating}
                            >
                                {isDeactivating ? (
                                    <LoaderIcon size={16} />
                                ) : (
                                    <CancelSharp />
                                )}
                                Kontrolle deaktivieren
                            </ButtonLink>
                        )}
                        {!driverLicenseControl.isActive && (
                            <ButtonLink
                                onClick={handleActivate}
                                disabled={!isEmployeeEnabled || isActivating}
                                title={
                                    !isEmployeeEnabled
                                        ? 'Der Mitarbeiter ist inaktiv'
                                        : undefined
                                }
                            >
                                {isActivating ? (
                                    <LoaderIcon size={16} />
                                ) : (
                                    <CheckCircleSharp />
                                )}
                                Kontrolle aktivieren
                            </ButtonLink>
                        )}
                        {!isInCreationProcess && (
                            <ButtonLink
                                onClick={openReCreateModal}
                                disabled={!isEmployeeEnabled}
                                title={
                                    !isEmployeeEnabled
                                        ? 'Der Mitarbeiter ist inaktiv'
                                        : undefined
                                }
                            >
                                <RotateRightSharp />
                                Erstelle neu
                            </ButtonLink>
                        )}
                    </ActionsMenu>
                )}
            </HamburgerWrapper>
            {isReCreateModalOpened && (
                <ConfirmModal
                    isOpen={isReCreateModalOpened}
                    confirmText="Neu erstellen"
                    confirmIcon={RotateRightSharp}
                    onConfirm={handleConfirmReCreateButtonClick}
                    onCancel={closeReCreateModal}
                >
                    <ModalTitle>
                        Möchten Sie wirklich einen neuen Führerschein erstellen?
                    </ModalTitle>
                    <WarningMessage>
                        <WarningSharp />
                        Die aktuelle Kontrolle wird automatisch archiviert!
                    </WarningMessage>
                </ConfirmModal>
            )}
        </GridItemWrapper>
    );
};

DriverLicenseControlGridItem.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    onActivate: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
};

export default DriverLicenseControlGridItem;
