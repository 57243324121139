import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'Common/constants/colors';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import ConfirmModal from 'Common/components/ConfirmModal';
import styled from 'styled-components/macro';
import createGroupInstructionAssignment from 'Education/api/instruction-assignment/group/createGroupInstructionAssignment';
import getInstructions from 'Education/api/instruction/getInstructions';
import getEmployees from 'Employee/api/getEmployees';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import Select from 'Common/components/form/fields/Select';
import * as Yup from 'yup';
import axios from 'axios';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import Loader from 'Common/components/Loader';

const SubTitle = styled.h3`
    margin-top: 0;
`;

const Content = styled.div`
    text-align: left;
`;

const SelectSecondaryLabel = styled.span`
    color: ${colors.LIGHTER_GRAY};
`;

const validationSchema = Yup.object().shape({
    instruction: Yup.object()
        .required('Pflichtangabe')
        .nullable(),
    groupLeader: Yup.object()
        .required('Pflichtangabe')
        .nullable(),
    employees: Yup.array()
        .of(Yup.object())
        .required('Pflichtangabe')
        .nullable(),
});

const GroupInstructionAssignmentCreateModal = ({
    isOpen,
    onCancel,
    onSuccess,
}) => {
    const [isCreating, setIsCreating] = useState(false);

    const instructionsRequest = useCallback(
        cancelToken => getInstructions(['areas'], cancelToken),
        [],
    );

    const {
        data: instructions,
        loadData: loadInstructions,
        isLoading: isLoadingInstructions,
        hasError: hasInstructionsError,
    } = useAxiosRequest(instructionsRequest, []);

    const employeesRequest = useCallback(
        cancelToken =>
            getEmployees(
                ['areas', 'completedInstructions', 'assignedGroupInstructions'],
                {
                    onlyActive: true,
                },
                cancelToken,
            ),
        [],
    );

    const {
        data: employees,
        loadData: loadEmployees,
        isLoading: isLoadingEmployees,
        hasError: hasEmployeesError,
    } = useAxiosRequest(employeesRequest, []);

    const isLoading = isLoadingInstructions || isLoadingEmployees;
    const hasError = hasInstructionsError || hasEmployeesError;

    const handleRetryLoading = () => {
        loadInstructions();
        loadEmployees();
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: {
            instruction: null,
            groupLeader: null,
            employees: [],
        },
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createGroupInstructionAssignment(
                    {
                        instructionId: values.instruction.value,
                        groupLeaderId: values.groupLeader.value,
                        employeeIds: values.employees.map(
                            option => option.value,
                        ),
                    },
                    source.token,
                );

                await onSuccess(response.data);

                setIsCreating(false);

                notify('Der Präsenzunterweisung wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsCreating(false);

                    notify(
                        'Der Präsenzunterweisung wurde nicht erfolgreich erstellt',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        },
    });

    const instructionId = values.instruction ? values.instruction.value : null;
    const groupLeaderId = values.groupLeader ? values.groupLeader.value : null;
    const selectedEmployeeOptions = values.employees ?? [];

    const instructionOptions = instructions.map(instruction => ({
        value: instruction.id,
        label: instruction.name,
        instruction,
    }));

    const employeesWithMatchingAreas = employees.filter(employee => {
        const instruction = instructions.find(
            instruction => instruction.id === instructionId,
        );

        if (!instruction) {
            return false;
        }

        const instructionAreaIds = instruction.areas.map(area => area.id);
        const employeeAreaIds = employee.areas.map(area => area.id);
        const intersectingAreaIds = instructionAreaIds.filter(areaId =>
            employeeAreaIds.includes(areaId),
        );

        return intersectingAreaIds.length > 0;
    });

    const leaderEmployeeOptions = employeesWithMatchingAreas
        .filter(employee => !employee.isTrainee)
        .map(employee => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
        }));

    const employeeOptions = employeesWithMatchingAreas
        .filter(employee => {
            const groupInstructionIds = employee.assignedGroupInstructions.map(
                instruction => instruction.id,
            );
            const completedInstructionIds = employee.completedInstructions.map(
                instruction => instruction.id,
            );

            const isPartOfAnotherGroupAssignmentOfThisInstruction = groupInstructionIds.includes(
                instructionId,
            );

            const hasCompletedInstruction = completedInstructionIds.includes(
                instructionId,
            );

            return (
                !isPartOfAnotherGroupAssignmentOfThisInstruction &&
                !hasCompletedInstruction
            );
        })
        .map(employee => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
        }));

    const handleInstructionChange = option => {
        setFieldValue('instruction', option);
        setFieldValue('groupLeader', '');
        setFieldValue('employees', []);
    };

    const handleGroupLeaderChange = option => {
        setFieldValue('groupLeader', option);

        const newEmployees = [...selectedEmployeeOptions];

        // Remove previously selected option
        if (groupLeaderId) {
            const currentlySelectedOptionIndex = selectedEmployeeOptions.findIndex(
                selectedOption => selectedOption.value === groupLeaderId,
            );

            if (currentlySelectedOptionIndex !== -1) {
                newEmployees.splice(currentlySelectedOptionIndex, 1);
            }
        }

        // Add new option
        if (option) {
            const optionIndex = employeeOptions.findIndex(
                employeeOption => employeeOption.value === option.value,
            );

            const isAlreadySelected =
                selectedEmployeeOptions.findIndex(
                    selectedOption => selectedOption.value === option.value,
                ) !== -1;

            if (optionIndex !== -1 && !isAlreadySelected) {
                newEmployees.push(option);
            }
        }

        setFieldValue('employees', newEmployees);
    };

    return (
        <ConfirmModal
            isOpen={isOpen}
            isLoading={isLoading}
            isConfirmLoading={isCreating}
            isConfirmDisabled={!isValid}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            confirmText="Erstellen"
        >
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={handleRetryLoading} />
            ) : (
                <>
                    <SubTitle>Präsenzunterweisung erstellen</SubTitle>
                    <Content>
                        <form onSubmit={handleSubmit}>
                            <FormField>
                                <Select
                                    label="Unterweisung"
                                    id="instruction"
                                    name="instruction"
                                    error={errors.instruction}
                                    touched={touched.instruction}
                                    value={values.instruction}
                                    onChange={handleInstructionChange}
                                    onBlur={handleBlur}
                                    options={instructionOptions}
                                    menuPosition="fixed"
                                    formatOptionLabel={({
                                        label,
                                        instruction,
                                    }) => {
                                        const areas = instruction.areas
                                            .map(area => area.name)
                                            .join(', ');

                                        return (
                                            <>
                                                {label}{' '}
                                                <SelectSecondaryLabel>
                                                    ({areas})
                                                </SelectSecondaryLabel>
                                            </>
                                        );
                                    }}
                                    isSearchable
                                    isClearable
                                />
                            </FormField>
                            <FormField>
                                <Select
                                    label="Gruppenleiter"
                                    id="groupLeader"
                                    name="groupLeader"
                                    error={errors.groupLeader}
                                    touched={touched.groupLeader}
                                    value={values.groupLeader}
                                    onChange={handleGroupLeaderChange}
                                    onBlur={handleBlur}
                                    options={leaderEmployeeOptions}
                                    menuPosition="fixed"
                                    isSearchable
                                    isClearable
                                />
                            </FormField>
                            <FormField>
                                <Select
                                    label="Mitarbeiter"
                                    id="employees"
                                    name="employees"
                                    error={errors.employees}
                                    touched={touched.employees}
                                    value={values.employees}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    options={employeeOptions}
                                    menuPosition="fixed"
                                    isMulti
                                    isSearchable
                                    isClearable
                                />
                            </FormField>
                        </form>
                    </Content>
                </>
            )}
        </ConfirmModal>
    );
};

GroupInstructionAssignmentCreateModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentCreateModal;
