import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (typeof document.scrollTo === 'function') {
            document.documentElement.scrollTo(0, 0);
        } else {
            document.documentElement.scrollTop = 0;
        }
    }, [pathname]);

    return null;
};

export default ScrollToTop;
