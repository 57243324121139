import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import GlobalStyles from 'Application/components/GlobalStyles';
import Login from 'User/components/Login';
import PageNotFound from 'Application/components/Static/PageNotFound';
import Dashboard from 'Dashboard/components/Dashboard';
import Route from 'Application/components/Route';
import roles from 'User/constants/roles';
import IconFont from 'Application/components/IconFonts';
import ImportFromLibrary from 'Education/components/library/ImportFromLibrary';
import EditInstructionPresentation from 'Education/components/instruction/presentation-edit/EditInstructionPresentation';
import EditInstructionTest from 'Education/components/instruction/test-edit/EditInstructionTest';
import AssignmentPresentationPreview from 'Education/components/instruction-assignment/presentation/AssignmentPresentationPreview';
import AssignmentExam from 'Education/components/instruction-assignment/exam/AssignmentExam';
import AssignmentExamPreview from 'Education/components/instruction-assignment/exam/AssignmentExamPreview';
import Imprint from 'Application/components/Static/Imprint';
import TermsAndConditions from 'Application/components/Static/TermsAndConditions';
import PrivacyPolicy from 'Application/components/Static/PrivacyPolicy';
import modules from 'Common/constants/modules';
import ManagerMeasures from 'Measure/components/ManagerMeasures';
import VehiclesDashboard from 'Measure/components/vehicle/VehiclesDashboard';
import Logout from 'Application/components/Logout';
import ReactHeader from 'Application/components/Header/ReactHeader';
import Footer from 'Application/components/Footer';
import routeTypes from 'Application/constants/route-types';
import PostLogin from 'User/components/PostLogin';
import ManagerInstructionsHomepage from 'Education/components/ManagerInstructionsHomepage';
import EmployeeManagement from 'Employee/components/EmployeeManagement';
import DriverLicenseExaminerDashboard from 'Measure/components/vehicle/driver-license/DriverLicenseExaminerDashboard';
import CreateDriverLicense from 'Measure/components/vehicle/driver-license/create/CreateDriverLicense';
import measures from 'Measure/constants/measures';
import EditDriverLicenseData from 'Measure/components/vehicle/driver-license/EditDriverLicenseData';
import DriverLicenseExaminerDirectCheck from 'Measure/components/vehicle/driver-license/direct-check/DriverLicenseExaminerDirectCheck';
import DriverLicenseExaminerOverview from 'Measure/components/vehicle/driver-license/overview/DriverLicenseExaminerOverview';
import DriverLicenseEmployeeOverview from 'Measure/components/vehicle/driver-license/overview/DriverLicenseEmployeeOverview';
import DriverLicenseEmployeeDirectCheck from 'Measure/components/vehicle/driver-license/direct-check/DriverLicenseEmployeeDirectCheck';
import getCurrentUser from 'User/api/getCurrentUser';
import { updateUser } from 'User/reduxUser';
import Loader from 'Common/components/Loader';
import DriverLicenseEmployeeDistanceCheck from 'Measure/components/vehicle/driver-license/distance-check/DriverLicenseEmployeeDistanceCheck';
import DriverLicenseExaminerDistanceCheck from 'Measure/components/vehicle/driver-license/distance-check/DriverLicenseExaminerDistanceCheck';
import 'react-medium-image-zoom/dist/styles.css';
import EmployeeListOverview from 'Employee/components/employee-list/EmployeeListOverview';
import AssignmentPresentationRouter from 'Education/components/instruction-assignment/presentation/AssignmentPresentationRouter';
import Archive from 'Archive/components/Archive';
import EmployeesListArchive from 'Archive/components/employee-list/EmployeesListArchive';
import EmployeeArchive from 'Archive/components/employee/EmployeeArchive';

const Main = styled.div`
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - 171px);
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const App = () => {
    const dispatch = useDispatch();

    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    useEffect(() => {
        if (isLoadingUser) {
            if (isAuthenticated) {
                (async () => {
                    const response = await getCurrentUser();

                    dispatch(updateUser(response.data));

                    setIsLoadingUser(false);
                })();
            } else {
                setIsLoadingUser(false);
            }
        }
    }, [dispatch, isAuthenticated, isLoadingUser]);

    return (
        <>
            <Helmet
                titleTemplate="%s - Amedic-Manager"
                defaultTitle="Amedic-Manager"
            >
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <GlobalStyles />
            <IconFont />
            {isAuthenticated && <ReactHeader />}
            <Main>
                {isLoadingUser ? (
                    <Loader />
                ) : (
                    <Switch>
                        <ReactRouterRoute exact path="/">
                            {isAuthenticated ? (
                                <Redirect to="/dashboard" />
                            ) : (
                                <Redirect to="/login" />
                            )}
                        </ReactRouterRoute>

                        <Route
                            exact
                            path="/login"
                            component={Login}
                            type={routeTypes.NOT_AUTHENTICATED}
                        />

                        {/* Public Routes (without authentication) */}
                        <Route
                            exact
                            path="/imprint"
                            component={Imprint}
                            type={routeTypes.PUBLIC}
                        />
                        <Route
                            exact
                            path="/terms-and-conditions"
                            component={TermsAndConditions}
                            type={routeTypes.PUBLIC}
                        />
                        <Route
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                            type={routeTypes.PUBLIC}
                        />

                        {/* Protected Routes (requires authentication) */}
                        <Route
                            exact
                            path="/dashboard"
                            component={Dashboard}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                        />
                        <Route
                            exact
                            path="/employees"
                            component={EmployeeListOverview}
                            authorizedRoles={[roles.MANAGER]}
                        />
                        <Route
                            exact
                            path="/employee/:id"
                            component={EmployeeManagement}
                            authorizedRoles={[roles.MANAGER]}
                        />
                        <Route
                            exact
                            path="/education"
                            component={ManagerInstructionsHomepage}
                            authorizedModules={[modules.INSTRUCTIONS]}
                            authorizedRoles={[roles.MANAGER]}
                        />
                        <Route
                            exact
                            path="/education/library"
                            component={ImportFromLibrary}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/instruction/:id/presentation"
                            component={EditInstructionPresentation}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/instruction/:id/test"
                            component={EditInstructionTest}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/assignment/presentation/preview/:id"
                            component={AssignmentPresentationPreview}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/assignment/presentation/:instructionId/:assignmentId?"
                            component={AssignmentPresentationRouter}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/assignment/exam/:id"
                            component={AssignmentExam}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/education/assignment/exam/preview/:id"
                            component={AssignmentExamPreview}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.INSTRUCTIONS]}
                        />
                        <Route
                            exact
                            path="/measures"
                            component={ManagerMeasures}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.MEASURES]}
                        />
                        <Route
                            exact
                            path="/measures/vehicles"
                            component={VehiclesDashboard}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.MEASURES]}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-licenses"
                            component={DriverLicenseExaminerDashboard}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/create/:id"
                            component={CreateDriverLicense}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/update/:id"
                            component={EditDriverLicenseData}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/overview/:id"
                            component={DriverLicenseExaminerOverview}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/overview"
                            component={DriverLicenseEmployeeOverview}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/examiner/direct-check/:id"
                            component={DriverLicenseExaminerDirectCheck}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/employee/direct-check"
                            component={DriverLicenseEmployeeDirectCheck}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/examiner/distance-check/:id"
                            component={DriverLicenseExaminerDistanceCheck}
                            authorizedRoles={[roles.MANAGER, roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                            authorizedMeasure={measures.DRIVER_LICENSE}
                        />
                        <Route
                            exact
                            path="/measures/vehicles/driver-license/employee/distance-check"
                            component={DriverLicenseEmployeeDistanceCheck}
                            authorizedRoles={[roles.EMPLOYEE]}
                            authorizedModules={[modules.MEASURES]}
                        />
                        <Route
                            exact
                            path="/archive"
                            component={Archive}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.ARCHIVE]}
                        />
                        <Route
                            exact
                            path="/archive/employees"
                            component={EmployeesListArchive}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.ARCHIVE]}
                        />
                        <Route
                            exact
                            path="/archive/employee/:id"
                            component={EmployeeArchive}
                            authorizedRoles={[roles.MANAGER]}
                            authorizedModules={[modules.ARCHIVE]}
                        />
                        {/* Temporary routes */}
                        <Route
                            exact
                            path="/react-post-login"
                            component={PostLogin}
                            type={routeTypes.PUBLIC}
                        />
                        <Route exact path="/react-logout" component={Logout} />

                        {/* Fallback Route */}
                        <Route component={PageNotFound} />
                    </Switch>
                )}
            </Main>
            <Footer />
        </>
    );
};

export default App;
