import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Tab, TabList, TabPanel, Tabs } from 'Common/components/tabs';
import useHash from 'Common/hooks/useHash';
import DriverLicenseFrontDataForm from 'Measure/components/vehicle/driver-license/create/DriverLicenseFrontDataForm';
import DriverLicenseBackDataForm from 'Measure/components/vehicle/driver-license/create/DriverLicenseBackDataForm';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import getDriverLicenseControl from 'Measure/api/driver-license/getDriverLicenseControl';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import { replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { BorderColorSharp } from '@material-ui/icons';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';

const StyledTabPanel = styled(TabPanel)`
    display: none;

    &.react-tabs__tab-panel--selected {
        display: block;
    }
`;

const EditIcon = styled(BorderColorSharp)`
    margin-left: 6px;
    width: 20px !important;
`;

const EditDriverLicenseData = () => {
    const dispatch = useDispatch();
    const { id: driverLicenseControlId } = useParams();

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getDriverLicenseControl(
                driverLicenseControlId,
                [
                    'employee',
                    'driverLicense',
                    'driverLicense.files',
                    'driverLicense.licenseData',
                    'driverLicense.licenseData.licenseClasses',
                    'driverLicense.employeeData',
                    'driverLicense.nextCheck',
                ],
                cancelToken,
            ),
        [driverLicenseControlId],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        if (error?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const [tabIndex, setTabIndex] = useHash();

    const [dirtyTabIndices, setDirtyTabIndices] = useState([]);

    const handleDataFormDirty = useCallback((index, isDirty) => {
        setDirtyTabIndices(prevDirtyTabIndices => {
            const newIndices = [...prevDirtyTabIndices];

            if (isDirty && !newIndices.includes(index)) {
                newIndices.push(index);
            } else if (!isDirty && newIndices.includes(index)) {
                newIndices.splice(newIndices.indexOf(index), 1);
            }

            return newIndices;
        });
    }, []);

    const handleFrontDataFormDirty = useCallback(
        isDirty => handleDataFormDirty(0, isDirty),
        [handleDataFormDirty],
    );

    const handleBackDataFormDirty = useCallback(
        isDirty => handleDataFormDirty(1, isDirty),
        [handleDataFormDirty],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const driverLicense = driverLicenseControl.driverLicense;
    const isInCreationProcess =
        driverLicenseControl &&
        (!driverLicense || driverLicense.isInCreationProcess);

    const handleConfirmData = () => {
        notify('Die Daten wurden erfolgreich gespeichert', {
            type: toast.TYPE.SUCCESS,
        });
    };

    if (!isInCreationProcess) {
        const breadcrumbs = isAuthorized([roles.MANAGER]) ? (
            <>
                <Helmet>
                    <title>{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}</title>
                </Helmet>
                <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                <Breadcrumb to="/measures/vehicles">Fuhrpark</Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    isActive
                >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
            </>
        ) : (
            <>
                <Helmet>
                    <title>{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName} - Führerscheinkontrolle - Maßnahmen`}</title>
                </Helmet>
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    isActive
                >{`${driverLicenseControl.employee.firstName} ${driverLicenseControl.employee.lastName}`}</Breadcrumb>
            </>
        );

        return (
            <>
                {breadcrumbs}
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={setTabIndex}
                    forceRenderTabPanel
                >
                    <TabList>
                        <Tab>
                            Führerscheinvorderseite
                            {dirtyTabIndices.includes(0) && <EditIcon />}
                        </Tab>
                        <Tab>
                            Führerscheinrückseite
                            {dirtyTabIndices.includes(1) && <EditIcon />}
                        </Tab>
                    </TabList>
                    <StyledTabPanel>
                        <DriverLicenseFrontDataForm
                            driverLicenseControl={driverLicenseControl}
                            onConfirm={handleConfirmData}
                            onDirty={handleFrontDataFormDirty}
                        />
                    </StyledTabPanel>
                    <StyledTabPanel>
                        <DriverLicenseBackDataForm
                            driverLicenseControl={driverLicenseControl}
                            onConfirm={handleConfirmData}
                            onDirty={handleBackDataFormDirty}
                        />
                    </StyledTabPanel>
                </Tabs>
            </>
        );
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

export default EditDriverLicenseData;
