import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import ReactSelectAsync from 'react-select/async';
import reactSelectCustomStyle from 'Common/components/form/fields/reactSelectCustomStyle';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import { debounce } from 'lodash';
import StyledInputLabel from 'Common/components/form/StyledInputLabel';
import StyledNotchedOutline from 'Common/components/form/StyledNotchedOutline';

const Wrapper = styled.div`
    position: relative;
    width: 100%;

    ${ifProp(
        'isDisabled',
        css`
            cursor: not-allowed;
        `,
    )};

    &:hover {
        .MuiOutlinedInput-notchedOutline:not(.Mui-disabled) {
            border-color: ${colors.LIGHT_GRAY};
        }
    }
`;

const getLoadingMessage = () => 'Wird geladen...';
const getNoOptionsMessage = () => 'Keine Übereinstimmung';

const AsyncSelect = ({
    label,
    id,
    error,
    touched,
    setFieldValue,
    styles,
    loadOptions,
    onFocus,
    onBlur,
    ...props
}) => {
    const debouncedLoadOptions = useCallback(
        debounce(async (inputValue, resolve) => {
            const loadedOptions = await loadOptions(inputValue);

            resolve(loadedOptions);
        }, 350),
        [],
    );

    const promiseOptions = inputValue =>
        new Promise(async resolve => {
            debouncedLoadOptions(inputValue, resolve);
        });

    const handleChange = option => {
        setFieldValue(props.name, option);
    };

    const hasError =
        !!error &&
        ((typeof touched === 'boolean' && touched) ||
            typeof touched === 'object');
    const hasLabel = label !== null;

    const [isFocused, setIsFocused] = useState(false);

    const [curriedCustomStyle, setCurriedCustomStyle] = useState(
        reactSelectCustomStyle,
    );

    useEffect(() => {
        if (hasError) {
            setCurriedCustomStyle({
                ...reactSelectCustomStyle,
                control: (provided, state) => ({
                    ...reactSelectCustomStyle.control(provided, state),
                    borderColor: colors.RED,
                    ':hover': {
                        borderColor: state.isFocused
                            ? colors.LIGHT_GRAY
                            : colors.RED,
                    },
                }),
            });
        } else {
            setCurriedCustomStyle(reactSelectCustomStyle);
        }
    }, [hasError]);

    useEffect(() => {
        if (Object.keys(styles).length > 0) {
            setCurriedCustomStyle(prevCurriedCustomStyle => {
                const newStyles = { ...prevCurriedCustomStyle };

                Object.keys(styles).forEach(key => {
                    const curriedStyle = newStyles[key];
                    const style = styles[key];

                    newStyles[key] = (provided, state) => {
                        const curriedProvided = curriedStyle
                            ? curriedStyle(provided, state)
                            : {};

                        return style(curriedProvided, state);
                    };
                });

                return newStyles;
            });
        }
    }, [styles]);

    const handleFocus = (...args) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(...args);
        }
    };

    const handleBlur = (...args) => {
        setIsFocused(false);

        if (onBlur) {
            onBlur(...args);
        }
    };

    const hasValue =
        !!props.value &&
        (!Array.isArray(props.value) ||
            (Array.isArray(props.value) && props.value.length > 0));

    return (
        <>
            <Wrapper isDisabled={props.isDisabled}>
                {hasLabel && (
                    <StyledInputLabel
                        id={id}
                        focused={isFocused}
                        shrink={isFocused || hasValue}
                        error={hasError}
                        disabled={props.isDisabled}
                    >
                        {label}
                    </StyledInputLabel>
                )}

                <ReactSelectAsync
                    onChange={handleChange}
                    inputId={id}
                    styles={curriedCustomStyle}
                    defaultOptions
                    loadOptions={promiseOptions}
                    loadingMessage={getLoadingMessage}
                    noOptionsMessage={getNoOptionsMessage}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={null}
                    {...props}
                />

                {hasLabel && (
                    <StyledNotchedOutline
                        label={label}
                        labelWidth={0}
                        notched={isFocused || hasValue}
                        classes={{
                            root: `MuiOutlinedInput-notchedOutline ${
                                isFocused ? 'Mui-focused' : ''
                            } ${hasError ? 'Mui-error' : ''} ${
                                props.isDisabled ? 'Mui-disabled' : ''
                            } ${!hasLabel ? 'no-label' : ''}`,
                        }}
                    />
                )}
            </Wrapper>

            {hasError && <ErrorMessage message={error} />}
        </>
    );
};

AsyncSelect.defaultProps = {
    label: null,
    error: null,
    touched: false,
    setFieldValue: undefined,
    onFocus: undefined,
    onBlur: undefined,
    styles: {},
};

AsyncSelect.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.any,
    setFieldValue: PropTypes.func,
    loadOptions: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    styles: PropTypes.object,
};

export default AsyncSelect;
