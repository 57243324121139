import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import GroupAssignmentEmployeeChecklist from 'Education/components/instruction-assignment/GroupAssignmentEmployeeChecklist';
import completeGroupInstructionAssignmentExam from 'Education/api/instruction-assignment/exam/completeGroupInstructionAssignmentExam';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const SuccessfulMessage = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5em;
    color: ${colors.ORANGE};

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const InformationText = styled.div`
    margin-bottom: 30px;
    font-size: 14px;
    color: ${colors.WHITE};
`;

const GroupAssignmentExamApprovalScreen = ({ assignment, onComplete }) => {
    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleEmployeeChecklistConfirm = async selectedIds => {
        try {
            await completeGroupInstructionAssignmentExam(
                assignment.id,
                selectedIds,
                source.token,
            );

            await onComplete();
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Wrapper>
            <SuccessfulMessage>Herzlichen Glückwunsch</SuccessfulMessage>
            <InformationText>
                Die Gruppe hat den Test erfolgreich bestanden
            </InformationText>
            <GroupAssignmentEmployeeChecklist
                employees={assignment.groupAssignmentDetails.assignees
                    .filter(
                        assignee =>
                            assignee.employee.id !==
                            assignment.groupAssignmentDetails.groupLeader
                                .employee.id,
                    )
                    .map(assignee => assignee.employee)}
                confirmButtonText="Bestätigen"
                onConfirm={handleEmployeeChecklistConfirm}
            />
        </Wrapper>
    );
};

GroupAssignmentExamApprovalScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default GroupAssignmentExamApprovalScreen;
