import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'Common/components/ConfirmModal';
import styled from 'styled-components/macro';
import getCurrentEmployee from 'Employee/api/getCurrentEmployee';
import Input from 'Common/components/form/fields/Input';
import signInstructionAssignmentWithPassword from 'Education/api/instruction-assignment/signInstructionAssignmentWithPassword';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Loader from 'Common/components/Loader';
import dataUriToBlob from 'Common/utils/dataUriToBlob';
import signInstructionAssignmentWithSignature from 'Education/api/instruction-assignment/signInstructionAssignmentWithSignature';
import SignaturePad from 'Common/components/SignaturePad';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import RetryLoading from 'Common/components/RetryLoading';

const Content = styled.div``;

const AssignmentSignatureModal = ({
    assignmentId,
    isOpen,
    onCancel,
    onSuccess,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [employeePassword, setEmployeePassword] = useState('');

    const signaturePad = useRef();

    const currentEmployeeRequest = useCallback(
        cancelToken => getCurrentEmployee([], cancelToken),
        [],
    );

    const {
        data: currentEmployee,
        loadData: loadCurrentEmployee,
        isLoading,
        hasError,
    } = useAxiosRequest(currentEmployeeRequest, null);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsConfirming(true);

        if (currentEmployee.isAutomaticSigningOfInstructionsAllowed) {
            try {
                await signInstructionAssignmentWithPassword(
                    assignmentId,
                    employeePassword,
                    source.token,
                );

                await onSuccess();
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify('Dein Passwort ist falsch', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        } else {
            if (signaturePad.current.isEmpty()) {
                notify('Die Unterschrift darf nicht leer sein', {
                    type: toast.TYPE.ERROR,
                });
            } else {
                try {
                    await signInstructionAssignmentWithSignature(
                        assignmentId,
                        dataUriToBlob(signaturePad.current.toDataURL()),
                        source.token,
                    );

                    await onSuccess();
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            }
        }

        setIsConfirming(false);
    };

    const handleEmployeePasswordChange = e => {
        setEmployeePassword(e.target.value);
    };

    return (
        <ConfirmModal
            isOpen={isOpen}
            isConfirmLoading={isConfirming}
            onConfirm={handleConfirm}
            onCancel={onCancel}
        >
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadCurrentEmployee} />
            ) : (
                <Content>
                    {currentEmployee.isAutomaticSigningOfInstructionsAllowed ? (
                        <Input
                            type="password"
                            label="Geben Sie Ihr Passwort ein"
                            value={employeePassword}
                            onChange={handleEmployeePasswordChange}
                            autoComplete="off"
                        />
                    ) : (
                        <SignaturePad ref={signaturePad} />
                    )}
                </Content>
            )}
        </ConfirmModal>
    );
};

AssignmentSignatureModal.propTypes = {
    assignmentId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default AssignmentSignatureModal;
