import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

export const Layout = styled.div``;

export const Title = styled.h1`
    display: block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 10px;
    color: ${colors.LIGHTEST_GRAY};
    text-align: center;
    font-size: 20px;
`;

export const ToolbarButtons = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 15px 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 680px) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    button,
    a {
        flex: 1;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (min-width: 680px) {
        width: 270px;
        flex: 0 0 270px;
        margin-bottom: 0;
    }
`;

export const FullWidthContent = styled.div`
    background: ${colors.DARK_GRAY};
    padding: 20px;
`;
