import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import Button from 'Common/components/Button';
import moment from 'moment-timezone';
import {
    AssignmentTurnedInSharp,
    CloudDownloadSharp,
    HistorySharp,
} from '@material-ui/icons';
import { useOnClickOutside } from 'crooks';
import { ifProp } from 'styled-tools';
import DriverLicenseSentEmailsTable from 'Measure/components/vehicle/driver-license/overview/DriverLicenseSentEmailsTable';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';

const Wrapper = styled.div`
    display: block;
    width: 100%;
`;

const Toolbar = styled.div`
    position: relative;

    > div,
    > button {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 620px) {
        display: flex;

        > div,
        > button {
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 940px) {
        > div,
        > button {
            width: 310px;
        }
    }
`;

const ActionsMenu = styled.div`
    position: absolute;
    z-index: 2;
    top: 55px;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    @media screen and (min-width: 620px) {
        width: calc(50% - 5px);
    }

    @media screen and (min-width: 940px) {
        width: 310px;
    }
`;

const PreviousCheck = styled.div`
    display: flex;
    margin-bottom: 15px;
    line-height: 1.5;
    min-height: 35px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const PreviousCheckName = styled.button`
    flex: 1;
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    padding: 10px;
    color: ${colors.LIGHTER_GRAY};
    text-align: left;
    border-radius: 4px;

    &:hover,
    &:focus {
        color: ${colors.LIGHTEST_GRAY};
        background: ${colors.DARK_GRAY};
    }

    ${ifProp(
        'isSelected',
        css`
            color: ${colors.ORANGE};
            pointer-events: none;
        `,
    )};
`;

const PreviousCheckDownloadButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50px;
    width: 40px;
    margin-left: 5px;
    text-decoration: none;
    color: ${colors.LIGHTER_GRAY};
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    &:hover,
    &:focus {
        color: ${colors.LIGHTEST_GRAY};
        background: ${colors.ALT_GRAY};
    }
`;

const EmptyWrapper = styled.div`
    padding: 55px 20px;
    text-align: center;
    color: ${colors.ORANGE};
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const DriverLicenseSentEmails = ({
    isExaminer,
    driverLicenseControl,
    selectedCheckId,
    onChangeSelectedCheckId,
}) => {
    const [
        isPreviousChecksMenuOpened,
        setIsPreviousChecksMenuOpened,
    ] = useState(false);

    const handlePreviousChecksButtonClick = () => {
        setIsPreviousChecksMenuOpened(prevIsOpened => !prevIsOpened);
    };

    const handleCurrentCheckButtonClick = () => {
        onChangeSelectedCheckId(null);
    };

    const handleClickOutsideActions = () => {
        setIsPreviousChecksMenuOpened(false);
    };

    const handlePreviousCheckClick = checkId => {
        setIsPreviousChecksMenuOpened(false);
        onChangeSelectedCheckId(checkId);
    };

    const previousChecksMenuButtonWrapperRef = useOnClickOutside(
        handleClickOutsideActions,
        !isPreviousChecksMenuOpened,
    );

    const driverLicense = driverLicenseControl.driverLicense;
    const nextCheck = driverLicense.nextCheck;
    const checks = driverLicense.checks;

    const selectedCheck = selectedCheckId
        ? checks.find(check => check.id === selectedCheckId)
        : checks[checks.length - 1];

    const dueDate = moment(driverLicense.nextCheck.dueDate.date);
    const reminderDate = moment(driverLicense.nextCheck.dueDate.date).subtract(
        14,
        'days',
    );

    const previousChecks = [...checks];
    previousChecks.pop();

    return (
        <Wrapper>
            <Toolbar>
                <div ref={previousChecksMenuButtonWrapperRef}>
                    {isExaminer && previousChecks.length > 0 && (
                        <Button
                            type="button"
                            onClick={handlePreviousChecksButtonClick}
                        >
                            <HistorySharp />
                            Vorherige Kontrollen
                        </Button>
                    )}
                    {isPreviousChecksMenuOpened && (
                        <ActionsMenu>
                            {previousChecks.map(check => {
                                const pdfDocumentLink = isAuthorized([
                                    roles.EMPLOYEE,
                                ])
                                    ? `/api/measures/vehicles/driver-licenses/controls/download-reminders-document/${check.id}`
                                    : `/api/measures/vehicles/driver-licenses/controls/${driverLicenseControl.id}/download-reminders-document/${check.id}`;

                                return (
                                    <PreviousCheck key={check.id}>
                                        <PreviousCheckName
                                            isSelected={
                                                check.id === selectedCheckId
                                            }
                                            onClick={() =>
                                                handlePreviousCheckClick(
                                                    check.id,
                                                )
                                            }
                                        >
                                            {`Prüfprotokoll vom ${moment(
                                                check.completedAt.date,
                                            ).format()}`}
                                        </PreviousCheckName>
                                        <PreviousCheckDownloadButton
                                            href={pdfDocumentLink}
                                            target="_blank"
                                        >
                                            <CloudDownloadSharp />
                                        </PreviousCheckDownloadButton>
                                    </PreviousCheck>
                                );
                            })}
                        </ActionsMenu>
                    )}
                </div>
                {selectedCheckId !== null && (
                    <Button
                        type="button"
                        onClick={handleCurrentCheckButtonClick}
                    >
                        <AssignmentTurnedInSharp />
                        Aktuelle Kontrolle
                    </Button>
                )}
            </Toolbar>
            {nextCheck.isInVerificationPeriod || selectedCheckId !== null ? (
                <DriverLicenseSentEmailsTable
                    driverLicenseControl={driverLicenseControl}
                    sentEmails={[...selectedCheck.sentEmails].reverse()}
                />
            ) : (
                <EmptyWrapper>
                    {`Die nächste Kontrolle ist am ${dueDate.format()} fällig.`}
                    <br />
                    {`Die Kontrolle wird mit der Benachrichtigung am ${reminderDate.format()} freigeschaltet.`}
                </EmptyWrapper>
            )}
        </Wrapper>
    );
};

DriverLicenseSentEmails.defaultProps = {
    selectedCheckId: null,
};

DriverLicenseSentEmails.propTypes = {
    isExaminer: PropTypes.bool.isRequired,
    driverLicenseControl: PropTypes.object.isRequired,
    selectedCheckId: PropTypes.number,
    onChangeSelectedCheckId: PropTypes.func.isRequired,
};

export default DriverLicenseSentEmails;
