import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import GroupAssignmentEmployeeSignatures from 'Education/components/instruction-assignment/exam/GroupAssignmentEmployeeSignatures';

import LinkButton from 'Common/components/LinkButton';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const SuccessfulMessage = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5em;
    color: ${colors.ORANGE};

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const InformationText = styled.div`
    margin-bottom: 30px;
    font-size: 14px;
    color: ${colors.WHITE};
`;

const SignatureQuestion = styled.div`
    margin-bottom: 40px;
`;

const HorizontalButtonsWrapper = styled.div`
    > a,
    > button {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 680px) {
        display: flex;
        align-items: center;

        > a,
        > button {
            width: auto;
            min-width: 280px;
            margin-right: 10px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const VerticalButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > a,
    > button {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 520px) {
        > a,
        > button {
            width: auto;
            min-width: 280px;
        }
    }
`;

const CompletedExamScreen = ({
    assignment,
    onShowExamMistakes,
    onSignaturesUpdates,
}) => {
    const [isSignatureScreenVisible, setIsSignatureScreenVisible] = useState(
        false,
    );

    const handleShowSignatureScreen = () => {
        setIsSignatureScreenVisible(true);
    };

    const handleHideSignatureScreen = () => {
        setIsSignatureScreenVisible(false);
    };

    let areSignaturesForGroupAssignmentPlaced = false;

    if (assignment.isGroupAssignment) {
        const groupAssignmentDetails = assignment.groupAssignmentDetails;

        const groupLeader = groupAssignmentDetails.groupLeader;
        const assignees = groupAssignmentDetails.assignees;
        const assigneesWhoSigned = assignees.filter(
            assignee => assignee.isSignaturePlaced,
        );

        areSignaturesForGroupAssignmentPlaced =
            groupLeader.isSignaturePlaced &&
            assigneesWhoSigned.length === assignees.length;
    }

    return (
        <Wrapper>
            {isSignatureScreenVisible ? (
                <>
                    <GroupAssignmentEmployeeSignatures
                        assignment={assignment}
                        onSignatureConfirmed={onSignaturesUpdates}
                    />
                    <HorizontalButtonsWrapper>
                        <Button
                            type="button"
                            onClick={handleHideSignatureScreen}
                        >
                            Zurück
                        </Button>
                        <LinkButton to="/dashboard">Schließen</LinkButton>
                    </HorizontalButtonsWrapper>
                </>
            ) : (
                <>
                    <SuccessfulMessage>
                        Herzlichen Glückwunsch
                    </SuccessfulMessage>
                    <InformationText>
                        {assignment.isGroupAssignment
                            ? 'Die Gruppe hat den Test erfolgreich bestanden'
                            : 'Sie haben den Test erfolgreich bestanden'}
                    </InformationText>
                    {assignment.isGroupAssignment &&
                        !areSignaturesForGroupAssignmentPlaced && (
                            <SignatureQuestion>
                                <InformationText>
                                    Möchten Sie jetzt als Gruppenleiter den
                                    Teilnehmer die Möglichkeit geben, die
                                    Teilnahmen zu bestätigen?
                                </InformationText>
                                <VerticalButtonsWrapper>
                                    <Button
                                        type="button"
                                        onClick={handleShowSignatureScreen}
                                    >
                                        Zur Bestätigung der Teilnehmer
                                    </Button>
                                </VerticalButtonsWrapper>
                            </SignatureQuestion>
                        )}
                    <VerticalButtonsWrapper>
                        <Button type="button" onClick={onShowExamMistakes}>
                            Prüfungsergebnisse anzeigen
                        </Button>
                        <LinkButton to="/dashboard">Schließen</LinkButton>
                    </VerticalButtonsWrapper>
                </>
            )}
        </Wrapper>
    );
};

CompletedExamScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onShowExamMistakes: PropTypes.func.isRequired,
    onSignaturesUpdates: PropTypes.func.isRequired,
};

export default CompletedExamScreen;
