import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Input from 'Common/components/form/fields/Input';
import Link from 'Common/components/Link';
import { Search } from '@material-ui/icons';

const Wrapper = styled.div`
    position: sticky;
    flex: 0 0 250px;
    top: 81px;
    background: ${colors.ALT_GRAY};
    padding: 15px 0;

    > div {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const SidebarInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 14px;
`;

const SearchWrapper = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.LIGHT_GRAY};
`;

const ImportFromLibrarySidebar = ({ searchTerm, onSearchTermChange }) => {
    const handleSearchTermChange = e => {
        onSearchTermChange(e.target.value);
    };

    return (
        <Wrapper>
            <SidebarInfo>
                <div>0 Filter aktiv</div>
                <Link to="/education">zurücksetzen</Link>
            </SidebarInfo>
            <SearchWrapper>
                <Input
                    type="search"
                    label="Stichwortsuche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
            </SearchWrapper>
        </Wrapper>
    );
};

export default ImportFromLibrarySidebar;
