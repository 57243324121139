import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';
import InstructionAreaSidebar from 'Education/components/instruction-area/InstructionAreaSidebar';
import Loader from 'Common/components/Loader';
import InstructionAreasTable from 'Education/components/instruction-area/InstructionAreasTable';
import getInstructionArea from 'Education/api/instruction-area/getInstructionArea';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 8px 0;
    border-radius: 4px;
`;

const loadProperties = ['instructionsCount', 'employeesCount'];

const InstructionAreaListTab = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const instructionAreasRequest = useCallback(
        cancelToken => getInstructionAreas(loadProperties, cancelToken),
        [],
    );

    const {
        data: instructionAreas,
        setData: setInstructionAreas,
        loadData: loadInstructionAreas,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAreasRequest, []);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleAreaCreate = async newInstructionArea => {
        try {
            const response = await getInstructionArea(
                newInstructionArea.id,
                loadProperties,
                source.token,
            );

            setInstructionAreas(prevInstructionAreas => [
                ...prevInstructionAreas,
                response.data,
            ]);
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleAreaUpdate = async id => {
        try {
            const response = await getInstructionArea(
                id,
                loadProperties,
                source.token,
            );

            setInstructionAreas(prevInstructionAreas => {
                const newInstructionAreas = [...prevInstructionAreas];

                const index = newInstructionAreas.findIndex(
                    area => area.id === id,
                );

                newInstructionAreas[index] = response.data;

                return newInstructionAreas;
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleAreaDelete = id => {
        setInstructionAreas(prevInstructionAreas => {
            const newInstructionAreas = [...prevInstructionAreas];

            const index = newInstructionAreas.findIndex(area => area.id === id);

            if (index !== -1) {
                newInstructionAreas.splice(index, 1);
            }

            return newInstructionAreas;
        });
    };

    let filteredInstructionAreas = [...instructionAreas];

    if (searchTerm) {
        filteredInstructionAreas = filteredInstructionAreas.filter(
            area =>
                area.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                area.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
        );
    }

    return (
        <>
            <Wrapper>
                <InstructionAreaSidebar
                    searchTerm={searchTerm}
                    onSearchTermChange={setSearchTerm}
                />
                <Content>
                    {isLoading ? (
                        <Loader />
                    ) : hasError ? (
                        <RetryLoading onRetry={loadInstructionAreas} />
                    ) : (
                        <InstructionAreasTable
                            instructionAreas={filteredInstructionAreas}
                            onAreaCreate={handleAreaCreate}
                            onAreaUpdate={handleAreaUpdate}
                            onAreaDelete={handleAreaDelete}
                        />
                    )}
                </Content>
            </Wrapper>
        </>
    );
};

export default InstructionAreaListTab;
