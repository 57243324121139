import axios from 'axios';
import { store } from 'Application/store';
import { clearUserData } from 'User/reduxUser';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: '/api',
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const sessionTimeoutToastId = 'session-timeout';

// Redirect the user to the login screen when an API request returns 403
api.interceptors.response.use(
    response => response,
    error => {
        if (
            error.response &&
            error.response.status === 403 &&
            error.config.url !== '/login-web'
        ) {
            store.dispatch(clearUserData());

            if (!toast.isActive(sessionTimeoutToastId)) {
                notify('Deine Sitzung ist abgelaufen', {
                    type: toast.TYPE.WARNING,
                    toastId: sessionTimeoutToastId,
                });
            }
        }

        return Promise.reject(error);
    },
);

export default api;
