import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Box = styled.div`
    margin-top: 10px;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    font-size: 14px;
    line-height: 1.5;

    a {
        color: ${colors.ORANGE};
        text-decoration: none;

        &:hover,
        &:focus {
            color: ${colors.LIGHT_ORANGE};
        }
    }

    @media screen and (min-width: 500px) {
        margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
`;

export default Box;
