import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { AutorenewSharp } from '@material-ui/icons';

const Text = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
`;

const RetryIcon = styled(AutorenewSharp)`
    margin-bottom: 10px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 40px !important;
    will-change: transform;
    transition: transform 0.36s ease-in-out;
`;

const Wrapper = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    background: ${colors.ALT_DARKEST_GRAY};
    border: 1px solid ${colors.ALT_DARKEST_GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0 none;
        border-color: ${colors.DARKEST_GRAY};

        ${Text},
        ${RetryIcon} {
            color: ${colors.LIGHTEST_GRAY};
        }

        ${RetryIcon} {
            transform: rotate(360deg);
        }
    }
`;

const RetryLoading = ({ onRetry }) => (
    <Wrapper onClick={onRetry}>
        <RetryIcon />
        <Text>Ladefehler. Klicken Sie hier, um es erneut zu versuchen.</Text>
    </Wrapper>
);

RetryLoading.propTypes = {
    onRetry: PropTypes.func.isRequired,
};

export default RetryLoading;
