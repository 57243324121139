import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import getInstruction from 'Education/api/instruction/getInstruction';
import Loader from 'Common/components/Loader';
import {
    ActionBar,
    ActionBarButtonsWrapper,
    ActionBarTitle,
} from 'Common/components/actionBar';
import LinkButton from 'Common/components/LinkButton';
import Button from 'Common/components/Button';
import getInstructionStats from 'Education/api/instruction/getInstructionStats';
import InstructionTestForm from 'Education/components/instruction/test-edit/InstructionTestForm';
import QuestionDeleteModal from 'Education/components/instruction/test-edit/QuestionDeleteModal';
import InstructionTestSaveModal from 'Education/components/instruction/test-edit/InstructionTestSaveModal';
import { v4 as uuidv4 } from 'uuid';
import QuestionList from 'Education/components/instruction/test-edit/QuestionList';
import updateInstructionTest from 'Education/api/instruction/updateInstructionTest';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div``;

const Layout = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const Sidebar = styled.div`
    padding: 20px;
    background-color: ${colors.DARK_GRAY};
    border-radius: 0 0 4px 4px;

    @media screen and (min-width: 940px) {
        position: sticky;
        top: 158px;
        max-height: calc(100vh - 188px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 300px;
        flex: 0 0 300px;
        align-self: flex-start;
        margin-right: 10px;
    }
`;

const WarningMessage = styled.div`
    font-size: 13px;
    color: ${colors.ORANGE};
`;

const SubTitle = styled.h2`
    margin-top: 0;
    margin-bottom: 20px;
    color: ${colors.LIGHT_GRAY};
    font-size: 16px;
    line-height: 18px;
`;

const Questions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background-color: ${colors.DARK_GRAY};
    padding: 20px;
`;

const validationSchema = Yup.object().shape({
    passingScorePercentage: Yup.number()
        .integer('Ungültige Nummer')
        .min(0, 'Ungültige Nummer')
        .max(100, 'Ungültige Nummer')
        .required('Pflichtangabe'),
    maxExamAttempts: Yup.number()
        .integer('Ungültige Nummer')
        .positive('Ungültige Nummer')
        .nullable(),
    recommendedTestDuration: Yup.number()
        .integer('Ungültige Nummer')
        .positive('Ungültige Nummer')
        .required('Pflichtangabe'),
    questions: Yup.array().of(
        Yup.object().shape({
            id: Yup.mixed(),
            title: Yup.string().required('Pflichtangabe'),
            image: Yup.mixed().nullable(),
            answers: Yup.array().of(
                Yup.object().shape({
                    id: Yup.mixed(),
                    text: Yup.string().required('Pflichtangabe'),
                    isCorrect: Yup.object(),
                }),
            ),
        }),
    ),
});

const EditInstructionTest = () => {
    const [isSavingChanges, setIsSavingChanges] = useState(false);
    const [isSaveChangesModalOpened, setIsSaveChangesModalOpened] = useState(
        false,
    );

    const instructionRequest = useCallback(
        cancelToken => id =>
            getInstruction(
                id,
                [
                    'passingScorePercentage',
                    'maxExamAttempts',
                    'recommendedTestDuration',
                    'questions',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: instruction,
        isLoading: isLoadingInstruction,
        loadData: loadInstruction,
        loadDataWithoutLoader: loadInstructionWithoutLoader,
        hasError: hasInstructionError,
    } = useAxiosRequest(instructionRequest, null, true, true);

    const instructionStatsRequest = useCallback(
        cancelToken => id =>
            getInstructionStats(id, ['examsInProgressCount'], cancelToken),
        [],
    );

    const {
        data: stats,
        isLoading: isLoadingStats,
        loadData: loadInstructionStats,
        loadDataWithoutLoader: loadInstructionStatsWithoutLoader,
        hasError: hasInstructionStatsError,
    } = useAxiosRequest(instructionStatsRequest, null, true, true);

    const isLoading = isLoadingInstruction || isLoadingStats;
    const hasError = hasInstructionError || hasInstructionStatsError;

    const handleSaveButtonClick = () => {
        setIsSaveChangesModalOpened(true);
    };

    const handleSaveChangesCancel = () => {
        setIsSaveChangesModalOpened(false);
    };

    const { id: instructionId } = useParams();

    const initialValues = useMemo(
        () =>
            instruction
                ? {
                      passingScorePercentage:
                          instruction.passingScorePercentage !== null
                              ? instruction.passingScorePercentage.toString()
                              : '',
                      maxExamAttempts:
                          instruction.maxExamAttempts !== null
                              ? instruction.maxExamAttempts.toString()
                              : '',
                      recommendedTestDuration: instruction.recommendedTestDuration
                          ? instruction.recommendedTestDuration.toString()
                          : '',
                      questions: instruction.questions.map(question => ({
                          id: question.id,
                          title: question.title,
                          image: question.image,
                          answers: question.answers.map(answer => ({
                              id: answer.id,
                              text: answer.text,
                              isCorrect: {
                                  value: answer.isCorrect,
                                  label: answer.isCorrect
                                      ? 'Richtig'
                                      : 'Falsch',
                              },
                          })),
                      })),
                  }
                : {
                      passingScorePercentage: 100,
                      maxExamAttempts: '',
                      recommendedTestDuration: 1,
                      questions: [],
                  },
        [instruction],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        setTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            setIsSavingChanges(true);

            // @TODO: Add validation (toast) to show an error when a question
            //        doesn't have at least 1 correct answer.

            try {
                await updateInstructionTest(
                    instructionId,
                    {
                        passingScorePercentage: Number(
                            values.passingScorePercentage,
                        ),
                        maxExamAttempts: values.maxExamAttempts
                            ? Number(values.maxExamAttempts)
                            : null,
                        recommendedTestDuration: values.recommendedTestDuration
                            ? Number(values.recommendedTestDuration)
                            : null,
                        questions: values.questions.map(question => {
                            // A new question is one that has a UUID for an id
                            const isNewQuestion = Number.isNaN(
                                Number(question.id),
                            );

                            const answers = question.answers ?? [];

                            return {
                                id: isNewQuestion
                                    ? undefined
                                    : Number(question.id),
                                title: question.title,
                                image: question.image,
                                answers: answers.map(answer => {
                                    // A new answer is one that has a UUID for an id
                                    const isNewAnswer = Number.isNaN(
                                        Number(answer.id),
                                    );

                                    return {
                                        id: isNewAnswer
                                            ? undefined
                                            : Number(answer.id),
                                        text: answer.text,
                                        isCorrect: answer.isCorrect.value,
                                    };
                                }),
                            };
                        }),
                    },
                    source.token,
                );

                await Promise.all([
                    loadInstructionWithoutLoader(instructionId),
                    loadInstructionStatsWithoutLoader(instructionId),
                ]);

                setIsSavingChanges(false);
                setIsSaveChangesModalOpened(false);

                notify('Der Test wurde erfolgreich gespeichert!', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsSavingChanges(false);
                    setIsSaveChangesModalOpened(false);

                    notify('Test konnte nicht gespeichert werden', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    // Initial load of the instruction
    useEffect(() => {
        loadInstruction(instructionId);
    }, [instructionId, loadInstruction]);

    // Initial load of the instruction stats
    useEffect(() => {
        loadInstructionStats(instructionId);
    }, [instructionId, loadInstructionStats]);

    const handleQuestionImageChange = (questionId, image) => {
        const newQuestions = [...values.questions];

        const index = newQuestions.findIndex(
            question => question.id === questionId,
        );

        if (index !== -1) {
            const question = newQuestions[index];

            newQuestions[index] = {
                ...question,
                image,
            };

            setFieldValue('questions', newQuestions, false);
        }
    };

    const handleQuestionMoveBackwards = questionId => {
        const newQuestions = [...values.questions];

        const index = newQuestions.findIndex(
            question => question.id === questionId,
        );

        if (index > 0) {
            const newQuestionsTouched = touched.questions
                ? [...touched.questions]
                : [];

            const movedQuestionTouched = newQuestionsTouched[index];
            newQuestionsTouched[index] = newQuestionsTouched[index - 1];
            newQuestionsTouched[index - 1] = movedQuestionTouched;

            setTouched({
                ...touched,
                questions: newQuestionsTouched,
            });

            const movedQuestion = newQuestions[index];
            newQuestions[index] = newQuestions[index - 1];
            newQuestions[index - 1] = movedQuestion;

            setFieldValue('questions', newQuestions);
        }
    };

    const handleQuestionMoveForwards = questionId => {
        const newQuestions = [...values.questions];

        const index = newQuestions.findIndex(
            question => question.id === questionId,
        );

        if (index < newQuestions.length - 1) {
            const newQuestionsTouched = touched.questions
                ? [...touched.questions]
                : [];

            const movedQuestionTouched = newQuestionsTouched[index];
            newQuestionsTouched[index] = newQuestionsTouched[index + 1];
            newQuestionsTouched[index + 1] = movedQuestionTouched;

            setTouched({
                ...touched,
                questions: newQuestionsTouched,
            });

            const movedQuestion = newQuestions[index];
            newQuestions[index] = newQuestions[index + 1];
            newQuestions[index + 1] = movedQuestion;

            setFieldValue('questions', newQuestions);
        }
    };

    const handleQuestionAdd = () => {
        const newQuestions = [...values.questions];

        newQuestions.push({
            id: uuidv4(),
            title: '',
            image: null,
            answers: [
                {
                    id: uuidv4(),
                    text: '',
                    isCorrect: {
                        value: true,
                        label: 'Richtig',
                    },
                },
                {
                    id: uuidv4(),
                    text: '',
                    isCorrect: {
                        value: false,
                        label: 'Falsch',
                    },
                },
            ],
        });

        setFieldValue('questions', newQuestions, false);
    };

    const [questionIdForDeletion, setQuestionIdForDeletion] = useState(null);

    const handleQuestionDeleteButtonClick = questionId => {
        setQuestionIdForDeletion(questionId);
    };

    const handleCancelQuestionDelete = () => {
        setQuestionIdForDeletion(null);
    };

    const handleQuestionDelete = () => {
        const newQuestions = [...values.questions];

        const index = newQuestions.findIndex(
            question => question.id === questionIdForDeletion,
        );

        if (index !== -1) {
            const newQuestionsTouched = touched.questions
                ? [...touched.questions]
                : [];

            newQuestionsTouched.splice(index, 1);

            setTouched({
                ...touched,
                questions: newQuestionsTouched,
            });

            newQuestions.splice(index, 1);

            setFieldValue('questions', newQuestions);
        }

        setQuestionIdForDeletion(null);
    };

    const handleAnswerAdd = questionId => {
        const newQuestions = [...values.questions];

        const questionIndex = newQuestions.findIndex(
            question => question.id === questionId,
        );

        if (questionIndex !== -1) {
            const question = newQuestions[questionIndex];

            newQuestions[questionIndex] = {
                ...question,
                answers: [
                    ...question.answers,
                    {
                        id: uuidv4(),
                        text: '',
                        isCorrect: {
                            value: false,
                            label: 'Falsch',
                        },
                    },
                ],
            };

            setFieldValue('questions', newQuestions, false);
        }
    };

    const handleAnswerDelete = (questionId, answerId) => {
        const newQuestions = [...values.questions];

        const questionIndex = newQuestions.findIndex(
            question => question.id === questionId,
        );

        if (questionIndex !== -1) {
            const question = newQuestions[questionIndex];

            const newAnswers = [...question.answers];

            const answerIndex = newAnswers.findIndex(
                answer => answer.id === answerId,
            );

            if (answerIndex !== -1) {
                const newQuestionsTouched = touched.questions
                    ? [...touched.questions]
                    : [];
                const newAnswersTouched = newQuestionsTouched[questionIndex]
                    ?.answers
                    ? [...newQuestionsTouched[questionIndex].answers]
                    : [];

                newAnswersTouched.splice(answerIndex, 1);

                if (newQuestionsTouched[questionIndex]) {
                    newQuestionsTouched[
                        questionIndex
                    ].answers = newAnswersTouched;

                    setTouched({
                        ...touched,
                        questions: newQuestionsTouched,
                    });
                }

                newAnswers.splice(answerIndex, 1);

                newQuestions[questionIndex] = {
                    ...question,
                    answers: newAnswers,
                };

                setFieldValue('questions', newQuestions);
            }
        }
    };

    const handleRetryLoading = () => {
        loadInstruction(instructionId);
        loadInstructionStats(instructionId);
    };

    return (
        <Wrapper>
            <Breadcrumb to="/education">Unterweisungen</Breadcrumb>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={handleRetryLoading} />
            ) : (
                <>
                    <Helmet>
                        <title>{`${instruction.name} - Unterweisungen`}</title>
                    </Helmet>
                    <Breadcrumb isActive>
                        Test bearbeiten - {instruction.name}
                    </Breadcrumb>
                    <ActionBar>
                        <ActionBarTitle>
                            <div>{`Unterweisung Test: ${instruction.name}`}</div>
                            {!!stats && stats.examsInProgressCount > 0 && (
                                <WarningMessage>
                                    {`Es gibt ${stats.examsInProgressCount} Mitarbeiter, die eine Testprüfung machen`}
                                </WarningMessage>
                            )}
                        </ActionBarTitle>
                        <ActionBarButtonsWrapper>
                            <LinkButton to="/education">Abbrechen</LinkButton>
                            <Button
                                type="button"
                                onClick={handleSaveButtonClick}
                                disabled={!isValid}
                            >
                                Speichern
                            </Button>
                        </ActionBarButtonsWrapper>
                    </ActionBar>
                    <Layout>
                        <Sidebar>
                            <SubTitle>Allgemein</SubTitle>
                            <InstructionTestForm
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                        </Sidebar>
                        <Questions>
                            <QuestionList
                                questions={values.questions}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                onImageChange={handleQuestionImageChange}
                                onMoveQuestionBackwards={
                                    handleQuestionMoveBackwards
                                }
                                onMoveQuestionForwards={
                                    handleQuestionMoveForwards
                                }
                                onQuestionAdd={handleQuestionAdd}
                                onQuestionDelete={
                                    handleQuestionDeleteButtonClick
                                }
                                onAnswerAdd={handleAnswerAdd}
                                onAnswerDelete={handleAnswerDelete}
                            />
                        </Questions>
                    </Layout>
                    <InstructionTestSaveModal
                        isOpen={isSaveChangesModalOpened}
                        isConfirmLoading={isSavingChanges}
                        onCancel={handleSaveChangesCancel}
                        onConfirm={handleSubmit}
                        stats={stats}
                        keepReference={instruction?.keepReference}
                    />
                    <QuestionDeleteModal
                        questionId={questionIdForDeletion}
                        onConfirm={handleQuestionDelete}
                        onCancel={handleCancelQuestionDelete}
                        stats={stats}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default EditInstructionTest;
