import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import DriverLicenseControlGridItem from 'Measure/components/vehicle/driver-license/DriverLicenseControlGridItem';
import { DriverLicenseControlGridItemWrapper } from 'Measure/components/vehicle/driver-license/DriverLicenseControlGridItemWrapper';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';

const EmptyWrapper = styled.div`
    padding: 55px 20px;
    text-align: center;
    color: ${colors.ORANGE};
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const LoaderWrapper = styled.div`
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;
`;

const Wrapper = styled.div`
    @media screen and (min-width: 580px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const DummyGridItem = styled(DriverLicenseControlGridItemWrapper)`
    display: none;

    @media screen and (min-width: 940px) {
        display: block;
        min-height: 120px;
        opacity: 0.5;
    }
`;

const minGridItems = 6;

const DriverLicenseControlsGrid = ({
    driverLicenseControls,
    onDriverLicenseControlActivate,
    onDriverLicenseControlDeactivate,
    isLoading,
    hasError,
    onReload,
}) => {
    if (isLoading) {
        return (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        );
    }

    if (hasError) {
        return (
            <LoaderWrapper>
                <RetryLoading onRetry={onReload} />
            </LoaderWrapper>
        );
    }

    if (driverLicenseControls.length === 0) {
        return (
            <EmptyWrapper>
                Aktuell liegen keine ausstehenden Aufgaben für Sie in der
                Führerscheinkontrolle vor.
            </EmptyWrapper>
        );
    }

    return (
        <Wrapper>
            {driverLicenseControls.map(driverLicenseControl => (
                <DriverLicenseControlGridItem
                    key={driverLicenseControl.id}
                    driverLicenseControl={driverLicenseControl}
                    onActivate={async () =>
                        onDriverLicenseControlActivate(driverLicenseControl)
                    }
                    onDeactivate={async () =>
                        onDriverLicenseControlDeactivate(driverLicenseControl)
                    }
                />
            ))}

            {driverLicenseControls.length < minGridItems && (
                <>
                    {[
                        ...Array(minGridItems - driverLicenseControls.length),
                    ].map((value, index) => (
                        <DummyGridItem key={index} />
                    ))}
                </>
            )}
        </Wrapper>
    );
};

DriverLicenseControlsGrid.propTypes = {
    driverLicenseControls: PropTypes.array.isRequired,
    onDriverLicenseControlActivate: PropTypes.func.isRequired,
    onDriverLicenseControlDeactivate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    onReload: PropTypes.func.isRequired,
};

export default DriverLicenseControlsGrid;
