import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { EmployeesIcon } from 'Common/components/icons';
import TileLink from 'Common/components/TileLink';
import ExternalTileLink from 'Common/components/ExternalTileLink';
import Breadcrumb from 'Application/components/Header/Breadcrumb';

const Content = styled.div`
    max-width: 480px;
    margin: 0 auto;

    @media screen and (min-width: 940px) {
        display: flex;
        max-width: 940px;
    }
`;

const ContentModuleExternalLink = styled(ExternalTileLink)`
    min-height: 180px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 940px) {
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const ContentModuleLink = styled(TileLink)`
    min-height: 180px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 940px) {
        width: calc(50% - 5px);
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const Title = styled.div`
    flex: 1;
    padding-right: 57px;
    text-align: center;

    @media screen and (min-width: 425px) {
        padding-right: 67px;
    }

    @media screen and (min-width: 1180px) {
        padding-right: 87px;
    }
`;

const Archive = () => (
    <>
        <Helmet>
            <title>Archiv</title>
        </Helmet>
        <Breadcrumb isActive>Archiv</Breadcrumb>
        <Content>
            <ContentModuleExternalLink href="/backend/m/archive/employees">
                <EmployeesIcon />
                <Title>Mitarbeiter (2019)</Title>
            </ContentModuleExternalLink>
            <ContentModuleLink to="/archive/employees">
                <EmployeesIcon />
                <Title>Mitarbeiter (2020 - )</Title>
            </ContentModuleLink>
        </Content>
    </>
);

export default Archive;
