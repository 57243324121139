import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import passwordConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/passwordConfirmSuccessfulCheckForDirectProcedure';
import signatureConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/signatureConfirmSuccessfulCheckForDirectProcedure';
import DriverLicenseToolbarButtons from 'Measure/components/vehicle/driver-license/check/DriverLicenseToolbarButtons';

const FullWidthContent = styled.div`
    margin-bottom: 10px;
    padding: 30px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 220px;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const Inner = styled.div`
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
    line-height: 24px;
`;

const EmployeeConfirmationFromExaminerAccount = ({ driverLicenseControl }) => {
    const dispatch = useDispatch();

    const [isInConfirmMode, setIsInConfirmMode] = useState(false);

    const proceedWithConfirmation = () => {
        setIsInConfirmMode(true);
    };

    const employee = driverLicenseControl.employee;
    const isAutomaticSigningAllowed = employee.isAutomaticSigningAllowed;

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const driverLicensesListLink = '/measures/vehicles/driver-licenses';

    const handleConfirmPassword = async password => {
        await passwordConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        dispatch(push(driverLicensesListLink));
    };

    const handleConfirmSignature = async blob => {
        const date = moment();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Employee_${employee.user.id}_${date.format(
                'YYYY-MM-DD_HH-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date.toDate(),
            },
        );

        await signatureConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        dispatch(push(driverLicensesListLink));
    };

    if (!isInConfirmMode) {
        return (
            <>
                <FullWidthContent>
                    <Inner>
                        Möchten Sie jetzt als Prüfer dem Inhaber des
                        Führerscheins die Möglichkeit geben, die Vorlage des
                        Führerscheins und somit die Kontrolle zu bestätigen?
                    </Inner>
                </FullWidthContent>
                <DriverLicenseToolbarButtons
                    cancelButtonLink={driverLicensesListLink}
                    cancelButtonText="Schließen"
                    onConfirm={proceedWithConfirmation}
                    confirmButtonText="Zur Bestätigung"
                    showBackButton={false}
                />
            </>
        );
    }

    const hint = (
        <>
            Hinweis: Hiermit bestätigen Sie, dass Sie Ihren Führerschein einem
            der zugewiesenen Prüfer im Original vorgelegt haben.
        </>
    );

    const confirmButtonText = 'Kontrolle abschließen';

    return isAutomaticSigningAllowed ? (
        <DriverLicensePasswordVerification
            hint={hint}
            cancelButtonLink={driverLicensesListLink}
            confirmButtonText={confirmButtonText}
            onConfirm={handleConfirmPassword}
            showBackButton={false}
        />
    ) : (
        <DriverLicenseSignatureVerification
            hint={hint}
            cancelButtonLink={driverLicensesListLink}
            confirmButtonText={confirmButtonText}
            onConfirm={handleConfirmSignature}
            showBackButton={false}
        />
    );
};

EmployeeConfirmationFromExaminerAccount.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default EmployeeConfirmationFromExaminerAccount;
