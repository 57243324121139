import React, { useCallback } from 'react';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import Loader from 'Common/components/Loader';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import AssignmentPresentationInProgress from 'Education/components/instruction-assignment/presentation/AssignmentPresentationInProgress';
import { examStatuses } from 'Education/constants/examStatuses';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';

const doNothing = async () => {};

const AssignmentPresentationPreview = () => {
    const [comingFromEmployeeId] = useQueryParameter('comingFromEmployeeId');
    const [fromTab] = useQueryParameter('fromTab');

    const tabHash = fromTab ? `#${fromTab}` : '';

    const { id: assignmentId } = useParams();

    const instructionAssignmentRequest = useCallback(
        cancelToken =>
            getInstructionAssignment(
                assignmentId,
                ['instruction', 'instruction.slides'],
                cancelToken,
            ),
        [assignmentId],
    );

    const {
        data: assignment,
        loadData: loadAssignment,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAssignmentRequest, null);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadAssignment} />;
    }

    if (
        assignment !== null &&
        assignment.examStatus === examStatuses.COMPLETED
    ) {
        return (
            <>
                <Helmet>
                    <title>{`${assignment.name} - Unterweisungen`}</title>
                </Helmet>
                <Breadcrumb to="/education">Unterweisungen</Breadcrumb>
                <Breadcrumb isActive>
                    Präsentation anzeigen - {assignment.name}
                </Breadcrumb>
                <AssignmentPresentationInProgress
                    assignment={assignment}
                    initialSlideNumber={1}
                    isInPreviewMode
                    disableLastSlideArrow
                    closeButtonLink={
                        comingFromEmployeeId
                            ? `/employee/${comingFromEmployeeId}${tabHash}`
                            : '/dashboard'
                    }
                    onProgress={doNothing}
                    onComplete={doNothing}
                />
            </>
        );
    }

    return <Redirect to="/dashboard" />;
};

export default AssignmentPresentationPreview;
