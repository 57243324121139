import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import getCompany from 'Company/api/getCompany';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    color: ${colors.LIGHTER_GRAY};
    margin-bottom: 1px;
`;
const Title = styled.div``;

const EmployeeLicensesUsage = () => {
    const companyRequest = useCallback(
        cancelToken => getCompany(['usedEmployeeLicenses'], cancelToken),
        [],
    );

    const {
        data: company,
        loadData: loadCompany,
        isLoading,
        hasError,
    } = useAxiosRequest(companyRequest, {});

    return (
        <Wrapper>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadCompany} />
            ) : (
                <>
                    <Title>Nutzerlizenzen</Title>
                    <div>{`${company.usedEmployeeLicenses}/${company.employeeLicensesLimit}`}</div>
                </>
            )}
        </Wrapper>
    );
};

export default EmployeeLicensesUsage;
