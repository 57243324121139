import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import axios from 'axios';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { ArrowBackSharp, CheckSharp } from '@material-ui/icons';
import {
    ButtonsWrapper,
    Layout,
    Title,
    ToolbarButtons,
} from 'Measure/components/vehicle/driver-license/layout';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import updateDriverLicenseBackData from 'Measure/api/driver-license/update/updateDriverLicenseBackData';
import { useFormik } from 'formik';
import LicensePreview from 'Measure/components/vehicle/driver-license/create/LicensePreview';
import FormField from 'Common/components/form/FormField';
import Select from 'Common/components/form/fields/Select';
import loadDriverLicenseClassesOptions from 'Education/utils/loadDriverLicenseClassesOptions';
import SingleDatePicker from 'Common/components/form/fields/SingleDatePicker';
import Button from 'Common/components/Button';
import LinkButton from 'Common/components/LinkButton';
import Loader from 'Common/components/Loader';
import Textarea from 'Common/components/form/fields/Textarea';
import { get } from 'lodash';
import { Prompt } from 'react-router';

const Content = styled.div`
    margin-bottom: 10px;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const Form = styled.form`
    display: block;
    width: 100%;

    @media screen and (min-width: 860px) {
        display: flex;
    }
`;

const LeftColumn = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 860px) {
        flex: 3;
        margin-bottom: 0;
        margin-right: 40px;
    }
`;

const RightColumn = styled.div`
    @media screen and (min-width: 860px) {
        flex: 2;
    }
`;

const FieldSet = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const FieldSetTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: ${colors.LIGHTEST_GRAY};
    font-size: 18px;
    line-height: 21px;
`;

const FieldSetRow = styled.div`
    display: flex;
`;

const LicenseClassesFieldSet = styled.div`
    width: 100%;
    margin-top: 15px;
    margin-bottom: 25px;

    @media screen and (min-width: 520px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const LicenseClassField = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    ${ifProp(
        'isHeading',
        css`
            @media screen and (max-width: 519px) {
                &:nth-child(2) {
                    display: none;
                }
            }
        `,
    )};

    @media screen and (min-width: 520px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
`;

const LicenseClassName = styled.div`
    display: flex;
    flex: 0 0 60px;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin-right: 5px;
    border-radius: 4px;
    text-align: center;
    background: ${colors.ALT_GRAY};
`;

const LicenseClassNameLabel = styled(LicenseClassName)`
    display: block;
    margin-right: 8px;
    font-size: 14px;
    background: none;
    color: ${colors.LIGHTER_GRAY};
    line-height: 21px;
`;

const LicenseClassDate = styled.div`
    flex: 1;
`;

const LicenseClassDateLabel = styled(LicenseClassDate)`
    display: block;
    font-size: 14px;
    background: none;
    color: ${colors.LIGHTER_GRAY};
    line-height: 21px;
`;

const RestrictionsTextarea = styled(Textarea)`
    textarea {
        min-height: 133px;
    }
`;

const validationSchema = Yup.object().shape({
    selectedClasses: Yup.array()
        .of(Yup.object())
        .min(1, 'Pflichtangabe')
        .required('Pflichtangabe')
        .nullable(),
    restrictions: Yup.string(),
});

const isExpiryDateOutsideRange = (date, driverLicense, licenseClassName) => {
    const today = moment().endOf('day');

    const initialExpiryDate = driverLicense.licenseData?.licenseClasses?.find(
        licenseClass => licenseClass.name === licenseClassName,
    )?.expiryDate;

    return (
        date.isBefore(today) &&
        (!initialExpiryDate ||
            !moment(initialExpiryDate.date)
                .startOf('day')
                .isSame(date.startOf('day')))
    );
};

const DriverLicenseBackDataForm = ({
    driverLicenseControl,
    title,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    onDirty,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [licenseClassOptions, setLicenseClassOptions] = useState([]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        (async () => {
            try {
                const options = await loadDriverLicenseClassesOptions(
                    source.token,
                );

                setLicenseClassOptions(options);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        })();

        return () => {
            source.cancel();
        };
    }, []);

    const driverLicense = driverLicenseControl.driverLicense;

    const backWithHologram = driverLicense.files.find(file =>
        [
            driverLicenseFileTypes.BACK_WITH_HOLOGRAM,
            driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
            driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
        ].includes(file.type),
    );

    const path = backWithHologram.file;

    const initialValues = useMemo(
        () =>
            driverLicense
                ? {
                      classes: driverLicense.licenseData?.licenseClasses
                          ? driverLicense.licenseData.licenseClasses.map(
                                licenseClass => ({
                                    name: licenseClass.name ?? '',
                                    expiryDate: licenseClass.expiryDate
                                        ? moment(licenseClass.expiryDate.date)
                                        : null,
                                }),
                            )
                          : [],
                      selectedClasses: driverLicense.licenseData?.licenseClasses
                          ? driverLicense.licenseData.licenseClasses.map(
                                licenseClass => ({
                                    value: licenseClass.name,
                                    label: licenseClass.name,
                                }),
                            )
                          : [],
                      restrictions:
                          driverLicense.licenseData?.restrictions ?? '',
                  }
                : {
                      classes: [],
                      selectedClasses: [],
                      restrictions: '',
                  },
        [driverLicense],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        isValid,
        dirty,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSaving(true);

            try {
                await updateDriverLicenseBackData(
                    driverLicenseControl.id,
                    {
                        classes: values.classes,
                        restrictions: values.restrictions,
                    },
                    source.token,
                );

                await onConfirm();

                resetForm({
                    values,
                });

                setIsSaving(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsSaving(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        },
    });

    useEffect(() => {
        if (onDirty) {
            onDirty(dirty);
        }
    }, [onDirty, dirty]);

    const handleSelectedClassesChange = (fieldName, options) => {
        setFieldValue(fieldName, options);

        const selectedClassNames = options
            ? options.map(option => option.value)
            : [];

        const newClasses = values.classes.filter(licenseClass =>
            selectedClassNames.includes(licenseClass.name),
        );

        selectedClassNames.forEach(className => {
            const index = newClasses.findIndex(
                licenseClass => licenseClass.name === className,
            );

            if (index === -1) {
                newClasses.push({
                    name: className,
                    expiryDate: null,
                });
            }
        });

        setFieldValue('classes', newClasses);
    };

    const today = moment();

    return (
        <Layout>
            {!!title && <Title>{title}</Title>}
            <Content>
                <Form onSubmit={handleSubmit}>
                    <LeftColumn>
                        <FieldSet>
                            <FieldSetTitle>
                                Klassen
                                <LicensePreview path={path} />
                            </FieldSetTitle>
                            <FormField>
                                <Select
                                    label="Wählen Sie Klassen aus"
                                    id="selectedClasses"
                                    name="selectedClasses"
                                    error={errors.selectedClasses}
                                    touched={touched.selectedClasses}
                                    value={values.selectedClasses}
                                    setFieldValue={handleSelectedClassesChange}
                                    onBlur={handleBlur}
                                    options={licenseClassOptions}
                                    isMulti
                                    isSearchable={false}
                                    isClearable
                                />
                            </FormField>
                            <FieldSetRow>
                                {!!values.classes && values.classes.length > 0 && (
                                    <LicenseClassesFieldSet>
                                        <LicenseClassField isHeading>
                                            <LicenseClassNameLabel>
                                                Ziff. 9
                                            </LicenseClassNameLabel>
                                            <LicenseClassDateLabel>
                                                Gültig bis (Ziff. 11)
                                            </LicenseClassDateLabel>
                                        </LicenseClassField>
                                        <LicenseClassField isHeading>
                                            {values.classes.length > 1 && (
                                                <>
                                                    <LicenseClassNameLabel>
                                                        Ziff. 9
                                                    </LicenseClassNameLabel>
                                                    <LicenseClassDateLabel>
                                                        Gültig bis (Ziff. 11)
                                                    </LicenseClassDateLabel>
                                                </>
                                            )}
                                        </LicenseClassField>
                                        {values.classes.map(
                                            (licenseClass, index) => (
                                                <LicenseClassField
                                                    key={licenseClass.name}
                                                >
                                                    <LicenseClassName>
                                                        {licenseClass.name}
                                                    </LicenseClassName>
                                                    <LicenseClassDate>
                                                        <SingleDatePicker
                                                            id={`classes[${index}].expiryDate`}
                                                            name={`classes[${index}].expiryDate`}
                                                            error={get(
                                                                errors,
                                                                `classes[${index}].expiryDate`,
                                                            )}
                                                            touched={get(
                                                                touched,
                                                                `classes[${index}].expiryDate`,
                                                            )}
                                                            value={get(
                                                                values,
                                                                `classes[${index}].expiryDate`,
                                                            )}
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                            isOutsideRange={date =>
                                                                isExpiryDateOutsideRange(
                                                                    date,
                                                                    driverLicense,
                                                                    licenseClass.name,
                                                                )
                                                            }
                                                            initialVisibleMonth={() =>
                                                                get(
                                                                    values,
                                                                    `classes[${index}].expiryDate`,
                                                                ) ?? today
                                                            }
                                                        />
                                                    </LicenseClassDate>
                                                </LicenseClassField>
                                            ),
                                        )}
                                    </LicenseClassesFieldSet>
                                )}
                            </FieldSetRow>
                        </FieldSet>
                    </LeftColumn>
                    <RightColumn>
                        <FieldSet>
                            <FieldSetTitle>
                                Schlüsselzahlen (Ziff. 12)
                            </FieldSetTitle>
                        </FieldSet>
                        <FormField>
                            <RestrictionsTextarea
                                label="Beschränkungen (falls vorhandene)"
                                id="restrictions"
                                name="restrictions"
                                error={errors.restrictions}
                                touched={touched.restrictions}
                                value={values.restrictions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                resize="none"
                            />
                        </FormField>
                    </RightColumn>
                </Form>
            </Content>
            <ToolbarButtons>
                <ButtonsWrapper>
                    <Button
                        disabled={isSaving || !isValid}
                        onClick={handleSubmit}
                    >
                        {isSaving ? <Loader size={24} /> : <CheckSharp />}
                        {confirmButtonText}
                    </Button>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    {!!onBackButtonClick && (
                        <Button
                            icon={ArrowBackSharp}
                            onClick={onBackButtonClick}
                        >
                            Zurück
                        </Button>
                    )}
                    <LinkButton
                        disabled={isSaving}
                        to="/measures/vehicles/driver-licenses"
                    >
                        Abbrechen
                    </LinkButton>
                </ButtonsWrapper>
            </ToolbarButtons>
            <Prompt
                when={dirty}
                message="Möchten Sie die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren."
            />
        </Layout>
    );
};

DriverLicenseBackDataForm.defaultProps = {
    title: null,
    confirmButtonText: 'Speichern',
    onBackButtonClick: null,
    onDirty: null,
};

DriverLicenseBackDataForm.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    title: PropTypes.string,
    confirmButtonText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    onDirty: PropTypes.func,
};

export default DriverLicenseBackDataForm;
