import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(RouterLink)`
    color: ${colors.WHITE};
    text-decoration: none;

    &:hover {
        color: ${colors.ORANGE};
    }

    ${ifProp(
        '$isDisabled',
        css`
            cursor: not-allowed;
            color: ${colors.LIGHT_GRAY};
        `,
    )}
`;

const Link = ({ icon: Icon, isDisabled, to, children, ...props }) => {
    const hasIcon = !!Icon;

    return (
        <StyledLink
            $isDisabled={isDisabled}
            to={isDisabled ? null : to}
            {...props}
        >
            {hasIcon && <Icon size="xs" />}
            {children}
        </StyledLink>
    );
};

Link.defaultProps = {
    isDisabled: false,
};

Link.propTypes = {
    isDisabled: PropTypes.bool,
};

export default Link;
