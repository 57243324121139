import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import LinkButton from 'Common/components/LinkButton';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    background: ${colors.DARK_GRAY};
    text-align: center;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const CongratulationsMessage = styled.div`
    margin-bottom: 20px;
    color: ${colors.ORANGE};
    font-size: 18px;
    line-height: 1.5em;

    @media screen and (min-width: 940px) {
        font-size: 22px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > a,
    > button {
        width: auto;

        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const CompletedPresentationScreen = ({
    assignment,
    onViewPresentationAgain,
}) => (
    <Wrapper>
        <CongratulationsMessage>
            Jetzt können Sie mit dem Test beginnen.
        </CongratulationsMessage>
        <ButtonWrapper>
            <LinkButton to={`/education/assignment/exam/${assignment.id}`}>
                Zum Test
            </LinkButton>
            <Button type="button" onClick={onViewPresentationAgain}>
                Präsentation erneut anzeigen
            </Button>
        </ButtonWrapper>
    </Wrapper>
);

CompletedPresentationScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onViewPresentationAgain: PropTypes.func.isRequired,
};

export default CompletedPresentationScreen;
