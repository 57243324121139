import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { VehiclesIcon } from 'Common/components/icons';
import VehicleMeasures from 'Measure/components/vehicle/VehicleMeasures';
import VehicleMeasuresProgress from 'Measure/components/vehicle/VehicleMeasuresProgress';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';

const Layout = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 860px) {
        flex-direction: row;
    }
`;

const Sidebar = styled.div`
    > * {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 860px) {
        order: 1;
        width: 300px;
        margin-right: 10px;
    }
`;

const Content = styled.div`
    margin-bottom: 10px;
    font-size: 0;

    @media screen and (min-width: 860px) {
        order: 2;
        flex: 1;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1180px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const VehiclesTile = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    background: ${colors.DARK_GRAY};
    color: ${colors.WHITE};
    font-size: 16px;
    border-radius: 4px;

    i {
        width: 42px;
        height: 42px;
        font-size: 42px;
        margin-right: 10px;
        color: ${colors.ORANGE};
    }

    @media screen and (min-width: 425px) {
        font-size: 20px;

        i {
            width: 52px;
            height: 52px;
            font-size: 52px;
            margin-right: 15px;
        }
    }

    @media screen and (min-width: 860px) {
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        i {
            width: 42px;
            height: 42px;
            font-size: 42px;
        }
    }
`;

const MobileVehiclesTile = styled(VehiclesTile)`
    display: flex;
    margin-bottom: 10px;

    @media screen and (min-width: 860px) {
        display: none;
    }
`;

const DesktopVehiclesTile = styled(VehiclesTile)`
    display: none;

    @media screen and (min-width: 860px) {
        display: flex;
    }
`;

const VehiclesDashboard = () => (
    <Layout>
        <Helmet>
            <title>Fuhrpark - Maßnahmen</title>
        </Helmet>
        <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
        <Breadcrumb isActive>Fuhrpark</Breadcrumb>
        <MobileVehiclesTile>
            <VehiclesIcon />
            Fuhrpark
        </MobileVehiclesTile>
        <Content>
            <VehicleMeasures />
        </Content>
        <Sidebar>
            <DesktopVehiclesTile>
                <VehiclesIcon />
                Fuhrpark
            </DesktopVehiclesTile>
            <VehicleMeasuresProgress />
        </Sidebar>
    </Layout>
);

export default VehiclesDashboard;
