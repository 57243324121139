import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
    ButtonsWrapper,
    Layout,
    Title,
    ToolbarButtons,
} from 'Measure/components/vehicle/driver-license/layout';
import Button from 'Common/components/Button';
import LinkButton from 'Common/components/LinkButton';
import controlMethods from 'Measure/constants/driverLicenseControlMethods';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import Loader from 'Common/components/Loader';
import createDriverLicense from 'Measure/api/driver-license/create/createDriverLicense';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { ArrowBackSharp, CheckSharp } from '@material-ui/icons';
import changeDriverLicenseControlMethod from 'Measure/api/driver-license/create/changeDriverLicenseControlMethod';
import reCreateDriverLicense from 'Measure/api/driver-license/create/reCreateDriverLicense';

const SideBySideContent = styled.div`
    background: ${colors.DARK_GRAY};
    padding: 20px;
    border-radius: 4px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 620px) {
        display: flex;
        min-height: 240px;
        padding: 0;
        background: none;

        > div {
            flex: 1;
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;
            padding: 20px;
            background: ${colors.DARK_GRAY};

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 940px) {
        min-height: 320px;

        > div {
            padding: 40px;
        }
    }
`;

const Content = styled.div`
    border-radius: 4px;

    @media screen and (min-width: 620px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ControlMethod = styled.button`
    width: 100%;
    min-height: 70px;
    padding: 20px;
    background: ${colors.DARKEST_GRAY};
    border: 1px solid ${colors.DARKEST_GRAY};
    color: ${colors.LIGHTEST_GRAY};
    cursor: pointer;
    border-radius: 4px;

    &:hover,
    &:focus {
        outline: 0 none;
        background: ${colors.ALT_DARKEST_GRAY};
    }

    &[disabled] {
        cursor: default;
    }

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.ALT_DARKEST_GRAY};
            border: 1px solid ${colors.DARKEST_GRAY};
        `,
    )};

    @media screen and (min-width: 620px) {
        height: 100%;
        max-width: 400px;
    }

    @media screen and (min-width: 940px) {
        max-height: 200px;
        padding: 40px;
    }
`;

const ControlMethodTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

const ControlMethodDescription = styled.div`
    margin-top: 20px;
    color: ${colors.ORANGE};
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
`;

const DriverLicenseControlMethodSelection = ({
    driverLicenseControl,
    onConfirm,
    shouldReCreate,
}) => {
    const [controlMethod, setControlMethod] = useState(() => {
        const driverLicense = driverLicenseControl.driverLicense;

        if (driverLicense && !shouldReCreate) {
            if (driverLicense.isUsingDirectControlMethod) {
                return 'DIRECT';
            }

            if (driverLicense.isUsingDistanceControlMethod) {
                return 'DISTANCE';
            }
        }

        return null;
    });
    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmButtonClick = async () => {
        if (controlMethod) {
            setIsConfirming(true);

            try {
                const driverLicense = driverLicenseControl.driverLicense;

                if (driverLicense && !shouldReCreate) {
                    const hasMethodChanged =
                        (driverLicense.isUsingDirectControlMethod &&
                            controlMethod !== 'DIRECT') ||
                        (driverLicense.isUsingDistanceControlMethod &&
                            controlMethod !== 'DISTANCE');

                    if (hasMethodChanged) {
                        await changeDriverLicenseControlMethod(
                            driverLicenseControl.id,
                            controlMethod,
                            source.token,
                        );
                    }
                } else if (shouldReCreate) {
                    await reCreateDriverLicense(
                        driverLicenseControl.id,
                        controlMethod,
                        source.token,
                    );
                } else {
                    await createDriverLicense(
                        driverLicenseControl.id,
                        controlMethod,
                        source.token,
                    );
                }

                await onConfirm(controlMethod);

                setIsConfirming(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsConfirming(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    const handleControlMethodClick = method => {
        setControlMethod(method);
    };

    return (
        <Layout>
            <Title>Bitte wählen Sie ein der Prüfmethoden</Title>
            <SideBySideContent>
                <Content>
                    <ControlMethod
                        disabled={controlMethod === controlMethods.DIRECT}
                        isSelected={controlMethod === controlMethods.DIRECT}
                        onClick={() =>
                            handleControlMethodClick(controlMethods.DIRECT)
                        }
                    >
                        <ControlMethodTitle>Direct</ControlMethodTitle>
                        {controlMethod === controlMethods.DIRECT && (
                            <ControlMethodDescription>
                                Hinweis: Die Prüfmethode Direct ist nicht
                                ausschließlich für EU-Kartenführerscheine aus
                                Deutschland geeignet!
                            </ControlMethodDescription>
                        )}
                    </ControlMethod>
                </Content>
                <Content>
                    <ControlMethod
                        disabled={controlMethod === controlMethods.DISTANCE}
                        isSelected={controlMethod === controlMethods.DISTANCE}
                        onClick={() =>
                            handleControlMethodClick(controlMethods.DISTANCE)
                        }
                    >
                        <ControlMethodTitle>Distance</ControlMethodTitle>
                        {controlMethod === controlMethods.DISTANCE && (
                            <ControlMethodDescription>
                                Hinweis: Die Prüfmethode Distance kann nur mobil
                                erstellte werden und setzt einen
                                EU-Kartenführerschein aus Deutschland voraus!
                            </ControlMethodDescription>
                        )}
                    </ControlMethod>
                </Content>
            </SideBySideContent>
            <ToolbarButtons>
                <ButtonsWrapper>
                    <Button
                        disabled={!controlMethod || isConfirming}
                        onClick={handleConfirmButtonClick}
                    >
                        {isConfirming ? <Loader size={24} /> : <CheckSharp />}
                        Auswahl übernehmen
                    </Button>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    <Button disabled icon={ArrowBackSharp}>
                        Zurück
                    </Button>
                    <LinkButton
                        disabled={isConfirming}
                        to="/measures/vehicles/driver-licenses"
                    >
                        Abbrechen
                    </LinkButton>
                </ButtonsWrapper>
            </ToolbarButtons>
        </Layout>
    );
};

DriverLicenseControlMethodSelection.defaultProps = {
    shouldReCreate: false,
};

DriverLicenseControlMethodSelection.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    shouldReCreate: PropTypes.bool,
};

export default DriverLicenseControlMethodSelection;
