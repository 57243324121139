import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    CreateTableBodyRow,
    DefaultTableBody,
    DefaultTableHead,
    Table,
    TableRowActionButton,
    TableRowActions,
} from 'Common/components/table';
import GroupInstructionAssignmentEditModal from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentEditModal';
import GroupInstructionAssignmentDeleteModal from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentDeleteModal';
import GroupInstructionAssignmentCreateModal from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentCreateModal';
import { presentationStatuses } from 'Education/constants/presentationStatuses';
import { EditIcon, TrashIcon } from 'Common/components/icons';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import colors from 'Common/constants/colors';
import styled from 'styled-components/macro';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const GroupInstructionAssignmentsTable = ({
    instructionAssignments,
    onAssignmentCreate,
    onAssignmentUpdate,
    onAssignmentDelete,
}) => {
    const [assignmentIdForEdit, setAssignmentIdForEdit] = useState(null);
    const [assignmentIdForDeletion, setAssignmentIdForDeletion] = useState(
        null,
    );
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const handleEditButtonClick = assignmentId => {
        setAssignmentIdForEdit(assignmentId);
    };

    const handleEditCancel = () => {
        setAssignmentIdForEdit(null);
    };

    const handleEditSuccess = async () => {
        await onAssignmentUpdate(assignmentIdForEdit);
        setAssignmentIdForEdit(null);
    };

    const handleDeleteButtonClick = assignmentId => {
        setAssignmentIdForDeletion(assignmentId);
    };

    const handleDeleteCancel = () => {
        setAssignmentIdForDeletion(null);
    };

    const handleDeleteSuccess = () => {
        if (assignmentIdForDeletion) {
            onAssignmentDelete(assignmentIdForDeletion);
            setAssignmentIdForDeletion(null);
        }
    };

    const handleCreateButtonClick = () => {
        setIsCreateModalOpened(true);
    };

    const handleCreateCancel = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateSuccess = async newInstructionAssignment => {
        await onAssignmentCreate(newInstructionAssignment);
        setIsCreateModalOpened(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Gruppenleiter',
                accessor: 'leader',
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Mitarbeiter',
                accessor: 'employees',
                disableSortBy: true,
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            instructionAssignments.map(assignment => {
                const hasStarted =
                    assignment.presentationStatus !==
                    presentationStatuses.NOT_STARTED;

                const assignmentDetails = assignment.groupAssignmentDetails;
                const groupLeader = assignmentDetails.groupLeader;

                return {
                    name: assignment.name,
                    leader: `${groupLeader.employee.firstName} ${groupLeader.employee.lastName}`,
                    employees: assignmentDetails.assignees
                        .map(
                            assignee =>
                                `${assignee.employee.firstName} ${assignee.employee.lastName}`,
                        )
                        .join(', '),
                    actions: (
                        <TableRowActions>
                            <TableRowActionButton
                                icon={EditIcon}
                                onClick={
                                    hasStarted
                                        ? undefined
                                        : () =>
                                              handleEditButtonClick(
                                                  assignment.id,
                                              )
                                }
                                title={
                                    hasStarted
                                        ? 'Kann nicht bearbeitet werden, da es bereits gestartet wurde'
                                        : undefined
                                }
                                disabled={hasStarted}
                            />
                            <TableRowActionButton
                                icon={TrashIcon}
                                onClick={() =>
                                    handleDeleteButtonClick(assignment.id)
                                }
                            />
                        </TableRowActions>
                    ),
                };
            }),
        [instructionAssignments],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['employees', 'leader', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <>
            {assignmentIdForEdit !== null && (
                <GroupInstructionAssignmentEditModal
                    assignmentId={assignmentIdForEdit}
                    onCancel={handleEditCancel}
                    onSuccess={handleEditSuccess}
                />
            )}
            {assignmentIdForDeletion !== null && (
                <GroupInstructionAssignmentDeleteModal
                    instructionAssignment={
                        assignmentIdForDeletion
                            ? instructionAssignments.find(
                                  assignment =>
                                      assignment.id === assignmentIdForDeletion,
                              )
                            : null
                    }
                    onCancel={handleDeleteCancel}
                    onSuccess={handleDeleteSuccess}
                />
            )}
            {isCreateModalOpened && (
                <GroupInstructionAssignmentCreateModal
                    isOpen={isCreateModalOpened}
                    onCancel={handleCreateCancel}
                    onSuccess={handleCreateSuccess}
                />
            )}
            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                    before={
                        <CreateTableBodyRow
                            onClick={handleCreateButtonClick}
                            columnsCount={visibleColumns.length}
                        >
                            Neue Präsenzunterweisung anlegen
                        </CreateTableBodyRow>
                    }
                />
            </Table>
        </>
    );
};

GroupInstructionAssignmentsTable.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    onAssignmentCreate: PropTypes.func.isRequired,
    onAssignmentUpdate: PropTypes.func.isRequired,
    onAssignmentDelete: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentsTable;
