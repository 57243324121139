import React, { useState } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { DownloadIcon } from 'Common/components/icons';
import {
    KeyboardArrowDownSharp,
    KeyboardArrowRightSharp,
} from '@material-ui/icons';

const Wrapper = styled.div`
    border-radius: 0 0 4px 4px;
    overflow: hidden;
`;

const DocumentBox = styled.div``;

const DocumentButton = styled.button`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    background: ${colors.DARK_GRAY};
    color: ${ifProp('isActive', colors.ORANGE, colors.LIGHT_GRAY)};
    border: 0 none;
    outline: 0;
    cursor: pointer;

    svg {
        margin-left: 10px;
    }
`;

const DocumentWrapper = styled.div``;

const DocumentName = styled.div`
    color: ${colors.LIGHTEST_GRAY};
    font-size: 14px;
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    color: ${colors.WHITE};
    border-radius: 50%;
    border: 1px solid ${colors.WHITE};

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background: ${colors.LIGHT_GRAY};
        position: absolute;
        top: 27px;
    }
`;

const DocumentItem = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
        ${IconWrapper} {
            &::after {
                display: none;
            }
        }
    }
    &:hover {
        ${DocumentName} {
            color: ${colors.ORANGE};
        }
        ${IconWrapper} {
            border-color: ${colors.ORANGE};
        }
    }
`;

const DriverLicenseControlDocumentsWrapper = styled.div`
    padding: 20px;
    background: ${colors.DARK_GRAY};
`;

const DriverLicenseControlDocuments = () => {
    const [isDossierBoxOpened, setIsDossierBoxOpened] = useState(false);

    const handleDossierButtonClick = () => {
        setIsDossierBoxOpened(
            prevIsDossierBoxOpened => !prevIsDossierBoxOpened,
        );
    };

    return (
        <Wrapper>
            <DocumentBox>
                <DocumentButton
                    isActive={isDossierBoxOpened}
                    onClick={handleDossierButtonClick}
                >
                    Dossier Führerscheinkontrolle
                    {isDossierBoxOpened ? (
                        <KeyboardArrowDownSharp />
                    ) : (
                        <KeyboardArrowRightSharp />
                    )}
                </DocumentButton>
                {isDossierBoxOpened && (
                    <DriverLicenseControlDocumentsWrapper>
                        <DocumentWrapper>
                            <DocumentItem
                                href="/documents/driver-license/Rechtsbelehrung.pdf"
                                target="_blank"
                            >
                                <IconWrapper>
                                    <DownloadIcon size="xs" />
                                </IconWrapper>
                                <DocumentName>Rechtsbelehrung</DocumentName>
                            </DocumentItem>

                            <DocumentItem
                                href="/documents/driver-license/Führerscheinkontrolle.pdf"
                                target="_blank"
                            >
                                <IconWrapper>
                                    <DownloadIcon size="xs" />
                                </IconWrapper>
                                <DocumentName>Muster</DocumentName>
                            </DocumentItem>

                            {/*<DocumentItem*/}
                            {/*    href="/documents/driver-license/Rechtsbelehrung.pdf"*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    <IconWrapper>*/}
                            {/*        <DownloadIcon size="xs" />*/}
                            {/*    </IconWrapper>*/}
                            {/*    <DocumentName>Hinweise</DocumentName>*/}
                            {/*</DocumentItem>*/}
                        </DocumentWrapper>
                    </DriverLicenseControlDocumentsWrapper>
                )}
            </DocumentBox>
        </Wrapper>
    );
};

DriverLicenseControlDocuments.defaultProps = {};

DriverLicenseControlDocuments.propTypes = {};

export default DriverLicenseControlDocuments;
