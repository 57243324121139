import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';

const mapInstructionAreasToOptions = instructionAreas =>
    instructionAreas.map(instructionArea => ({
        value: instructionArea.id,
        label: instructionArea.name,
    }));

const loadInstructionAreaOptions = async query => {
    const response = await getInstructionAreas();
    const instructionAreas = response.data;

    if (!query) {
        return mapInstructionAreasToOptions(instructionAreas);
    }

    const searchTerm = query.toLowerCase();

    return mapInstructionAreasToOptions(
        instructionAreas.filter(instructionArea =>
            instructionArea.name.toLowerCase().includes(searchTerm),
        ),
    );
};

export default loadInstructionAreaOptions;
