import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { CheckSharp, CloseSharp } from '@material-ui/icons';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: ${colors.DARKEST_GRAY};

    @media screen and (min-width: 580px) {
        width: 320px;
    }
`;

const Question = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: none;
    border: 0 none;
    color: ${colors.LIGHT_GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        background: ${colors.DARKEST_GRAY};
        outline: 0 none;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    ${ifProp(
        'isAnswered',
        css`
            color: ${colors.WHITE};

            &:hover,
            &:focus {
                color: ${colors.WHITE};
            }
        `,
    )}

    ${ifProp(
        'isActive',
        css`
            color: ${colors.ORANGE};

            &:hover,
            &:focus {
                color: ${colors.ORANGE};
            }
        `,
    )}
`;

const CorrectIcon = styled(CheckSharp)`
    flex: 0 0 24px;
    color: ${colors.GREEN};
    margin-right: 10px;
`;

const WrongIcon = styled(CloseSharp)`
    flex: 0 0 24px;
    color: ${colors.RED};
    margin-right: 10px;
`;

const Title = styled.div`
    flex: 1;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const QuestionsSidebarMenu = ({
    elementRef,
    questions,
    questionNumber,
    isLoading,
    isShowingMistakes,
    correctlyAnsweredQuestionNumbers,
    answeredQuestionNumbers,
    onQuestionNumberChange,
}) => (
    <Wrapper ref={elementRef}>
        {questions.map((question, index) => {
            const number = index + 1;
            const isActive = number === questionNumber;
            const isAnswered = answeredQuestionNumbers.includes(number);

            const isCorrect = correctlyAnsweredQuestionNumbers.includes(number);

            return (
                <Question
                    key={question.id}
                    isAnswered={isAnswered}
                    isActive={isActive}
                    disabled={isLoading}
                    onClick={
                        isLoading
                            ? undefined
                            : () => onQuestionNumberChange(number)
                    }
                >
                    {isShowingMistakes && isCorrect && <CorrectIcon />}
                    {isShowingMistakes && !isCorrect && <WrongIcon />}
                    <Title>{`${number}. ${question.title}`}</Title>
                </Question>
            );
        })}
    </Wrapper>
);

QuestionsSidebarMenu.propTypes = {
    questions: PropTypes.array.isRequired,
    questionNumber: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isShowingMistakes: PropTypes.bool.isRequired,
    correctlyAnsweredQuestionNumbers: PropTypes.array.isRequired,
    answeredQuestionNumbers: PropTypes.array.isRequired,
    onQuestionNumberChange: PropTypes.func.isRequired,
};

export default QuestionsSidebarMenu;
