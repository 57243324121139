import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { ExpandMore, Search } from '@material-ui/icons';
import Input from 'Common/components/form/fields/Input';
import Checkbox from 'Common/components/form/fields/Checkbox';
import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';
import PropTypes from 'prop-types';
import LinkToLibraryInstructions from 'Education/components/LinkToLibraryInstructions';
import {
    SidebarAccordionContent,
    SidebarAccordionPanel,
    SidebarAccordionTitle,
} from 'Common/components/layout';
import colors from 'Common/constants/colors';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';

const Wrapper = styled.div`
    padding: 20px 0;
    background-color: ${colors.DARK_GRAY};
    border-radius: 4px;

    > div {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 940px) {
        align-self: flex-start;
        position: sticky;
        top: 137px;
        width: 300px;
        flex: 0 0 300px;
        max-height: calc(100vh - 207px);
        margin-right: 10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;

const SearchWrapper = styled.div`
    margin-top: 35px;
    margin-bottom: 30px;
`;

const InstructionSidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedAreaIds,
    activeFilters,
    onCheckboxChange,
    onActiveFilterChange,
}) => {
    const instructionAreasRequest = useCallback(
        cancelToken => getInstructionAreas([], cancelToken),
        [],
    );

    const {
        data: instructionAreas,
        loadData: loadInstructionAreas,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAreasRequest, []);

    const handleSearchInputChange = event => {
        onSearchTermChange(event.target.value);
    };

    const [expandedPanel, setExpandedPanel] = useState(null);

    const handleChange = (panel, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : null);
    };

    return (
        <Wrapper>
            <LinkToLibraryInstructions />
            <SearchWrapper>
                <Input
                    name="search"
                    label="Suche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </SearchWrapper>

            <SidebarAccordionPanel
                expanded={expandedPanel === 'areas'}
                onChange={(e, isExpanded) => handleChange('areas', isExpanded)}
            >
                <SidebarAccordionTitle
                    aria-controls="panel-areas-content"
                    id="panel-areas-header"
                    expandIcon={<ExpandMore />}
                >
                    Bereiche
                    <Checkbox
                        name="area"
                        checked={activeFilters.includes('area')}
                        onClick={e => e.stopPropagation()}
                        onFocus={e => e.stopPropagation()}
                        onChange={e =>
                            onActiveFilterChange('area', e.target.checked)
                        }
                    />
                </SidebarAccordionTitle>
                <SidebarAccordionContent>
                    {isLoading ? (
                        <Loader size={80} />
                    ) : hasError ? (
                        <RetryLoading onRetry={loadInstructionAreas} />
                    ) : (
                        instructionAreas.map(instructionArea => (
                            <Checkbox
                                key={instructionArea.id}
                                label={instructionArea.name}
                                id={`instructionArea_${instructionArea.id}`}
                                name="instructionArea"
                                checked={selectedAreaIds.includes(
                                    instructionArea.id,
                                )}
                                onChange={e =>
                                    onCheckboxChange(
                                        instructionArea.id,
                                        e.target.checked,
                                    )
                                }
                            />
                        ))
                    )}
                </SidebarAccordionContent>
            </SidebarAccordionPanel>
        </Wrapper>
    );
};

InstructionSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    activeFilters: PropTypes.array.isRequired,
    selectedAreaIds: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    onActiveFilterChange: PropTypes.func.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
};

export default InstructionSidebar;
