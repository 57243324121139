import React, { useState } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import TileLink from 'Common/components/TileLink';
import {
    DriverLicenseControlIcon,
    UvvTuvIcon,
    VehicleContractIcon,
} from 'Common/components/icons';
// import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
// import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
// import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import HamburgerButton from 'Common/components/HamburgerButton';
import { useOnClickOutside } from 'crooks';
import ExternalTileLink from 'Common/components/ExternalTileLink';
// import ExternalLink from 'Common/components/ExternalLink';

const ContentModule = styled.div`
    position: relative;
    display: flex;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:first-child {
            width: 100%;
            margin-right: 0;
        }

        &:nth-child(3) {
            margin-right: 0;
        }
    }
`;

const ContentModuleLink = styled(TileLink)`
    flex: 1 1 auto;
    width: 100%;
    padding-right: 62px;

    @media screen and (min-width: 1180px) {
        padding-right: 0;
    }
`;

const ContentModuleExternalLink = styled(ExternalTileLink)`
    flex: 1 1 auto;
    width: 100%;
    padding-right: 62px;

    @media screen and (min-width: 1180px) {
        padding-right: 0;
    }
`;

const ModuleName = styled.span`
    @media screen and (min-width: 1180px) {
        flex: 1;
        padding-right: 87px;
        text-align: center;
    }
`;

const StyledHamburgerButton = styled(HamburgerButton)`
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);

    i {
        width: 32px;
        height: 32px;
        font-size: 32px;
        color: ${colors.LIGHTEST_GRAY};
    }

    &:hover {
        i {
            color: ${colors.WHITE};
        }
    }

    @media screen and (min-width: 1180px) {
        top: 10px;
        transform: none;
    }
`;

const HamburgerActionsMenu = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: 65px 45px 30px 20px;
    background-color: ${colors.DARKEST_GRAY};
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    text-align: left;
    font-size: 15px;
    z-index: 1;
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        > i,
        > svg {
            font-size: 18px !important;
            margin-right: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 500px) {
        max-width: 350px;
    }
`;

const menuIds = {
    DRIVER_LICENSES: 'DRIVER_LICENSES',
    ORDER_VEHICLE_MANAGEMENT: 'ORDER_VEHICLE_MANAGEMENT',
    UVV_TESTING: 'UVV_TESTING',
    VEHICLE_INSPECTION: 'VEHICLE_INSPECTION',
};

const VehicleMeasures = () => {
    const [openedMenuId, setOpenedMenuId] = useState(null);

    const handleHamburgerButtonClick = (e, menuId) => {
        e.stopPropagation();

        setOpenedMenuId(prevOpenedMenuId => {
            if (menuId === prevOpenedMenuId) {
                setOpenedMenuId(null);
            } else {
                setOpenedMenuId(menuId);
            }
        });
    };

    const handleClickOutsideActions = () => {
        setOpenedMenuId(null);
    };

    const remindersActionMenuRef = useOnClickOutside(
        handleClickOutsideActions,
        openedMenuId === null,
    );

    return (
        <>
            <ContentModule>
                <ContentModuleLink to="/measures/vehicles/driver-licenses">
                    <DriverLicenseControlIcon />
                    <ModuleName>Führerscheinkontrolle</ModuleName>
                </ContentModuleLink>
                {/*<StyledHamburgerButton*/}
                {/*    onClick={e =>*/}
                {/*        handleHamburgerButtonClick(e, menuIds.DRIVER_LICENSES)*/}
                {/*    }*/}
                {/*    isOpened={openedMenuId === menuIds.DRIVER_LICENSES}*/}
                {/*/>*/}
                {/*{openedMenuId === menuIds.DRIVER_LICENSES && (*/}
                {/*    <HamburgerActionsMenu ref={remindersActionMenuRef}>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <AccessTimeOutlinedIcon />*/}
                {/*            Erinnerung ausstehende Aufgaben*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ArchiveOutlinedIcon />*/}
                {/*            Alle Dokumente herunterladen*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ListAltOutlinedIcon />*/}
                {/*            Hinweise*/}
                {/*        </ExternalLink>*/}
                {/*    </HamburgerActionsMenu>*/}
                {/*)}*/}
            </ContentModule>
            <ContentModule>
                <ContentModuleExternalLink href="/backend/m/reminder/content?t=car">
                    <VehicleContractIcon />
                    <ModuleName>Auftrag Fahrzeugführung</ModuleName>
                </ContentModuleExternalLink>
                {/*<StyledHamburgerButton*/}
                {/*    onClick={e =>*/}
                {/*        handleHamburgerButtonClick(*/}
                {/*            e,*/}
                {/*            menuIds.ORDER_VEHICLE_MANAGEMENT,*/}
                {/*        )*/}
                {/*    }*/}
                {/*    isOpened={openedMenuId === menuIds.ORDER_VEHICLE_MANAGEMENT}*/}
                {/*/>*/}
                {/*{openedMenuId === menuIds.ORDER_VEHICLE_MANAGEMENT && (*/}
                {/*    <HamburgerActionsMenu ref={remindersActionMenuRef}>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <AccessTimeOutlinedIcon />*/}
                {/*            Erinnerung ausstehende Aufgaben*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ArchiveOutlinedIcon />*/}
                {/*            Alle Dokumente herunterladen*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ListAltOutlinedIcon />*/}
                {/*            Hinweise*/}
                {/*        </ExternalLink>*/}
                {/*    </HamburgerActionsMenu>*/}
                {/*)}*/}
            </ContentModule>
            <ContentModule>
                <ContentModuleExternalLink href="/backend/m/reminder/content?t=car">
                    <UvvTuvIcon />
                    <ModuleName>UVV/TÜV Prüfung</ModuleName>
                </ContentModuleExternalLink>
                {/*<StyledHamburgerButton*/}
                {/*    onClick={e =>*/}
                {/*        handleHamburgerButtonClick(e, menuIds.UVV_TESTING)*/}
                {/*    }*/}
                {/*    isOpened={openedMenuId === menuIds.UVV_TESTING}*/}
                {/*/>*/}
                {/*{openedMenuId === menuIds.UVV_TESTING && (*/}
                {/*    <HamburgerActionsMenu ref={remindersActionMenuRef}>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <AccessTimeOutlinedIcon />*/}
                {/*            Erinnerung ausstehende Aufgaben*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ArchiveOutlinedIcon />*/}
                {/*            Alle Dokumente herunterladen*/}
                {/*        </ExternalLink>*/}
                {/*        <ExternalLink href={null} isDisabled>*/}
                {/*            <ListAltOutlinedIcon />*/}
                {/*            Hinweise*/}
                {/*        </ExternalLink>*/}
                {/*    </HamburgerActionsMenu>*/}
                {/*)}*/}
            </ContentModule>
        </>
    );
};

export default VehicleMeasures;
