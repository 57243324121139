import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledCheckbox = styled(MaterialCheckbox)`
    &.MuiCheckbox-root {
        width: 22px;
        height: 22px;
        padding: 0;
        border: 1px solid ${colors.LIGHT_GRAY};
        border-radius: 2px;

        &:hover {
            border-color: ${colors.LIGHTER_GRAY};
        }

        &.Mui-focusVisible {
            border-color: ${colors.LIGHTER_GRAY};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
        }

        &.Mui-checked {
            border-color: ${colors.ORANGE};
            background-color: ${colors.ORANGE};
        }

        &.Mui-disabled {
            opacity: 0.7;
        }

        .MuiIconButton-label {
            color: ${colors.LIGHTER_GRAY};
        }
    }
`;

const StyledLabel = styled(Label)`
    margin-bottom: 0;
    padding-left: 10px;
    cursor: pointer;

    ${ifProp(
        'isDisabled',
        css`
            cursor: default;
        `,
    )}
`;

const Checkbox = ({ label, error, touched, className, ...props }) => {
    const hasError = !!error && touched;
    const hasLabel = label !== null;

    return (
        <Wrapper className={className}>
            <StyledCheckbox
                color="default"
                icon={<></>}
                checkedIcon={<></>}
                disableRipple
                {...props}
            />

            {hasLabel && (
                <StyledLabel
                    label={label}
                    hasError={hasError}
                    htmlFor={props.id}
                    isDisabled={props.disabled}
                />
            )}

            {hasError && <ErrorMessage message={error} />}
        </Wrapper>
    );
};

Checkbox.defaultProps = {
    label: null,
    error: null,
    touched: false,
};

Checkbox.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
};

export default Checkbox;
