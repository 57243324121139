import React from 'react';
import LinkToLibraryInstructions from 'Education/components/LinkToLibraryInstructions';
import Input from 'Common/components/form/fields/Input';
import { Search } from '@material-ui/icons';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    padding: 20px 0;
    background-color: ${colors.DARK_GRAY};
    border-radius: 4px;

    > div {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 940px) {
        align-self: flex-start;
        position: sticky;
        top: 137px;
        width: 300px;
        flex: 0 0 300px;
        max-height: calc(100vh - 207px);
        margin-right: 10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;

const SearchWrapper = styled.div`
    margin-top: 35px;
`;

const GroupInstructionAssignmentSidebar = ({
    searchTerm,
    onSearchTermChange,
}) => {
    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    return (
        <Wrapper>
            <LinkToLibraryInstructions />
            <SearchWrapper>
                <Input
                    name="search"
                    label="Suche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </SearchWrapper>
        </Wrapper>
    );
};

GroupInstructionAssignmentSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentSidebar;
