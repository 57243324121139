import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
    DefaultHiddenColumnProperties,
    DefaultTableBodyRow,
    DefaultTableHead,
    HiddenColumnsTableBodyCell,
    HiddenColumnsTableBodyRow,
    Table,
    TableBody,
    TableRowActions,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import { push } from 'connected-react-router';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/useClickableTableRow';
import colors from 'Common/constants/colors';
import { DocumentIcon } from 'Common/components/icons';
import { useDispatch } from 'react-redux';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import Button from 'Common/components/Button';
import measures from 'Measure/constants/measures';
import moment from 'moment-timezone';
import {
    CheckSharp,
    PeopleAltSharp,
    UpdateSharp,
    WarningSharp,
} from '@material-ui/icons';
import HamburgerButton from 'Common/components/HamburgerButton';
import ExternalLink from 'Common/components/ExternalLink';
import { useOnClickOutside } from 'crooks';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';

const ActionButton = styled(Button)`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`;

const Actions = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 260px;
    padding: 20px 45px 20px 20px;
    font-size: 15px;
    background-color: ${colors.DARKEST_GRAY};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 4px;

    > a,
    > button {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        i,
        svg {
            margin-right: 10px;
        }

        svg {
            width: 15px;
            height: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ExaminerIcon = styled(PeopleAltSharp)`
    color: ${colors.LIGHTER_GRAY};
`;

const PendingNotInitiatedIcon = styled(UpdateSharp)`
    color: ${colors.LIGHTER_GRAY};
`;

const PendingInProgressIcon = styled(UpdateSharp)`
    color: ${colors.ORANGE};
`;

const CompletedIcon = styled(CheckSharp)`
    color: ${colors.ORANGE};
`;

const OverdueIcon = styled(WarningSharp)`
    color: ${colors.ORANGE};
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 81px;

        @media screen and (min-width: 940px) {
            top: 137px;
        }
    }
`;

const MeasureTasksTable = ({ measureTasks, employeeId }) => {
    const dispatch = useDispatch();

    const [openedActionsMenuId, setOpenedActionsMenuId] = useState(null);

    const handleClickOutsideActions = () => {
        setOpenedActionsMenuId(null);
    };

    const actionsRef = useOnClickOutside(
        handleClickOutsideActions,
        openedActionsMenuId === null,
    );

    const handleHamburgerButtonClick = instructionId => {
        setOpenedActionsMenuId(prevOpenedActionsMenuId => {
            if (prevOpenedActionsMenuId === instructionId) {
                return null;
            }

            return instructionId;
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Aufgabe',
                accessor: 'name',
            },
            {
                Header: 'Recht',
                accessor: 'examiner',
                disableSortBy: true,
            },
            {
                Header: 'Absolvieren bis',
                accessor: 'dueDate',
                customCellStyles: {
                    minWidth: 150,
                },
                customBodyCellStyles: {
                    color: colors.LIGHTER_GRAY,
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            measureTasks.map(task => {
                let targetLink = null;

                switch (task.measure.value) {
                    case measures.DRIVER_LICENSE:
                        if (task.isExaminer) {
                            targetLink = '/measures/vehicles/driver-licenses';
                        } else {
                            if (employeeId !== null) {
                                targetLink = `/measures/vehicles/driver-license/overview/${task.entity?.id}`;
                            } else {
                                targetLink =
                                    '/measures/vehicles/driver-license/overview';
                            }
                        }
                        break;

                    case measures.VEHICLE_INSPECTION:
                    case measures.VEHICLE_MANAGEMENT:
                    case measures.UVV_TUV:
                        targetLink = '/backend/m/reminder/content?t=car';
                        break;

                    case measures.LADDER:
                    case measures.DGUV_REGULATION_3:
                    case measures.ELECTRIC_GATE:
                        targetLink = '/backend/m/reminder/content?t=ladder';
                        break;

                    case measures.PERSONAL_PROTECTIVE_EQUIPMENT:
                    case measures.PPE_FALL_EQUIPMENT:
                        targetLink = '/backend/m/reminder/content?t=psa';
                        break;

                    case measures.FIRST_AID:
                        targetLink = '/backend/m/reminder/content?t=aid';
                        break;

                    case measures.FIRE_EXTINGUISHER:
                    case measures.FIRE_PROTECTION_ASSISTANT:
                        targetLink = '/backend/m/reminder/content?t=fire';
                        break;

                    case measures.EC_SAFETY_DATA_SHEETS:
                        targetLink = '/backend/m/reminder/content?t=eg';
                        break;

                    case measures.REQUIREMENT_TO_CARRY_PERSONAL_ID:
                    case measures.TRANSMISSION_OF_ENTREPRENEURIAL_RESPONSIBILITIES:
                        targetLink = '/backend/m/reminder/content?t=other';
                        break;

                    default:
                        targetLink = null;
                        break;
                }

                let StatusIcon;
                let statusTitle;

                if (task.status === 'PENDING_NOT_INITIATED') {
                    StatusIcon = PendingNotInitiatedIcon;
                    statusTitle = 'Ausstehend (nicht initiiert)';
                }
                if (task.status === 'PENDING_IN_PROGRESS') {
                    StatusIcon = PendingInProgressIcon;
                    statusTitle = 'Ausstehend (in Bearbeitung)';
                }

                if (task.status === 'COMPLETED') {
                    StatusIcon = CompletedIcon;
                    statusTitle = 'Abgeschlossen';
                }

                if (task.status === 'OVERDUE') {
                    StatusIcon = OverdueIcon;
                    statusTitle = 'Überfällig';
                }

                const menuId = `${task.measure.value}_${
                    task.isExaminer ? 'examiner' : 'employee'
                }`;

                let areActionsOpened = openedActionsMenuId === menuId;

                const driverLicenseProofDocumentLink = isAuthorized([
                    roles.EMPLOYEE,
                ])
                    ? '/api/measures/vehicles/driver-licenses/controls/download-proof-document'
                    : `/api/measures/vehicles/driver-licenses/controls/${task.entity?.id}/download-proof-document`;

                return {
                    measure: task.measure.value,
                    name: task.measure.name,
                    examiner: task.isExaminer ? (
                        <div title="Prüfer">
                            <ExaminerIcon />
                        </div>
                    ) : (
                        ''
                    ),
                    dueDate: task.dueDate
                        ? moment(task.dueDate.date).format()
                        : '-',
                    status: StatusIcon ? (
                        <div title={statusTitle}>
                            <StatusIcon />
                        </div>
                    ) : (
                        '-'
                    ),
                    actions: task.measure.value === measures.DRIVER_LICENSE &&
                        !task.isExaminer &&
                        !task.entity.driverLicense?.isInCreationProcess && (
                            <TableRowActions ref={actionsRef}>
                                <HamburgerButton
                                    isOpened={areActionsOpened}
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleHamburgerButtonClick(menuId);
                                    }}
                                />
                                {areActionsOpened && (
                                    <Actions>
                                        <ExternalLink
                                            href={
                                                driverLicenseProofDocumentLink
                                            }
                                            target="_blank"
                                        >
                                            <DocumentIcon size="xs" />
                                            Dokument anzeigen
                                        </ExternalLink>
                                    </Actions>
                                )}
                            </TableRowActions>
                        ),
                    targetLink,
                    isClickable: targetLink !== null,
                };
            }),
        [actionsRef, employeeId, measureTasks, openedActionsMenuId],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
        onRowClick,
    } = useTable(
        {
            columns,
            data,
            onRowClick: row => {
                if (row.original.measure === measures.DRIVER_LICENSE) {
                    dispatch(push(row.original.targetLink));
                } else {
                    window.location.href = row.original.targetLink;
                }
            },
            hideColumnsPriority: ['dueDate', 'examiner', 'status', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useCustomCellStyles,
        useClickableTableRow,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    const hasHiddenColumns =
                        !!hiddenColumns && hiddenColumns.length > 0;

                    return (
                        <Fragment key={`${row.getRowProps().key}_fragment`}>
                            <DefaultTableBodyRow
                                row={row}
                                hasHiddenColumns={hasHiddenColumns}
                            />
                            {hasHiddenColumns && (
                                <HiddenColumnsTableBodyRow>
                                    <HiddenColumnsTableBodyCell
                                        colSpan={visibleColumns.length}
                                    >
                                        <ActionButton
                                            theme="dark"
                                            onClick={() => onRowClick(row)}
                                        >
                                            Zur Aufgabe
                                        </ActionButton>
                                        <DefaultHiddenColumnProperties
                                            row={row}
                                            hiddenColumns={hiddenColumns}
                                        />
                                    </HiddenColumnsTableBodyCell>
                                </HiddenColumnsTableBodyRow>
                            )}
                        </Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

MeasureTasksTable.defaultProps = {
    employeeId: null,
};

MeasureTasksTable.propTypes = {
    measureTasks: PropTypes.array.isRequired,
    employeeId: PropTypes.number,
};

export default MeasureTasksTable;
