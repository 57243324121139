import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { HamburgerIcon } from 'Common/components/icons';
import colors from 'Common/constants/colors';

const StyledButton = styled.button`
    background: none;
    border: 0 none;
    padding: 0;
    width: 38px;
    height: 38px;
    outline: 0 none;
    color: ${colors.LIGHTER_GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        color: ${colors.WHITE};
        outline: 0 none;
    }

    ${ifProp(
        'isOpened',
        css`
            position: relative;
            z-index: 2;
        `,
    )}
`;

// @TODO: Refactor "isOpened" to a more meaningful name like "isOnTopOfMenu" or something similar
const HamburgerButton = ({ size, isOpened, ...props }) => (
    <StyledButton type="button" isOpened={isOpened} {...props}>
        <HamburgerIcon size={size} />
    </StyledButton>
);

HamburgerButton.defaultProps = {
    size: 'sm',
    isOpened: false,
};

HamburgerButton.propTypes = {
    size: PropTypes.string,
    isOpened: PropTypes.bool,
};

export default HamburgerButton;
